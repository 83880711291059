import {SvgKeepRatio} from 'utils/style'

export default function GradebookIcon(props) {
  const fill= props.fill || "#fff"
  return (
    <SvgKeepRatio viewBox={{width: 19, height: 23}} {...props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(4, -2)">
                    <polygon stroke="#fff" transform="translate(7.142160, 9.330075) rotate(-180.000000) translate(-7.142160, -9.330075) " points="0 0.429939099 14.2843192 0.429939099 14.2843192 18.2302102 4.02397601 18.2302102 0 14.2148531"></polygon>
                    <path d="M14.2191963,0.5 L14.2191963,4.65817701 L10.0740534,0.673644527 L14.2191963,0.5 Z" stroke="#fff" transform="translate(12.146625, 2.579089) rotate(-180.000000) translate(-12.146625, -2.579089) "></path>
                </g>
        </g>
        <g stroke="none" strokeWidth="1" fill="#0a4986" fillRule="evenodd">
            <polygon stroke="#fff" transform="translate(7.142160, 9.330075) rotate(-180.000000) translate(-7.142160, -9.330075) " points="0 0.429939099 14.2843192 0.429939099 14.2843192 18.2302102 4.02397601 18.2302102 0 14.2148531"></polygon>
            <path d="M14.2191963,0.5 L14.2191963,4.65817701 L10.0740534,0.673644527 L14.2191963,0.5 Z" stroke="#fff" transform="translate(12.146625, 2.579089) rotate(-180.000000) translate(-12.146625, -2.579089) "></path>
            <text fontFamily="FontAwesome5Pro-Solid, Font Awesome 5 Pro" fontSize="11" fontWeight="800" letterSpacing="0.435416638" fill={fill}>
                <tspan x="3.12119715" y="15.6132404">A</tspan>
            </text>
            <text fontFamily="Helvetica-Bold, Helvetica" fontSize="4" fontWeight="bold" letterSpacing="0.237499985" fill={fill}>
                <tspan x="8.02593554" y="9.16794424">+</tspan>
            </text>
        </g>
    </SvgKeepRatio>
  )
}