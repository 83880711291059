import {Link} from 'external/react'
import {styled} from 'external/material'
import Icon from 'components/Common/Icon'

const StyledLink = styled(Link)(helpLinkStyles)

import useSession from 'hooks/session'
import { centerVertically } from 'utils/style'

export default function HelpLink () {
    const {navigation, languageId, pushHistory} = useSession()
    return (
    <StyledLink to={languageId ? navigation.page.link({pageName: 'help'}) : '#'} className="help" onClick={()=> pushHistory('help', true)}>
        <Icon name="faQuestionCircle" />
    </StyledLink>
    )
}

function helpLinkStyles() {
    return {
        ...centerVertically,
        color: '#69738e',
        cursor: 'pointer',
        marginRight: 10,
        fontSize: '1.9em',
        "&:hover": {
            transform: 'scale(1.04)'
        }
    }
}