import { useState } from 'external/react'
import {styled} from 'external/material'
import Icon from 'external/font-awesome'

import useTranslations from 'hooks/translations'
import ConfirmDialog from 'components/Common/ConfirmDialog'

const StyledDeleteUser = styled('div')(deleteUserStyles)

export default function DeleteUser(props) {

    const [open, setOpen] = useState(false)
    const {user, onDeleteUser} = props
    const {translate} = useTranslations()

    const contentText = translate('delete-user-text', user)

    function handleOpen() {
        setOpen(true)
    }
    function handleClose() {
        setOpen(false)
    }
    
    return (<StyledDeleteUser>
        <span onClick={handleOpen} title={contentText}><Icon name="frTrashCan"/></span>
        <ConfirmDialog
            open={open}
            title={translate('confirm-action')}
            contentText={contentText}
            confirmText={translate('delete-user-confirmation')}
            confirmLabel={translate('yes')}
            cancelLabel={translate('no')}
            onConfirm={() => {onDeleteUser(user)}}
            handleClose={handleClose}
        />
    </StyledDeleteUser>)

}

function deleteUserStyles() {
    return {
        color: '#d32f2f',
        cursor: 'pointer',
        fontSize: 18
    }
}