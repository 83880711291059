import { useState ,useEffect } from 'external/react'
import {Box, clsx} from 'external/material'
import { secondsToDate } from 'external/date-fns'

import useInterval from 'hooks/interval'

export default function Timer (props) {

  const {onExpiration, startsAt, className, format = formatCountDown, startWrapper, endWrapper} = props
  const [count, setCount] = useState(0)

  useInterval(() => {
    const newCount = count - 1
    setCount(newCount)
    if (newCount <= 0) {
      onExpiration && onExpiration()
    }
  }, count  > 0 ? 1000 : null)

  useEffect(()=>{
    if (startsAt) {
      const remaining = secondsToDate(startsAt)
      setCount(remaining)
    }
  }, [startsAt])

  return (
      <Box className={clsx("timer-wrapper", className)}>
        {startWrapper && (<Box className="start-wrapper">{startWrapper}</Box>)}
        <Box className="timer-container">
          {format(count)}
        </Box>
        {endWrapper && (<Box className="end-wrapper">{endWrapper}</Box>)}
      </Box>
  )  
}

function formatCountDown (time) {
  const hours = Math.floor(time / 3600).toString().padStart(2,0)
  const minutes = Math.floor((time % 3600) / 60).toString().padStart(2,0)
  const seconds = Math.floor(time % 60).toString().padStart(2,0)
  return `${hours}:${minutes}:${seconds}`
}
