import {styled, Box} from 'external/material'
import Event from '../Event'
import {take} from 'external/lodash'
import moment from 'external/moment'
import useSession from 'hooks/session'

import { changeDate } from '../Toolbar'
import useAdmin from 'hooks/admin'

const StyledDay = styled(Box)(dayStyles)

export default function Day (props) {
  const {navigation} = useSession()
  const {eventsByDay} = useAdmin()
  const {date, daySize} = props
  const day = moment(date).format('YYYY-MM-DD')
  const isToday = moment(date).isSame(new Date(), 'day')
  const className=`day ${isToday ? 'current' : ''}`
  const displayCount = 3
  const events = eventsByDay[day] || []
  return (
    <StyledDay
      className={className}
      style={daySize}
      onClick={() => changeDate(navigation, 'day')(day)}
    >
      <div className="events">
        {take(events, displayCount).map((props, i) => (<Event key={i} {...props}/>))}
      </div>
    </StyledDay>
  )
}

function dayStyles() {
  return {
    "&.day": {
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      borderLeft: 'solid 1px #e1eaee',
      "&.current": {
        backgroundColor: 'rgba(228,215,215,0.9)'
      },
      "&:first-of-type": {
        borderLeft: 'none'
      }
    }
  }
}
