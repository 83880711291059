import {styled, Box} from 'external/material'

import useCatalog from 'hooks/catalog'
import useTranslations from 'hooks/translations'
import {homeworkColor, assessmentColor, adminColor} from 'settings'

const StyledEvent = styled(Box)(eventStyles)

export default function Event (props) {
  const {subjects = {}} = useCatalog()
  const {translate} = useTranslations()

  const title = props.type == 'comment'
    ? translate('comment')
    : props.type == 'admin-comment'
      ? translate('admin-comment') 
      : props.content.title || props.title

  const isAssessment = ['quiz', 'a.assignment'].indexOf(props.type) >= 0
  const isHomework = props.type == 'homework'
  const isAdminComment = props.content.source == 'admin'

  const subject = subjects[props.content.subject] || {}

  const backgroundColor = isAssessment
    ? assessmentColor
    : isHomework
      ? homeworkColor
      : isAdminComment
        ? adminColor 
        : subject.color

  return (
    <StyledEvent
        className="event"
        style={{
          color: isAdminComment ? '#fff' : subject.textColor,
          backgroundColor,
        }}
      >
        <label title={title || ''}>{title}</label>
        <div className="flex spacer" />
    </StyledEvent>)
}

function eventStyles() {
  return {
    "&.event": {
      fontSize: '10pt',
      fontFamily: 'monospace',
      borderRadius: '8px',
      padding: '5px 10px 2px 10px',
      marginLeft: 5,
      marginRight: 5,
      marginTop: '5px',
      display: 'flex',
      "& label": {
        flexGrow: 1,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        textAlign: 'left'
      }
    },
  }
}
