import { useState } from 'external/react'
import {styled} from 'external/material'
import Icon from 'external/font-awesome'

import useTranslations from 'hooks/translations'
import ConfirmDialog from 'components/Common/ConfirmDialog'

const StyledDeleteGroup = styled('div')(deleteGroupStyles)

export default function DeleteGroup(props) {

    const [open, setOpen] = useState(false)
    const {group, onDeleteGroup} = props
    const {translate} = useTranslations()

    const contentText = translate('delete-group-text', group)

    function handleOpen() {
        setOpen(true)
    }
    function handleClose() {
        setOpen(false)
    }
    
    return (<StyledDeleteGroup>
        <span onClick={handleOpen} title={contentText}><Icon name="frTrashCan"/></span>
        <ConfirmDialog
            open={open}
            title={translate('confirm-action')}
            contentText={contentText}
            confirmText={translate('delete-group-confirmation')}
            confirmLabel={translate('yes')}
            cancelLabel={translate('no')}
            onConfirm={() => {onDeleteGroup(group)}}
            handleClose={handleClose}
        />
    </StyledDeleteGroup>)

}

function deleteGroupStyles() {
    return {
        color: '#d32f2f',
        cursor: 'pointer',
        fontSize: 18
    }
}