import {styled, Box, ButtonBase, Typography} from 'external/material'
import {logout} from 'utils/users'

import useTranslations from 'hooks/translations'

const StyledLogoutButton = styled(Box)(logoutButtonStyles)

export default function LogoutButton(props) {
  const {translate} = useTranslations()
  return (
  <StyledLogoutButton {...props}>
    <Typography variant="caption" component={ButtonBase} disableRipple onClick={logout}>
        {translate('logout')}
    </Typography>
  </StyledLogoutButton>)
}

function logoutButtonStyles ({theme}) {
  return {
    "& .MuiButtonBase-root": {
      color: theme.palette.text.primary,
      borderRadius: 50,
      padding: '1px 13px',
      [theme.breakpoints.up('desktop')]: {
        padding: '2px 20px'
      },
      '&:hover': {
          color: '#69738eb0',
          backgroundColor: '#f3f2f2'
      },
      '&:active': {
          color: '#69738e',
      }
    }
  }
}