import {styled, Table, TableHead, TableRow, TableBody, TableCell} from 'external/material'

import useAdmin from 'hooks/admin'
import useTranslations from 'hooks/translations'

import Row from './Row'

const StyledPreviewTable = styled(Table)(previewTableStyle)

export default function PreviewTable() {
  const {importUsers} = useAdmin()
  const {translate} = useTranslations()
  const {importedUsers, searchUser} = importUsers

  return (
    <StyledPreviewTable size="small">
      <TableHead>
        <TableRow className='head'>
          {importedUsers.length > 0 && Object.keys(importedUsers[0]).map(header => 
            <TableCell key={header}>
              {translate(`${header}-header`)}
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {searchUser.length > 0
          ? searchUser.map((user, index) => ( <Row key={index} user={user} /> ))
          : importedUsers.map((user, index) => ( <Row key={index} user={user} /> ))
        }
      </TableBody>
    </StyledPreviewTable>
  )
}

function previewTableStyle() {
  return {
    overflow: 'auto',
    '& .head': {
      borderTop: 'solid 1px #d2d2d2',
    },
  }
}