import Icon from 'components/Common/Icon'
import {ToggleButton, ToggleButtonGroup, styled} from 'external/material'
import useTranslations from 'hooks/translations'

const validFormats = ["xlsx", "csv"]
const formatIcons = {xlsx: 'faFileExcel', csv: 'faFileCsv'}

const StyledTogleExportFormat = styled(ToggleButtonGroup)(toogleExportFormats)

export default function ToggleExportFormat(props) {
  const {translate} = useTranslations()
  const {format, handleFormatChange} = props
  return (
    <StyledTogleExportFormat
      color="primary"
      value={format}
      exclusive
      onChange={(_, format) => handleFormatChange(format)}
      aria-label="Platform"
    >
      {validFormats.map((value, index) => (
        <ToggleButton className="button" key={index} value={value}>
          <span className="label">{translate(`export-format-${value}`)}</span>
          <span className="icon"><Icon name={formatIcons[value]} /></span>
        </ToggleButton>
      ))}
    </StyledTogleExportFormat>
  )
}

function toogleExportFormats() {
  return {
    "& .button": {
      padding: "5px 15px",
      "& .label": {
        textTransform: 'capitalize',
        marginRight: 8,
        fontSize: '12pt'
      },
      "& .icon": {
        fontSize: '18px'
      }    
    },
  }
}