import {styled, Box} from 'external/material'
import { centerVertically } from 'utils/style'
import {adminColor} from 'settings'
import useTranslations from 'hooks/translations'

const StyledAdminBand = styled(Box)(adminBandStyles)

export default function AdminBand (props) {

  const {translate} = useTranslations()

  return (
    <StyledAdminBand className="group">
      <div className="color-band" style={{backgroundColor: adminColor, color: '#fff'}} />
      <div className="title">
       <div className="name">
          <label>{translate('admin-comment')}:</label>
        </div>
      </div>
    </StyledAdminBand>
  )
}

function adminBandStyles() {
  return {
    "&.group": {
      flexShrink: 0,
      flexGrow: 0,
      display: 'flex',
      fontSize: '11pt',
      maxWidth: 200,
      minWidth: 200,
      "& .color-band": {
        width: 5,
        borderBottom: 'none'
      },
      "& > .title": {
        ...centerVertically,
        flexGrow: 1,
        borderBottom: 'solid 1px #e1eaee',
      },
      "& > div": {
        display: 'flex',
        padding: '4px 10px',
        "& label": {
          width: '175px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      }
    }
  }
}
