import {useState, useEffect, useLayoutEffect} from 'external/react'
import {styled, clsx} from 'external/material'
import Editor from 'external/jodit'

import useTranslations from 'hooks/translations'


const StyledEditorHtml = styled('div')(editableHtmlStyles)

export default function EditableHtml (props) {

  const {onChange, html} = props
  const {language} = useTranslations()
  const config = {
    uploader: {
      insertImageAsBase64URI: true
    },
    showPlaceholder: true,
    enter: "BR",
    disablePlugins: "wraptextnodes",
    toolbarAdaptive: false,
    showXPathInStatusbar: true,
    language: language,
    toolbarButtonSize: "small",
    buttons: "bold,strikethrough,underline,italic,|,symbol,superscript,subscript,image,|,ul,ol,|,outdent,indent,|,font,fontsize,brush,paragraph,|,,align,undo,redo,fullsize"
    ,...props.config
  }

  const [jodit, setJodit] = useState(undefined)
  const [ready, setReady] = useState(false)

  const parser = new DOMParser()

  function handleChange (html) {
    const doc = parser.parseFromString(html, "text/html")
    const treeWalker = doc.createTreeWalker(doc.body, NodeFilter.SHOW_TEXT)
    const nodeList = []
    let currentNode =  treeWalker.nextNode()
    while(currentNode) {
      nodeList.push(currentNode.textContent)
      currentNode = treeWalker.nextNode()
    }
    onChange && onChange(html, nodeList.join(' '))
  }

  function handleInit(jodit) {
    setJodit(jodit)
  }

  useLayoutEffect(() => {
    setReady(true)
  }, [])

  useEffect(() => {
    // if (jodit)
    //   jodit.setElementValue(html)
  }, [html])

  return (
      <StyledEditorHtml className="html-editor-root">
          <div className={clsx("editor", {ready})} >
            <Editor
              value={html}
              config={config}
              onInit={handleInit}
              onBlur={handleChange}
              onChange={_ => {}}
              />
          </div>
      </StyledEditorHtml>
  )
}


function editableHtmlStyles() {
  return {
    display: 'flex',
    "& .editor": {
      display: 'none',
      "&.ready": {
        display: 'flex',
        flexGrow: 1,
        "& .jodit-container": {
          flexGrow: 1,
          "& .jodit-wysiwyg": {
            "&:focus": {
              outline: '#3260c9 auto 1px'
            }
          }
        }
      }
    }
  }
}