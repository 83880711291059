import {styled, TableRow, TableCell, Avatar, clsx} from 'external/material'
import Icon from 'external/font-awesome'

import useTranslations from 'hooks/translations'

const StyledRow = styled(TableRow)(rowStyle)

export default function Row({ user }) {
  const {translate} = useTranslations()

  return (
    <StyledRow>
      {Object.entries(user).map(([key, value], idx) => {
        let cell = value || '-'
        if (key === 'email') {
          cell = value ? <Icon size="1x" name="faCheck" color="#66c571" /> : <Icon size="lg" name="faTimes" color="#f28888" />
        }
        if (key === 'role') {
          const roleInitial = translate(user.role)[0]
          cell = <Avatar className={clsx('avatar', {[value]: true})}>{roleInitial}</Avatar>
        }
        return <TableCell key={idx}>{ cell }</TableCell>
      })}
    </StyledRow>
  )
}

function rowStyle() {
  return {
    '& .avatar': {
      '&.student': {
        color: '#87425e',
        backgroundColor: '#efcbd6',
      },
      '&.teacher': {
        color: '#69653e',
        backgroundColor: '#efeccb',
      }
    },
  }
}