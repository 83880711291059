import {SvgKeepRatio} from 'utils/style'

export default function MonitoringIcon(props) {
  const fill= props.fill || "#fff"
  return (
    <SvgKeepRatio viewBox={{width: 37, height: 31}} {...props}>
      <g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path stroke={fill} d="M29,18 C31.5773288,18 34.4444444,15.5 36,13 C34.4444444,10.5 31.5773288,8 29,8 C26.4226712,8 23.5555556,10.5 22,13 C23.5555556,15.5 26.4226712,18 29,18 Z" />
        <circle stroke={fill} cx="29" cy="13" r="2" />
        <path d="M29,20 L30,20 L30,26 L21,26 L21,25 L29,25 L29,20 Z M1,25 L1,4 L29,4 L29,5 L30,5 L30,3 L0,3 L0,26 L10,26 L10,25 L1,25 Z M29,4 L29,5 L30,5 L30,3 L0,3 L0,26 L10,26 L10,25 L1,25 L1,4 L29,4 Z" fill={fill} fillRule="nonzero" />
        <rect stroke={fill} sx="0.5" y="0.5" width="29" height="3"></rect>
        <path stroke={fill} d="M11.5,30.5 L19.5,30.5 L19.5,25.5 C19.5,23.290861 17.709139,21.5 15.5,21.5 C13.290861,21.5 11.5,23.290861 11.5,25.5 L11.5,30.5 Z" />
        <circle stroke={fill} cx="15.5" cy="17.5" r="3.5" />
      </g>
    </SvgKeepRatio>
  )
}