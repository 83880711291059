

import {useMemo} from 'external/react'
import {styled} from 'external/material'
import { orderBy } from 'external/lodash'

import useTranslations from 'hooks/translations'
import useAdmin from 'hooks/admin'
import useCatalog from 'hooks/catalog'


import ClearableSelect from 'components/Common/ClearableSelect'

const StyledSelectCourse = styled('div')(selectCourseStyles)


export default function SelectCourse(props) {

    const {translate} = useTranslations()

    const {subject, course, onSubjectChange, onCourseChange, readonly} = props

    const {contractTerms, contractSubjects} = useAdmin()
    const {subjectsList} = useCatalog() 

    const subjects = useMemo(()=> {
        return orderBy(subjectsList
            .filter(s => contractSubjects[s.id])
            .map(s => ({text: s.name, value: s.id}))
        , ['id'])
    }, [subjectsList, contractSubjects])
    
    const courses = useMemo(()=> {
        const courses =  orderBy(subject == '' ? contractTerms : contractTerms.filter(c => subject == c.course.substring(0, 2)), ['course'])
        return courses.map(c => ({text: `${c.course.substring(0,4)}_${c.courseName}`, value: c.course}))
    }, [subject, contractTerms])
    
    const selectedSubject = useMemo(() => {
        if (!readonly) return
        return (subjects.find(s => s.value == subject) || {}).text || ""
    }, [course, subjects, readonly])

    const selectedCourse = useMemo(() => {
        if (!readonly) return
        return (courses.find(s => s.value == course) || {}).text || ""
    }, [subject, courses, readonly])
   
    return <StyledSelectCourse className={props.className}>
        <div className="field-container">
            <label>{translate('subject-label')}:</label>
            {!readonly && (<ClearableSelect
                caption={translate('subject-label') || ""}
                value={subject}
                onChange={onSubjectChange}
                items={subjects}
                className="subject"
            />)}
            {readonly && (<span className="subject">{selectedSubject}</span>)}
        </div>
        <div className="field-container">
            <label>{translate('course-label')}:</label>
            {!readonly && (
             <ClearableSelect
                caption={translate('course-label') || ""}
                value={course}
                onChange={onCourseChange}
                items={courses}
                className="course"
            />
            )}
            {readonly && (<span className="course">{selectedCourse}</span>)}
        </div>
    </StyledSelectCourse>

}

function selectCourseStyles() {
    return {
        display: 'flex',
        '& .subject': {
            width: 200,
        },
        '& .course': {
            width: '80%',
        },
        '& .field-container': {
            '& > span': {
                padding: '8.5px 14px',
                paddingLeft: 20,
                lineHeight: '1.4375em',
                cursor: 'default',
                fontSize: '1rem',
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                borderRadius: 5,
                letterSpacing: '0.00938em',
                border: 'solid 1px #bdbdbd',
                color: '#4c5263',
                fontWeight: 500
            },
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
        }
    }
}