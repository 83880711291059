import {styled} from 'external/material'

const StyledGroups = styled('div')(groupsStyles)

import FilterBar from './FilterBar'
import GroupsTable from './GroupsTable'

export default function Groups() {
  return (
      <StyledGroups>
        <FilterBar className="filters" />
        <GroupsTable className="groups"/>
      </StyledGroups>
  )
}

function groupsStyles() {
   return {
    display: 'flex',
    padding: 40,
    flexGrow: 1,
    overflowY: 'hidden',
    flexDirection: 'column',
    "& .filters": {
        flexShrink: 0,
        flexWrap: 'wrap'
    },
    "& .groups": {
        marginTop: 10,
        flexGrow: 1,
        overflowY: 'auto'
    }
   } 
}
