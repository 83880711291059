import {omit}  from 'external/lodash'
import {DatePicker as ReactDatePicker, forwardRef} from "external/react"
import {styled} from 'external/material'
import Icon from 'components/Common/Icon'

const StyledDateInput = styled('div')(DateInputStyles)

const DateInput = forwardRef((props, ref) => (
  <StyledDateInput {...omit(props, 'value')}>
    <Icon name="faCalendarAlt" />
    {props.value}
  </StyledDateInput>
))

export default function DatePicker(props) {

  const {
    date,
    setDate,
    minDate,
    maxDate,
    locale,
    format
  } = props

  return (
    <ReactDatePicker
      customInput={<DateInput className="date-input" />}
      selected={date}
      onChange={setDate}
      minDate={minDate}
      maxDate={maxDate}
      locale={locale}
      dateFormat={format}
    />
  )
}

function DateInputStyles(theme) {
  return {
    "& .date-input": {
      border: 'solid 1px #4F576E',
      borderRadius: 2,
      padding: 6,
      color: '#4f576e'
    },
    "& svg": {
      cursor: 'pointer',
      color: '#2c74bc',
      marginRight: 8,
      transition: '0.5s ease',
      "&:hover": {
        transform: "scale(1.1)",
        transition: '0.5s ease'
      }
    }
  }
}