import {viewerGuard, subdomainResource} from 'utils/url'

import useTranslations from 'hooks/translations'
import useProfile from 'hooks/profile'

export default function  ResourceRow ({kind, id}) {
  const {translate} = useTranslations()
  const {project} = useProfile()
  const name = part => `${kind}_${project}_${id}_${part}`
  const downloadLink = translate(name('download'))
  const href = /^http[s]*:\/\//.test(downloadLink)
    ? downloadLink
    : `//${subdomainResource('www', downloadLink)}`
      return (
        <tr className={kind}>
          <td className= "name">{translate(name('name'))}</td>
          <td className= "description">{translate(name('description'))}</td>
          <td className= "download"><a {...viewerGuard({target: "_blank", href})}>{translate('link')}</a></td>
        </tr>
      )
}