import {SvgKeepRatio} from 'utils/style'

export default function OverviewIcon(props) {
  const stroke = props.stroke || "#45C7FF"
  const fill = props.fill || "#D8D8D8"
  return (
    <SvgKeepRatio viewBox={{width: 17, height: 21}} {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M4.5,7.5 L12.5,7.5 L12.5,7.98627286 L12.9855317,7.5 L13,7.5 L12.9927604,7.49276036 L12.9992374,7.48627344 L12.9862932,7.48629319 L12.5,7 L12.5,7.4870349 L4.5,7.4992368 L4.5,7 L4.00000058,7.49999942 L3.99923738,7.50000058 L4.5,8 L4.5,7.5 Z" stroke={stroke} fill={fill}></path>
        <path d="M8.5,10.5 L13,10.5 L12.5,10 L12.5,11 L13,10.5 L8.5,10.5 Z M8.5,10.5 L4,10.5 L4.5,11 L4.5,10 L4,10.5 L8.5,10.5 Z" stroke={stroke} fill={fill}></path>
        <path d="M8.5,13.5 L13,13.5 L12.5,13 L12.5,14 L13,13.5 L8.5,13.5 Z M8.5,13.5 L4,13.5 L4.5,14 L4.5,13 L4,13.5 L8.5,13.5 Z" stroke={stroke} fill={fill}></path>
        <path d="M8.49249268,16.5 L12.9849854,16.5 L12.4850417,16.0075065 L12.5000564,17.0075065 L13,16.5 L8.49249268,16.5 Z M8.49249268,16.5 L4,16.5 L4.5,17 L4.5,16 L4,16.5 L8.49249268,16.5 Z" stroke={stroke} fill={fill}></path>
        <path d="M0,0 L17,0 L17,21 L5,21 L0,16 L0,0 Z M1,1 L1,15.5857864 L5.41421356,20 L16,20 L16,1 L1,1 Z" fill={stroke} fillRule="nonzero" transform="translate(8.500000, 10.500000) rotate(-180.000000) translate(-8.500000, -10.500000) "></path>
        <path d="M11,0 L17,0 L17,6 L11,0 Z M13.4142136,1 L16,3.58578644 L16,1 L13.4142136,1 Z" fill={stroke} fillRule="nonzero" transform="translate(14.000000, 3.000000) rotate(-180.000000) translate(-14.000000, -3.000000) "></path>
      </g>
    </SvgKeepRatio>
  )
}