import AddButton from 'components/Common/AddButton'
import {styled} from 'external/material'

import useTranslations from 'hooks/translations'
import useAdmin from 'hooks/admin'

const StyledAddComment = styled('div')(addCommentStyles)

export default function AddComment() {

    const {translate} = useTranslations()
    const {update} = useAdmin()

    const contentText = translate('add-comment') || ''

    async function handleOpen() {
        update({scheduleCommentForm: {open: {$set: true}, activeTab: {$set: 'students'}}})
    }
    
    return (<StyledAddComment>
        <AddButton onClick={handleOpen} title={contentText} label={contentText} />
    </StyledAddComment>)
}

function addCommentStyles() {
    return {
        color: '#2c75bd',
        cursor: 'pointer',
        fontSize: 18,
        "& .MuiButtonBase-root": {
            paddingTop: 8,
            paddingBottom: 8
        }
    }
}