import {useParams} from 'external/react'
import {
  styled,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from 'external/material'

import Icon from 'components/Common/Icon'
import MenuLink from 'components/Menu/MenuLink'
import useTranslations from 'hooks/translations'

const StyledMenuItem = styled(ListItem)(menuItemStyles)

export default function MenuItem (props) {
  const {page, href, isSmallFactor, theme, context, target, iconProps} = props
  const {languageId} = useParams()
  const {translate} = useTranslations()
  const height = props.height
    ? props.height
    : isSmallFactor
      ? theme.status.smallMenuHeight
      : theme.status.menuHeight
  return (
    <StyledMenuItem
      onClick={(href || !isSmallFactor) ? undefined : () => context.toggleMenu(false) && context.toggleSidebar(false)}
      component={MenuLink}
      disableGutters={true}
      dense={true}
      target={target}
      {...{...(href ? {href}: {to: `/${languageId}/${page}`})}}
     >
    <ListItemButton className="button" variant="menuItem">
      <ListItemIcon className="icon">
        <Icon {...iconProps} />
      </ListItemIcon>
      <ListItemText primaryTypographyProps={{variant: "menuItem"}} primary={translate(`${page}_title`)} />
    </ListItemButton>
  </StyledMenuItem>
  )
}

function menuItemStyles({theme}) {
  return {
    padding:0,
    borderLeft: "solid 5px none",
    '&:first-of-type': {
      marginTop: -5
    },
    "&:hover": {
      backgroundColor: theme.palette.active.background
    },
    "&.active-page": {
      backgroundColor: theme.palette.active.background,
      borderLeftStyle: 'solid',
      borderLeftColor: theme.palette.active.indicator,
      borderLeftWidth: 4,
      [theme.breakpoints.down('desktop')]: {
        borderWidth: 2
      }
    },
    "& .icon": {
      justifyContent: 'center',
      "& svg": {
        color: '#fff',
      }
    },
    "& .button": {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      paddingTop: 20,
      [theme.breakpoints.down('desktop')]: {
        paddingTop: 15
      },
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0)"
      }
    },
  }
}