import {Typography} from 'external/material'
import useSession from 'hooks/session'
import useTranslations from 'hooks/translations'
import LinkBehavior from 'components/Common/LinkBehavior'

export default function PageLanguage (props) {
  const {translate} = useTranslations()
  const {pageName, navigation, languageId} = useSession()
  const pageTitle = translate(`${pageName}_title`) || '...'
  const to = pageName && languageId ? navigation.page.link() : undefined
  document.title = pageTitle
  return (<Typography variant="subtitle" component={LinkBehavior} to={to} {...props}>{pageTitle}</Typography>)
}