import {merge, omit} from 'external/lodash'
import {createTheme, CardMedia, clsx} from 'external/material'

export function withClassName (prefix, suffix, props = {}) {
  const {classes = []} = props
  const className = clsx(`${prefix}-${suffix}`, ...classes)
  return {className, ...omit(props, 'classes')}
}

export function withComposableMixins(theme){
    return createTheme(theme, {mixins: {
        use: (...keys) => {
            const hasKey = keys.indexOf.bind(keys)
            return merge({},...Object.entries(theme.mixins)
                .filter(([key]) => hasKey(key) >= 0)
                .map(([_,value]) => value))
        }
    }})
}

export function mixStyle(styles, targets, mixer) {
  return Object.entries(styles).reduce((styles, [key, value]) => {
    const shouldMix = targets.indexOf(key) >= 0
    const shouldRecur = typeof(value) == 'object'
    if (shouldMix) return {...styles, ...mixer(value, key, styles)}
    else if (shouldRecur) return {...styles, [key]: mixStyle(value, targets, mixer)}
    else return {...styles, [key]: value}
  }, {})
}

export const centerVertically = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}

export const themedWidth = (theme, width, breakpoints = {}, direction = 'up') => {
  return  Object.entries(breakpoints).reduce((breakpointWidths, [breakpoint, width])=> {
    return theme.breakpoints.values[breakpoint]
      ? {...breakpointWidths, [theme.breakpoints[direction](breakpoint)]: forcedWidth(width)}
      : breakpointWidths
  }, {...forcedWidth(width)})
}

export const forcedWidth = (width) => ({
  width,
  minWidth: width,
  maxWidth: width
})

export const flexGrow = (flexGrow = 1) => ({flexGrow})

export function countBox(styles, elements = ['assessment', 'grade', 'lesson', 'homework']) {
  const colors = {
    assessment: { backgroundColor: '#a346cc' },
    grade: { backgroundColor: '#22c5cb' },
    lesson: { backgroundColor: '#ff47cb' },
    homework: { backgroundColor: '#f6910c' }
  }
  const colorStyles = elements.reduce((styles, element) => {
    return {...styles, [`&.${element}`]: colors[element]}
  }, {})

  return {
    "& .count": {
      ...centerVertically,
      textAlign: 'center',
      fontSize: '11.5pt',
      width: 45,
      color: '#fff',
      height: 22,
      borderRadius: 20,
      ...colorStyles,
      ...styles
    }
  }
}

export function pickAttributes (props, prefix) {
  const pattern = new RegExp(`^${prefix}`)
  return Object
    .entries(props)
    .filter(([key]) => pattern.test(key))
    .reduce((acc, [key, value]) => ({...acc, [key.replace(pattern, '')]: value}), {})
}

class Ratio {

  constructor({width, height, unit}) {
    this.unit = unit
    this.width = width
    this.height = height
    this.widthRatio = width / height
    this.heightRatio = height / width
  }

  size(width, height) {
    return {width: `${width}${this.unit}`, height: `${height}${this.unit}`, "viewBox": `0 0 ${this.width} ${this.height}`}
  }

  keep(width, height){
    return height
      ? this.size(Math.round(this.widthRatio * height), height)
      : width 
        ? this.size(width, Math.round(this.heightRatio * width))
        : this.size(this.width, this.height)
  }
}

export function createRatio(width, height, unit = 'px') {
  return new Ratio({width, height, unit})
}

export function SvgKeepRatio ({viewBox, width, height, children}) {
  const ratio = createRatio(viewBox.width, viewBox.height)
  return (<svg {...ratio.keep(width, height)}>{children}</svg>)
}

export function CardMediaWithRatio (props) {
  const {viewBox} = props
  const ratio = createRatio(viewBox.width, viewBox.height)
  const {width, height} = ratio.keep(props.width, props.height)
  return <CardMedia component="img" {...{...omit(props, 'width', 'height', 'viewBox'), width, height}} sx={{width, height}} />
}

export function customScroller() {
  return {
      "& ::-webkit-scrollbar": {
        width: 9,
        height: 8 
      },
      "& ::-webkit-scrollbar-track": {
        opacity: 0.3,
        backgroundColor: 'rgb(230 230 230 / 50%)',
        border: 'inset 1px #d2c7c726',
        borderRadius: 15
      },
      "& ::-webkit-scrollbar-thumb": {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        borderRadius: 5,
        border: 'outset 1px #726b6b26',
      },
      "& ::-webkit-scrollbar-button": {
        display: 'none'
      },
      "& ::-webkit-scrollbar-corner": {
        display: 'none'
      }
    }
}

export function border(color = 'black', width = '1px', type = 'solid') {
  return {border: `${type} ${color} ${width}`}
}