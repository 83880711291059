import {intersectionBy, isUUID, range} from 'external/lodash'
import moment from 'external/moment'
import create from 'utils/command'
import { dummyPassword } from 'settings'

import { paramsHook } from 'utils/hook'

const hooks =  [
  {paths: paramsHook('pageName', '?orderBy', '?page', '?pattern', '?role', '?categoryId', '?gradeId').concat(['refreshData', 'users/refreshes', 'usersPageSize']), resolves: [searchUsers]},
  {paths: paramsHook('pageName', '?orderBy', '?page', '?pattern', '?language', '?teacherId', '?subjectId', '?gradeId').concat(['refreshData', 'groups/refreshes', 'groupsPageSize']), resolves: [searchGroups]},
  {paths: paramsHook('pageName').concat(['refreshData', 'studentCategories/refreshes']), resolves: [getStudentCategories]},

  {paths: paramsHook('action', 'userId').concat(['refreshData']), resolves: [getUser]},

  {paths: ['refreshData', 'props/params/pageName'], resolves: [getDashboardActivity]},
  {paths: ['editGroupForm/open', 'addGroupForm/open', 'sendUsersInfo/open', 'props/params/pageName'], resolves: [getTeachersList]},
  {paths: ['editGroupForm/open', 'addGroupForm/open', 'props/params/pageName'], resolves: [getGroupsList]},
  {paths: ['props/params/teacherId', 'props/params/pageName', 'props/query/notDownloaded'], resolves: [getStudentLogs]},
  {paths: ['scheduleCommentForm/open', 'editGroupForm/open', 'addGroupForm/open', 'sendUsersInfo/open'], resolves: [getStudentsList]},
  {paths: ['editScheduleCommentForm/id'], resolves: [getEditScheduleComment]},

  {paths: ['refreshData', 'props/params/pageName', 'props/params/section'], resolves: [getLicenseCount]},
  {paths: ['refreshData', 'props/params/pageName', 'props/params/section'], resolves: [getExportUsers]},

  {paths: ['refreshEvents/', 'refreshData','props/params/pageName', 'props/query/clique', 'props/query/teacher', 'props/query/eventCategory','props/query/start', 'props/query/end', 'scheduleView' ], resolves: [fetchEvents]},
  {paths: ['refreshData', 'studentLog/refreshes', 'props/params/pageName', 'props/query/orderBy', 'props/query/page', 'props/query/pattern', 'studentLogPageSize/'], resolves: [searchStudentLog]},
  {paths: ['refreshData', 'scheduledMessages/refreshes', 'props/params/pageName', 'props/query/orderBy', 'props/query/page', 'props/query/pattern', 'props/query/language', 'scheduledMessagesPageSize'], resolves: [searchScheduledMessages]},
  {paths: ['refreshData', 'deletedMessages/refreshes', 'props/params/pageName', 'props/query/orderBy', 'props/query/page', 'props/query/pattern', 'props/query/language', 'deletedMessagesPageSize'], resolves: [searchDeletedMessages]},

  {paths: ['refreshData', 'contractTerms/refreshes'], resolves: [getContractTerms]},
  {paths: ['sendUsersInfo/activeTab'], resolves: [getPreview]},

  {paths: ['refreshData', 'props/params/pageName', 'props/params/section'], resolves: [getDownloadUsers]},
]

export {hooks as default}

export async function searchUsers({state, update}, {path}) {
  const {usersPageSize: pageSize, props: {query, params}} = state
  const {pageName} = params
  if (pageName != 'users') return
  // PageSize validation
  if (pageSize == 0) return
  if (!path || !path.startsWith('props/query'))
    update({users: {$initLoading: true}}) // Clear content unless is updating a query parameter
  else
    update({users: {$startLoading: true}})
  const users = await create('admin', 'searchUsers')({pageSize, ...query})
  update({users: {$updateLoadableList: users}})
}

export async function searchGroups({state, update}, {path}) {
  const {groupsPageSize: pageSize, props: {query, params}} = state
  const {pageName} = params
  if (pageName != 'groups') return
  // PageSize validation
  if (pageSize == 0) return
  if (!path || !path.startsWith('props/query'))
    update({groups: {$initLoading: true}}) // Clear content unless is updating a query parameter
  else
    update({groups: {$startLoading: true}})
  const groups = await create('admin', 'searchGroups')({pageSize, ...query})
  update({groups: {$updateLoadableList: groups}})
}

export async function getContractTerms({update}) {
  update({contractTerms: {$startLoading: true}})
  const contractTerms = await create('admin', 'getContractTerms')()
  update({contractTerms: {$updateLoadableList: contractTerms}})
}

export async function getStudentCategories({state, update}) {
  const {props: {params}} = state
  const {pageName} = params
  if (pageName != 'users') return
  update({studentCategories: {$startLoading: true}})
  const studentCategories = await create('admin', 'get_student_categories')()
  update({studentCategories: {$updateLoadableList: studentCategories}})
}

export async function getStudentsList({state, update}) {

  const {scheduleCommentForm, editGroupForm, addGroupForm, sendUsersInfo} = state

  const studentListForms = Object.entries({scheduleCommentForm, editGroupForm, addGroupForm, sendUsersInfo})

  const fetchStudentList = create('admin', 'getStudentList')
  
  let resetCommand = null
  if (editGroupForm.open) {
    resetCommand = {studentList: {$set: []}, checkedStudents: {$set: {}}}
  } else {
    resetCommand = {studentList: {$set: []}, selectedStudents: {$set: []}, checkedStudents: {$set: {}}}
  }
  
  studentListForms.forEach(async ([formName, form]) => {
    if (form.open) {
      update({[formName]: {loadingStudents: {$set: true}, ...resetCommand}})
      const studentList = await fetchStudentList()
      update({[formName]: {loadingStudents: {$set: false}, studentList: {$set: studentList}}})
    } else {
      update({[formName]: resetCommand})
    }
  })

}

export async function getEditScheduleComment({state, update}) {
  const {editScheduleCommentForm} = state
  const resetCommand = {studentList: {$set: []}, selectedStudents: {$set: []}, checkedStudents: {$set: {}}}
  if (editScheduleCommentForm.id) {
    update({scheduleCommentForm: {loadingStudents: {$set: true}, ...resetCommand}})
    const studentList = await create('admin', 'getStudentList')()
    const [scheduleComment] = await create('admin', 'getScheduleComment')({id: editScheduleCommentForm.id})
    if (scheduleComment) {
    update({editScheduleCommentForm: {loadingStudents: {$set: false},
      studentList: {$set: studentList},
      date: {$set: moment(scheduleComment.date).toDate()},
      message: {$set: scheduleComment.message},
      selectedStudents: {$set: intersectionBy(studentList,scheduleComment.selectedStudents, 'id')}
    }})
    }
  } else {
    update({editScheduleCommentForm: resetCommand})
  }
}

export async function getDashboardActivity({state, update}) {
  const {props: {params}} = state
  const {pageName} = params
  if (pageName != 'dashboard') return
  const [dashboardActivity = {}] = await create('admin', 'getDashboardActivity')()
  update({dashboardActivity: {$set: dashboardActivity}})
}

export async function getCourseLicenseCount({state, update}) {
}

export async function getSubjectLicenseCount({state, update}) {
}

export async function getLicenseCount({state, update}) {
  const {props: {params = {}}} = state
  const {pageName, section} = params
  if (pageName != 'settings') return
  if (section != 'license-count') return
  update({licenseCountForm: {licensesLoaded: {$set: false}, licenseCount: {$set: {}}}})
  const data = await create('admin', 'getLicenseCount')()
  const licenseCount = data.reduce((licenseCount, row) => {
    const subjectId = row.id.substring(0,2)
    const subjectRecord = licenseCount[subjectId] || (licenseCount[subjectId] = {used: 0, total: 0, courses: {}})
    subjectRecord.used += row.used

    if (!subjectRecord.courses.hasOwnProperty(row.id)) {
      subjectRecord.total += row.total
    }

    const courseRecord = subjectRecord.courses[row.id] || (subjectRecord.courses[row.id] = {used: 0, total: row.total, groups: {}})
    courseRecord.used += row.used
    courseRecord.courseName = row.courseName
    courseRecord.groups[row.group] = {id: row.group, name: row.name, used: row.used}
    return licenseCount
  }, {})
  update({licenseCountForm: {licensesLoaded: {$set: true}, licenseCount: {$set: licenseCount}}})
}

export async function getTeachersList({state, update}) {
  const {editGroupForm = {}, addGroupForm = {}, sendUsersInfo = {}, props: {params = {}}} = state
  const {pageName} = params
  if (['groups', 'calendar', 'monitoring', 'settings'].indexOf(pageName) < 0) return
  if (!sendUsersInfo.open && ['groups', 'calendar', 'monitoring'].indexOf(pageName) < 0) return

  update({teacherList: {$startLoading: true}})

  const fetchTeacherList = create('admin', 'getTeacherList')

  fetchTeacherList().then(teacherList => {
    update({teacherList: {$updateLoadableList: teacherList}})
  })

  const teacherListForms = Object.entries({editGroupForm, addGroupForm, sendUsersInfo})
  let resetCommand = null
  if (editGroupForm.open) {
    resetCommand = {teacherList: {$set: []}, checkedTeachers: {$set: {}}}
  } else {
    resetCommand = {teacherList: {$set: []}, selectedTeachers: {$set: []}, checkedTeachers: {$set: {}}}
  }
  
  teacherListForms.forEach(async ([formName, form]) => {
    if (form.open) {
      update({[formName]: {loadingTeachers: {$set: true}, ...resetCommand}})
      const teacherList = await fetchTeacherList()
      update({[formName]: {loadingTeachers: {$set: false}, teacherList: {$set: teacherList}}})
    } else {
      update({[formName]: resetCommand})
    }
  })

}

export async function getGroupsList({state, update}) {
  const {editGroupForm = {}, addGroupForm = {}, props: {params = {}}} = state
  const {pageName} = params
  if (['groups', 'calendar', 'monitoring', 'settings'].indexOf(pageName) < 0) return
  if (!editGroupForm.open && !addGroupForm.open && ['monitoring', 'settings'].indexOf(pageName) < 0) return
  update({groupList: {$startLoading: true}})
  const groupList = await create('admin', 'getGroupList')()
  update({groupList: {$updateLoadableList: groupList}})
}

export async function getStudentLogs({state, update}) {
  const {props: {query = {}, params = {}}} = state
  const {pageName, teacherId} = params
  if (pageName != 'monitoring') return
  if (!isUUID(teacherId)) return
  update({studentLogs: {$startLoading: true}})
  const studentLogs = await create('admin', 'getStudentLogs')({teacherId, ...query})
  update({studentLogs: {$updateLoadableList: studentLogs}})
}


export async function  fetchEventCategories({state, update}) {
  const shouldFetch = state.eventCategories.length == 0
  if (shouldFetch) {
    const eventCategories = await create('schedule', 'eventCategories')()
    update({eventCategories: {$set: eventCategories}})
  }
}

export async function fetchEvents ({state, update}, matches) {

  const {path} = matches
  const {props: {query, params}} = state
  if (['calendar', 'dashboard'].indexOf(params.pageName) < 0 || !params.languageId) return
  // Clear content unless is updating a query parameter
  if (!path || !path.startsWith('props/query')) update({events: {$set: []}, eventsByDay: {$set: {}}})

  const language = params.languageId
  const start = query.start && moment(query.start).startOf('day').toISOString()
  const end = query.end && moment(query.end).endOf('day').toISOString()
  const events = await create('admin', 'events')({language, ...query, start, end})
  const eventsByDay = events.reduce((days, event) => {
    const start = moment(event.start)
    range(Math.max(event.duration, 1)).forEach(i => {
      const day = moment(start).add(i,'days').format('YYYY-MM-DD') 
      days[day] = days[day] || [] 
      days[day].push(event)
    })
    return days
  }, {})
  update({events: {$set: events}, eventsByDay: {$set: eventsByDay}})
}

export async function searchStudentLog({state, update}, {path}) {
  const {studentLogPageSize: pageSize, props: {query, params}} = state
  const {studentId} = params
  if (!studentId) return
  // PageSize validation
  if (pageSize == 0) return
  if (!path || !path.startsWith('props/query'))
    update({groups: {$initLoading: true}}) // Clear content unless is updating a query parameter
  else
    update({groups: {$startLoading: true}})
  const studentLog = await create('admin', 'searchStudentLog')({studentId, pageSize, ...query})
  update({studentLog: {$updateLoadableList: studentLog}})
}

export async function searchDeletedMessages({state, update}, {path}) {
  const {deletedMessagesPageSize: pageSize, props: {query, params}} = state
  const {pageName, section} = params
  if (pageName != 'messages') return
  if (section != 'deleted') return
  // PageSize validation
  if (pageSize == 0) return
  if (!path || !path.startsWith('props/query'))
    update({deletedMessages: {$initLoading: true}}) // Clear content unless is updating a query parameter
  else
    update({deletedMessages: {$startLoading: true}})
  const deletedMessages = await create('admin', 'searchDeletedMessages')({pageSize, ...query})
  update({deletedMessages: {$updateLoadableList: deletedMessages}})
}

export async function searchScheduledMessages({state, update}, {path}) {
  const {scheduledMessagesPageSize: pageSize, props: {query, params}} = state
  const {pageName, section} = params
  if (pageName != 'messages') return
  if (section != 'scheduled') return
  // PageSize validation
  if (pageSize == 0) return
  if (!path || !path.startsWith('props/query'))
    update({scheduledMessages: {$initLoading: true}}) // Clear content unless is updating a query parameter
  else
    update({scheduledMessages: {$startLoading: true}})
  const scheduledMessages = await create('admin', 'searchScheduledMessages')({pageSize, ...query})
  update({scheduledMessages: {$updateLoadableList: scheduledMessages}})
}

export async function getExportUsers({state, update}) {
  const {props: {params = {}}} = state
  const {pageName, section} = params
  if (pageName != 'settings') return
  if (section != 'export-users') return
  update({exportUsers: {$startLoading: true}})
  const exportUsers = await create('admin', 'exportUsers')()
  update({exportUsers: {$updateLoadableList: exportUsers}})
}

export async function getPreview({state, update}) {
  const {sendUsersInfo = {}} = state
  if (sendUsersInfo.activeTab !== 'preview') return
  const payload = {
    Content: sendUsersInfo.content,
    SendPuk: sendUsersInfo.sendPuk,
    Language: sendUsersInfo.language,
  }
  update({sendUsersInfo: {loadingPreview: {$set: true}}})
  const preview = await create('admin', 'get_preview', {method: 'POST'})(payload)
  update({sendUsersInfo: {loadingPreview: {$set: false}, preview: {$set: preview[0]}}})
}

export async function getDownloadUsers({state, update}) {
  const {props: {params = {}}} = state
  const {pageName, section} = params
  if (pageName != 'settings') return
  if (section != 'export-users') return
  const users = await create('admin', 'download_users')()
  update({downloadUsers: {users: {$set: users}}})
}


export async function getUser({state, update}) {
  const {props: {params}} = state
  const {action, userId} = params
  if (action != 'edit' || !isUUID(userId)) return
  await update({editUserForm: {loading: {$set: true}}})
  const [serverUser] = await create('admin', 'getUser')({id: userId})
  const editableUser = {...serverUser, password: dummyPassword} 
  update({editUserForm: {user: {$set: editableUser}, loading: {$set: false}}})
   
}