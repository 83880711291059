export const apiDomain = process.env.EDUSYSTEM_DOMAIN
export const apiHost = process.env.EDUSYSTEM_API_HOST
export const apiProtocol = location.protocol.substring(0, location.protocol.length - 1) //process.env.EDUSYSTEM_API_PROTOCOL
export const environment = process.env.NODE_ENV
export const contentHost = process.env.EDUSYSTEM_CONTENT_HOST
export const converseDisabled = process.env.CONVERSE_DISABLED == 'true'
export const staticAssetsDomain = process.env.STATIC_ASSETS_DOMAIN

export const inViewer = navigator.userAgent.indexOf("EduSystemViewer") !== -1 || location.host.startsWith('localhost')
export const inWindowStore = navigator.userAgent.indexOf("EduSystemViewer-WindowsStore") !== -1

export const drawerWidth = 100;

export const courseItemHeight = 310;
export const courseItemWidth = 550;
export const courseItemMargin = 40;
export const maxCoursesPerRow = 3;

export const dashboardSidebarWidth = 250;
export const rowHeight = 100;
export const assessmentsRowHeight = 100;
export const homeworksRowHeight = 100;

export const dashboardActivityItemWidth = 270;
export const dashboardActivityItemMargin = 35;
export const dashboardActivityItemHeight = 390;

export const homeworkColor = '#93e2c6'
export const assessmentColor = '#c98571'
export const adminColor = '#1aa408'


export const pixelRatio = Math.max(window.devicePixelRatio ? window.devicePixelRatio : 1, 1)

export const desktop = Math.max(1280, Math.floor(1280 / pixelRatio))

export const pversion = process.env.CLIENT_VERSION || 'development'
export const version = `${pversion}:${window.devicePixelRatio}`

export const keepAliveLeeway = 60

export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const phoneRegex = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/

export const minEventHeight = 70
export const minEventWidth = 120

export const dummyPassword = '😭-dummy-dummy'