import {styled} from 'external/material'
import Icon from 'components/Common/Icon'
import FilterChip from 'components/Common/FilterChip'
import useSession from 'hooks/session'
import useAdmin from 'hooks/admin'
import { centerVertically } from 'utils/style'

const StyledStudentHeader = styled('div')(studentHeaderStyles)

export default function StudentHeader () {
    const {studentId} = useSession()
    const {studentRecords} =  useAdmin()
    const {name = ''} = studentRecords[studentId] || {}
    return (
      <StyledStudentHeader className="student-header">
        <div className="icon">
          <Icon name='faUserLarge' />
        </div>
        <div className="name">
          {name}
        </div>
        <div style={{flexGrow: 1}} />
        <div className='filter'>
          <FilterChip name="pattern" />
        </div>
      </StyledStudentHeader>
    )
}

function studentHeaderStyles(){
  return {
    display: 'flex',
    padding: '15px 20px',
    '& > div': {
      ...centerVertically,
      "&.icon": {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          fontSize: '18pt',
          color: '#78a7ff'
      },
      "&.name": {
          marginLeft: '20px',
          fontSize: '17pt'
      },
    }
  }
}