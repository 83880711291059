import {useState, useEffect, useMemo} from 'external/react'
import {mapValues} from 'external/lodash'
import {isEmpty} from 'utils/text'

export default function useValidations(validations, state) {

  const [errors, setErrors] = useState(mapValues(validations, () => ''))
  const [touched, setTouched] = useState(mapValues(validations, false))

  const errorCount = useMemo(()=> Object.values(errors).filter(e => e.length > 0).length, [errors])

  function validate (state, validations) {
    let valid = true
    const validationResults = mapValues(validations, (checks, name) => {

      if (!(touched[name])) return ''

      const error = checks.map(validation => validation(state)).find(result => result.length > 0)
      valid = valid && (error == undefined)
      return error || ''

    })
    setErrors({...validationResults})
    return valid
  }

  function resetErrors () {
    setErrors({...mapValues(validations, () => '')})
    setTouched({...mapValues(validations, () => false)})
  }
  
  useEffect(()=> {
    validate(state, validations)
  }, [state, validations, touched])
  
  function touchField(name) {
    return () => {
      if (!touched[name]) setTouched({...touched, [name]: true})
    }
  }

  function touchAll() {
    setTouched({...mapValues(validations, () => true)})
  }

  function allFieldsTouched() {
    return Object.values(touched).every(field => field && true)
  }

  return {errors, errorCount, validate, resetErrors, touchField, touchAll, allFieldsTouched}

}

export function notEmpty (name) {
  return state => isEmpty(state[name]) ? `${name}-is-empty` : ''
}

export function minLength (name, minLength) {
  return state => !state[name] || state[name].length < minLength ? `${name}-is-too-short` : ''
}

