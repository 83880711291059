import {omit} from 'external/lodash'
import {useContext, useNavigate} from 'external/react'
import Session from 'context/session-context'

export default function useSession () {
  const {state, update} = useContext(Session.Context)
  const {navigation, params, active, query} = state.props
  const navigate = useNavigate()
  
  function historyTrack(track) {
    return state.historyTrack[track] || false
  }

  function pushHistory(track, clear) {
    return clear
      ? update({historyTrack:{$autoArray: {[track]: {$set: [`${location.pathname}${location.search}`]}}}})
      : update({historyTrack:{$autoArray: {[track]: {$push: [`${location.pathname}${location.search}`]}}}})
  }

  function popHistory(track) {
    const stack = state.historyTrack[track]
    if (!stack) return false
    else {
      const path = stack[stack.length - 1]
      update({historyTrack: {[track]: {$splice: [[stack.length - 1, 1]]}}})
      navigate(path)
    }
  }

  return {...omit(state, 'props'), ...params, query, active, navigation, pushHistory, popHistory, historyTrack}
}
