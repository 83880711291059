import { useMemo, useEffect } from 'react'
import { clsx } from 'external/material'
import { withClassName } from 'utils/style'

export default function WrapperContainer(props) {

  const wrapperProps = useMemo(() => {
      const wrapperProps = withClassName(props.prefix, 'wrapper', props.wrapperProps)
      if (props.className) wrapperProps.className = clsx(wrapperProps.className, props.className)
      return wrapperProps
  }, [props.wrapperProps, props.prefix, props.className])

  const containerProps = useMemo(() => {
      const wrapperProps = withClassName(props.prefix, 'container', props.wrapperProps)
      if (props.className) wrapperProps.className = clsx(wrapperProps.className, props.className)
      return wrapperProps
  }, [props.containerProps, props.prefix, props.className])

  return (
      <div {...wrapperProps}>
          <div {...containerProps}>
              {props.children}
          </div>
      </div>
  )
}