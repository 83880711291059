import {orderBy} from 'external/lodash'
import {
   clsx
  ,Fade
  ,List 
  ,ListItem 
  ,ListItemText 
  ,ListItemIcon 
} from 'external/material'
import useSession from 'hooks/session'
import useCatalog from 'hooks/catalog'
import PreviewLesson from 'components/Common/PreviewLesson'

export default function UnitDetails({id}) {
  const {course: courseId} = useSession() 
  const {unitLessons = {}} = useCatalog()
  const {course, lessons = [], content = {}} = unitLessons[id] || {}
  const lessonsLoaded = course == courseId && lessons.length > 0 
  return (<Fade in={lessonsLoaded}>
    <List className="lessons">
      {orderBy(lessons, ['id']).map(lesson => {
        const scheduled = content[lesson.id]
        const hasEpl = lesson.hasEpl && lesson.inContract
        const events = scheduled && scheduled.map(({event}) => event)
        return (<ListItem className={clsx("lesson", {scheduled})} key={lesson.id} secondaryAction= {<PreviewLesson lesson={lesson.id} hasEpl={hasEpl} />}>
          <ListItemIcon className={clsx("lessonIcon", {scheduled})}><span className={clsx('scheduleCount', {visible: scheduled})}>{scheduled && events.length}</span></ListItemIcon>
          <ListItemText className={clsx("lessonName", {scheduled})}>{lesson.id}: {lesson.name}</ListItemText>
          </ListItem>)
      })}
    </List>
  </Fade>)
}

function LessonLinks() {
  return (<div>links</div>)
}
