import { inViewer } from "settings"
import {createPost } from "utils/command"

export  function logLessonAccess(type, props) {
    const {id, events = [], hasEpl} = props
    return async (e) => {
      if (hasEpl) {
        const properties = {type, inViewer, events}
        await createPost('download', 'logLessonAccess')({id, properties})
      }
    }
}

export  function logUploadedAccess(type, {id, events}) {
    return async (e) => {
        const properties = {id, type, inViewer, events}
        await createPost('download', 'logLessonAccess')({id: 'C000G00U00L00', properties})
    }
}