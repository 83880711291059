import {useEffect} from 'external/react'
import {styled, clsx} from 'external/material'

import StudentSelector from 'components/Common/StudentSelector'
import EditGroupButtons from './EditGroupButtons'

import useAdmin from 'hooks/admin'
import useTranslations from 'hooks/translations'

const StyledStudentStep = styled('div')(studentStepStyles)


export default function StudentStep(props) {

    const {handlePrevious, handleSave, canSave, touchField, errors} = props
    const {update, editGroupForm, contractTerms} = useAdmin()
    const {studentList, selectedStudents, checkedStudents, course, credits} = editGroupForm
    const {usedCredits, totalCredits} = credits
    const {translate} = useTranslations()
    
    useEffect(() => {
        touchField('credits')()
        const courseCredits = contractTerms.find((term) => term.course === course) || 0
        update({
            editGroupForm: {
                credits: {
                    usedCredits: {$set: selectedStudents.length},
                    totalCredits: {$set: courseCredits.credits || courseCredits}
                }
            }
        })
    }, [course])

    const handleStudentsChecked = (studentId) => {
      update({editGroupForm: {checkedStudents:{[studentId]: {$apply: checked => !checked}}}})
    }

    const handleStudentsChange = (props) => {
        const {right: selectedStudents} = props
        touchField('selectedStudents')()
        update({
            editGroupForm: {
                selectedStudents: {$set: selectedStudents},
                credits: {usedCredits: {$set: selectedStudents.length}}
            }
        })
    }

    return (
        <StyledStudentStep className="tab-content">
            <div className='step-content'>
                <StudentSelector {...{studentList, selectedStudents, checkedStudents, handleStudentsChange, handleStudentsChecked}} />
                {errors.selectedStudents && <label className="field-error">{translate(errors.selectedStudents)}</label>}
            </div>
            <div className="credits-container">
                <span className={clsx({"credits-warn": usedCredits > totalCredits})}>
                    {translate('group-credits')}: {usedCredits} / {totalCredits}
                </span>
                {errors.credits && <label className="field-error">{translate(errors.credits)}</label>}
            </div>
            <EditGroupButtons
              left={{label: 'back', onClick: handlePrevious, enabled: true}}
              right={{label: 'save', onClick: handleSave, enabled: canSave}}
            />
        </StyledStudentStep>
    )
}

function studentStepStyles() {
    return {
        '& .student-selector': {
            flexGrow: 1
        },
        '& .field-error': {
            color: '#d32f2f',
            margin: '0 14px'
        },
        '& .credits-container': {
            margin: '10px 15px',
            display: 'flex',
            flexDirection: 'row-reverse',
            '& .credits-warn': {
                color: '#d32f2f'
            },
        },
    }
}