import {SvgKeepRatio} from 'utils/style'

export default function CalendarIcon(props) {
  const fill= props.fill || "#fff"
  return (
    <SvgKeepRatio viewBox={{width: 29, height: 33}}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
                <path fill={fill} d="M7,3 L7,4 L3,4 C1.8954305,4 1,4.8954305 1,6 L1,30 C1,31.1045695 1.8954305,32 3,32 L26,32 C27.1045695,32 28,31.1045695 28,30 L28,6 C28,4.8954305 27.1045695,4 26,4 L22.0116915,4 L22.0116915,3 L26,3 C27.6568542,3 29,4.34314575 29,6 L29,30 C29,31.6568542 27.6568542,33 26,33 L3,33 C1.34314575,33 2.02906125e-16,31.6568542 0,30 L0,6 C-2.02906125e-16,4.34314575 1.34314575,3 3,3 L7,3 Z" fillRule="nonzero"></path>
                <rect stroke={fill} x="6.5" y="0.5" width="3" height="6" rx="1.5"></rect>
                <rect stroke={fill} x="19.5" y="0.5" width="3" height="6" rx="1.5"></rect>
                <polygon fill={fill} fillRule="nonzero" points="0 10 0 9 29 9 29 10"></polygon>
                <polygon fill={fill} fillRule="nonzero" points="9 4 9 3 23 3 23 4"></polygon>
                <rect stroke={fill} x="4.5" y="13.5" width="4" height="3"></rect>
                <rect stroke={fill} x="4.5" y="19.5" width="4" height="3"></rect>
                <rect stroke={fill} x="4.5" y="25.5" width="4" height="3"></rect>
                <rect stroke={fill} x="12.5" y="13.5" width="4" height="3"></rect>
                <rect stroke={fill} x="12.5" y="19.5" width="4" height="3"></rect>
                <rect stroke={fill} x="20.5" y="13.5" width="4" height="3"></rect>
                <rect stroke={fill} x="20.5" y="19.5" width="4" height="3"></rect>
            </g>
        </g>
    </SvgKeepRatio>
  )
}