import {uniqBy, isArray, flatten, diff} from 'external/lodash'

export const contexts = {}

const contextHooks = {}

export function register(id, path, trigger) {
  const hooks = contextHooks[id] || (contextHooks[id] = [])
  const paths = isArray(path) ? path : [path]
  const triggers = isArray(trigger) ? trigger : [trigger]
  paths.forEach(p => triggers.forEach(t => hooks.push(createHook(p, t))))
}

export function registerContext(id, context) {
  contexts[id] = context
  if (!contextHooks[id]) contextHooks[id] = []
}

export function paramsHook (...names) {
  const parseName = (name, query = 'query', param = 'params') => name.startsWith('?') ? query : param
  return names.map(name => `props/${parseName(name)}/${parseName(name, name.substring(1), name)}/`)
}

function createHook (path, callback) {
  const trigger = (...diff) => (...args) => callback.apply(path, args.concat(diff))
  const hook = {path, trigger, hook: callback}
  return  hook
}

export default function trigger(id, current, previous, update) {
  const difference = diff(current, previous, filterHooks(id))
  if (difference) {
    const triggers = flatten(difference.map(createTrigger(id)))
    const hooks = uniqBy(triggers, 'hook').map(hook => ({...hook, trigger: hook.trigger(hook.diff)}))
    hooks.forEach(hook => {
      hook.trigger({state: current, update}, {contexts, path: hook.path, diff: hook.diff})
    })
  }
}

function filterHooks(id) {
  const hooks = contextHooks[id]
  return (path, key) => {
    const id = path.concat([key]).join('/')
    return !hooks.some(matchHookId(id))
  }
}

function createTrigger(id) {
  const hooks = contextHooks[id]
  return (diff) => {
    const ids = hooks.filter(matchHookId(diff.path.join('/')))
    return uniqBy(ids, 'hook').map(trigger => ({...trigger, diff}))
  }
}

function matchHookId(id) {
  return ({path}) => {
    if (path.length > id.length) return path.startsWith(id)
    else return id.startsWith(path)
  }
}
