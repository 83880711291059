import {SvgKeepRatio} from 'utils/style'

export default function GuideIcon(props) {
  const stroke = props.stroke || "#45C7FF"
  const fill = props.fill || "#D8D8D8"
  return (<SvgKeepRatio viewBox={{width: 25, height: 25}} {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1240.000000, -135.000000)">
          <g transform="translate(1240.000000, 135.500000)">
            <g transform="translate(0.000000, 6.500000)" fill={stroke}>
              <path d="M5.04249229,6.74039399 C7.82738389,6.74039399 10.0849846,8.99799469 10.0849846,11.7828863 L10.0849846,17.4977109 L0,17.4977109 L0,11.7828863 C-3.41050858e-16,8.99799469 2.2576007,6.74039399 5.04249229,6.74039399 Z"></path>
              <circle cx="5.04249229" cy="3.71489862" r="3.69782768"></circle>
            </g>
            <path d="M11.5,5 L19.5,5 L19.5,5.48627286 L19.9855317,5 L20,5 L19.9927604,4.99276036 L19.9992374,4.98627344 L19.9862932,4.98629319 L19.5,4.5 L19.5,4.9870349 L11.5,4.9992368 L11.5,4.5 L11.0000006,4.99999942 L10.9992374,5.00000058 L11.5,5.5 L11.5,5 Z" stroke={stroke} fill={fill}></path>
            <path d="M15.5,8 L20,8 L19.5,7.5 L19.5,8.5 L20,8 L15.5,8 Z M15.5,8 L11,8 L11.5,8.5 L11.5,7.5 L11,8 L15.5,8 Z" stroke={stroke} fill={fill}></path>
            <path d="M15.5,11 L20,11 L19.5,10.5 L19.5,11.5 L20,11 L15.5,11 Z M15.5,11 L11,11 L11.5,11.5 L11.5,10.5 L11,11 L15.5,11 Z" stroke={stroke} fill={fill}></path>
            <path d="M15.4924927,14 L19.9849854,14 L19.4850417,13.5075065 L19.5000564,14.5075065 L20,14 L15.4924927,14 Z M15.4924927,14 L11,14 L11.5,14.5 L11.5,13.5 L11,14 L15.4924927,14 Z" stroke={stroke} fill={fill}></path>
            <polygon fill={stroke} fillRule="nonzero" points="7.5 1 23.5 1 23.5 18 13 18 13 19 24.5 19 24.5 0 6.5 0 6.5 4.5 7.5 4.5"></polygon>
          </g>
        </g>
      </g>
    </SvgKeepRatio>
  )
}