import {useMemo} from 'external/react'
import {styled, Dialog, DialogTitle, DialogContent} from 'external/material'

import useAdmin from 'hooks/admin'
import useTranslations from 'hooks/translations'
import useValidations, { minLength, notEmpty } from 'hooks/validations'

import {createPost} from 'utils/command'
import { extractId } from 'utils/functions'

import {IconButton} from 'components/Common/Icon'

import TabBar from './EditGroupTabBar'
import GroupStep from './GroupStep'
import StudentStep from './StudentStep'

const StyledEditGroupForm = styled(Dialog)(editGroupFormStyles)

const steps = {
    group: GroupStep,
    students: StudentStep,
}

export default function EditGroupForm() {

    const {editGroupForm, update, isGroupNameUsed} = useAdmin()
    const {open, activeTab, isSaving, groupName} = editGroupForm
    const {translate} = useTranslations()

    const currentName = useMemo(() => groupName, [open])
    const validations = useMemo(() => ({
        groupName: [
            notEmpty('groupName'),
            minLength('groupName', 3),
            ({groupName}) => (groupName !== currentName && isGroupNameUsed(groupName)) ? 'groupName-used' : ''
        ],
        selectedTeachers: [
            minLength('selectedTeachers', 1),
        ],
        selectedStudents: [
            minLength('selectedStudents', 1),
        ],
        credits: [
            ({credits}) => credits.usedCredits > credits.totalCredits ? 'credits-limit' : ''
        ]
    }), [isGroupNameUsed])

    const {errors, errorCount, resetErrors, touchField} = useValidations(validations, editGroupForm)

    function resetForm() {
        resetErrors()
        update({
            editGroupForm: {
                activeTab: {$set: 'group'},
                course: {$set: ''},
                group: {$set: {}},
                checkedStudents: {$set: {}},
                checkedTeachers: {$set: {}},
                selectedStudents: {$set: []},
                selectedTeachers: {$set: []}
            }
        })
    }

    function closeForm(refresh) {
        if (refresh) update({groups: {$refreshLoadableList: true}, editGroupForm: {open: {$set: false}, group: {$set: {}}}})
        else update({editGroupForm: {open: {$set: false}, group: {$set: {}}}})
    }

    function handleClose(refresh = false) {
        closeForm(refresh)
        resetForm()
    } 

    const StepContent = useMemo(() => steps[activeTab] || steps.group, [activeTab])

    function handleNext() {
        const next = {'group': 'students'}
        update({editGroupForm: {activeTab: {$set: next[activeTab]}}})
    }

    function handlePrevious() {
        const previous = {'students': 'group'}
        update({editGroupForm: {activeTab: {$set: previous[activeTab]}}})
    }

    async function handleSave() {
        try {
            update({editGroupForm: {isSaving: {$set: true}}})
            const {group, groupName: Name, selectedTeachers, selectedStudents} = editGroupForm
            const {Group} = group
            const Teachers = selectedTeachers.map(extractId)
            const Students = selectedStudents.map(extractId)
            await createPost('admin', 'edit_clique')({Name, Teachers, Students, Group})
            update({editGroupForm: {isSaving: {$set: false}}})
            handleClose(true)
        } catch(e) {
            console.error('error' + e)
        }
    }

    const canSave = useMemo(() => errorCount == 0 && !isSaving,  [errorCount, isSaving])
    
    return (
        <StyledEditGroupForm
            open={open}
            onClose={() => handleClose(false)}
        >
            <DialogTitle className="title">
                <label>{translate('edit-group-form-title', {groupname: currentName})}</label>
                <IconButton
                    className="close-button"
                    onClick={() => handleClose(false) }
                    iconName="faCircleXmark"
                />
            </DialogTitle>
            <DialogContent>
                <TabBar className="tab-heading" errors={errors} />
                <StepContent {...{handlePrevious, handleNext, handleClose, handleSave, canSave, errors, touchField}} />
            </DialogContent>
        </StyledEditGroupForm>)
    
}

function editGroupFormStyles() {
    return {
        '& .MuiDialog-paper':{
            height: 'calc(100% - 80px)',
            minWidth: 1024,
            maxWidth: 1280,
            '& .title': {
                textAlign: 'center',
                backgroundColor: '#2c7cbd',
                color: '#fff',
                '& .close-button': {
                    position: 'absolute',
                    right: 15,
                    color: '#fff'
                }
            }, 
            '& .MuiDialogContent-root': {
                padding: 0,
                display: 'flex',
                flexDirection: 'column',
                "& .tab-heading": {
                    flexShrink: 0
                },
                "& .tab-content": {
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    overflow: 'hidden',
                    padding: '0 15px',
                    "& .step-content": {
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        overflowY: 'hidden',
                    },
                    '& .add-group-buttons': {
                        flexShrink: 0,
                        padding: '12px 0'
                    },
                }
            }
        }
    }
}
