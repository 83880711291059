import {Routes, Route, Navigate} from 'external/react'
import MessagesContainer from './MessagesContainer'
import ScheduledMessages from './ScheduledMessages'
import DeletedMessages from './DeletedMessages'


export default function Messages() {
  return (
    <Routes>
      <Route element={<MessagesContainer />} >
          <Route index element={<Navigate to="scheduled" />} />
          <Route exact path="scheduled" element={<ScheduledMessages />} />
          <Route exact path="deleted" element={<DeletedMessages />} />
      </Route>
    </Routes>
  )
}