import {createPost} from 'utils/command'
import {styled, Box, clsx} from 'external/material'
import {useState} from 'external/react'
import Group from './Group'
import AdminBand from './AdminBand'
import useCatalog from 'hooks/catalog'
import { centerVertically } from 'utils/style'
import useTranslations from 'hooks/translations'
import Icon from 'components/Common/Icon'

import DeleteComment from './DeleteComment'

import Message from 'components/Common/Message'

import useAdmin from 'hooks/admin'
import useQueryDate from 'hooks/query-date'
import EditScheduleComment from '../EditScheduleComment'

const StyledEvent = styled(Box)(eventStyles)

export default function Event (props) {

  const {teacherGroups, update} = useAdmin()
  const [queryDate] = useQueryDate()
  const [[openMessage, anchorEl], setOpenMessage] = useState([false, null])
  const {type, content} = props
  const {subjects = {}} = useCatalog()
  
  const isAdminComment = props.type == 'admin-comment'

  const group = !isAdminComment && teacherGroups[props.group]
  const subjectId = !isAdminComment && group && group.course.substring(0,2)
  const subject = !isAdminComment && subjectId && subjects[subjectId]

  const date = queryDate().toDate()

  const {formatDate} = useTranslations()

  const getMessage = () => {
    if (isAdminComment) {
      return content.message
    }
    const isUploaded = !!content.url
    const lesson = isUploaded
      ? content.title
      : props.title
    const title = isUploaded 
      ? content.title
      : isAdminComment
        ? content.message
        : content.name
    const text = type == 'quiz'
      ? content.comments
      : type == 'homework'
        ? content.instructions
        : content.message
    return {date: formatDate(date), lesson, title, text}
  }

  function handleMessageClick (e) {
    setOpenMessage([true, e.target])
  }
  
  async function handleDeleteComment(event) {
    const deleteResult = await createPost('admin', 'delete_schedule')({Event: event.id})
    update({refreshEvents: {$apply: i => i + 1}})
  }

  const message = openMessage ? getMessage() : {}

  return (
    <StyledEvent className="schedule-event">
      <Message open={openMessage} anchorEl={anchorEl} {...message} onClose={() => setOpenMessage([false, null])} />
      {subject && (<Group {...{group, type, subject}} />)}
      {isAdminComment && (<AdminBand />)}
      <div className="content">
        {eventContent(props)}
      </div>
      <div className="actions-wrapper">
        <div className="actions">
          {!isAdminComment && (<span onClick={handleMessageClick} className={clsx("link", "message")}><Icon name="esMessage" fill="#2c75bd" width={18} /></span>)}
          {isAdminComment && (<>
            <DeleteComment event={props} className={clsx("link", "delete")} onDeleteComment={handleDeleteComment} />
            <EditScheduleComment event={props} className={clsx('link', 'edit')} />
          </>)}
        </div>
      </div>
    </StyledEvent>
  )
}
  


function eventContent({type, content, title, lessonName}) {
  switch (type) {
    case 'comment': return content.message
    case 'uploaded': return content.title
    case 'a.assignment': return content.title
    case 'schedule': return `${title} - ${lessonName}`
    case 'admin-comment': return content.message
    default: return title
  }
}

function eventStyles(){
  return {
   "&.schedule-event": {
      display: 'flex',
      flexShrink: 0,
      minHeight: '90px',
      "& .content": {
        flexGrow: 1,
        ...centerVertically,
        borderBottom: 'solid 1px #e1eaee',
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 10,
      },
      "& .actions-wrapper": {
        ...centerVertically,
        borderBottom: 'solid 1px #e1eaee',
        paddingRight: 5,
        "& .actions": { 
          display: 'flex',
          width: '80px',
          "& .link": {
            cursor: 'pointer',
            textAlign: 'center',
            marginRight: '15px',
            borderRadius: '5px',
            padding: '4px 0',
            "&:hover": { 
              transform: 'scale(1.04)'
            }
          }
        }
     }
    }
  }
}
