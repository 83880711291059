import moment from 'external/moment'
import {styled} from 'external/material'
import {range} from 'external/lodash'

import Day from './Day'

const StyledWeek = styled('div')(weekGroupStyles)

export default function Week(props) {

  const {start, daySize, goToDay} = props 

  const days = range(7)
    .map(d => ({goToDay, daySize, date: moment(start).add(d, 'days')}))
    .map((props,i) => (<Day key={i} {...props}/>))

  return (
    <StyledWeek className="week">
      {days}
    </StyledWeek>
  )
}

function weekGroupStyles() {
    return {
      "&.week": {
        display: 'flex',
        flexGrow: 1,
        borderBottom: 'solid 1px #e1eaee',
      },
      "& .title": {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        "& > div": {
          padding: '4px 10px'
        }
      }
  }
}
