import ClearableQueryFilter from 'components/Common/ClearableQueryFilter'

import useTranslations from 'hooks/translations'
import useAdmin from 'hooks/admin'

export default function  TeacherFilter (props) {

  const {translate} = useTranslations()
  const {teacherList} = useAdmin()
  
  const interceptQuery = (query) => ({...query, clique: undefined})

  return (<ClearableQueryFilter {...{...props, caption: translate('teacher_label'), items: teacherList.items, name: 'teacher', interceptQuery}} />)

}
