import { isEmpty } from 'utils/text'
import {useSearchParams, stringifyQueryString} from 'external/react'

export default function useSearchParam (name, preserveQuery = true) {

  const [params, setSearchParams] = useSearchParams()
  
  const param = params.has(name) ? params.get(name) : false

  function setSearchParam(value) {

    const currentQuery = {}

    if (preserveQuery) {
      for (const [key, value] of params.entries()) {
        if (key != name)
          currentQuery[key] = value
      }
    }

    const updatedQuery = stringifyQueryString(isEmpty(value) ? currentQuery : {...currentQuery, [name]: value})
    setSearchParams(updatedQuery)

  }

  return [param, setSearchParam]
}