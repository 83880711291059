import {styled} from 'external/material'
import {Link} from 'external/react'
import Icon from 'components/Common/Icon'

import useTranslations from 'hooks/translations'
import useSession from 'hooks/session'

const StyledEditUser = styled('div')(editUserStyles)

export default function EditUser(props) {

    const {user} = props
    const {translate} = useTranslations()
    const {navigation} = useSession()


    const contentText = translate('edit-user-text', user)

    return (<StyledEditUser>
        <Link style={{color: 'inherit'}} title={contentText || ''} to={navigation.usersAction.link({action: 'edit', userId: user.id})} >
            <Icon name="faPenToSquare"/>
        </Link>
        {/* <span onClick={handleOpen} title={contentText}><Icon name="faPenToSquare"/></span> */}
    </StyledEditUser>)
    
}

function editUserStyles() {
    return {
        color: '#2c75bd',
        cursor: 'pointer',
        fontSize: 18
    }
}