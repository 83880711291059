import {SvgKeepRatio} from 'utils/style'

export default function PreviewIcon(props) {
  const fill = props.fill || "#009dd8"
  return <SvgKeepRatio viewBox={{width: 29, height: 19}} {...props}>
      <g stroke="none" strokeWidth="1" fill="#009dd8" fillRule="evenodd">
          <g transform="translate(-741.000000, -346.000000)" fill={fill}>
              <path d="M755.511331,350.75 C758.173219,350.75 760.333556,352.873065 760.333556,355.488865 C760.333556,358.104992 758.173219,360.228057 755.511331,360.228057 C752.849276,360.228057 750.689106,358.104992 750.689106,355.488865 C750.689106,355.140397 750.764422,354.584126 750.857401,354.247613 C751.208986,354.501922 751.848673,354.708251 752.285239,354.708251 C753.6151,354.708251 754.694352,353.64762 754.694352,352.340702 C754.694352,351.911668 754.4844,351.283018 754.225626,350.937498 C754.570214,350.838918 755.141083,350.754913 755.5,350.75 L755.511331,350.75 Z M755.503499,362.624918 C759.50358,362.624918 762.75,359.434507 762.75,355.503439 L762.75,355.5 C762.75,351.566967 759.501913,348.374918 755.5,348.374918 C751.49792,348.374918 748.249833,351.566967 748.249833,355.5 C748.249833,359.43287 751.49792,362.624918 755.5,362.624918 L755.503499,362.624918 Z M770,355.499672 C770,355.71026 769.921351,356.034 769.824707,356.221826 C767.096001,361.455226 761.689077,365 755.5,365 C749.310756,365 743.905499,361.457683 741.175126,356.222317 C741.078315,356.034492 741,355.710751 741,355.500164 C741,355.289577 741.078315,354.966 741.175126,354.778174 C743.903832,349.544774 749.310756,346 755.5,346 C761.689077,346 767.094501,349.542154 769.824707,354.777683 C769.921351,354.965508 770,355.289085 770,355.499672 L770,355.499672 Z"></path>
          </g>
      </g>
  </SvgKeepRatio>
}