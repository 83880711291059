import {styleAndCapture, clsx} from 'external/material'
import {Link, useMemo} from 'external/react'

import useSession from 'hooks/session'

const capturedProps = ['id', 'name', 'precedence', 'selected', 'studentGrade', 'subject']
const StyledGradeFilterButton = styleAndCapture(Link, ...capturedProps)(gradeFilterButtonStyles())

export default function GradeFilterButton(props) {
  const {navigation: {catalogCourses}} = useSession()
  const buttonProps = useMemo(() => {
    const {id, selected, subject} = props
    const className = clsx('grade', 'filter', {selected})
    const to = subject ? catalogCourses.linkWithQuery({grade: selected ? undefined : id}, true, {subject: subject.id}) : undefined    
    return {...props, className, to}
  }, [catalogCourses, props])
  return (<StyledGradeFilterButton {...buttonProps}>{buttonProps.name}</StyledGradeFilterButton>)
}

function gradeFilterButtonStyles() {
    return {
      padding: '5px',
      textAlign: 'center',
      marginLeft: '12px',
      width: '55px',
      fontSize: '10.5pt',
      border: 'solid 1px #d8dcdb',
      borderRadius: '3px',
      backgroundColor: '#fff',
      textDecoration: 'none',
      color: '#92999f',
      "&:hover": {
        transform: 'scale(1.05)',
        fontWeight: 'bold'
      },
      "&.selected": {
        fontWeight: 'bold',
        color: '#fff',
        backgroundColor: '#2ca5ec'
      }
    }
}