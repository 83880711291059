import immutableUpdate from 'external/immutability-helper'
import triggerHooks from 'utils/hook'
import {useReducer, useEffect} from 'external/react'

const reducer = (state, action) => {
  const {command, update, resolve} = action.value
  const commands = Array.isArray(command) ? command : [command]
  const newState = commands.reduce(immutableUpdate, state)
  triggerHooks(action.type, newState, state, update)
  resolve(newState)
  return newState
}

export default function useImmutableState(id, initialState) {
  const [state, dispatch] = useReducer(reducer, initialState)

  function update(command) {
    return new Promise((resolve) => {
      dispatch({type: id, value: {command, update, resolve}})
    })
  }

  return {state, update}

}
