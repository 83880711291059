import {inViewer} from 'settings'
import { parentDomain } from './url'
import create from './command'

export function getFullName ({first_name, middle_name, last_name, maiden_name}) {
  return `${first_name} ${middle_name || ''} ${last_name} ${maiden_name || ''}`
}

export async function logout () {
  try {
    await create('security', 'logout')()
  } finally {
    const logoutUrl = inViewer ? '//logout' : `//${parentDomain()}`
    location.replace(logoutUrl)
  }
}

export async function keepAlive () {
    return create('security', 'keepAlive')()
}
