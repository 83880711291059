import {Routes, Route, Navigate} from 'external/react' 

import MonitoringContainer from './MonitoringContainer'

import Teachers from './Teachers'
import Groups from './Groups'
import Students from './Students'
import Student from './Student'

export default function Monitoring() {
  return (
    <Routes>
        <Route element={<MonitoringContainer />}>
          <Route index element={<Navigate to='teachers' />} />
          <Route exact path="teachers" element={<Teachers />} />
          <Route exact path="teachers/:teacherId" element={<Groups />} />
          <Route exact path="teachers/:teacherId/:groupId" element={<Students />} />
          <Route exact path="student/:studentId" element={<Student />} />
       </Route>
    </Routes>
  )
}