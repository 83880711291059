import {SvgKeepRatio} from 'utils/style'

export default function DownloadIcon(props) {
  const fill= props.fill || "#fff"
  return (
    <SvgKeepRatio viewBox={{width: 33, height: 33}} {...props}>
     <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
       <g transform="" fillRule="nonzero" stroke={fill}>
         <g transform="">
           <g transform="">
             <path d="M29.0625,27.6785714 C29.0625,26.9174107 28.4397321,26.2946429 27.6785714,26.2946429 C26.9174107,26.2946429 26.2946429,26.9174107 26.2946429,27.6785714 C26.2946429,28.4397321 26.9174107,29.0625 27.6785714,29.0625 C28.4397321,29.0625 29.0625,28.4397321 29.0625,27.6785714 Z M23.25,26.2946429 C22.4888393,26.2946429 21.8660714,26.9174107 21.8660714,27.6785714 C21.8660714,28.4397321 22.4888393,29.0625 23.25,29.0625 C24.0111607,29.0625 24.6339286,28.4397321 24.6339286,27.6785714 C24.6339286,26.9174107 24.0111607,26.2946429 23.25,26.2946429 Z M15.1055668,24.1980046 L5.36961583,14.4620536 C5.02363368,14.1091788 5.27278137,13.514049 5.76404899,13.514049 L11.625,13.514049 L11.625,0.553571429 C11.625,0.249080113 11.8740801,0 12.1785714,0 L18.8214286,0 C19.1259199,0 19.375,0.249080113 19.375,0.553571429 L19.375,13.514049 L25.2289908,13.514049 C25.7272186,13.514049 25.9694061,14.1160714 25.623424,14.4620536 L15.887473,24.1980046 C15.6729911,24.4124865 15.3201163,24.4124865 15.1055668,24.1980046 Z M31,22.9732143 L31,30.1696429 C31,30.6263123 30.6263123,31 30.1696429,31 L0.830357143,31 C0.373687744,31 0,30.6263123 0,30.1696429 L0,22.9732143 C0,22.5165449 0.373687744,22.1428571 0.830357143,22.1428571 L9.92279489,22.1428571 L13.5417546,25.7618168 C14.6211918,26.8413217 16.3788082,26.8413217 17.4582454,25.7618168 L21.0772051,22.1428571 L30.1696429,22.1428571 C30.6263123,22.1428571 31,22.5165449 31,22.9732143 Z"></path>
           </g>
         </g>
       </g>
      </g>
   </SvgKeepRatio>
 )
}