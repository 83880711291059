import {useMemo} from 'external/react'
import {styled, clsx} from 'external/material'
import { centerVertically } from 'utils/style'
import Units from './Units'
import useCatalog from 'hooks/catalog'
import useSession from 'hooks/session'
import BackButton from 'components/Common/BackButton'
import useTranslations from 'hooks/translations'
import IconLink from 'components/Common/IconLink'
import { viewerGuard, pdfPreviewLink } from 'utils/url'
import useProfile from 'hooks/profile'

const StyledCatalogueUnits = styled('div')(catalogueUnitStyles)

export default function CatalogueUnits() {

    const {language} = useTranslations()
    const {navigation} = useSession()
    const {unitsCourse} = useCatalog()
    const profile = useProfile()

    const isDreyfous = useMemo(() => profile.domainName == 'dreyfous' , [profile.domainName])

    return <StyledCatalogueUnits>
        { unitsCourse && (
          <div className="course-info">
            <BackButton className="back" to={unitsCourse ? navigation.catalogCourses.linkWithQuery({subject: unitsCourse.course.substring(0,2)}, true) : ''} />
            <label>{unitsCourse.name}</label>
            <div className={clsx('link', 'overview', {inactive: !unitsCourse.hasOverview})}>
                <IconLink
                  {...viewerGuard({href: pdfPreviewLink('download', 'overview', language, unitsCourse.course), target: "blank"})}
                  name="esOverview"
                  label='overview_label'
                />
              </div>
              <div className={clsx('link', 'guide', {inactive: !unitsCourse.hasGuide})}>
                <IconLink
                  {...viewerGuard({href:pdfPreviewLink('download', 'guide', language, unitsCourse.course), target:"blank"})}
                  name="esGuide"
                  label="guide_label"
                />
              </div>
          {isDreyfous && (
               <div className={clsx('link', 'workbook', {inactive: !unitsCourse.hasWorkbook})}>
                <IconLink
                  {...viewerGuard({href:pdfPreviewLink('download', 'workbook', language, unitsCourse.course), target:"blank"})}
                  name="maAssignmentTurnedIn"
                  label="workbook_label"
                />
              </div>
          )}

          </div>
        )}

        <Units />
    </StyledCatalogueUnits>
}

function catalogueUnitStyles() {
  return {
    display: 'flex',
    padding: 40,
    flexGrow: 1,
    overflowY: 'hidden',
    flexDirection: 'column',
    '& .course-info': {
        display: 'flex',
        paddingRight: 7,
        paddingBottom: 25,
        '& > label': {
          paddingLeft: '10px',
          fontSize: '18pt',
          color: '#535562',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          flexGrow: 1
        },

    '& .link': {
      borderRadius: '5px',
      cursor: 'pointer',
      padding: '6px 15px',
      margin: '5px',
      display: 'flex',
      textDecoration: 'none',
      color: '#565f77',
      fontSize: '13pt',
      backgroundColor: '#fff',
      '&.guide': {
        marginLeft: 15
      },
      '&.inactive': {
        display: 'none',
        pointerEvents: 'none',
        opacity: '0.5'
      },
      '& a': {
        display: 'flex',
        textDecoration: 'none',
        '& label': {
          cursor: 'pointer',
          marginLeft: 10,
          '&:hover': {
            color: '#00f'
          }
        },
        '& svg': {
          width: 20,
          height: 20,
          fill: '#6bb2ff'
        }
    },
    },
    },
    "& .unitsWrapper": {
      flexGrow: 1,
      overflowY: 'auto',
      paddingBottom: 30,
      paddingRight: 11
    },
    "& .units": {
      flexGrow: 1,
    },
    "& .unit": {
      "&.expanded": {
        borderTop: 'solid 2px #dddddd'
      },
      textDecoration: 'none',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff'
    },
    "& .unitSummary": {
      padding: '10px 20px',
      "&.expanded": {
        borderBottom: 'solid 1px #dddddd',
      },
      "& .unitName": {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        color: '#565f77',
        fontSize: '13.5pt',
        flexShrink: 1,
        "&.expanded": {
          fontWeight: 600
        }
      },
      "& .unitCountWrapper": {
        marginLeft: 15,
        "& .unitCount": {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start'
        },
        display: 'flex',
        justifyContent: 'flex-end',
        flexGrow: 1,
        "& .count.lesson": {
          ...centerVertically,
          textAlign: 'center',
          fontSize: '11.5pt',
          width: 45,
          color: '#979797',
          border: 'solid 1px #979797',
          height: 22,
          borderRadius: 20,
          marginRight: 20,
          backgroundColor: '#fff',
          "&.expanded": {
            borderColor: '#ff47cb',
            color: '#fff',
            backgroundColor: '#ff47cb'
          }
        }
      }
    },
    "& .unitDetails": {
      backgroundColor: '#f6fbfe',
      padding: 0,
      paddingLeft: 25
    },
    "& .lessons": {
      "& .lesson": {
        borderBottom: 'solid 1px #e4e8eb',
        paddingRight: 25,
        "&:last-child": {
          borderBottom: 0
        },
        "& .lessonName": {
          "& span": {
            color: '#6b748c',
            fontSize: '13pt',
          },
          "&.scheduled": {
            "& span": {
              fontWeight: 600
            }
          }
        },
        "& .lessonIcon": {
          "& .scheduleCount.visible": {
            width: 30,
            height: 30,
            borderRadius: 50,
            textAlign: 'center',
            ...centerVertically,
            padding: 0,
            margin:0,
            backgroundColor: '#ff47cb',
            color: '#fff'
          }
        },
        "& .lessonLinks": {
          display: 'flex',
          "& .lessonLink": {
            marginRight: 25
          }
        }
      }
    }
  }
}
