import { omit } from 'external/lodash'
import moment from 'external/moment'

import { clsx, styled } from 'external/material'
import {DatePicker, forwardRef} from "external/react"
import Icon from 'components/Common/Icon'

import useTranslations from 'hooks/translations'
import useAdmin from 'hooks/admin'

const DateInput = forwardRef((props, ref) => (
  <div {...omit(props, 'value')}>
    <Icon name="faCalendarAlt" />
    {props.value}
  </div>
))

const StyledDateStep = styled('div')(scheduleCommentDateStepStyles)

export default function ScheduleCommentDateStep(props) {

  const {update, scheduleCommentForm: {message, date}} = useAdmin()
  const {locale, translate} = useTranslations()

  const setDate = date => update({scheduleCommentForm: {date: {$set: date}}})
  const setMessage = event => update({scheduleCommentForm: {message: {$set: event.target.value}}})
    
 return (
   <StyledDateStep className={clsx(props.className, 'date-step')}>
     <div className="instructions">
       {translate('schedule-comment-date-instructions')}
     </div>
     <div className="content">
       <div className="date">
         <DatePicker
           customInput={<DateInput className="date-input" />}
           selected={date}
           onChange={setDate}
           minDate={new Date()}
           maxDate={moment().add(1,'year').toDate()}
           locale={locale}
         />
       </div>
       <div className="message">
         <div className="label">
           {translate('schedule-comment-message-label')}
         </div>
         <div className="value">
           <textarea
             placeholder={translate('schedule-comment-message-placeholder')}
             value={message}
             onChange={setMessage}
           />
         </div>
       </div>
     </div>
   </StyledDateStep>
 )
 }

function scheduleCommentDateStepStyles() {
  return {
    display: 'flex',
    flexDirection: 'column',
    "& .content": {
      flexGrow: 1,
      marginTop: 45,
      display: 'flex',
      flexDirection: 'column',
      "& .date": {
        display: 'flex',
        width: 150
      },
      "& .date-input": {
        cursor: 'pointer',
        padding: '4px 12px 4px 12px',
        fontSize: '13pt',
        fontWeight: 'bold',
        border: 'solid 1px #c1c1c1',
        borderRadius: 2,
        "&:hover": {
          border: 'outset 1px #ccc'
        },
        "& svg": { 
          marginRight: 12
        }
      },
      "& .message": {
        marginTop: 35,
        paddingBottom: 25,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        "& .value": {
          display: 'flex',
          marginTop: 10,
          flexGrow: 1,
          "& textarea": {
            flexGrow: 1,
            resize: 'none',
            padding: 8,
            border: 'solid 1px #c1c1c1',
            borderRadius: 2
          }
        }
      }
    }
  }
}
