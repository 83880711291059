import {styled} from 'external/material'

import StudentHeader from './StudentHeader'
import LogTable from "./LogTable"

const StyledStudentLog = styled('div')(studentLogStyles)

export default function StudentLog() {
    return (
        <StyledStudentLog>
            <StudentHeader />
            <LogTable className="student-log" />
        </StyledStudentLog>
    )
}

function studentLogStyles() {
  return {
      display: 'flex',
      overflowY: 'hidden',
      flexDirection: 'column',
      marginTop: '15px',
      flexGrow: 1,
      backgroundColor: '#fff',
      border: 'solid 1px #d9d9d9',
      borderRadius: '4px',
      "& > .students-count": {
        borderBottom: 'solid 1px #e7edf0',
        padding: '10px 20px',
        display: 'flex',
        "& .icon": {
          color: '#78a7ff',
          fontSize: '15pt',
        },
      },
      "& .student-log": {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflowY: 'auto',
        borderTop: 'solid 1px #d9d9d9',
        padding: '0',
      }
    }
}
