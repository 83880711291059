
import {orderBy, intersectionWith} from 'external/lodash'

import useSession from 'hooks/session'
import useTranslations from 'hooks/translations'

import LanguageFilterButton from './LanguageFilterButton'

export default function LanguageFilter ({availableLanguages}) {

  const {languageId, query} = useSession()
  const translationsContext = useTranslations()
  const selectedLanguage = query.languageId || languageId
  
  const filteredLanguages = availableLanguages
    ? translationsContext.languages.filter(a => availableLanguages.indexOf(a.id) >= 0)
    : translationsContext.languages

  const languages = orderBy(filteredLanguages, ['id'], ['desc'])
        .map(l => ({...l, selected: l.id == selectedLanguage}))
        

  return (
    <div className="language">
      {languages.map((props, index) => <LanguageFilterButton key={index} {...props} />)}
    </div>
  )
}