import {secondsToDate, isDate} from 'external/date-fns'
import { useEffect, useRef } from 'external/react'
 
export default function useIntervalCallback(callback, target) {

  const savedCallback = useRef()
 
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback])
 
  useEffect(() => {

    const delay = getMilliseconds(target)

    function tick() {
      savedCallback.current();
    }

    if (!isNaN(delay)) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }

  }, [target])

}

function getMilliseconds (delay) {
    if (isDate(delay)) return secondsToDate(delay) * 1000
    return NaN
}