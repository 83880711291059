import moment from 'external/moment'
import {uuid} from 'external/lodash'
import {useContext, useEffect, useMemo} from 'external/react'
import Rollbook from 'context/rollbook-context'
import { createPost } from 'utils/command'
import useTranslations from './translations'

function createPeriodObject(index, start, finish, name) {
  return {
    id: uuid(),
    index,
    properties: {name},
    start,
    finish
  }
}

export default function useRollbook () {

  const {state, update} = useContext(Rollbook.Context)
  const {translate} = useTranslations()
  const {
    id,
    periods,
    newPeriods,
    periodTypes,
    start,
    finish,
    rearrange,
    isSaving,
  } = state

  const currentPeriod = useMemo(() => (
    periods.items.length > 0
    ? periods.items.find(period => moment().isBetween(period.start, period.finish)) || periods.items[0]
    : {}
  ), [periods])
  
  function addPeriod() {
    if (periods.items.length >= 12) return
    update({periods: {items: {$push: [true]}}})
    update({periods: {items: {$apply: rearrangePeriods}}})
  }

  function deletePeriod(index) {
    if (periods.items.length <= 1) return
    update({periods: {items: {$splice: [[index, 1]]}}})
    update({periods: {items: {$apply: rearrangePeriods}}})
  }

  function periodChange(field, index, date) {
    update({periods: {items: {[index]: {[field]: {$set: date}}}}})
  }

  async function handleSave() {
    update({isSaving: {$set: true}})
    const {items: newPeriods} = periods
    const result = await createPost('grading', 'createSchoolYear')({id, start, finish, periods: newPeriods})
    update({isSaving: {$set: false}})
    return result
  }

  function periodLabel(index, length) {
    const monthsPerPeriod = 12 / length
    const frequency = Math.floor(monthsPerPeriod) == monthsPerPeriod ? monthsPerPeriod : -1
    const periodType = periodTypes.find(t => t.frequency == frequency) || {}
    const typeLabel = `${translate(periodType.name || '')} ${index + 1}`
    return typeLabel
  }

  function rearrangePeriods (periods) {
    const stateStart = () => moment(start)
    const stateFinish = () => moment(finish)
    const daysInPeriodYear = moment.duration(stateFinish().diff(stateStart())).asDays()

    const monthInPeriod = Math.floor(12 / periods.length)
    const evenMonths = monthInPeriod == (12 / periods.length)
    const type = evenMonths ? 'months' : 'days'

    const timeInPeriod = type == 'months' ? monthInPeriod : Math.floor(daysInPeriodYear / periods.length)

    const getStart = time => {
      const start = stateStart().add(time, type)
      return moment.max(stateStart(), start).toDate()
    }

    const getFinish = time => {
      const finish = stateStart().add(time, type).subtract(1, 'days')
      return moment.min(stateFinish(), finish).toDate()
    }

    return periods.map((_,index) => {
      const start = getStart(index * timeInPeriod)
      const finish = getFinish(index * timeInPeriod + timeInPeriod)
      return createPeriodObject(index, start, finish, periodLabel(index, periods.length))
    })
  }

  return {
    update,
    periods,
    periodTypes,
    start,
    finish,
    rearrange,
    isSaving,
    addPeriod,
    deletePeriod,
    periodChange,
    handleSave,
    rearrangePeriods,
    currentPeriod,
    newPeriods,
  }
}
