import {Outlet} from 'external/react'
import {styled, clsx, Fade} from 'external/material'
import BackButton, { BackToDashboard } from 'components/Common/BackButton'
import useSession from 'hooks/session'
import useTranslations from 'hooks/translations'

const StyledSettingsWrapper = styled('div')(settingStyles)

const Title = styled('span')(() => ({
    color: '#4f556d',
    fontSize: '14pt',
    marginLeft: '20px',
}))

export default function SettingsWrapper() {
    const {section, navigation, course} = useSession()
    const {translate} = useTranslations()

    return <StyledSettingsWrapper className={clsx('settings', 'wrapper')}>
        <div className={clsx('settings', 'container')}>
            <div className='topbar'>
                {section
                    ? <BackButton to={navigation.page.link({pageName: 'settings'})} />
                    : course
                        ? <BackButton to={navigation.page.link({pageName: 'settings/license-count'})} />
                        : <BackToDashboard />
                }
                {section ? (
                    <Fade in>
                        <Title>{`${translate(`settings-${section}-label`)}`}</Title>
                    </Fade>
                ) : course ? (
                    <Fade in>
                        <Title>{`${translate(`settings-${course}-label`)}`}</Title>
                    </Fade>
                ) : null}
            </div>
            <div className='content'>
                <Outlet/>
            </div>
        </div>
    </StyledSettingsWrapper>
}

function settingStyles () {
    return {
        flexGrow: 1,
        overflowY: 'hidden',
        '& .wrapper': {
            overflowY: 'hidden',
        },
        '&.wrapper': {
            display: 'flex',
            padding: 30,
            justifyContent: 'center',
            '& .settings.container': {
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                minWidth: 960,
                maxWidth: 1920,
                '& > .topbar': {
                    display: 'flex',
                    alignItems: 'center',
                    flexShrink: 0,
                },
                '& > .content': {
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 20,
                    flexGrow: 1,
                    overflowY: 'hidden',
                }
            }
        }
    }
}
