import { useMemo, useRef } from 'external/react'
import {clsx, styled, styleAndCapture, Popper, IconButton} from 'external/material'
import Icon from 'components/Common/Icon'
import useTranslations from 'hooks/translations'
import {formatDate} from 'external/date-fns'

const StyledEventLog = styleAndCapture('div', 'log', 'onLogClick')(eventLogButtonStyles)

export default function EventLogButton(props) {
  const {log, onLogClick} = props
  const buttonRef = useRef(null) 
  return (<StyledEventLog {...props}>
        <span ref={buttonRef}
            className={clsx("lessonLink", "log", {hidden: log.log.length == 0})}
            onClick={e => {
                onLogClick({anchorEl: buttonRef.current, log})
            }}
        >
            <Icon name="faCalendarDays" style={{color: "#2c75bd", fontSize:'15pt'}} />
        </span>
    </StyledEventLog>)
}

function eventLogButtonStyles() {
    return {

    }
}

const StyledEventLogPopper = styled('div')(eventLogPopperStyles)

export function EventLogPopper(props) {
  const {anchorEl, onClose} = props
  const {language} = useTranslations()
  const open = useMemo(() => anchorEl != null, [anchorEl])
  const {lesson, log = []} = props.log

  return (
    <Popper
      placement="top"
      anchorEl={anchorEl}
      open={open}
      disablePortal={false}
      sx={{zIndex: 100}}
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
        {
          name: 'arrow',
          enabled: false,
          options: {
          },
        },
      ]}
    >
      <StyledEventLogPopper>
        <div className="header">
          <div className="title">{lesson}</div>
          <IconButton  onClick={onClose} className="close"><Icon name="maClose" /></IconButton>
        </div>
        <div className="content">
          {log.map((entry, i) => {
              return (
              <div key={i}>
                  <span className="stamp">{formatDate(entry.stamp, 'yyyy-MM-dd - hh:mm aa',language)}</span>
              </div>)
            
          })}
        </div>
      </StyledEventLogPopper>
    </Popper>
  )
}

function eventLogPopperStyles(){
 return {
   backgroundColor: '#fff',
   minWidth: 400,
   display: 'flex',
   borderRadius: 10,
   flexDirection: 'column',
   color: '#798092',
   border: 'solid 1px #e5e7ee',
   boxShadow: '1px 0px 4px #e5e7ee',
   '& .header': {
    padding: '20px 20px',
    display: 'flex',
    borderBottom: 'solid 1px #e5e7ee',
    flexShrink: 0,
    fontWeight: 'bold',
   '& .title': {
     width: 325,
     overflow: 'hidden',
     whiteSpace: 'nowrap',
     textOverflow: 'ellipsis'
   },
   '& .close': {
      position: 'absolute',
     right: 6,
     top: 12 
    }
   },
   '& .content': {
      padding: '15px 20px',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
     '& .name': {
       display: 'flex',
       fontStyle: 'oblique',
       '& .title': {
          display: 'flex',
          justifyContent: 'flex-start',
          wordBreak: 'break-all',
          marginRight: 20,
          flexGrow: 1,
          '&.hidden': {
            display: 'none'

          }
       },
        '& .date': {
          flexShrink: 0,
         display: 'flex',
          justifyContent: 'flex-end'
        }
     },
     '& .text': {
       padding: 10,
       minHeight: 100,
       marginTop: 10,
       borderRadius: 5,
       border: 'solid 1px #e5e7ee',
     }
   }
 } 
}
