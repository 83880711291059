import {styled, clsx, Box} from 'external/material'

const StyledHeader = styled(Box)(headerStyles)

export default function Header({date, daySize}) {
    return (
        <StyledHeader className={clsx('header', 'week')} style={{minWidth: daySize.width, maxWidth: daySize.width}} >{date.format('ddd')}</StyledHeader>
    )
}

function headerStyles() {
  return {
    "&.header": {
        display: 'flex',
        justifyContent: 'flex-end',
        flexGrow: 1,
        fontWeight: 'bold',
        padding: '6px 10px',
        textTransform: 'uppercase',
        "&:first-of-type":{
            borderLeft: 'none'
        }
    }
  }
}