import {SvgKeepRatio} from 'utils/style'

export default function LessonIcon(props) {
  const fill = props.fill || '#92aabb'
  const backgroundColor = props.backgroundColor || 'none'
  return (<SvgKeepRatio viewBox={{width: 16, height: 20}} {...props}>
    <g stroke="none" strokeWidth="1" fill={backgroundColor} fillRule="evenodd">
      <polygon  stroke={fill} transform="translate(7.142160, 9.330075) rotate(-180.000000) translate(-7.142160, -9.330075) " points="14.2843192 0.429939099 14.2843192 18.2302102 4.02397601 18.2302102 0 14.2148531 0 0.429939099"></polygon>
      <path d="M10.0921605,3.1671777 L10.0921605,4.6842314 L11.093018,4.6842314 L11.093018,7.50153309 L3.17531185,7.50153309 L3.17531185,3.1671777 L10.0921605,3.1671777 Z"  stroke={fill}></path>
      <rect fill={fill} x="2.67531185" y="9.77965155" width="8.91770616" height="1"></rect>
      <rect fill={fill} x="2.67531185" y="11.55777" width="8.91770616" height="1"></rect>
      <rect fill={fill} x="2.67531185" y="13.3358885" width="8.91770616" height="1"></rect>
      <path d="M14.2191963,0.5 L14.2191963,4.65897732 L10.1189012,0.684873885 L14.2191963,0.5 Z"  stroke={fill} transform="translate(12.169049, 2.579489) rotate(-180.000000) translate(-12.169049, -2.579489) "></path>
    </g>
  </SvgKeepRatio>)
}