import ClearableQueryFilter from 'components/Common/ClearableQueryFilter'

import useTranslations from 'hooks/translations'
import useCatalog from 'hooks/catalog'

export default function GradeFilter (props) {

  const {translate} = useTranslations()
  const {gradesList} = useCatalog()

  return (<ClearableQueryFilter {...{...props, caption: translate('grade-label'), items: gradesList, name: 'grade'}} />)

}
