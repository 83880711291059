import { useMemo } from 'external/react'
import {styled, clsx} from 'external/material'
import { orderBy } from 'external/lodash'

import useTranslations from 'hooks/translations'

import SearchPattern from 'components/Common/SearchPattern'
import { BackToDashboard } from 'components/Common/BackButton'
import DropdownQueryFilter from 'components/Common/DropdownQueryFilter'
import FilterChip from 'components/Common/FilterChip'

import { centerVertically } from 'utils/style'

import AddGroupForm from './AddGroup/Form'
import AddGroup from './AddGroup'
import useAdmin from 'hooks/admin'
import useCatalog from 'hooks/catalog'

const StyledFilterBar = styled('div')(filterBarStyles)

export default function FilterBar(props) {
    
    const {className} = props
    const {languages} = useTranslations()
    const {subjectsList, gradesList} = useCatalog()
    const {groupsCounts, teacherList} = useAdmin()
    
    const languageFilterList = useMemo(() => {
        const available = Object.keys(groupsCounts.languages || {})
        return languages.filter(i => available.indexOf(i.id) >= 0)
    }, [languages, groupsCounts])
    
    const teacherFilterList = useMemo(() => {
        const available = Object.keys(groupsCounts.teachers || {})
        return orderBy(teacherList.items.filter(i => available.indexOf(i.id) >= 0), 'name')
    }, [groupsCounts, teacherList])

    const subjectsFilterList = useMemo(() => {
        const available = Object.keys(groupsCounts.subjects || {})
        return subjectsList.filter(i => available.indexOf(i.id) >= 0)
    }, [groupsCounts, subjectsList])

    const gradesFilterList = useMemo(() => {
        const available = Object.keys(groupsCounts.grades || {})
        return gradesList.filter(i => available.indexOf(i.id) >= 0)
    }, [groupsCounts, gradesList])



    return <StyledFilterBar className={className}>
        <div className="row">
            <BackToDashboard />
            <SearchPattern />
            <div style={{flexGrow: 1}} />
            <div className="filter-chip" >
                <FilterChip name="pattern" />
            </div>
            <AddGroupForm />
            <AddGroup className="add-group" />
        </div>
        <div className="row">
            <DropdownQueryFilter name="language" items={languageFilterList} />
            <DropdownQueryFilter name="subjectId" items={subjectsFilterList} />
            <DropdownQueryFilter name="gradeId" items={gradesFilterList} />
            <DropdownQueryFilter name="teacherId" items={teacherFilterList} />
        </div>
    </StyledFilterBar>
}

function filterBarStyles() {
    return {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        '& > .row': {
            display: 'flex',
            flexWrap: 'no-wrap',
            flexShrink: 0,
            marginBottom: 15,
            gap: 20,
            '& .roles': {
                display: 'flex',
                marginLeft: 5,
                gap: 20,
                flexGrow: 1
            },
            '& .add-group': {
                display: 'flex'
            },
            '& .filter-chip': {
                ...centerVertically
            }
        }
    }
}