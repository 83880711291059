import {styled, clsx, Button} from 'external/material'
import {useEffect} from 'external/react'
import moment from 'external/moment'

import useRollbook from 'hooks/rollbook'
import useTranslations from 'hooks/translations'

import RollbookName from './RollbookName'
import RollbookPeriods from './RollbookPeriods'

const currentYear = moment().get('year')
const StyledCreatePeriods = styled('div')(createPeriodsStyles)

export default function CreatePeriods () {
  
  const {
    update,
    periods,
    rearrange,
    isSaving,
    rearrangePeriods,
    handleSave,
  } = useRollbook()
  const {translate, language} = useTranslations()

  useEffect(() => {
    update({periods: {items: {$apply: rearrangePeriods}}})
  }, [rearrange, language])

  return (
    <StyledCreatePeriods>
      <div className={'contentWrapper'}>
        <div className={'content'}>
          {periods.items.length > 0 && <RollbookName className={'section'} />}
          <div className={clsx('section', 'periods')}>
            <div className="label">
              {translate('periods-and-terms')}
            </div>
            <div className="value">
              <RollbookPeriods />
            </div>
          </div>
          <div className={clsx('section', 'buttons')}>
            <div className={'buttonsWrapper'}>
              <Button
                onClick={handleSave}
                className={clsx('button', 'continue', 'navy')}
                disabled={isSaving}
              >
                {translate('save_label')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </StyledCreatePeriods>
  )
}

function createPeriodsStyles(theme) {

  return {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 0,
    backgroundColor: '#fff',
    color: '#4f576e',
    border: 'solid 1px #d9d9d9',
    borderRadius: 5,
    '& .header': {
      borderBottom: 'solid 1px #bdd0d7',
      flexShrink: 0,
      padding: 15,
      display: 'flex',
      justifyContent: 'center',
      fontSize: '12pt',
      fontWeight: 'bold'
    },
    '& .contentWrapper': {
      display: 'flex',
      justifyContent: 'center',
      overflowY: 'hidden',
      flexGrow: 1,
      padding: 30
    },
    '& .content': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flexGrow: 1,
      // minWidth: theme.breakpoints.values.sm, 
      maxWidth: 1280
    },
    '& .section': {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 30,
      "&:firs-child": {
        marginTop:0
      },
      "&> .label": {
        marginBottom: 10
      }
    },
    '& .periods': {
      flexGrow: 1,
      overflowY: 'hidden',
      "& > .value": {
        display: 'flex',
        overflowY: 'auto',
        flexGrow: 1
      }
    },
    '& .buttons': {
      justifyContent: 'center'
    },
    '& .buttonsWrapper': {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    '& .button': {
      width: 150,
      padding: '10px 10px',
      fontSize: '12pt',
    },
    '& .continue': {
      marginLeft: 20
    },
    '& .navy': {
      backgroundColor: '#2c7cbd',
      color: '#fff',
      '&:disabled': {
        opacity: 0.6,
      },
      '&:hover': {
        backgroundColor: '#2ca5ec',
        borderColor: '#2ca5ec',
        boxShadow: 'none',
      },
    },
  }
}
