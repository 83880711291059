import {omit} from 'external/lodash'
import {Link, useLocation, forwardRef, stringifyQueryString, parseQueryString} from 'external/react'
import {
   styled
  ,MuiPagination
  ,MuiPaginationItem 
} from 'external/material'

const Pagination = styled(MuiPagination)(() => ({
  ul: {
    justifyContent: 'center'
  }
}))

const PaginationItem = styled(MuiPaginationItem)(({theme}) => ({
  root: {
    color: '#4f576e',
    fontSize: '12pt',
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      borderRadius: 3,
    },
  },
  outlined: {
    backgroundColor: '#fff',
    borderRadius: 3,
    border: 'solid 1px #909ebb'
  },
  page: {
  },
  selected: {
    borderRadius: 3,
    backgroundColor: '#c9f1fd !important'
  }
}))

const PageLink = forwardRef(
  (props, ref) => {
    const location = useLocation()
    const query = parseQueryString(location.search)
    return <Link
      ref={ref}
      to={`${location.pathname}?${stringifyQueryString({...query, page: props.itemPage})}`}
      {...omit(props, ['itemPage'])}
    />
  }
)

export default function TablePagination(props) {
  const page = props.page || 1
  const count = props.count && props.count != Infinity ? props.count : 0
  return (
      <Pagination
        count={count}
        page={page}
        shape="rounded"
        renderItem={item => {
          const variant = ['next', 'previous'].indexOf(item.type) >= 0 ? 'outlined' : 'text'
          return  (<PaginationItem itemPage={item.page} component={PageLink}  {...{...item, variant}} page={item.page} sx={{...(variant == 'outlined' ? {backgroundColor: '#fff'} : {})}} />)
        }}
      />
  )
}

