import {omit} from 'external/lodash'
import {
   forwardRef
  ,NavLink
  ,useLocation
  ,stringifyQueryString
  ,useMemo
  ,parseQueryString
} from 'external/react'

const SortLink = forwardRef(
  (props, ref) => {
    const location = useLocation()
    const query = useMemo(() => parseQueryString(location.search), [location.search])
    const orderBy = `${props.orderBy == props.property && props.order == 'asc' ? '-' : ''}${props.property}`
    return <NavLink
      ref={ref}
      to={`${location.pathname}?${stringifyQueryString({...query, orderBy})}`}
      {...omit(props, ['order', 'property', 'orderBy'])}
    />
  }
)

export {SortLink as default}
