import { styled, clsx } from 'external/material'

import StudentSelector from 'components/Common/StudentSelector'

import useAdmin from 'hooks/admin'
import useTranslations from 'hooks/translations'

const StyledStep = styled('div')(stepStyles)


export default function StudentStep(props) {

    const {touchField, className, errors} = props
    const {update, sendUsersInfo} = useAdmin()
    const {studentList, selectedStudents, checkedStudents, selectedTeachers} = sendUsersInfo
    const {translate} = useTranslations()
    
    const handleStudentsChecked = (studentId) => {
      update({sendUsersInfo: {checkedStudents:{[studentId]: {$apply: checked => !checked}}}})
    }

    const handleStudentsChange = (props) => {
        const {right: selectedStudents} = props
        touchField('passports')()
        update({
            sendUsersInfo: {
                selectedStudents: {$set: selectedStudents},
                passports: {$set: [...selectedTeachers, ...selectedStudents]}
            }
        })
    }

    return (
        <StyledStep className={clsx('step', 'container', className)}>
            <StudentSelector {...{studentList, selectedStudents, checkedStudents, handleStudentsChange, handleStudentsChecked}} />
            {errors.passports && <label className="field-error">{translate(errors.passports)}</label>}
        </StyledStep>
    )
}

function stepStyles() {
    return {
        '& .student-selector': {
            flexGrow: 1
        }
    }
}