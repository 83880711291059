import {useMemo} from 'external/react'
import {styled} from 'external/material'
import useAdmin from 'hooks/admin'
import useTranslations from 'hooks/translations'

const StyledSendUsersInfoButtons = styled('div')(sendUsersInfoButtonStyles)

export default function SendUsersInfoButtons ({
  className,
  handleBack,
  handleNext,
  handlePrevious,
  handleSave,
  canSave
}) {

  const {translate, language} = useTranslations()
  const {sendUsersInfo: {activeTab}} = useAdmin()

  const left = useMemo(() => {
    const tabs = {
      comment: {label: 'cancel', enabled: true, onClick: handleBack},
      students: {label: 'back', enabled: true, onClick: handlePrevious},
      preview: {label: 'back', enabled: true, onClick: handlePrevious},
    }
    return tabs[activeTab]
  }, [activeTab, language])

  const right = useMemo(() => {
    const tabs = {
      comment: {label: 'continue', enabled: true, onClick: handleNext},
      students: {label: 'continue', enabled: true, onClick: handleNext},
      preview: {label: 'send', enabled: canSave, onClick: handleSave},
    }
    return tabs[activeTab]
  }, [activeTab, canSave])

  return (
    <StyledSendUsersInfoButtons className={className}>
      <div className="filler" />
      <button disabled={!left.enabled} className={left.label} onClick={left.onClick}>
        {translate(`${left.label}_label`)}
      </button>
      <button disabled={!right.enabled} className={right.label} onClick={right.onClick}>
        {translate(`${right.label}_label`)}
      </button>
    </StyledSendUsersInfoButtons>
  )
}

function sendUsersInfoButtonStyles() {
    return {
        display: 'flex',
        marginTop: 15,
        "& .filler": {
          flexGrow: 1
        },
        "& button": {
          fontSize: '12pt',
          borderRadius: '4px',
          padding: '8px',
          width: '150px',
          cursor: 'pointer',
          "&.cancel, &.back": {
            backgroundColor: '#fff',
            color: '#687189',
            border: 'solid 1px #d2d2d2',
            marginRight: '20px',
            "&:hover": {
            }
          },
          "&.continue, &.send": {
            backgroundColor: '#2c75bd',
            color: '#fff',
            border: 'none',
            "&:hover": {
            }
          },
          "&.send[disabled]": {
            cursor: 'default',
            opacity: '0.5',
            border: '1px solid #999999',
            backgroundColor: '#cccccc',
            color: '#666666'
          }

        }
    }
}
