import {omit} from 'external/lodash'

export default function Label(props) {
  const {value, maxLength} = props
  const labelProps = omit(props, ['value', 'maxLength'])
  const hasTitle = value.length > maxLength
  const title = hasTitle ? value : undefined
  const text = hasTitle ? `${value.substring(0, maxLength - 3)}...` : value
  return (<label {...{...labelProps, title}}>{text}</label>)
}
