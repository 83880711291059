import {styled, clsx} from 'external/material'
import StudentSelector from 'components/Common/StudentSelector'
import useAdmin from 'hooks/admin'

const StyledStudentCommentsStep = styled('div')(studentCommentsStepStyles)

export default function ScheduleCommentStudentsStep(props) {

  const {update, scheduleCommentForm: {studentList, selectedStudents, checkedStudents}} = useAdmin()
  
  const handleStudentsChecked = (studentId) => {
    update({scheduleCommentForm: {checkedStudents:{[studentId]: {$apply: checked => !checked}}}})
  }

  const handleStudentsChange = (props) => {
    const {right: selectedStudents} = props
    update({scheduleCommentForm: {selectedStudents: {$set: selectedStudents}}})
  }
  
  return (
      <StyledStudentCommentsStep className={clsx('students-step', props.className)}>
        <StudentSelector {...{studentList, selectedStudents, checkedStudents, handleStudentsChange, handleStudentsChecked}} />
     </StyledStudentCommentsStep>
  )
}

function studentCommentsStepStyles(){
  return {
    '& .student-selector': {
      flexGrow: 1
    }
  }
}