import {styleAndCapture} from 'external/material'

import ClearableQueryFilter from './ClearableQueryFilter'

import useTranslations from 'hooks/translations'

const StyledFilter = styleAndCapture(ClearableQueryFilter, 'filterLabel')(filterStyles)

export default function DropdownQueryFilter(props){
    const { name } = props
    const { translate } = useTranslations()
    const className = `select-${name}`
    const caption = translate(`users-filter-${name}`)
    return(<StyledFilter
        className={className}
        caption={caption}
        preserveQuery={true}
        interceptQuery={q => ({...q, page: undefined})}
        getOptionText={item => {
            if (item.name) return item.name
            else return translate(item.id)}
        }
        {...props}
    />)
}

function filterStyles() {
    return {
        "&.select-role": {
            width: 165
        },
        "&.select-gradeId": {
            width: 120
        },
        "&.select-language": {
            width: 150
        },
        "&.select-subjectId": {
            width: 180
        },
        height: 40
    }
}