import create from 'utils/command'
import {styled} from 'external/material'
import Icon from 'components/Common/Icon'

import useTranslations from 'hooks/translations'
import useAdmin from 'hooks/admin'

const StyledEditScheduleComment = styled('div')(editScheduleCommentStyles)

export default function EditScheduleComment(props) {

    const {event} = props
    const {translate} = useTranslations()
    const {update} = useAdmin()

    const contentText = translate('edit-shedule-comment-text', props.content)

    async function handleOpen() {
        update({editScheduleCommentForm: {open: {$set: true}, id: {$set: event.id}}})
    }
    
    return (<StyledEditScheduleComment>
        <span onClick={handleOpen} title={contentText} className={props.className}><Icon name="faPenToSquare"/></span>
    </StyledEditScheduleComment>)
    
}

function editScheduleCommentStyles() {
    return {
        color: '#2c75bd',
        cursor: 'pointer',
        fontSize: 18
    }
}