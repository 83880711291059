import { intersectionBy, uniq, uniqBy, orderBy, differenceBy, sortBy } from 'external/lodash'
import { searchSubstring, isEmpty, capitalizeFirstLetter } from 'utils/text'
import {useState, useEffect, useMemo} from 'external/react'
import {clsx, styled} from 'external/material'

import SelectStudents from 'components/Common/TransferList/SelectStudents'

import useTranslations from 'hooks/translations'
import useCatalog from 'hooks/catalog'

import { centerVertically } from 'utils/style'


const StyledStudentSelector = styled('div')(studentSelectorStyles)


const initialFilters = {
  pattern: '',
  grade: '',
  category: ''
}

export default function StudentSelector(props) {

  const {translate} = useTranslations()
  const [filters, setFilters] = useState(initialFilters)
  const {gradesList} = useCatalog() 

  const {studentList, selectedStudents, checkedStudents, handleStudentsChange, handleStudentsChecked} = props
  
  const filteredStudents = useMemo(()=> {
    let filteredStudents = studentList
    if (filters.pattern != ''){
      filteredStudents = filteredStudents.filter(s => searchSubstring(s.name, filters.pattern))
    }
    if (filters.grade != '') {
      filteredStudents = filteredStudents.filter(s => s.category.grade == filters.grade)
    }
    if (filters.category != '') {
      filteredStudents = filteredStudents.filter(s => s.category.category == filters.category)
    }
    if (selectedStudents.length > 0) {
      filteredStudents = differenceBy(filteredStudents, selectedStudents, 'id')
    }
    // Sort students by name ascending
    filteredStudents = sortBy(filteredStudents, 'name')
    return filteredStudents
  }, [studentList, selectedStudents, filters])

  const studentGrades = useMemo(() => {
    const studentGrades = uniq(studentList.map(s => s.category.grade)).map(id => ({id}))
    return [{id: '', name: '*'}].concat(intersectionBy(gradesList, studentGrades, 'id'))
  }, [studentList, gradesList])

  const studentCategories = useMemo(() => {
    const studentCategories = orderBy(uniqBy(studentList.map(s => s.category), 'category'), 'categoryName')
    return [{category: '', categoryName: '*'}].concat(studentCategories)
  }, [studentList])
 
  useEffect(() => {
    if (open) {
      setFilters(initialFilters)
    }
  }, [open])
  
  const handlePatternChange = (e) => {
    setFilters({...filters, pattern: e.target.value})
  }

  const handleGradeChange = (e) => {
    setFilters({...filters, grade: e.target.value})
  }

  const handleCategoryChange = (e) => {
    setFilters({...filters, category: e.target.value})
  }

  return (
      <StyledStudentSelector className={clsx('student-selector', props.className)}>
        <div className='select-students-filters'>
          <div className={clsx('select-students-filter', 'search')}>
            <div className='select-students-filter-label'>{capitalizeFirstLetter(translate('search'))}:</div>
            <input
              type="text"
              value={filters.pattern}
              onChange={handlePatternChange}
            />
          </div>
          <div className={clsx('select-students-filter', 'grade')}>
            <div className='select-students-filter-label'>{capitalizeFirstLetter(translate('grade'))}:</div>
            <select value={filters.grade} onChange={handleGradeChange}>
              {studentGrades.map(g => (<option key={g.id} value={g.id}>{g.name}</option>))}
            </select>
          </div>
          <div className={clsx('select-students-filter', 'category')}>
            <div className='select-students-filter-label'>{capitalizeFirstLetter(translate('category'))}:</div>
            <select value={filters.category} onChange={handleCategoryChange}>
              {studentCategories.map(g => (<option key={g.category} value={g.category}>{isEmpty(g.categoryName) ? translate('uncategorized-students') : g.categoryName}</option>))}
            </select>
          </div>
        </div>
        <SelectStudents
          students={filteredStudents}
          selectedStudents={selectedStudents}
          checkedStudents={checkedStudents}
          handleStudentsChange={handleStudentsChange}
          handleStudentsChecked={handleStudentsChecked}
        />
      </StyledStudentSelector>
  )
}

function studentSelectorStyles(){
  return {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    marginTop: 20,
    "& .select-students-filters": {
      display: 'flex',
      padding: '0 20px',
      "& input": {
       padding: '8.5px 14px' 
      },
      "& .select-students-filter": {
        display: 'flex',
        marginLeft: 15,
        marginBottom: 20,
        '&:first-of-type': {
          marginLeft: 0
        },
        "& .select-students-filter-label": {
          ...centerVertically,
          marginRight: 8 
        }
      }
    },
    "& .transfer-list": {
      flexGrow: 1
    },
    "& .select-students": {
      flexGrow: 1
    }

  }
}