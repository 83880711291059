import {Link} from 'external/react'
import {clsx, styled} from 'external/material'

import useTranslations from 'hooks/translations'

const StyledSubject = styled('div')(subjectCardStyles)

export default function SubjectCard(props) {
  const {id, name, total, used} = props
  const {translate} = useTranslations()
  const subjectLink = id
  return (
    <StyledSubject>
      <Link className="subject-link" to={subjectLink}>
        <div className="subject-label">
          {translate(`settings-${id}-label`)}
        </div>
        <div className="lesson-count">
          <div className="wrapper">
            <label className={clsx('label', 'total')}>
              {translate('contracted-credits')}: {total}
            </label>
            <label className={clsx('label', 'label-border', 'used')}>
              {translate('used-credits')}: {used}
            </label>
            <label className={clsx('label', 'available')}>
              {translate('available-credits')}: {total < used ? 0 : total - used}
            </label>
          </div>
        </div>
      </Link>
    </StyledSubject>
  )
}

function subjectCardStyles() {
  return {
    width: 'calc(100% / 3 - 40px)',
    flex: 1,
    minWidth: 200,
    maxWidth: 500,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 5,
    border: 'solid 1px #d9d9d9',
    '&:hover': {
      transform: 'scale(1.01)'
    },
    '&.filler': {
      backgroundColor: 'transparent',
      border: 'none'
    },
    '& .subject-link': {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      textDecoration: 'none'
    },
    '& .color-band': {
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      height: 4 
    },
    '& .subject-label': {
      display: 'flex',
      padding: '35px 0',
      justifyContent: 'center',
      color: '#565f77',
      fontWeight: 'bold',
      fontSize: '20pt',
      borderBottom: 'solid 1px #e1eaee',
      textAlign: 'center'
    },
    '& .lesson-count': {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      fontSize: '12pt',
      color: '#6a758e',
      '& .wrapper': {
        display: 'flex',
      },
      '& label': {
        padding: '8px 0',
        flex: 1,
        textAlign: 'center',
        color: '#fff',
        cursor: 'pointer',
        textTransform: 'lowercase',
        '&:first-letter': {
          textTransform: 'capitalize',
        },
      },
      '& .used': {
        backgroundColor: '#f28888',
      },
      '& .available': {
        backgroundColor: '#66c571'
      },
      '& .total': {
        backgroundColor: '#b4c3ca'
      },
      '& .value': {
        marginLeft: 8,
        marginRight: 5
      }
    }
  }
}