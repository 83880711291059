import {styled, clsx} from 'external/material'
import {chunk} from 'external/lodash'
import { isEmpty } from 'utils/text'

import LanguageFilter from 'components/Pages/Catalogue/LanguageFilter'
import SearchPattern from 'components/Common/SearchPattern'

import useSession from 'hooks/session'
import useCatalog from 'hooks/catalog'

import Subject from './Subject'
import SubjectRow from './SubjectRow'
import { BackToDashboard } from 'components/Common/BackButton'

const StyledSubjects = styled('div')(subjectStyles)

export default function CatalogueSubjects () {
    const {catalogSubjects} = useCatalog()
    const {navigation, pushHistory} = useSession()
    const rows = chunk(catalogSubjects.items || [], 3)

    function handlePatternSearch(query) {
        pushHistory('catalogue-lessons', true)
        navigation.catalogSearch.goWithQuery(query, false)
    }

    return (
    <StyledSubjects>
      <div className="filters-row">
          <div className="filter">
              <BackToDashboard />
              <LanguageFilter  />
          </div>
          <div className={clsx("filter", "filler")} />
          <SearchPattern  className="filter" searchOnBlur={false} onPatternSearch={handlePatternSearch} />
      </div>
      <div className="content">
        {rows.map((subjects, index) => (
            <SubjectRow key={index} length={subjects.length}>
                {subjects.map((s, i) => <Subject key={i} {...s} />)}
            </SubjectRow>
        ))}
      </div>
    </StyledSubjects>)
}

function subjectStyles(){
    return {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        padding: '40px 0',
        "& .filters-row": {
            display: 'flex',
            justifyContent: 'space-evenly',
            '& .backButton': {
                marginRight: 20
            },
            '& .language': {
                display: 'flex'
            },
            '& .filter': {
                width: 'calc(100% / 3 - 40px)',
                minWidth: 200,
                maxWidth: 500,
                '&:first-of-type': {
                    display: 'flex'
                    
                }
            }
        }
    }
}
    