

export function getLanguageRoute (route = '') {
  return `:languageId/${route}`
}

export function getPageRoute (route = '') {
  return getLanguageRoute(`:pageName/${route}`)
}

export function getGroupRoute (route = '') {
  return `:groupId/${route}`
}

export function getUnitsRoute (route = '') {
  return getPageRoute(getGroupRoute(`units/:unitId/${route}`))
}

export const routes = {
  language:'/:languageId',
  page:'/:languageId/:pageName',
  section:'/:languageId/:pageName/:section',
  users:'/:languageId/users',
  usersAction:'/:languageId/users/:action/:userId',
  catalog:'/:languageId/catalogue',
  catalogSearch:'/:languageId/:pageName/search',
  catalogCourses:'/:languageId/:pageName/:subject',
  catalogUnits:'/:languageId/:pageName/:subject/:course',
  catalogLessons:'/:languageId/:pageName/:subject/:course/:unit',
  calendar:'/:languageId/calendar',
  calendarView:'/:languageId/calendar/:calendarView',
  monitoringTeacher:'/:languageId/monitoring/teachers/:teacherId',
  monitoringGroup:'/:languageId/monitoring/teachers/:teacherId/:groupId',
  monitoringStudent:'/:languageId/monitoring/student/:studentId',
  messages: '/:languageId/messages/:section',
  settings: '/:languageId/settings/:section'
}
