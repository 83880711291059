import {useMemo} from 'external/react'
import TabBar from 'components/Common/TabBar'
import useAdmin from 'hooks/admin'
import {isEmpty} from 'utils/text'

const tabFields = {
  group:['groupName', 'course', 'selectedTeachers'],
  students: ['selectedStudents', 'credits']
}

export default function EditGroupTabBar(props) {

  const {errors} = props
  const {editGroupForm: {activeTab}, update} = useAdmin()
  
  function handleTabButtonClick(tab) {
    if (activeTab != tab) {
      update({editGroupForm: {activeTab: {$set: tab}}})
    }
  }

  const checkErrors = useMemo(() => {
    return tabId => tabFields[tabId].find(fieldId => !isEmpty(errors[fieldId])) != undefined
  }, [errors])

  return (
    <TabBar
      tabs={[
        {id: 'group', label: 'add-group-form-tab-label-group', icon: 'faUserGroup'},
        {id: 'students', label: 'add-group-form-tab-label-student', icon: 'faUserLarge'},
      ]}
      tabButtonActiveTab={activeTab}
      tabButtonOnClick={handleTabButtonClick}
      tabButtonCheckErrors={checkErrors} />
  )
}
