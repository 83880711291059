import {styled} from 'external/material'
import {Link} from 'external/react'
import {formatNumber} from 'utils/text'
import { centerVertically } from 'utils/style'

import useSession from 'hooks/session'
import useTranslations from 'hooks/translations'

import Icon from 'components/Common/Icon'


import CourseCover from 'components/Common/CourseCover'

const StyledCourse = styled('div')(courseStyles)

export default function Course(props) {

  const {navigation} = useSession()
  const {translate} = useTranslations()
  const {id: course, name: courseName, lessonCount, inContract} = props
  const label = translate(lessonCount > 1 ? 'lessons_label' : 'lesson_label')
  const courseLink = navigation.catalogUnits.linkWithQuery({}, true, {course})

  return (
    <StyledCourse className="course">
      <Link className="course-link" to={courseLink}>
        <div className="cover">
          <CourseCover {...{course, courseName, viewBox: {width: 201, height: 260}}} />
        </div>
        <div className="footer">
          <div className="course-name"><div className="wrapper">{courseName}</div></div>
          <div className="lesson-count-container">
            <div className="wrapper">
              <span className="lesson-count"><Icon name="esLessonCount" /></span>
              <label className="value">
                {formatNumber(lessonCount)}
              </label>
              <label className="label">{label}</label>
            </div>
          </div>
        </div>
      </Link>
      {!inContract && <div className="lock"><Icon name="esLock" width={40} height={40} /></div>}
    </StyledCourse>
  )
}

function courseStyles() {
  return {
    '& .lock': {
      position: 'relative',
      top: '-350px',
      left: '168px',
      color: '#fff',
      zIndex: 1000 
    },

    padding: '0 5px',
    '& .course-link': {
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer',
      backgroundColor: '#fff',
      textDecoration: 'none',
      borderRadius: '5px',
      border: 'solid 1px #dbdbdb',
      "& .lesson-count-container": {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          fontSize: '12pt',
          padding: '8px 0',
          color: '#6a758e',
          '& .wrapper': {
            display: 'flex',
            justifyContent: 'center'
          },
          '& .lesson-count': {
            ...centerVertically
          },
          '& .label': {
            ...centerVertically,
            cursor: 'pointer',
            textTransform: 'lowercase'
          },
          '& .value': {
            ...centerVertically,
            marginLeft: 8,
            marginRight: 5
          }
      },
      '&:hover': {
        transform: 'scale(1.01)'
      },
      '& .cover': {
        display: 'flex',
        justifycontent: 'center',
        padding: '15px',
        borderBottom: 'solid 1px #dbdbdb',
        '& img': {
          border: 'solid 1px #dbdbdb'
        }
      },
      '& .footer': {
        display: 'flex',
        padding: '8px 0',
        flexDirection: 'column',
        '& .course-name': {
          display: 'flex',
          justifyContent: 'center',
          fontWeight: 'bold',
          color: '#333e54',
          textOverflow: 'ellipsis',
          '& .wrapper': {
            overflow: 'hidden',
            textAlign: 'center',
            width: 210,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }
        }
      }
    }
  }
}