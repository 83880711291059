import MonitoringIcon from "./svg/MonitoringIcon"
import UsersIcon from "./svg/UsersIcon"
import AssessmentIcon from "./svg/AssessmentIcon"
import GradeIcon from "./svg/GradeIcon"
import LessonIcon from "./svg/LessonIcon"
import HomeworkIcon from "./svg/HomeworkIcon"
import PreviewIcon from "./svg/PreviewIcon"
import DownloadIcon from "./svg/DownloadIcon"
import MessageIcon from "./svg/MessageIcon"
import ScheduleIcon from "./svg/ScheduleIcon"
import SideMenuOpenIcon from "./svg/SideMenuOpenIcon"
import SideMenuCloseIcon from "./svg/SideMenuCloseIcon"
import DashboardIcon from "./svg/DashboardIcon"
import MyCoursesIcon from "./svg/MyCoursesIcon"
import ChatIcon from "./svg/ChatIcon"
import GradebookIcon from "./svg/GradebookIcon"
import DownloadsIcon from "./svg/DownloadsIcon"
import CalendarIcon from "./svg/CalendarIcon"
import CatalogueIcon from "./svg/CatalogueIcon"
import LessonCountIcon from "./svg/LessonCountIcon"
import SettingsIcon from "./svg/SettingsIcon"
import ArrowLeftIcon from "./svg/ArrowLeftIcon"
import ArrowRightIcon from "./svg/ArrowRightIcon"
import DoubleArrowLeftIcon from "./svg/DoubleArrowLeftIcon"
import DoubleArrowRightIcon from "./svg/DoubleArrowRightIcon"
import OverviewIcon from "./svg/OverviewIcon"
import GuideIcon from "./svg/GuideIcon"
import LockIcon from "./svg/LockIcon"

const icons = {
  esArrowLeft: ArrowLeftIcon,
  esArrowRight: ArrowRightIcon,
  esDoubleArrowLeft: DoubleArrowLeftIcon,
  esDoubleArrowRight: DoubleArrowRightIcon,
  esMonitoring: MonitoringIcon,
  esUsers: UsersIcon,
  esAssessment: AssessmentIcon,
  esGrade: GradeIcon,
  esLesson: LessonIcon,
  esHomework: HomeworkIcon,
  esPreview: PreviewIcon,
  esDownload: DownloadIcon,
  esMessage: MessageIcon,
  esSchedule: ScheduleIcon,
  esSideMenuOpen: SideMenuOpenIcon,
  esSideMenuClose: SideMenuCloseIcon,
  esDashboard: DashboardIcon,
  esGroups: MyCoursesIcon,
  esChat: ChatIcon,
  esGradebook: GradebookIcon,
  esDownloads: DownloadsIcon,
  esCalendar: CalendarIcon,
  esCatalogue: CatalogueIcon,
  esLessonCount: LessonCountIcon,
  esSettings: SettingsIcon,
  esOverview: OverviewIcon,
  esGuide: GuideIcon,
  esLock: LockIcon
}

export default icons