import makeHead from 'components/Common/Table/Head' 

const StudentGroupsTableHead = makeHead([
  { id: 'groupname', align: 'left', disablePadding: false, useSorting: false},
  { id: 'subject', align: 'left', disablePadding: false, useSorting: false},
  { id: 'active', align: 'left', disablePadding: false, useSorting: false},
],({styles}) => ({...styles}))

const TeacherGroupsTableHead = makeHead([
  { id: 'group', align: 'left', disablePadding: false, useSorting: false},
  { id: 'subject', align: 'left', disablePadding: false, useSorting: false},
],({styles}) => ({...styles}))

const tableHeads = {
  student: StudentGroupsTableHead,
  teacher: TeacherGroupsTableHead
}

export {tableHeads as default}