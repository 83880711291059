import {omit} from 'external/lodash'
import create, {createPost} from 'utils/command'
import {useContext, useMemo} from 'external/react'
import Admin from 'context/admin-context'
import { extractId } from 'utils/functions'

const sendGroup = createPost('admin', 'add_clique')

export default function useAdmin () {

  const context = useContext(Admin.Context)
  const {state, update} = context

  const {exportUsers, events, eventsByDay, eventCategories, licenseCountForm,editUserForm, addUserForm, studentCategories, dashboardActivity, addGroupForm, editGroupForm,
    teacherList,
    groupList,
    studentLogs,
    scheduleCommentForm,
    editScheduleCommentForm,
    sendUsersInfo,
    downloadUsers,
    importUsers,
  } = state

  const [usersPageCount = {}, usersCounts = {}, ...users] = state.users.items
  const [groupsPageCount = {}, groupsCounts = {}, ...groups ] = state.groups.items
  const [scheduledMessagesPageCount = {}, ...scheduledMessages ] = state.scheduledMessages.items
  const [deletedMessagesPageCount = {}, ...deletedMessages ] = state.deletedMessages.items
  const [studentLogPageCount = {}, ...studentLog] = state.studentLog.items
  
  async function deleteUser(user) {
    await createPost('admin', 'delete_user')({User: user.id})
    update({users: {'$refreshLoadableList': true}})
  }

  async function createGroup(groupForm) {
    const {selectedStudents, selectedTeachers, course: Course, groupName: Name} = groupForm
    const Students = selectedStudents.map(extractId)
    const Teachers = selectedTeachers.map(extractId)
    await sendGroup({Students, Teachers, Name, Course})
    update({groups: {'$refreshLoadableList': true}})
  }

  async function deleteGroup(group) {
    await createPost('admin', 'delete_group')({Group: group.id})
    update({groups: {'$refreshLoadableList': true}})
  }

  const isGroupNameUsed = useMemo(() => (value) => {
    return groupList.items.find(g => g.groupName == value) != undefined
  }, [groupList])

  async function deleteTeacherScheduledMessage(event, reason) {
    await createPost('admin', 'deleteTeacherScheduledMessage')({event: event.id, reason})
    update({scheduledMessages: {'$refreshLoadableList': true}})
  }
  
  async function sendUsers(sendUsersInfo) {
    const {sendPuk: SendPuk, content: Content, passports, language: Language} = sendUsersInfo
    const Passports = [...passports.map(extractId)]
    return await createPost('admin', 'send_users')({SendPuk, Content, Passports, Language})
  }

  async function importUsersFile(importUsers) {
    const {importedUsers} = importUsers
    return await createPost('admin', 'importUsers')({users: importedUsers})
  }

  async function getPosibleUsernames(importUsers) {
    const {importedUsers} = importUsers
    return await createPost('admin', 'getPosibleUsernames')({users: importedUsers})
  }

  const teacherGroups =  useMemo(() => teacherList.items.length > 0
    ? teacherList.items.reduce(
      (groups, teacher) => ({
        ...groups,
        ...teacher.groups.filter(g => g.course !== null).reduce((acc, group) => {
          const teachers = ((groups[group.id] && groups[group.id].teacher) || []).concat([teacher.id])
          return {...acc, [group.id]: {...group, teachers}}
        }, {})
      }), {})
    : [], [teacherList.items])
  
  const studentRecords = useMemo(() =>
    groupList.items.reduce((groups, group) => {
      const groupRecord = omit(group, 'students')
      const students = group.students.reduce((students, student) => {
        const studentRecord = groups[student.passport] || {...student, groups: {}}
        studentRecord.groups = {...studentRecord.groups, [groupRecord.id]: {studentId: student.id, ...groupRecord}}
        return {...students, [student.passport]: studentRecord}
      }, {})
      return {...groups, ...students}
    }, {})
  , [groupList.items])

  const groupTeachers = useMemo(() => groupList.items.length > 0
    ? groupList.items.reduce((groups, group) => {
      const groupRecord = omit(group, 'teachers')
      const teachers = group.teachers.reduce((teachers, teacher) => {
        const teacherRecord = groups[teacher.passport] || {...teacher, groups: {}}
        teacherRecord.groups = {...teacherRecord.groups, [groupRecord.id]: groupRecord}
        return {...teachers, [teacher.passport]: teacherRecord}
      }, {})
      return {...groups, ...teachers}
    }, {})
    : {}, [groupList.items])
  
  const studentDownloads = useMemo(() => studentLogs.items.length > 0
    ? studentLogs.items.reduce((students, log)=> {
      const {downloadCount, onlineViews, downloads} = log
      return {...students, [log.id]: {downloadCount, onlineViews, downloads}}
    }, {})
    : {}
  , [studentLogs.items])
  
  const contractTerms = useMemo(() => state.contractTerms.items.length > 0
    ? state.contractTerms.items
    : [], [state.contractTerms.items])
  
  const contractSubjects = useMemo(() => {
    return contractTerms.reduce((contractSubjects, {course, credits, usedCredits}) => {
      const subjectId = course.substring(0, 2)
      const subject = contractSubjects[subjectId] || (contractSubjects[subjectId] = {credits: 0, usedCredits: 0, count: 0})
      subject.credits += credits
      subject.usedCredits += usedCredits
      subject.count += 1
      return contractSubjects
    }, {})
  }, [contractTerms])

  const contractGrades = useMemo(() => {
    return contractTerms.reduce((contractGrades, {course, credits, usedCredits}) => {
      const gradeId = course.substring(4, 7)
      const grade = contractGrades[gradeId] || (contractGrades[gradeId] = {credits: 0, usedCredits: 0, count: 0})
      grade.credits += credits
      grade.usedCredits += usedCredits
      grade.count += 1
      return contractGrades
    }, {})
  }, [contractTerms])


  return {
    contractTerms,
    contractSubjects,
    contractGrades,
    exportUsers,
    events,
    eventsByDay,
    eventCategories,
    teacherList,
    teacherGroups,
    studentLog,
    studentLogPageCount,
    studentLogs,
    studentDownloads,
    studentRecords,
    groupList,
    groupTeachers,
    usersPageCount, usersCounts, users, usersLoaded: state.users.loaded,
    groupsPageCount, groupsCounts, groups,
    update, deleteUser, createGroup, deleteGroup, licenseCountForm,
    isGroupNameUsed,
    addUserForm, editUserForm,
    addGroupForm, editGroupForm,
    studentCategories, dashboardActivity,
    scheduleCommentForm, editScheduleCommentForm,
    scheduledMessages,
    scheduledMessagesPageCount,
    deletedMessages,
    deletedMessagesPageCount,
    deleteTeacherScheduledMessage,
    sendUsersInfo,
    sendUsers,
    downloadUsers,
    importUsers,
    importUsersFile,
    getPosibleUsernames
  }

}