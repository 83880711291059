import {omit} from 'external/lodash'
import {styled} from 'external/material'
import { filterByPrefix } from 'utils/functions'
import TabButton from './TabButton'

const StyledTabBar = styled('div')(tabBarStyles)

export default function TabBar(props) {

  const {rest: tabProps, filtered: buttonProps} = filterByPrefix('tabButton', props)

  const {tabs} = tabProps

  return (
    <StyledTabBar {...omit(tabProps, 'tabs')}>
      {tabs.map((props, i) => <TabButton key={i} {...buttonProps} {...props} />)}
    </StyledTabBar>
  )
}

function tabBarStyles () {
  return {
    display: 'flex',
    borderBottom: 'solid 1px #dce1ec',
  }
}