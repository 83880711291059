import { useState } from 'external/react'
import {styled} from 'external/material'
import Icon from 'external/font-awesome'

import useTranslations from 'hooks/translations'
import ConfirmDialog from 'components/Common/ConfirmDialog'

const StyledDeleteScheduledMessage = styled('div')(deleteScheduledMessageStyles)

export default function DeleteScheduledMessage(props) {

    const [open, setOpen] = useState(false)
    const {scheduledMessage, onDeleteScheduledMessage} = props
    const {translate} = useTranslations()

    const contentText = translate('delete-scheduledMessage-text', scheduledMessage)

    function handleOpen() {
        setOpen(true)
    }
    function handleClose() {
        setOpen(false)
    }
    
    return (<StyledDeleteScheduledMessage>
        <span onClick={handleOpen} title={contentText}><Icon name="frTrashCan"/></span>
        <ConfirmDialog
            open={open}
            title={translate('confirm-action')}
            contentText={contentText}
            confirmText={translate('delete-scheduledMessage-confirmation', scheduledMessage)}
            confirmLabel={translate('yes')}
            cancelLabel={translate('no')}
            onConfirm={(reason) => {onDeleteScheduledMessage(scheduledMessage, reason)}}
            handleClose={handleClose}
            withReason={true}
        />
    </StyledDeleteScheduledMessage>)

}

function deleteScheduledMessageStyles() {
    return {
        color: '#d32f2f',
        cursor: 'pointer',
        fontSize: 18
    }
}