import { withComposableMixins } from 'utils/style'
import {createTheme} from 'external/material'
import {desktop} from 'settings'

const defaultTheme = createTheme({
    status: {
        defaultBorder: '1px solid #ced4da',
        smallMenuHeight: 25,
        menuHeight: 35
    },
    palette: {
        text: {
            title: '#1c4b90',
            subtitle: '#fff',
            primary: '#434a5d',
            secondary: '#8b919e'
        },
        primary: {
            light: '#68a3f0',
            main: '#2c7cbd',
            dark: '#0a4986',
            contrastText: '#fff',
        },
        paper: {
            light: '#fff',
            main: '#fff',
            dark: '#c2c2d2',
            contrastText: '#000'
        },
        background: {
            default: '#f2f2f2',
            paper: '#fff',
        },
        active: {
            indicator: '#ff8916',
            background: '#0f4071'
        }
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h1: undefined,
        h2: undefined,
        h3: undefined,
        h4: undefined,
        h5: undefined,
        h6: undefined,
        subtitle1: undefined,
        subtitle2: undefined,
        body2: undefined,
        button: undefined,
        caption: undefined,
        overline: undefined
    },
    breakpoints: {
        values: {
            desktop
        }
    },
    mixins: {
        toolbar: {}
    }
})

const theme = withComposableMixins(createTheme(defaultTheme, {
    typography: {
        color: defaultTheme.palette.text,
        title: {
            fontSize: '1.5rem',
            fontWeight: '500',
            [defaultTheme.breakpoints.down('desktop')]: {
               fontSize: '1.25rem'
            },
        },
        subtitle: {
            fontSize: '1.25rem',
            color: defaultTheme.palette.text.title,
            fontWeight: '500',
            [defaultTheme.breakpoints.down('desktop')]: {
               fontSize: '1.06rem'
            },
        },
        menuItem: {
            fontSize: '0.75rem',
            color: '#fff !important',
            [defaultTheme.breakpoints.down('desktop')]: {
               fontSize: '11px',
            },
        },
        caption: {
            fontSize: '1.20rem',
            color: defaultTheme.palette.text.title,
            fontWeight: '400',
            [defaultTheme.breakpoints.down('desktop')]: {
               fontSize: '0.95rem'
            },
        },
        secondaryCaption: {
            
        }
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: ({theme, ownerState}) => {
                   const {component, variant} = ownerState
                   const isLink = typeof(component) == 'object' && component.displayName == 'link'
                   return {
                       color: theme.palette.text[variant] || theme.palette.text.primary,
                        ...(isLink && {
                           textDecoration: 'none',
                       }
                    )}
                }
            }
        }
    },
    mixins: {
        toolbar: {
            [defaultTheme.breakpoints.up('desktop')]: {
                maxHeight: 50,
                minHeight: 50,
            },
            [defaultTheme.breakpoints.down('desktop')]: {
               maxHeight: 40,
               minHeight: 40 
            }
        },
        sidebar: {
            [defaultTheme.breakpoints.up('desktop')]: {
                width: 350
            },
            [defaultTheme.breakpoints.down('desktop')]: {
               width: 320,
            },
        },
        drawer: {
            [defaultTheme.breakpoints.up('desktop')]: {
                width: 120,
                '& svg': {
                    fontSize: '2.3rem',
                },
            },
            [defaultTheme.breakpoints.down('desktop')]: {
               width: 110,
               '& svg': {
                   fontSize: '1.7rem'
               }
            },
        }
    }
}))


export {theme as default}