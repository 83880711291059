import moment from 'external/moment'
import {take} from 'external/lodash'
import {styled, clsx, Box} from 'external/material'

import useTranslations from 'hooks/translations'
import useSession from 'hooks/session'
import useAdmin from 'hooks/admin'

import { changeDate } from '../Toolbar'
import Event from '../Event'

const StyledDay = styled(Box)(dayStyles)

const dayTitleHeight = 28
const eventHeight = 28
const viewMoreHeight = 20

export default function Day (props) {
    const {translate} = useTranslations()
    const {navigation} = useSession()
    const {eventsByDay = {}} = useAdmin()
    const {date, daySize} = props

    const day = moment(date).format('YYYY-MM-DD')
    const current = moment(date).isSame(new Date(), 'day')
    const displayCount = Math.floor((daySize.height - dayTitleHeight - viewMoreHeight - 14) / eventHeight)
    const events = eventsByDay[day] || []
    return (
        <StyledDay
            className={clsx('day', {current})}
            style={{
                minWidth: daySize.width,
                maxWidth: daySize.width,
                minHeight: daySize.height,
                maxHeight: daySize.height
            }}
            onClick={() => changeDate(navigation, 'day')(day)}
        >
            <div className="title">{date.format('D')}</div>
            <div className="events">
                {take(events, displayCount).map((props, i) => (<Event key={i} {...props}/>))}
            </div>
            <div style={{flexGrow: 1}} />
            <div className={clsx('view-more', {visible: events.length > displayCount})}>
                {translate("view-more")} {events.length - displayCount}...
            </div>
        </StyledDay>
    )
}

function dayStyles() {
    return {
        "&.day": {
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'column',
            borderLeft: 'solid 1px #e1eaee',
            "& .outside": {
                backgroundColor: '#b1b0b7',
                borderLeft: 'solid 1px #434044'
            },
            "&.current": {
                backgroundColor: '#eafdff'
            },
            "&:first-of-type": {
                borderLeft: 'none'
            },
            "& .title": {
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '5px 10px 0px 10px'
            },
            "& .view-more": {
                display: 'none',
                marginTop: '4px',
                marginRight: '10px',
                height: '26px',
                fontSize: '8.5pt',
                justifyContent: 'flex-end',
                cursor: 'pointer',
                "&.visible": {
                    display: 'flex',
                },
                "&:hover": {
                    fontWeight: 'bold'
                }
            }
        }
    }
}