import {useContext} from 'external/react'
import Profile from 'context/profile-context'
import Settings from 'context/settings-context'
import {getFullName} from 'utils/users'

export default function useProfile () {
  const {state: {items: settings = {}}} = useContext(Settings.Context)
  const {update, state: {info = {}}} = useContext(Profile.Context)
  const {domain: domainName, institution = {}, Passport: passport = {}, individual = {}, expiration} = info
  const {project = '', premium = false, noChat = false} = institution
  const fullName = getFullName(individual)
  return {update, domainName, institution, project, passport, fullName, premium, noChat, expiration, settings}
}

