import {useCallback, useState} from 'external/react'
import {styled} from 'external/material'

import useAdmin from 'hooks/admin'
import useTranslations from 'hooks/translations'

import Icon from 'components/Common/Icon'

const StyledPreviewSearchBar = styled('div')(PreviewSearchBarStyle)

export default function PreviewSearchBar() {
  const [searchInput, setSearchInput] = useState('')
  const {update, importUsers: {importedUsers}} = useAdmin()
  const {translate, language} = useTranslations()

  const handleSearch = useCallback(() => {
    const filter = importedUsers.filter((user) => {
        const firstName = searchInput.split(' ')[0]
        const lastName = searchInput.split(' ')[1]
        return user.first_name.toLowerCase().includes(firstName) && (!lastName || user.last_name.toLowerCase().includes(lastName))
    })
    update({
      importUsers: {
        searchUser: {$set: filter},
        isSearching: {$set: searchInput !== ''}
      }
    })
  }, [importedUsers, searchInput])

  return (
    <StyledPreviewSearchBar>
      <div className="wrapper">
        <Icon name="faSearch" />
        <input
          value={searchInput}
          onChange={e => setSearchInput(e.target.value)}
          onBlur={() => handleSearch()}
          onKeyDown={({key}) => key == 'Enter' && handleSearch()}
        /> 
        <span className="button" onClick={handleSearch}>
          {translate("go-label")}
        </span>
      </div>
      <div>
        <span>
          {language !== 'es'
            ? `${importedUsers.length} users will be imported from file`
            : `${importedUsers.length} usuarios seran importados desde el archivo`
          }
        </span>
      </div>
    </StyledPreviewSearchBar>
  )
}

function PreviewSearchBarStyle() {
  return {
    margin: '15px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 20,
    "& .wrapper": {
      display: 'flex',
      backgroundColor: 'white',
      border: 'solid 1px #bdbdbd',
      borderRadius: '5px',
      "& svg": {
        padding: '12px 6px 8px 10px'
      },
      "& input": {
        padding: '8px 0',
        flexGrow: 1,
        paddingLeft: 5,
        color: '#565f77',
        fontSize: '12pt',
        border: 'none',
        "&:focus": {
          outline: 'none'
        }
      },
      "& .button": {
        flexShrink: 0,
        padding: '8px 0',
        width: '30px',
        textAlign: 'center',
        borderLeft: 'solid 1px #f4f4f4',
        cursor: 'pointer',
        backgroundColor: 'white',
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
        fontSize: '11pt',
        "&:hover": {
          fontWeight: 'bold'
        }
      }
    }
  }
}