import {styled, clsx } from 'external/material'
import {useMemo} from 'external/react'
import {orderBy} from 'external/lodash'

import { centerVertically}  from 'utils/style'

import SearchPattern from 'components/Common/SearchPattern'
import { BackToDashboard } from 'components/Common/BackButton'
import FilterChip from 'components/Common/FilterChip'
import DropdownQueryFilter from 'components/Common/DropdownQueryFilter'

import ImportUsers from '../ImportUsers'
import AddUserForm from '../AddUser/Form'
import AddUser from '../AddUser'
import ResponsiveLabeledCount from './ResponsiveLabeledCount'

import useAdmin from 'hooks/admin'
import useSession from 'hooks/session'
import useCatalog from 'hooks/catalog'

const StyledUsersTableBar = styled('div')(filterBarStyles)

export default function UsersTableBar(props) {
    
    const {className} = props
    const {query: {role}} = useSession()
    const {usersCounts = {}, studentCategories} = useAdmin()
    const {studentGrades} = useCatalog()

    const filterRoleSelected = useMemo(() => {
        const iconNames = {
            student: 'faUserLarge',
            teacher: 'faUserGraduate'
        }
        return Object.entries(usersCounts.roles || {}).map(([id, count]) => ({
            count, id, iconName: iconNames[id] 
        }))
    }
    ,[usersCounts])

    const gradeFilterList = useMemo(() => {
        const available = Object.keys(usersCounts.grades || {})
        return orderBy(studentGrades.filter(i => available.indexOf(i.id) >= 0), 'precedence')
    }, [usersCounts, studentGrades])

    const categoryFilterList = useMemo(() => {
        const available = Object.keys(usersCounts.categories || {})
        return studentCategories.items.filter(i => available.indexOf(i.id) >= 0)
    }, [usersCounts, studentCategories.items])

    return <StyledUsersTableBar className={className}>
        <div className='filter-row'>
            <BackToDashboard />
            <SearchPattern preserveQuery={true} interceptQuery={q => ({...q, page: undefined})} />
            <div className={clsx('roles')}>
                {filterRoleSelected.map(role => (<ResponsiveLabeledCount key={role.id} {...role}/>))}
            </div>
            <ImportUsers className="import-users" />
            <AddUser className="add-user" />
        </div>
        <div className='filter-row'>
            <DropdownQueryFilter name="role" items={filterRoleSelected}/>
            {role !== 'teacher' && <DropdownQueryFilter name="gradeId" items={gradeFilterList} />}
            {role !== 'teacher' && <DropdownQueryFilter name="categoryId" items={categoryFilterList} />}
            <div style={{flexGrow: 1}} />
            <div className="filter-chip" >
                <FilterChip name="pattern" />
            </div>
        </div>
        <AddUserForm/>
    </StyledUsersTableBar>
}

function filterBarStyles() {
    return {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        flexGrow: 1,
        '& > .filter-row': {
            display: 'flex',
            flexWrap: 'no-wrap',
            flexShrink: 0,
            marginBottom: 15,
            gap: 20,
            '& .roles': {
                display: 'flex',
                marginLeft: 5,
                gap: 20,
                flexGrow: 1
            },
            '& .add-user': centerVertically,
            '& .filter-chip': {
                ...centerVertically
            }
        }
    }
}