import {styled} from 'external/material'
import SettingsButton from './SettingButton'

import { formatDate } from 'external/date-fns'
import useAdmin from 'hooks/admin'
import useTranslations from 'hooks/translations'
import useProfile from 'hooks/profile'
import { exportCSV } from "external/sheetjs"

const StyledSettingsMenu = styled('div')(settingsMenuStyles)

export default function SettingsMenu() {
    
    const { downloadUsers: { users } } = useAdmin()
    const { translate } = useTranslations()
    const { institution: {name: institution } } = useProfile()

    function exportUsersFile(e) {
      e.preventDefault()
      const bookFile = `${institution}-${formatDate(new Date(), 'yyyy-MM-dd')}.csv`
      const jsonCSV = generateJsonCSV()
      return exportCSV(jsonCSV, {filename: bookFile}) 
    }

    const generateJsonCSV = () => {
      const headers = users[0]
        .split(',')
        .map((header) => translate(`export-users-csv-${header}-header`))
      const jsonCSV = users.reduce((acc, user, index) => {
        if (index === 0) return [...acc]
        const values = user.split(',')
        let jsonValue = values.reduce((acc, value, index) => ({ ...acc, [headers[index]]: value }), {})
        return [...acc, jsonValue]
      }, [])

      return jsonCSV
    }

    const settingsButton = [
        ['send-info', 'faFileArrowUp'],
        ['export-users', 'maBrowserUpdated'],
        ['license-count', 'faFileCircleCheck'],
        ['rollbook', 'maInventory']
    ]


    return (
        <StyledSettingsMenu>
            {settingsButton.map(([id, icon, onClick]) => (<SettingsButton key={id} id={id} icon={icon} onClick={onClick}/>))}
        </StyledSettingsMenu>
    )
}

function settingsMenuStyles () {
    return {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: '15px'
    }
}
