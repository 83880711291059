import ClearableQueryFilter from 'components/Common/ClearableQueryFilter'

import useTranslations from 'hooks/translations'
import useAdmin from 'hooks/admin'

export default function EventCategoryFilter (props) {

  const {translate} = useTranslations()
  const {eventCategories} = useAdmin()

  return (<ClearableQueryFilter {...{...props,
    caption: translate('category_label'),
    name: 'eventCategory',
    items: eventCategories,
    getOptionValue: eventType => eventType,
    getOptionText: eventType => translate(`${eventType}-event-category`)
  }} />)
}