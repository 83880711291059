import {useState, useSearchParams, useEffect, stringifyQueryString} from 'external/react'
import {styled, Chip, clsx} from 'external/material'

const StyledChipPattern = styled(Chip)(searchPatternStyles)

export default function FilterChip (props) {

  const {
    onDelete,
    className,
    queryParamName = 'pattern',
    initialPattern = '',
    preserveQuery = true,
  } = props

  const [params, setSearchParams] = useSearchParams()
  const [pattern, setPattern] = useState(initialPattern)
  
  useEffect(() => {
    if (params.has(queryParamName)) {
      const searchPattern = params.get(queryParamName)
      if (pattern != searchPattern) setPattern(searchPattern)
    } else if (pattern != '') {
      setPattern('')
    }
  }, [params])

  function clearPattern() {
    const currentQuery = {}
    if (preserveQuery) {
      for (const [key, value] of params.entries()) {
        if (key != queryParamName)
          currentQuery[key] = value
      }
    }
    setSearchParams(stringifyQueryString(currentQuery))
    onDelete && onDelete()
  }

  return (
    <StyledChipPattern className={clsx(className, {hidden: pattern == ''})} label={pattern} onDelete={clearPattern}/>
  )
}

function searchPatternStyles(){
  return {
    '&.hidden': {
      display: 'none'
    }
  }
}