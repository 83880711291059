import {SvgKeepRatio} from 'utils/style'

export default function AssessmentIcon(props) {
  const fill = props.fill || '#92aabb'
  const backgroundColor = props.backgroundColor || 'none'
  return (<SvgKeepRatio viewBox={{width: 16, height: 20}} {...props}>
      <g stroke="none" strokeWidth="1" fill={backgroundColor} fillRule="evenodd">
        <g stroke={fill}>
            <polygon transform="translate(7.142160, 9.330075) rotate(-180.000000) translate(-7.142160, -9.330075) " points="12.0560361 0.429939099 14.2843192 0.429939099 14.2843192 18.2302102 4.02397601 18.2302102 0 14.2148531 0 0.429939099"></polygon>
            <path d="M14.2191963,0.5 L14.2191963,4.83435539 L9.8685726,0.860994837 L14.2191963,0.5 Z" transform="translate(12.043884, 2.667178) rotate(-180.000000) translate(-12.043884, -2.667178) "></path>
        </g>
        <rect fill={fill} x="2.67531185" y="4.44529616" width="1.78354123" height="1.77811846"></rect>
        <rect fill={fill} x="5.35062369" y="13.3358885" width="6.24239431" height="1"></rect>
        <rect fill={fill} x="5.35062369" y="10.6687108" width="6.24239431" height="1"></rect>
        <rect fill={fill} x="5.35062369" y="8.00153309" width="6.24239431" height="1"></rect>
        <rect fill={fill} x="2.67531185" y="13.3358885" width="1.78354123" height="1"></rect>
        <rect fill={fill} x="2.67531185" y="10.6687108" width="1.78354123" height="1"></rect>
        <rect fill={fill} x="2.67531185" y="8.00153309" width="1.78354123" height="1"></rect>
      </g>
    </SvgKeepRatio>
  )
}