import {styled} from 'external/material'
import ActivityItem from './ActivityItem'

import useTranslations from 'hooks/translations'
import useSession from 'hooks/session'
import useAdmin from 'hooks/admin'

const StyledDashboardActivity = styled('div')(dashboardActivityStyles)

export default function DashboardActivity(props) {
    const {translate} = useTranslations()
    const {navigation} = useSession()
    const {dashboardActivity} = useAdmin() 

    return <StyledDashboardActivity {...props}>
        <div className='activity-container'>
        <div className='activity-label'>{translate('activity')}</div>
        <div className='activities'>
            <ActivityItem
                count={dashboardActivity.teacherCount}
                to={navigation.page.linkWithQuery({role: 'teacher'}, false, {pageName: 'users'})}
                circleColor="#d6e3ec"
                icon="faUserLarge"
                label={translate('teachers')}
            />
            <ActivityItem
                count={dashboardActivity.studentCount}
                to={navigation.page.linkWithQuery({role: 'student'}, false, {pageName: 'users'})}
                circleColor="#d6ecd6"
                icon="faUserGraduate"
                label={translate('students')}
            />
            <ActivityItem
                count={dashboardActivity.assignedLessons}
                to={navigation.page.link({pageName: 'calendar'})}
                circleColor="#ecd6de"
                icon="faFileInvoice"
                label={translate('assigned-lessons')}
            />
            <ActivityItem
                count={dashboardActivity.assignedMessages}
                to={navigation.page.link({pageName: 'messages'})}
                circleColor="#e6d6ec"
                icon="faMessage"
                label={translate('assigned-messages')}
            />
        </div>
        </div>
    </StyledDashboardActivity>
}

function dashboardActivityStyles() {
    return {
        display: 'flex',
        justifyContent: 'center',
        '& .activity-label': {
            fontWeight: 'bold',
            color: '#4e576e',
            paddingLeft: 2,
            paddingBottom: 8,
            fontSize: '15pt'
        },
        '& .activities': {
            display: 'flex',
            '& .activity-item': {
                marginLeft: 30,
                '&:first-of-type': {
                    marginLeft: 0
                }
            }
        },
    }
}

