import { SvgKeepRatio } from "utils/style"

export default function DoubleArrowLeftIcon(props) {
  const fill= props.fill || "#4E576E"
  return (<SvgKeepRatio viewBox={{width: 22, height: 17}} {...props}>
      <g fill={fill}>
        <path d="M21.7236604,1.09595242 L21.7236604,15.1683371 C21.7236604,16.1427763 20.5455321,16.6307436 19.856546,15.9417041 L12.8203537,8.90551174 C12.3932154,8.47842688 12.3932154,7.78586261 12.8203537,7.35877775 L19.856546,0.322585424 C20.5455321,-0.366507476 21.7236604,0.121513211 21.7236604,1.09595242 Z" transform="translate(17.111830, 8.132133) rotate(-360.000000) translate(-17.111830, -8.132133) "></path>
        <path d="M9.22366041,1.09595242 L9.22366041,15.1683371 C9.22366041,16.1427763 8.04553212,16.6307436 7.35654603,15.9417041 L0.320353698,8.90551174 C-0.106784566,8.47842688 -0.106784566,7.78586261 0.320353698,7.35877775 L7.35654603,0.322585424 C8.04553212,-0.366507476 9.22366041,0.121513211 9.22366041,1.09595242 Z" transform="translate(4.611830, 8.132133) rotate(-360.000000) translate(-4.611830, -8.132133) "></path>
      </g>
    </SvgKeepRatio>
  )
}