import {omit} from 'external/lodash'
import {Button, styled, AddIcon} from 'external/material'
import Icon from 'external/font-awesome'

const StyledAddButton = styled(Button)(addButtonStyles)

export default function AddButton(props) {
    const  {label} = props
   return <StyledAddButton {...omit(props, 'label')} startIcon={<Icon name="frSquarePlus" />}>{label}</StyledAddButton>
}

function addButtonStyles() {
  return {
    backgroundColor: '#2c7cbd',
    color: '#fff',
    fontSize: '13pt',
    '& .MuiButton-startIcon': {
        marginLeft: 8,
        marginRight: 16 
    },
    '&:hover': {
      backgroundColor: '#2ca5ec',
      borderColor: '#2ca5ec',
      boxShadow: 'none',
    },
  }
}
