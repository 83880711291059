import {Routes, Route} from 'external/react'

import LicenseSubjects from './LicenseSubjects'
import CourseAccordion from './CourseAccordion'

export default function LicenseCountForm() {
  return (
    <Routes>
      <Route index element={<LicenseSubjects />} />
      <Route exact path=":course" element={<CourseAccordion />} />
    </Routes>
  )
}
