import {merge} from 'external/lodash'
import {Outlet, useEffect, useState, useMemo} from 'external/react'
import {
  Box,
  useMediaQuery,
  styleAndCapture,
  CssBaseline,
  MuiContainer,
} from 'external/material'

import TopBar from 'components/TopBar'
import Loader from 'components/Loader'
import Menu from 'components/Menu'
import {mixStyle} from 'utils/style'

import useSession from 'hooks/session'
import useMenu from 'hooks/menu'

import KeepAliveForm from 'components/Common/KeepAliveForm'

const StyledContainer = styleAndCapture(Box, 'open', 'sidebarOpened', 'windowHeight', 'inDashboard')(containerStyles)

export default function Container() {
  const {open, sidebarOpened, hideMenu} = useMenu()
  const {active, pageName} = useSession()
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  
  const isMenuVisible = useMemo(() => pageName ? !hideMenu[pageName] : true , [hideMenu, pageName])

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerHeight != windowHeight) {
        setWindowHeight(window.innerHeight)
      }
    })  
  }, [])

  return (
    <Loader >
      <StyledContainer windowHeight={windowHeight} open={open} sidebarOpened={sidebarOpened} inDashboard={pageName == 'dashboard'}>
        <CssBaseline />
        <KeepAliveForm />
        <TopBar isMenuVisible={isMenuVisible} />
        {isMenuVisible && <Menu />}
        <MuiContainer className="container-root" maxWidth={false}>
            {active &&(<Outlet />)}
        </MuiContainer>
      </StyledContainer>
    </Loader>
  )
}

function containerStyles({theme, open, sidebarOpened, inDashboard, windowHeight}) {

  const isSmallFactor = useMediaQuery(theme.breakpoints.down('desktop'))
  
  const dynamicComponentsWidth = merge(
    mixStyle(theme.mixins.sidebar, ['width'], sidebarWidth => ({sidebarWidth})),
    mixStyle(theme.mixins.drawer, ['width', '& svg'], (menuWidth, type) => type != 'width' ? undefined : ({menuWidth}))
  )
  
  const hasSidebar = false

  const width = mixStyle(dynamicComponentsWidth, ['menuWidth', 'sidebarWidth'], (width, type, styles) => {
    if (isSmallFactor) {
      if (type == 'menuWidth') return
      else if (hasSidebar) return {marginLeft: 0, offsetWidth: width} 
      else return {marginLeft: 0, offsetWidth: 0}
    } else {
      if (open && hasSidebar) return {
        ...(type == 'menuWidth' ? {marginLeft: width} : {}),
        offsetWidth: width + (styles.offsetWidth || 0)
      }
      else if (open) return {
        ...(type != 'sidebarWidth' ? {marginLeft: width, offsetWidth: 0} : {}),
      }
      else if (hasSidebar) return {
        marginLeft: 0,
        ...(type == 'sidebarWidth' ? {offsetWidth: width} : {}),
      }
      else return {
        marginLeft: 0, offsetWidth: 0
      }
    }
  })

  const horizontalOffset = mixStyle(width, ['offsetWidth'], (offsetWidth) => {
    return {
      transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.shortest,
      }),
      width: `calc(100% - ${offsetWidth}px)`,
    }
  })

  const verticalOffset = mixStyle(theme.mixins.toolbar, ['minHeight'], offset => {
    const height = windowHeight - (offset * 2)
    return {
      marginTop: offset * 2,
      height: `${height}px`,
      maxHeight: `${height}px`,
    }
  })

  return {
    display: 'flex',
    "& > .container-root": {
      ...merge(verticalOffset,horizontalOffset),
      overflow: 'auto',
      backgroundColor: '#f2f2f2',
      display: 'flex',
      flexDirection: 'column',
      paddingRight: 0,
      paddingLeft: 0,
      "& .lessonLink": {
        cursor: 'pointer !important'
      },
      "& ::-webkit-scrollbar": {
        width: 9 ,
        height: 8 
      },
      "& ::-webkit-scrollbar-track": {
        opacity: 0.3,
        backgroundColor: 'rgb(230 230 230 / 50%)',
        border: 'inset 1px #d2c7c726',
        borderRadius: 15
      },
      "& ::-webkit-scrollbar-thumb": {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        borderRadius: 5,
        border: 'outset 1px #726b6b26',
      },
      "& ::-webkit-scrollbar-button": {
        display: 'none'
      },
      "& ::-webkit-scrollbar-corner": {
        display: 'none'
      }
    }
  }
}
