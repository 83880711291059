import {SvgKeepRatio} from 'utils/style'

export default function LessonCountIcon(props) {
  const fill= props.fill || "#7A85A0"
  return (
    <SvgKeepRatio viewBox={{width: 22, height: 18}}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="count-icon" fill={fill} fillRule="nonzero">
            <path d="M19,12 C20.6568542,12 22,13.3431458 22,15 L22,18 L16,18 L16,15 C16,13.3431458 17.3431458,12 19,12 Z M21,17 L21,15 C21,13.8954305 20.1045695,13 19,13 C17.8954305,13 17,13.8954305 17,15 L17,17 L21,17 Z"/>
            <path d="M19,12.5 C20.3807119,12.5 21.5,11.3807119 21.5,10 C21.5,8.61928813 20.3807119,7.5 19,7.5 C17.6192881,7.5 16.5,8.61928813 16.5,10 C16.5,11.3807119 17.6192881,12.5 19,12.5 Z M19,11.5 C18.1715729,11.5 17.5,10.8284271 17.5,10 C17.5,9.17157288 18.1715729,8.5 19,8.5 C19.8284271,8.5 20.5,9.17157288 20.5,10 C20.5,10.8284271 19.8284271,11.5 19,11.5 Z"/>
            <polygon points="1.5 16.5 1.5 4.5 19.5 4.5 19.5 5.95832549 20.5 5.95832549 20.5 3.5 0.5 3.5 0.5 17.5 15.0333984 17.5 15.0333984 16.5" />
            <path d="M0.5,0.5 L20.5,0.5 L20.5,4.5 L0.5,4.5 L0.5,0.5 Z M1.5,1.5 L1.5,3.5 L19.5,3.5 L19.5,1.5 L1.5,1.5 Z" />
            <path d="M8.97460855,6.35229821 L8.97460855,14.6881722 L13.3648046,10.5003434 L8.97460855,6.35229821 Z M9.97460855,8.67290549 L11.9123903,10.5038046 L9.97460855,12.3522638 L9.97460855,8.67290549 Z" />
          </g>
        </g>
    </SvgKeepRatio>
  )
}