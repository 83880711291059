import {SvgKeepRatio} from 'utils/style'

export default function MyCoursesIcon(props) {
  const fill= props.fill || "#fff"
  return (
    <SvgKeepRatio viewBox={{width: 26, height: 38}} {...props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill={fill}>
                <polygon points="10 16 22 16 22 17 10 17"></polygon>
                <polygon points="10 23 22 23 22 24 10 24"></polygon>
                <polygon points="10 30 22 30 22 31 10 31"></polygon>
                <path d="M13,7.5 C11.6192881,7.5 10.5,6.38071187 10.5,5 C10.5,3.61928813 11.6192881,2.5 13,2.5 C14.3807119,2.5 15.5,3.61928813 15.5,5 C15.5,6.38071187 14.3807119,7.5 13,7.5 Z M13,6.5 C13.8284271,6.5 14.5,5.82842712 14.5,5 C14.5,4.17157288 13.8284271,3.5 13,3.5 C12.1715729,3.5 11.5,4.17157288 11.5,5 C11.5,5.82842712 12.1715729,6.5 13,6.5 Z" fillRule="nonzero"></path>
                <path d="M3,7 L3,8 L2,8 C1.44771525,8 1,8.44771525 1,9 L1,36 C1,36.5522847 1.44771525,37 2,37 L24,37 C24.5522847,37 25,36.5522847 25,36 L25,9 C25,8.44771525 24.5522847,8 24,8 L23,8 L23,7 L24,7 C25.1045695,7 26,7.8954305 26,9 L26,36 C26,37.1045695 25.1045695,38 24,38 L2,38 C0.8954305,38 0,37.1045695 0,36 L0,9 C0,7.8954305 0.8954305,7 2,7 L3,7 Z" fillRule="nonzero"></path>
                <path d="M9,5 L9,6 L7,6 C6.44771525,6 6,6.44771525 6,7 L6,9 C6,9.55228475 6.44771525,10 7,10 L19,10 C19.5522847,10 20,9.55228475 20,9 L20,7 C20,6.44771525 19.5522847,6 19,6 L17,6 L17,5 C17,2.790861 15.209139,1 13,1 C10.790861,1 9,2.790861 9,5 Z M8,5 C8,2.23857625 10.2385763,0 13,0 C15.7614237,0 18,2.23857625 18,5 L19,5 C20.1045695,5 21,5.8954305 21,7 L21,9 C21,10.1045695 20.1045695,11 19,11 L7,11 C5.8954305,11 5,10.1045695 5,9 L5,7 C5,5.8954305 5.8954305,5 7,5 L8,5 Z" fillRule="nonzero"></path>
                <polygon points="4 15 7 15 7 18 4 18"></polygon>
                <polygon points="4 22 7 22 7 25 4 25"></polygon>
                <polygon points="4 29 7 29 7 32 4 32"></polygon>
            </g>
        </g>
    </SvgKeepRatio>
  )
}