import Icon from 'components/Common/Icon'
import useTranslations from 'hooks/translations'
import FilterChip from 'components/Common/FilterChip'

export default function StudentsCount ({groups}) {
    const {translate} = useTranslations()
    const studentsCount = groups.reduce((count, group) => count + group.students.length, 0)
    return (
      <div className="students-count">
        <div className="icon">
          <Icon name='faUserGraduate' />
        </div>
        <div className="count">
          {studentsCount}{" "}{translate(`student${studentsCount == 1 ? '' : 's'}_label`)}
        </div>
        <div style={{flexGrow: 1}} />
        <FilterChip name="pattern" />
      </div>
    )
}
