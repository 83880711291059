import {clsx, styled, List} from 'external/material'
import Icon from 'components/Common/Icon'

import TransferButton from './TransferButton'
import ListElement from './ListElement'

const StyledTransferList = styled('div')(transferListStyles)

export default function TransferList (props) {
  const {
    left,
    right,
    checklist,
    LeftHeader,
    RightHeader,
    id = elem => typeof(elem) == 'object' ? elem.id : elem,
    label = elem => typeof(elem) == 'object' ? elem.label : elem,
    ListItem = ListElement,
    onChecked,
    onTransfer
  } = props

  const isChecked = elem => checklist[id(elem)]
  const isNotChecked = elem => !checklist[id(elem)]

  const createItem = (elem, index) => {
    const key = id(elem)
    const checked = checklist[key]
    return (<ListItem
        index={index}
        key={key}
        label={label(elem)}
        checked={checked}
        className={clsx({"checked": checked})}
        onClick={() => onChecked(key, !checked)}
    />)
  }

  function handleTransfer(source, sourceKey, sink, sinkKey, all) {
    return () => {
      const newSource = all ? [] : source.filter(isNotChecked)
      const newSink = sink.concat(all ? source : source.filter(isChecked))
      onTransfer({[sourceKey]: newSource, [sinkKey]: newSink})
    }
  }

  return (
    <StyledTransferList className="transfer-list">
      <div className="transfer-list-left">
        {LeftHeader && <LeftHeader />}
        <div className="transfer-list-left-paper">
          <List dense component="div" role="list">
            {left.map(createItem)}
          </List>
        </div>
      </div>
      <div className="transfer-list-operations-wrapper">
        <div className="transfer-list-operations-container">
          <TransferButton
            disabled={left.length == 0}
            onClick={handleTransfer(left, 'left', right, 'right', true)}
          >
            <Icon name="esDoubleArrowRight" />
          </TransferButton>
          <TransferButton
            disabled={left.find(isChecked) == undefined}
            onClick={handleTransfer(left, 'left', right, 'right')}
          >
            <Icon name="esArrowRight" />
          </TransferButton>
          <TransferButton
            disabled={right.find(isChecked) == undefined}
            onClick={handleTransfer(right, 'right', left, 'left')}
          >
            <Icon name="esArrowLeft" />
          </TransferButton>
          <TransferButton
            disabled={right.length == 0}
            onClick={handleTransfer(right, 'right', left, 'left', true)}
          >
            <Icon name="esDoubleArrowLeft" />
          </TransferButton>
        </div>
      </div>
      <div className="transfer-list-right">
        {RightHeader && <RightHeader/>}
        <div className="transfer-list-right-paper">
          <List dense component="div" role="list">
            {right.map(createItem)}
          </List>
        </div>
      </div>
    </StyledTransferList>
  );
}

function transferListStyles(){
    return {
        display: 'flex',
        overflowY: 'hidden',
        "& .transfer-list-left, .transfer-list-right": {
          display: 'flex',
          overflowY: 'hidden',
          flexDirection: 'column',
          width: 'calc(100% / 2)'
        },
        "& .transfer-list-left-paper, .transfer-list-right-paper": {
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          borderRadius: 5,
          border: 'solid 1px rgb(220, 225, 236)',
          overflowY: 'hidden',
          '& .MuiList-root': {
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            padding:0
          },
          '& .MuiListItem-root': {
            cursor: 'pointer',
            padding: '5px 16px',
            "&.checked": {
              backgroundColor: '#2ca5ec',
              color: '#fff',
            },
            '&:hover': {
              backgroundColor: '#7cc9f7b8',
              '&.checked': {
                backgroundColor: '#2ca5ec',
              }

            }
          }

        },
        "& .transfer-list-operations-wrapper": {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          flexShrink: 0,
          margin: '0 10px',
          "& .transfer-list-operations-container": {
            display: 'flex',
            flexDirection: 'column',
          }
        },
        "& .transfer-button": {
          marginTop: 10,
          "&:first-of-type": {
            marginTop: 0
          }
        }
    }
}
