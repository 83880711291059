import {useCallback, useEffect, useMemo, useNavigate} from 'external/react'
import {styled, clsx} from 'external/material'

import useAdmin from 'hooks/admin'
import useValidations from 'hooks/validations'
import useTranslations from 'hooks/translations'

import TabBar from './Components/TabBar'
import NavButtons from './Components/NavButtons'
import CommentStep from './Steps/CommentStep'
import StudentStep from './Steps/StudentStep'
import PreviewStep from './Steps/PreviewStep'
import AlertDialog from 'components/Common/AlertDialog'

const steps = {
    comment: CommentStep,
    students: StudentStep,
    preview: PreviewStep,
}

const StyledSendUserInfo = styled('div')(styledSendUserInfo)

export default function SendUsersInfo() {

    const {sendUsersInfo, update, sendUsers} = useAdmin()
    const {activeTab, isSaving, successDialog, emailCount} = sendUsersInfo
    const {translate, language} = useTranslations()
    const navigate = useNavigate()
    const validations = useMemo(() => ({
        passports: [
            ({passports}) => passports.length > 0 ? '' : 'passports-empty'
        ],
    }), [])

    const {errors, errorCount, resetErrors, touchAll, allFieldsTouched, touchField} = useValidations(validations, sendUsersInfo)

    const StepContent = useMemo(() => steps[activeTab] || steps.instructions, [activeTab])

    function resetForm() {
        resetErrors()
        update({
            sendUsersInfo: {
                activeTab: {$set: 'comment'},
                content: {$set: ''},
                sendPuk: {$set: false},
                passports: {$set: []},
                checkedStudents: {$set: {}},
                checkedTeachers: {$set: {}},
                selectedStudents: {$set: []},
                selectedTeachers: {$set: []}
            }
        })
    }

    const handleBack = useCallback(() => {
        navigate(`/${language}/settings`, {replace: true})
    }, [language])

    const handleNext = () => {
        const next = {'comment': 'students', 'students': 'preview'}
        update({sendUsersInfo: {activeTab: {$set: next[activeTab]}}})
    }

    const handlePrevious = () => {
        const previous = {'students': 'comment', 'preview': 'students'}
        update({sendUsersInfo: {activeTab: {$set: previous[activeTab]}}})
    }

    const handleSave = async () => {
        try {
            if (!allFieldsTouched()) return touchAll()
            update({sendUsersInfo: {isSaving: {$set: true}}})
            const mailsSent = await sendUsers(sendUsersInfo)
            const count = mailsSent.filter((mail) => mail.result === 'OK').length
            update({sendUsersInfo: {successDialog: {$set: true}, emailCount: {$set: count}}})
        } catch (error) {
            handleBack()
        } finally {
            update({sendUsersInfo: {isSaving: {$set: false}}})
        }
    }

    const handleDialogClose = () => {
        update({sendUsersInfo: {successDialog: {$set: false}}})
    }

    const canSave = useMemo(() => errorCount == 0 && !isSaving,  [errorCount, isSaving])

    const successMessage = useMemo(() => {
        const plural = emailCount > 1
        const message = language === 'es'
            ? `Se enviaron ${emailCount} correo${plural ? 's' : ''} de información de usuario.`
            : `${emailCount} user infomation email${plural ? 's' : ''} ${plural ? 'were' : 'was'} sent.`
        return message
    }, [language, emailCount])

    useEffect(() => {
        update({sendUsersInfo: {open: {$set: true}, language: {$set: language}}})
        return () => {
            resetForm()
            update({sendUsersInfo: {open: {$set: false}}})
        }
    }, [language])

    return (
        <StyledSendUserInfo className="send-users">
            <TabBar errors={errors}  className="tabbar"/>
            <div className={clsx('step', 'wrapper')}>
                <StepContent className={activeTab} {...{errors, touchField}} />
            </div>
            <NavButtons className="navbuttons" {...{handleBack, handlePrevious, handleNext, handleSave, canSave}} />
            <AlertDialog
                open={successDialog}
                onClose={handleDialogClose}
                title={translate('send-user-alert-title')}
                content={successMessage}
                icon="maCheckCircleOutline"
            />
        </StyledSendUserInfo>
    )
}

function styledSendUserInfo() {
    return {
        flexGrow: 1,
        display: 'flex',
        border: 'solid 1px #dce1ec',
        borderRadius: 5,
        backgroundColor: '#fff',
        flexDirection: 'column',
        overflowY: 'hidden',
        maxWidth: 1920,
        '& .tabbar': {
            flexShrink: 0
        },
        '& .step.wrapper': {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            padding: '10px 20px',
            '& > .container': {
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                overflowY: 'hidden',
                '& .step.content': {
                    flexGrow: 1,
                    overflowY: 'auto'
                }
            }
        },
        '& .navbuttons': {
            flexShrink: 0,
            borderTop: 'solid 1px #dce1ec',
            padding: 10
        },
        '& .label': {
            fontWeight: 'bold',
        },
        '& .field-error': {
            color: '#d32f2f',
        }
    }
}
