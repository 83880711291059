import {styled} from 'external/material'

import useTranslations from 'hooks/translations'
import useCatalog from 'hooks/catalog'
import useSession from 'hooks/session'

import GradeFilterButton from './GradeFilterButton'

const StyledGradeFilter = styled('div')(gradeFilterStyles)

export default function GradeFilter (props) {

  const {translate} = useTranslations()
  const {courseGrades} = useCatalog()
  const {query} = useSession()
  return (
    <StyledGradeFilter className="grade-filters">
      <div className="label">{translate('grades')}:</div>
      <div className="content">
        {courseGrades.map((grade, i) => (<GradeFilterButton key={i} subject={props.subject} selected={grade.id == query.grade} {...grade} />))}
      </div>
    </StyledGradeFilter>
  )
}

function gradeFilterStyles() {
  return {
    display: 'flex',
    flexShrink: 0,
    color: '#909495',
    '& > div': {
      backgroundColor: '#fff',
      border: 'solid 1px #d8dcdb',
      padding: '10px 0',
      '&.label': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontWeight: 'bold',
        backgroundColor: '#f5fbfb',
        borderRight: 'solid 1px #d8dcdb',
        paddingLeft: '8px',
        paddingRight: '8px'
      },
      '&.content': {
        display: 'flex',
        flexGrow: 1
      }
    }
  }
}