import {range, chunk, orderBy} from 'external/lodash'
import { useMemo, useRef } from 'external/react'
import { styled } from 'external/material'

import useDimension from 'hooks/dimensions'

import Group, {paddingWidth, itemHeight, itemWidth} from './Group'
import GroupsCount from './StudentsCount'

import useSession from 'hooks/session'
import useAdmin from 'hooks/admin'
import useSearchParam from 'hooks/search-param'
import { searchSubstring } from 'utils/text'

const StyledMonitoringGroups = styled('div')(monitoringGroupsStyles)


export default function Groups () {

  const {groupList, groupTeachers, studentDownloads} = useAdmin()
  const {teacherId} = useSession()
  const [pattern] = useSearchParam('pattern')
  const contentRef = useRef(null)
  const {width: contentWidth} = useDimension(contentRef)

  const groups = useMemo(() => {
    const groups = groupTeachers[teacherId]
      ? Object.values(groupTeachers[teacherId].groups)
      : groupList.items
    return orderBy(pattern ? groups.filter(g => searchSubstring(g.groupName, pattern)) : groups, 'groupName')
  }, [teacherId, groupTeachers, groupList.items, pattern])

  const groupsPerRow = useMemo(() =>
    contentWidth > 0
      ? Math.floor((contentWidth - paddingWidth) / itemWidth)
      : false
  , [contentWidth])

  const fillersNeeded = useMemo(() => {
    const needed = groupsPerRow > 0 && groups.length > 0
      ? groupsPerRow - groups.length % groupsPerRow
      : 0
    return needed == groupsPerRow ? 0 : needed
  }, [groupsPerRow, groups])
  
  const rows = useMemo(() =>
    groupsPerRow > 0    
      ? chunk(groups, groupsPerRow)
      : []
  , [groupsPerRow, groups])


  return (
    <StyledMonitoringGroups ref={contentRef}>
      <GroupsCount groups={groups} />
      <div className="groups">
          {rows.map((row, i, rows) => {
            const lastRow = i == (rows.length - 1)
            return (<div key={i} className='group-row'>
            {row.map(group => <Group key={group.id} {...group} />)}
            {lastRow && fillersNeeded > 0 && range(fillersNeeded).map(key => <div key={key} className="group-filler" style={{flexShrink: 0, width: itemWidth, height: itemHeight}} />)}
          </div>)}
          )}
      </div>
    </StyledMonitoringGroups>)
}

function monitoringGroupsStyles() {
  return {
      display: 'flex',
      overflowY: 'hidden',
      flexDirection: 'column',
      marginTop: '15px',
      flexGrow: 1,
      backgroundColor: '#fff',
      border: 'solid 1px #d9d9d9',
      borderRadius: '4px',
      "& > .students-count": {
        borderBottom: 'solid 1px #e7edf0',
        padding: '10px 20px',
        display: 'flex',
        "& .icon": {
          color: '#78a7ff',
          fontSize: '15pt',
        },
        "& .count": {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textTransform: 'lowercase',
          padding: '0 20px 0 8px',
          borderRight: 'solid 1px #e7edf0'
        }
      },
      "& .groups": {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflowY: 'auto',
        borderTop: 'solid 1px #d9d9d9',
        padding: '0 20px 20px 20px',
        "& .group-row": {
          display: 'flex',
          justifyContent: 'space-evenly',
          marginTop: 30
        }
      }
    }
}
