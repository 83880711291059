import { useMemo, useEffect } from 'react'
import {IconButton} from 'components/Common/Icon'
import {
    styled, 
    Dialog, 
    DialogTitle,
    DialogContent,  
} from 'external/material'
import useTranslations from 'hooks/translations'
import useAdmin from 'hooks/admin'
import useCatalog from 'hooks/catalog'
import useValidations, {notEmpty, minLength} from 'hooks/validations'
import TabBar from './AddGroupTabBar'

const StyledAddGroupForm = styled(Dialog)(addGroupFormStyles)

import GroupStep from './GroupStep'
import StudentStep from './StudentStep'

const steps = {
    group: GroupStep,
    students: StudentStep,
}

export default function AddGroupForm() {

    const {addGroupForm, update, isGroupNameUsed, createGroup} = useAdmin()
    const {open, activeTab, isSaving} = addGroupForm
    const {} = useCatalog()

    const validations = useMemo(() => ({
        groupName: [
            notEmpty('groupName'),
            minLength('groupName', 3),
            ({groupName}) => isGroupNameUsed(groupName) ? 'groupName-used' : ''
        ],
        course: [
            notEmpty('course'),
        ],
        selectedTeachers: [
            minLength('selectedTeachers', 1),
        ],
        selectedStudents: [
            minLength('selectedStudents', 1),
        ],
        credits: [
            ({credits}) => credits.usedCredits > credits.totalCredits ? 'credits-limit' : ''
        ]
    }), [isGroupNameUsed])
    
    const {errors, errorCount, resetErrors, touchField, touchAll, allFieldsTouched} = useValidations(validations, addGroupForm)
    const {translate} = useTranslations()
    
    function resetForm() {
        resetErrors()
        update({
            addGroupForm: {
               subject: {$set: ''},
               course: {$set: ''},
               groupName: {$set: ''},
               checkedStudents: {$set: {}},
               checkedTeachers: {$set: {}},
               selectedStudents: {$set: []},
               selectedTeachers: {$set: []}
            }
        })
    }

    function closeForm(refresh) {
        if (refresh) update({groups: {$refreshLoadableList: true}, addGroupForm: {open: {$set: false}, ...resetForm}})
        else update({addGroupForm: {open: {$set: false}, ...resetForm}})
    }

    function handleClose() {
        closeForm(false)
    } 

    const StepContent = useMemo(() => steps[activeTab] || steps.group, [activeTab])

    const handleNext = () => {
        const next = {'group': 'students'}
        update({addGroupForm: {activeTab: {$set: next[activeTab]}}})
    }

    const handlePrevious = () => {
        const previous = {'students': 'group'}
        update({addGroupForm: {activeTab: {$set: previous[activeTab]}}})
    }

    const handleSave = async () => {
        if (!allFieldsTouched()) return touchAll()
        update({addGroupForm: {isSaving: {$set: true}}})
        await createGroup(addGroupForm)
        update({addGroupForm: {isSaving: {$set: false}}})
        closeForm(true)
        handlePrevious()
    }

    const canSave = useMemo(() => errorCount == 0 && !isSaving,  [errorCount, isSaving])

    useEffect(() => {resetForm()}, [open])

    return (
        <StyledAddGroupForm
            open={open}
            onClose={handleClose}
        >
            <DialogTitle className="title">
                <label>{translate('add-group-form-title')}</label>
                <IconButton
                    className="close-button"
                    onClick={handleClose}
                    iconName="faCircleXmark"
                />
            </DialogTitle>
            <DialogContent>
                <TabBar className="tab-heading" errors={errors} />
                <StepContent {...{handlePrevious, handleNext, handleClose, handleSave, canSave, errors, touchField}} />
            </DialogContent>
        </StyledAddGroupForm>)
    
}

function addGroupFormStyles() {
    return {
        '& .MuiDialog-paper':{
          height: 'calc(100% - 80px)',
          minWidth: 1024,
          maxWidth: 1280,
          '& .title': {
              textAlign: 'center',
              backgroundColor: '#2c7cbd',
              color: '#fff',
              '& .close-button': {
                  position: 'absolute',
                  right: 15,
                  color: '#fff'
              }
          }, 
          '& .MuiDialogContent-root':{
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            "& .tab-heading": {
              flexShrink: 0
            },
            "& .tab-content": {
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              overflow: 'hidden',
              padding: '0 15px',
              "& .step-content": {
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                overflowY: 'hidden',
              },
              '& .add-group-buttons': {
                flexShrink: 0,
                padding: '12px 0'
              }

            }
          }
        }
    }
}
