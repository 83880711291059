import {orderBy} from 'external/lodash'
import {
   clsx
  ,Fade
  ,Box 
  ,Accordion 
  ,AccordionDetails 
  ,AccordionSummary 
  ,ExpandMoreIcon 
} from 'external/material'

import LinkBehavior from 'components/Common/LinkBehavior'
import useCatalog from 'hooks/catalog'
import useTranslations from 'hooks/translations'
import useSession from 'hooks/session'

import UnitDetails from './UnitDetails'

export default function Units() {
  const {translate} = useTranslations()
  const {unit: unitId, course, navigation} = useSession()
  const {units = [], unitsCourse = {}} = useCatalog()
  const unitsLoaded = (course == unitsCourse.course) && units.length > 0
  return (
    <Fade in={unitsLoaded}>
      <Box className="unitsWrapper">
        <Box className="units">
          {orderBy(units, ['id']).map(unit => {
            const expanded = unit.id == unitId
            const to = expanded
              ? navigation.catalogUnits.link({subject: unitsCourse.course.substring(0, 2), course: unitsCourse.course})
              : navigation.catalogLessons.link({subject: unitsCourse.course.substring(0, 2), course: unitsCourse.course, unit: unit.id})
            return (
              <Accordion disableGutters={true} className={clsx('unit',{expanded})} key={unit.id} expanded={expanded} >
                <AccordionSummary
                  component={LinkBehavior} to={to} 
                  replace
                  className={clsx('unitSummary', {expanded})}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Box className={clsx('unitName', {expanded})}>{translate('unit')} {unit.id}: {unit.name}</Box>
                  {unit.content > 0 && (<Box className="unitCountWrapper"><Box className="unitCount"><Box className={clsx('count', 'lesson', {expanded})}>{unit.content}</Box></Box></Box>)}
                </AccordionSummary>
                <AccordionDetails className="unitDetails">
                  <UnitDetails {...unit} />
                </AccordionDetails>
              </Accordion>
            )
          })}
        </Box>
      </Box>
    </Fade>
  )
}
