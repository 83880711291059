import create from 'utils/command'
import {associate} from 'utils/functions' 

const hooks =  []

export {hooks as default}

export async function refreshSettings({update}) {
  const items = await create('web', 'settings')({Prefix: 'admin-client'}).then(associate('name', 'value'))
  update({items: {$set: items}})
}
