
import {styled, clsx} from 'external/material'
import {useMemo} from 'external/react'

import AddGroupButtons from './AddGroupButtons'

import SelectCourse from 'components/Common/Forms/SelectCourse'

import useAdmin from 'hooks/admin'
import CourseCover from 'components/Common/CourseCover'
import TextInput from 'components/Common/Forms/TextInput'
import useTranslations from 'hooks/translations'
import TeacherSelector from 'components/Common/TeacherSelector'

const StyledGroupStep = styled('div')(groupStepStyles)

export default function GroupStep(props) {

    const {handleClose, handleNext, errors, touchField} = props
    const {update, addGroupForm} = useAdmin()
    const {subject, course, groupName, teacherList, selectedTeachers, checkedTeachers} = addGroupForm
    const {translate} = useTranslations()
  
    const courseSelected = course != ''

    function onSubjectChange(e) {
      update({addGroupForm: {subject: {$set: e.target.value || ''}, course: {$set: ''}}})
    }

    function onCourseChange(e) {
      const courseId = e.target.value || ''
      const subjectId = courseId == '' ?  subject : courseId.substring(0, 2)
      touchField('course')()
      update({addGroupForm: {course: {$set: courseId}, subject: {$set: subjectId}}})
    }

    function handleGroupNameChange(e) {
      update({addGroupForm: {groupName: {$set: e.target.value}}})
    }

    const handleTeachersChecked = (teacherId) => {
      update({addGroupForm: {checkedTeachers:{[teacherId]: {$apply: checked => !checked}}}})
    }

    const handleTeachersChange = (props) => {
      const {right: selectedTeachers} = props
      touchField('selectedTeachers')()
      update({addGroupForm: {selectedTeachers: {$set: selectedTeachers}}})
    }
    
    return (<StyledGroupStep className="tab-content">
            <div className="step-content">
              <div className="group-step">
                <div className="select-container">
                  <SelectCourse {...{subject, course, onSubjectChange, onCourseChange, className: 'select-course'}}/>
                  {errors.course && <label className="field-error">{translate(errors.course)}</label>}
                  <div className="name-step">
                    <label>{translate('group-name-label')}:</label>
                    <TextInput
                      id="add-group-name"
                      inputValue={groupName}
                      inputLabel={translate('group')}
                      inputOnChange={handleGroupNameChange}
                      inputOnBlur={touchField('groupName')}
                      validationResults={errors.groupName}
                      controlClassName="group-input"
                    />
                  </div>
                </div>
                <div className={clsx("cover-container", {"no-cover": !courseSelected})}>
                  {courseSelected && (<CourseCover course={course} courseName="" viewBox={{width: 201, height: 260}} />)}
                  {!courseSelected && (<div className="dummy-cover" />)}
                </div>
              </div>
              <div className="teachers-selector-container">
                <TeacherSelector {...{teacherList, selectedTeachers, checkedTeachers, handleTeachersChange, handleTeachersChecked}} />
                {errors.selectedTeachers && <label className="field-error">{translate(errors.selectedTeachers)}</label>}
              </div>
            </div>
            <AddGroupButtons
              left={{label: 'cancel', onClick: handleClose, enabled: true}}
              right={{label: 'continue', onClick: handleNext, enabled: true}}
            />
    </StyledGroupStep>
    )
}

function groupStepStyles() {
  return {
    '& .group-step': {
      display: 'flex',
      flexShrink: 0,
      paddingTop: 5,
      '& .select-container': {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        gap: 10,
      },
      '& .cover-container': {
        backgroundColor: '#ccc',
        width: 203,
        height: 262,
        border: 'solid 1px black',
        display: 'flex',
        marginRight: 50
      },
      '& .select-course': {
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 10,
      },
      '& .name-step': {
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        gap: 1,
      },
    },
    '& .teachers-selector-container': {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'hidden', 
      flexGrow: 1,
      '& .teacher-selector': {
        flexGrow: 1,
        overflowY: 'hidden', 
        '& .select-teachers': {
          "& .transfer-list .MuiList-root": {
          }
        }
      }
    },
    '& .field-error': {
      color: '#d32f2f',
      margin: '0 14px'
    },
  }
}