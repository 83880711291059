
import { useMemo, useRef } from 'external/react'
import {clsx, styled, styleAndCapture, Popper, IconButton} from 'external/material'
import Icon from 'components/Common/Icon'
import useTranslations from 'hooks/translations'
import {formatDate} from 'external/date-fns'

const StyledDeletedMessage = styleAndCapture('div', 'deletedMessage', 'onDetailClick')(deletedMessageButtonStyles)

export default function DeletedMessageButton(props) {
  const {deletedMessage, onDetailClick} = props
  const buttonRef = useRef(null)
  return (<StyledDeletedMessage {...props}>
        <span ref={buttonRef}
            className="link"
            onClick={e => {
                onDetailClick({anchorEl: buttonRef.current, deletedMessage})
            }}
        >
            <Icon name="faCircleInfo" style={{color: "#2c75bd", fontSize:'15pt'}} />
        </span>
    </StyledDeletedMessage>)
}

function deletedMessageButtonStyles() {
    return {
      cursor: 'pointer'
    }
}

const StyledDeletedMessagePopper = styled('div')(deletedMessagePopperStyles)

export function DeletedMessagePopper(props) {
  const {anchorEl, onClose, deletedMessage} = props
  const {translate, language} = useTranslations()
  const open = useMemo(() => anchorEl != null, [anchorEl])
  console.log(anchorEl)
  return (
    <Popper
      placement="left"
      anchorEl={anchorEl}
      open={open}
      disablePortal={false}
      sx={{zIndex: 100}}
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            // altAxis: true,
            // altBoundary: true,
            // tether: true,
            // rootBoundary: 'document',
            boundariesElement: 'viewport',
            escapeWithReference: true,
            // padding: 8,
          },
        },
        {
          name: 'arrow',
          enabled: false,
          options: {
          },
        },
      ]}
    >
      <StyledDeletedMessagePopper>
        <div className="header">
          <div className="title">{translate('deleted-message-detail-title')}</div>
          <IconButton  onClick={onClose} className="close"><Icon name="maClose" /></IconButton>
        </div>
        {deletedMessage && (
          <div className="content">
            <div className="left">
              <div className="teacher">
                <div className="label">{translate('teacher')}</div>
                <div className="text">{deletedMessage.teachername}</div>
              </div>
              <div className="group">
                <div className="label">{translate('group')}</div>
                <div className="text">{deletedMessage.groupname}</div>
              </div>
              <div className="scheduled">
                <div className="label">{translate('scheduled-date')}</div>
                <div className="text">{deletedMessage.scheduleddate}</div>
              </div>
              <div className="deleted">
                <div className="label">{translate('deleted-date')}</div>
                <div className="text">{deletedMessage.deleteddate}</div>
              </div>
            </div>
            <div className="right">
              <div className="message">
                <div className="label">{translate('comment')}</div>
                <div className={clsx('text', 'message')}>{deletedMessage.message}</div>
              </div>
              <div className="reason">
                <div className="label">{translate('reason')}</div>
                <div className={clsx('text', 'reason')}>{deletedMessage.reason}</div>
              </div>
            </div>
          </div>
        )}
      </StyledDeletedMessagePopper>
    </Popper>
  )
}

function deletedMessagePopperStyles(){
 return {
   backgroundColor: '#fff',
   minWidth: 700,
   display: 'flex',
   borderRadius: 10,
   flexDirection: 'column',
   color: '#798092',
   border: 'solid 1px #e5e7ee',
   boxShadow: '1px 0px 4px #e5e7ee',
   '& .header': {
    padding: '20px 20px',
    display: 'flex',
    borderBottom: 'solid 1px #e5e7ee',
    flexShrink: 0,
    fontWeight: 'bold',
   '& .title': {
     width: 325,
     overflow: 'hidden',
     whiteSpace: 'nowrap',
     textOverflow: 'ellipsis'
   },
   '& .close': {
      position: 'absolute',
     right: 6,
     top: 12 
    }
   },
   '& .content': {
      padding: '15px 20px',
      display: 'flex',
      flexGrow: 1,
    '& .label': {
      color: '#798092',
      fontWeight: 600,
      marginBottom: 10
    },
     '& .left': {
        flexShrink: 0,
        width: 250,
        '& .text': {
          marginBottom: 20
        }
     },
     '& .right': {
        display: 'flex',
       flexDirection: 'column',
       flexGrow: 1,
       '& .message': {
         marginBottom: 15
       },
       '& .text': {
         padding: 10,
         minHeight: 100,
         marginTop: 10,
         borderRadius: 5,
         border: 'solid 1px #e5e7ee',
       }
     }
   }
 } 
}
