import {Fade} from 'external/material'
import useTranslations from 'hooks/translations'
import useMenu from 'hooks/menu'

export default function Loader({children}) {
  const {translate} = useTranslations()
  const {menu} = useMenu()
  const loaded = translate(true) && menu.length > 0
  return <Fade in={loaded} children={children} />
}
