import {clsx, styleAndCapture} from 'external/material'
import LinkBehavior from 'components/Common/LinkBehavior' 

import Icon from 'components/Common/Icon'
import useTranslations from 'hooks/translations'
import useSession from 'hooks/session'

const StyledSettingsButton = styleAndCapture(LinkBehavior, 'icon', 'id')(settingsButtonStyles)

export default function SettingsButton(props) {

    const {translate} = useTranslations()
    const {navigation} = useSession()

    const {id, icon, className, onClick} = props

    return <StyledSettingsButton
        {...props}
        id={`settings-button-${id}`}
        className={clsx('setting-button', className)}
        to={onClick ? undefined : navigation.settings.link({section: id})}
        onClick={onClick}
    >
        <div className={clsx('settings-icon', id, icon)}>{<Icon name={icon} />}</div>
        <div className='settings-label'>{translate(`settings-${id}`)}</div>
    </StyledSettingsButton>
        
}

function settingsButtonStyles() {
    return {
        backgroundColor: '#fff',
        borderRadius: 8 ,
        border: 'solid 1px #dbdbdb',
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'calc(100% / 4 - 50px)',
        minWidth: 200,
        height: 125,
        cursor: 'pointer',
        textDecoration: 'none',
        fontSize: '13.5pt',
        color: '#2c75bd',
        '& .settings-icon': {
            marginBottom: 5,
            display: 'flex',
            justifyContent: 'center',
            color: '#2c75bd',
            '& svg': {
                fontSize: '40px'
            }
        }
    }
}