import { ListItem, ListItemText } from "external/material"

export default function ListElement (props) {
  const {index, label, onClick} = props
  const labelId = `transfer-list-item-${index}-label`
  return (
    <ListItem role="listitem" onClick={onClick} className={props.className}>
      <ListItemText id={labelId} primary={label} />
    </ListItem>
   )
}