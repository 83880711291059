import {SvgKeepRatio} from 'utils/style'

export default function DashboardIcon(props) {
  const fill= props.fill || "#fff"
  return (
    <SvgKeepRatio viewBox={{width: 30, height: 32}} {...props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M18.4862581,31.4929952 L18.4862581,26.5496857 C18.4862581,24.3405467 16.6953971,22.5496857 14.4862581,22.5496857 C12.2771191,22.5496857 10.4862581,24.3405467 10.4862581,26.5496857 L10.4862581,31.4929952 L0.528280084,31.4929952 L0.528280084,14.4653871 L14.5175771,1 L28.5101858,14.4653596 L28.5101858,31.4929952 L18.4862581,31.4929952 Z" stroke={fill} fillRule="nonzero"></path>
        </g>
    </SvgKeepRatio>
  )
}