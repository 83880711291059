import {SvgKeepRatio} from 'utils/style'

export default function ArrowLeftIcon(props) {
  const fill= props.fill || "#4E576E"
  return (<SvgKeepRatio viewBox={{width: 10, height: 17}} {...props}>
      <g transform="translate(-762.000000, -544.000000)" fill={fill}>
        <path d="M771.999975,545.512353 L771.999975,559.584738 C771.999975,560.559177 770.821847,561.047144 770.132861,560.358105 L763.096668,553.321912 C762.66953,552.894828 762.66953,552.202263 763.096668,551.775178 L770.132861,544.738986 C770.821847,544.049893 771.999975,544.537914 771.999975,545.512353 Z"></path>
      </g>
    </SvgKeepRatio>
  )
}