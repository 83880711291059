import {
   Box
  ,styled
  ,clsx
  ,Popper
  ,IconButton
  ,CloseIcon
} from 'external/material'

const StyledMessage = styled(Box)(messageStyles)

export default function Message(props) {
  const {open, anchorEl, onClose, lesson, title, date, text, hasTitle = true} = props
  const name = title && title.replace(`${lesson}: `, '')
  return (
    <Popper
      placement="top"
      anchorEl={anchorEl}
      open={open}
      disablePortal={false}
      sx={{zIndex: 100}}
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
        {
          name: 'arrow',
          enabled: false,
          options: {
          },
        },
      ]}
    >
      <StyledMessage className="messageWrapper" sx={{ bgcolor: 'background.paper', width: 400 }}>
        <Box className="header">
          <Box className="lesson">{lesson}</Box>
          <IconButton  onClick={onClose} className="close"><CloseIcon /></IconButton>
        </Box>
        <Box className="content">
          <Box className="name">
              <Box className={clsx('title', {hidden: !hasTitle})}>{name}</Box>
              <Box className="date">{date}</Box>
          </Box>
          <Box className="text">{text} </Box>
        </Box>
      </StyledMessage>
    </Popper>
  )
}

function messageStyles(){
 return {
   display: 'flex',
   borderRadius: 10,
   flexDirection: 'column',
   color: '#798092',
   border: 'solid 1px #e5e7ee',
   boxShadow: '1px 0px 4px #e5e7ee',
   '& .header': {
    padding: '15px 20px',
    display: 'flex',
    borderBottom: 'solid 1px #e5e7ee',
    flexShrink: 0,
    fontWeight: 'bold',
   '& .lesson': {
     width: 325,
     overflow: 'hidden',
     whiteSpace: 'nowrap',
     textOverflow: 'ellipsis'
   },
   '& .close': {
      position: 'absolute',
     right: 8,
     top: 8
    }
   },
   '& .content': {
      padding: '15px 20px',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
     '& .name': {
       display: 'flex',
       fontStyle: 'oblique',
       '& .title': {
          display: 'flex',
          justifyContent: 'flex-start',
          wordBreak: 'break-all',
          marginRight: 20,
          flexGrow: 1,
          '&.hidden': {
            display: 'none'

          }
       },
        '& .date': {
          flexShrink: 0,
         display: 'flex',
          justifyContent: 'flex-end'
        }
     },
     '& .text': {
       padding: 10,
       minHeight: 100,
       marginTop: 10,
       borderRadius: 5,
       border: 'solid 1px #e5e7ee',
     }
   }
 } 
}
      