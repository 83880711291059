import {Link} from 'external/react'
import {styled} from 'external/material'
import {formatNumber} from 'utils/text'

import LessonCountIcon from 'resources/icons/svg/LessonCountIcon'

import useSession from 'hooks/session'
import useTranslations from 'hooks/translations'

const StyledSubject = styled('div')(subjectStyles)

export default function Subject(props) {

    const {translate} = useTranslations()
    const {navigation} = useSession()
    const {id, name, color, lessonCount} = props
    const subjectLink = id ? navigation.catalogCourses.linkWithQuery({}, true, {subject: id}) : undefined
    return (
    <StyledSubject>
        <Link className="subject-link" to={subjectLink}>
            <div className="color-band" style={{backgroundColor: color}}/>
            <div className="subject-label">
              {name}
            </div>
            <div className="lesson-count">
              <div className="wrapper">
                <span className="count"><LessonCountIcon /></span>
                <label className="value">
                  {formatNumber(lessonCount)}
                </label>
                <label className="label">
                  {translate(`lesson${ lessonCount > 1 ? 's' : ''}_label`)}
                </label>
                </div>
            </div>
          </Link>
        </StyledSubject>
    )
}

function subjectStyles() {
    return {
        width: 'calc(100% / 3 - 40px)',
        minWidth: 200,
        maxWidth: 500,
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 5,
        '&:hover': {
          transform: 'scale(1.01)'
        },
        '&.filler': {
          backgroundColor: 'transparent',
          border: 'none'
        },
        '& .subject-link': {
            display: 'flex',
            flexDirection: 'column',
          flexGrow: 1,
          textDecoration: 'none'
        },
        '& .color-band': {
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          height: 4 
        },
        '& .subject-label': {
          display: 'flex',
          padding: '35px 0',
          justifyContent: 'center',
          color: '#565f77',
          fontWeight: 'bold',
          fontSize: '20pt',
          borderBottom: 'solid 1px #e1eaee',
          textAlign: 'center'
        },
        '& .lesson-count': {
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          fontSize: '12pt',
          padding: '8px 0',
          color: '#6a758e',
          '& .wrapper': {
            display: 'flex',
            justifyContent: 'center'
          },
          '& label': {
            cursor: 'pointer',
            textTransform: 'lowercase'
         },
          '& .value': {
            marginLeft: 8,
            marginRight: 5
        }
    }
}
}