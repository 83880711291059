import {styled, clsx, IconButton} from 'external/material'
import Icon from 'components/Common/Icon'
import useTranslations from 'hooks/translations'
import useMenu from 'hooks/menu'

const StyledMeeting = styled('div')(meetingStyles)


export default function ScheduleSpecialistMeeting () {
    const {translate} = useTranslations()
    const {displayingScheduleMeeting, displayScheduleMeeting} = useMenu()

    return <StyledMeeting className={clsx({displaying: displayingScheduleMeeting})}>
        <div className="meeting-head">
            <span onClick={displayScheduleMeeting} className="meeting-label"><strong>{translate('schedule-specialist-meeting-label')}</strong></span>
            <IconButton onClick={displayScheduleMeeting} className="close-meeting"><Icon name="maCancel"/></IconButton>
        </div>
        <div className="meeting-content">
            <iframe src="https://outlook.office365.com/owa/calendar/ReuninconEspecialistadeMatemticas@dreyfous.com/bookings/" width="100%" height="100%" />
        </div>
    </StyledMeeting>
}
function meetingStyles() {
    return {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        '& .meeting-head': {
            flexShrink: 0,
            display: 'flex',
            '& .meeting-label': {
                marginTop: 20,
                flexGrow: 1,
                cursor: 'pointer',
                color: '#1c4b90',

            },
            '& .close-meeting': {
                display: 'none'
            },
        },
        '& .meeting-content': {
            display: 'none'
        },
        '&.displaying': {
                '& .meeting-label': {
                    marginTop: 0
                },
                "& .close-meeting": {
                    display: 'block'
                },
                '& .meeting-content': {
                    display: 'block',
                    flexGrow: 1,
                    "& iframe": {
                        border: 0
                    }
                },

        },
    }
}