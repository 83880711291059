import {Outlet} from 'external/react'
import {styled} from 'external/material'
import Topbar from './Topbar'

const StyledMessages = styled('div')(messageStyles)

export default function MessagesContainer() {
  return (
      <StyledMessages>
        <Topbar className="topbar" /> 
        <div className="messages-content-wrapper">
            <Outlet />
        </div>
      </StyledMessages>
  )
}

function messageStyles() {
   return {
    display: 'flex',
    padding: 40,
    flexGrow: 1,
    overflowY: 'hidden',
    flexDirection: 'column',
    "& .topbar": {
        display: 'flex',
        flexShrink: 0,
        marginBottom: 30,
        flexWrap: 'wrap'
    },
    "& .messages-content-wrapper": {
        display: 'flex',
        flexGrow: 1,
        overflowY: 'auto'
    }
   } 
}