import { differenceBy, sortBy } from 'external/lodash'
import { searchSubstring, capitalizeFirstLetter } from 'utils/text'
import {useState, useEffect, useMemo} from 'external/react'
import {clsx, styled} from 'external/material'

import SelectTeachers from 'components/Common/TransferList/SelectTeachers'

import useTranslations from 'hooks/translations'

import { centerVertically } from 'utils/style'

const StyledTeacherSelector = styled('div')(teacherSelectorStyles)


const initialFilters = {
  pattern: '',
}

export default function TeacherSelector(props) {

  const {translate} = useTranslations()
  const [filters, setFilters] = useState(initialFilters)
  const {teacherList, selectedTeachers, checkedTeachers, handleTeachersChange, handleTeachersChecked, teacherFilter} = props
  
  const filteredTeachers = useMemo(()=> {
    let filteredTeachers = teacherList
    if (filters.pattern != ''){
      filteredTeachers = filteredTeachers.filter(s => searchSubstring(s.name || '', filters.pattern))
    }

    if (selectedTeachers.length > 0) {
      filteredTeachers = differenceBy(filteredTeachers, selectedTeachers, 'id')
    }

    if (teacherFilter) {
      filteredTeachers = filteredTeachers.filter(teacherFilter)
    }

    // Sort teachers by name ascending
    filteredTeachers = sortBy(filteredTeachers, 'name')

    return filteredTeachers
  }, [teacherList, selectedTeachers, filters])

  useEffect(() => {
    if (open) {
      setFilters(initialFilters)
    }
  }, [open])
  
  const handlePatternChange = (e) => {
    setFilters({...filters, pattern: e.target.value})
  }

  return (
      <StyledTeacherSelector className={clsx('teacher-selector', props.className)}>
        <div className='select-teachers-filters'>
          <div className={clsx('select-teachers-filter', 'search')}>
            <div className='select-teachers-filter-label'>{capitalizeFirstLetter(translate('search'))}:</div>
            <input
              type="text"
              value={filters.pattern}
              onChange={handlePatternChange}
            />
          </div>
        </div>
        <SelectTeachers
          teachers={filteredTeachers}
          selectedTeachers={selectedTeachers}
          checkedTeachers={checkedTeachers}
          handleTeachersChange={handleTeachersChange}
          handleTeachersChecked={handleTeachersChecked}
        />
      </StyledTeacherSelector>
  )
}

function teacherSelectorStyles(){
  return {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    "& .select-teachers-filters": {
      display: 'flex',
      flexShrink: 0,
      marginBottom: 10,
      "& .select-teachers-filter": {
        display: 'flex',
        '&:first-of-type': {
          marginLeft: 0
        },
        "& input": {
          padding: "8.5px 14px",
        },
        "& .select-teachers-filter-label": {
          ...centerVertically,
          marginRight: 8 
        }
      }
    },
    "& .transfer-list": {
      flexGrow: 1
    },
    "& .select-teachers": {
      flexGrow: 1
    }
  }
}