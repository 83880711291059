import {styled, Box, clsx} from 'external/material'
import moment from 'external/moment'
import {DatePicker} from "external/react"
import Icon from "components/Common/Icon"
import { centerVertically } from 'utils/style'
import CalendarAltInput from 'components/Common/CalendarAltInput'

import useSession from 'hooks/session'
import useQueryDate from 'hooks/query-date'
import useTranslations from 'hooks/translations'
import { BackToDashboard } from 'components/Common/BackButton'

const StyledToolbar = styled(Box)(toolbarStyles)

export default function Toolbar (props) {

  const {navigation, calendarView: view} = useSession()
  const {translate, language} = useTranslations()
  const [date] = useQueryDate()

  const level = `${view}s`

    const navigate = changeDate(navigation, view) 
    const navigateToView = changeView(navigation)
    const previous = () => navigate(date().subtract(1, level))
    const next = () => navigate(date().add(1, level))
    return (
      <StyledToolbar className="toolbar">

          <BackToDashboard />
        <div className="navigation-wrapper">
          <div className="navigation">
          <div className="previous" onClick={previous}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Icon name="faChevronLeft" />
            </div>
          </div>
          <div className="icon">
            <DatePicker
              selected={moment(date()).toDate()}
              dateFormat="MM/yyyy"
              locale={language}
              popperPlacement="top-end"
              popperModifiers={[

                { name: 'offset',
                  options: {
                    offset: [-14, 2]
                  }
                },
                { name: 'arrow',
                  options: {
                    padding: 25 
                  }
                },
                {
                  name: 'preventOverflow',
                  options: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: 'viewport'
                  },
                }
              ]}
              onChange={date => {navigate(moment(date))}}
              customInput={<CalendarAltInput />}
              showMonthYearPicker={view == 'month'}
            />
          </div>
          <div className="next" onClick={next}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Icon name="faChevronRight" />
            </div>
          </div>
      </div>
        </div>
        <div className="title">
          {title(date(), view, translate, language)}
        </div>
        <div className="button today" onClick={() => navigate(new Date())}>
          {translate("today_label")}
        </div>
        <div
          className={clsx('button', 'day', {active: view == 'day'})}
          onClick={() => view != 'day' && navigateToView('day', dateQuery(date, 'day'))}
        >
          {translate("day_label")}
        </div>
        <div
          className={clsx('button', 'week', {active: view == 'week'})}
          onClick={() => view != 'week' && navigateToView('week', dateQuery(date, 'week'))}
        >
          {translate("week_label")}
        </div>
        <div
          className={clsx('button', 'month', {active: view == 'month'})}
          onClick={() => view != 'month' && navigateToView('month', dateQuery(date, 'month'))}
        >
          {translate("month_label")}
        </div>
      </StyledToolbar>
    )
}

function dateQuery(date, view) {
  return view == 'month'
    ? {
      start: moment(date()).startOf(view).startOf('week').format('YYYY-MM-DD'),
      end: moment(date()).endOf(view).endOf('week').format('YYYY-MM-DD')
    }
    : {
      start: moment(date()).startOf(view).format('YYYY-MM-DD'),
      end: moment(date()).endOf(view).format('YYYY-MM-DD')
    }
}

function toolbarStyles(){

  const navigationButton = {
      cursor: 'pointer',
      borderRadius: '50%',
      width: '30px',
      height: '30px',
      backgroundColor: '#fff',
      fontSize: '12pt',
      "&:hover": {
        transform: 'scale(1.06)',
        fontSize: '14pt'
      }
  }

  return {
    "&.toolbar": {
      display: 'flex',
      padding: '40px 40px 8px 40px',
      color: '#515a71',
    },
    "& .backButton": {
      marginRight: 20,
    },
    "& .title": {
      flexGrow: 1,
      fontSize: '18pt',
      paddingLeft: '20px',
      ...centerVertically
    },
    "& .button": {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      backgroundColor: '#fff',
      borderRadius: '4px',
      cursor: 'pointer',
      width: '100px',
      fontSize: '13pt',
      "&:hover": {
        transform: 'scale(1.02)',
        fontWeight: 'bold'
      },
      "&.today": {
        marginRight: '15px',
      },
      "&.day": {
        borderRadius: '4px 0 0 4px',
        borderRight: '1px solid #e0e5e8',
      },
      "&.week": {
        borderRadius: 0,
        borderRight: '1px solid #e0e5e8',
      },
      "&.month": {
        borderRadius: '0 4px 4px 0'
      },
      "&.active": { 
        cursor: 'default',
        color: '#fff',
        backgroundColor: '#5da9ff',
        "&:hover": {
          fontWeight: 'normal',
          transform: 'scale(1.0)'
        }
    },
   },
    "& .navigation-wrapper": {
      ...centerVertically
    },
  "& .navigation": {
      display: 'flex',
      "& .icon": {
        fontSize: '15pt',
        marginLeft: '15px',
        marginRight: '15px',
        cursor: 'pointer',
        "& svg:hover": {
          transform: 'scale(1.06)'
        }
      },
    "& .previous": {
      ...centerVertically,
      ...navigationButton

    },
    "& .next": {
      ...centerVertically,
      ...navigationButton
    }
  }
}
}


export function changeView (navigation) {
  return (calendarView, query) => {
    navigation.calendarView.goWithQuery(query, true, {calendarView})
  }
}

function title (date, level, translate, languageId) {
  switch (level) {
    case 'week':
      const start = moment(date).startOf(level).format('DD')
      const end = moment(date).endOf(level).format('DD')
      return `${translate('week_label')} ${start} - ${end}`
    case 'day':
      const formats = {
        es: "dddd, DD [de] MMMM [de] YYYY",
        en: "dddd, MMMM do YYYY"
      }
      return moment(date).format(formats[languageId])
    default:
      return moment(date).format('MMMM YYYY')
  }
}

export function changeDate (navigation, view) {
  const pad = view == 'day' ? 'day' : 'week'
  return (date) => {
    const query = {
      start: moment(date).startOf(view).startOf(pad).format('YYYY-MM-DD'),
      end: moment(date).endOf(view).endOf(pad).format('YYYY-MM-DD')
    }
    navigation.calendarView.goWithQuery(query, true, {calendarView: view})
  }
}
