import {SvgKeepRatio} from 'utils/style'

export default function ChatIcon(props) {
  const fill= props.fill || "#fff"
  return (
    <SvgKeepRatio viewBox={{width: 38, height: 31}} {...props}>
      <g stroke={fill} strokeWidth="1" fill="none" transform="translate(1.000000, 0.687919)">
        <path d="M12.4951863,0 C19.0841956,0 24.48091,4.14175228 24.956335,9.39705096 C18.470943,9.76952621 13.3543539,14.1599631 13.3543539,19.5237509 C13.3543539,19.7775007 13.3658051,20.0290722 13.3883104,20.2781429 C13.0936179,20.2960657 12.7956417,20.3046777 12.4951863,20.3046777 C10.6208893,20.3046777 8.84912042,19.9581506 7.25304608,19.3577578 C6.05235584,20.3144491 3.62652304,21.866576 0.390474571,21.866576 C0.234275209,21.866576 0.0976186426,21.7787287 0.034176058,21.6323007 C-0.0292665267,21.4858728 -2.85993451e-13,21.319902 0.10739004,21.2076501 C0.131794701,21.1832455 2.1720148,18.9916879 2.78213132,16.5317267 C1.04453854,14.7892244 -2.85993451e-13,12.5732621 -2.85993451e-13,10.1523388 C-2.85993451e-13,4.54412875 5.59352916,0 12.4951863,0 Z"></path>
        <path d="M25.8495402,11.5580488 C31.370879,11.5580488 35.845713,15.1933604 35.845713,19.6799392 C35.845713,24.166518 31.370879,27.8018296 25.8495402,27.8018296 C24.350099,27.8018296 22.9326806,27.5246073 21.6558181,27.0442919 C20.6952636,27.8096468 18.7545927,29.0513512 16.1657478,29.0513512 C16.040788,29.0513512 15.9314625,28.9810733 15.8807083,28.8639306 C15.8299541,28.746788 15.8533674,28.6140111 15.9392796,28.5242093 C15.9588034,28.5046855 17.5909833,26.7514353 18.0790777,24.7834616 C16.6890002,23.3894565 15.8533674,21.6166824 15.8533674,19.6799392 C15.8533674,15.1933604 20.3282014,11.5580488 25.8495402,11.5580488 Z" transform="translate(25.849540, 20.304700) scale(-1, 1) translate(-25.849540, -20.304700) "></path>
      </g>
    </SvgKeepRatio>
  )
}