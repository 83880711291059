import {Outlet} from 'external/react'
import {styled, clsx} from 'external/material'
import useSession from 'hooks/session'

import WrapperContainer from 'components/Common/WrapperContainer'

import Filters from './Filters'
import Toolbar from './Toolbar'

const StyledCalendar = styled(WrapperContainer)(calendarStyles)

export default function CalendarContainer() {
  const {calendarView, navigation} = useSession()
  return (
    <StyledCalendar
      prefix="calendar"
      containerProps={{classes: ['calendar', 'view', {[calendarView]: navigation.calendarView.active}]}}>
        <Toolbar />
        <WrapperContainer
          prefix="calendar-content"
          containerProps={{classes: 'calendar'}}
        >
           <Filters />
          <Outlet />
        </WrapperContainer>
    </StyledCalendar>
  )
}

function calendarStyles() {
  return {
    "&.calendar-wrapper": {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflowY: 'hidden',
    },
    "& .calendar-container": {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flexGrow: 1,
      overflowY: 'hidden',
    },
    "& .calendar-content-wrapper": {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px 40px',
      flexGrow: 1,
      overflowY: 'hidden',
    },
    "& .calendar-content-container": {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflowY: 'auto',
      borderRadius: 8,
      border: 'solid 1px #e1eaee',
    },
  }
}
