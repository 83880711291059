import {useState, useSearchParams, useEffect, stringifyQueryString} from 'external/react'
import {styled} from 'external/material'
import Icon from "external/font-awesome"

import useTranslations from 'hooks/translations'
import { centerVertically } from 'utils/style'

const StyledSearchPattern = styled('div')(searchPatternStyles)

export default function SearchPattern (props) {

  const {
    onPatternSearch,
    className,
    searchOnBlur = true,
    searchPlaceholder,
    queryParamName = 'pattern',
    initialPattern = '',
    interceptQuery = false,
    preserveQuery = false,
  } = props

  const {translate} = useTranslations()
  const [params, setSearchParams] = useSearchParams()
  const [pattern, setPattern] = useState(initialPattern)
  
  useEffect(() => {
    if (params.has(queryParamName)) {
      const searchPattern = params.get(queryParamName)
      if (pattern != searchPattern) setPattern(searchPattern)
    } else if (pattern != '') {
      setPattern('')
    }
  }, [params])

  function searchPattern() {
    const currentQuery = {}

    if (preserveQuery) {
      for (const [key, value] of params.entries()) {
        if (key != queryParamName)
          currentQuery[key] = value
      }
    }

    let updatedQuery = pattern == '' ? currentQuery : {...currentQuery, [queryParamName]: pattern}
    if (interceptQuery) updatedQuery = interceptQuery(updatedQuery)
    if (onPatternSearch) onPatternSearch(updatedQuery)
    else setSearchParams(stringifyQueryString(updatedQuery))

  }

  return (
    <StyledSearchPattern className={className}>
      <div className="wrapper">
        <Icon name="faSearch" />
        <input
          value={pattern}
          placeholder={searchPlaceholder}
          onChange={e => setPattern(e.target.value)}
          onBlur={searchOnBlur ? () => searchPattern() : undefined}
          onKeyDown={({key}) => key == 'Enter' && searchPattern()}
        /> 
        <span className="button" onClick={() => searchPattern()}>
          {translate("go-label")}
        </span>
      </div>
    </StyledSearchPattern>
  )
}

function searchPatternStyles(){
  return {
    ...centerVertically,
    "& .wrapper": {
      display: 'flex',
      backgroundColor: 'white',
      border: 'solid 1px #bdbdbd',
      borderRadius: '5px',
      "& svg": {
        padding: '12px 6px 8px 10px'
      },
      "& input": {
        padding: '8px 0',
        flexGrow: 1,
        paddingLeft: 5,
        color: '#565f77',
        fontSize: '12pt',
        border: 'none',
        "&:focus": {
          outline: 'none'
        }
      },
      "& .button": {
        flexShrink: 0,
        padding: '8px 0',
        width: '30px',
        textAlign: 'center',
        borderLeft: 'solid 1px #f4f4f4',
        cursor: 'pointer',
        backgroundColor: 'white',
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
        fontSize: '11pt',
        "&:hover": {
          fontWeight: 'bold'
        }
      }
    }
  }
}