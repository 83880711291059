import {Routes, Route} from 'external/react'

import SettingsContainer from './SettingsContainer'

import SettingsMenu from './SettingsMenu'
import ExportUsers from './ExportUsers'
import CreatePeriods from './Rollbook'
import SendUsersInfo from './SendUsersInfo'
import LicenseCount from './LicenseCount'

export default function SettingsPage() {

    return(
        <Routes>
            <Route element={<SettingsContainer />}>
                <Route index element={<SettingsMenu />} />
                <Route exact path='export-users' element={<ExportUsers />} />
                <Route exact path='rollbook' element={<CreatePeriods />} />
                <Route exact path='send-info' element={<SendUsersInfo />} />
                <Route exact path='license-count/*' element={<LicenseCount />} />
            </Route>
        </Routes>
    )
}