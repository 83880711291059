import {clsx, styleAndCapture} from 'external/material'
import Icon from 'components/Common/Icon'
import {viewerGuard, subdomainLink} from 'utils/url'
import { logLessonAccess } from 'utils/lesson'

import useTranslations from 'hooks/translations'

const StyledPreviewLesson = styleAndCapture('div', 'lesson', 'hasEpl')(previewLessonStyles)

export default function PreviewLesson(props) {
  const {language} = useTranslations()
  const {hasEpl, lesson} = props

  return (<StyledPreviewLesson {...props}>
        <a
        className={clsx("lessonLink", "online", {hidden: !hasEpl})}
        onClick={logLessonAccess('online', {id: lesson, hasEpl})}
        {...viewerGuard({target: "_blank", href: subdomainLink('preview', '#',  language, 'lesson', lesson)})}
        >
            <Icon name="esPreview" fill="#2c75bd" width={25} />
        </a>
    </StyledPreviewLesson>)
}

function previewLessonStyles() {
    return {
        "& .hidden": {
            display: 'none'
        }

    }
}
