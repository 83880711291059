import {styled, Box} from 'external/material'
import { centerVertically } from 'utils/style'
import {homeworkColor, assessmentColor} from 'settings'

const StyledGroup = styled(Box)(groupStyles)

export default function Group (props) {

  const {subject, group, type} = props
  const isAssessment = ['quiz', 'a.assignment'].indexOf(type) >= 0
  const isHomework = type == 'homework'

  const isRedundant = group.name.trim().toLowerCase() == group.courseName.trim().toLowerCase()

  const backgroundColor = isAssessment
    ? assessmentColor 
    : isHomework
      ? homeworkColor
      : subject.color
  return (
    <StyledGroup className="group">
      <div className="color-band" style={{backgroundColor}} />
      <div className="title">
        {!isRedundant && (
        <div className="subject">
          {group.courseName}
        </div>)}
        <div className="name">
          <label title={group.name}>{group.name}</label>
        </div>
      </div>
    </StyledGroup>
  )
}

function groupStyles() {
  return {
    "&.group": {
      flexShrink: 0,
      flexGrow: 0,
      display: 'flex',
      fontSize: '11pt',
      maxWidth: 200,
      minWidth: 200,
      "& .color-band": {
        width: 5,
        borderBottom: 'none'
      },
      "& > .title": {
        ...centerVertically,
        flexGrow: 1,
        borderBottom: 'solid 1px #e1eaee',
      },
      "& > div": {
        display: 'flex',
        padding: '4px 10px',
        "& label": {
          width: '175px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      }
    }
  }
}
