import {SvgKeepRatio} from 'utils/style'

export default function ArrowRightIcon(props) {
  const fill= props.fill || "#4E576E"
  return (<SvgKeepRatio viewBox={{width: 10, height: 17}} {...props}>
      <g transform="translate(-764.000000, -480.000000)" fill={fill}>
        <path d="M773.999975,481.512353 L773.999975,495.584738 C773.999975,496.559177 772.821847,497.047144 772.132861,496.358105 L765.096668,489.321912 C764.66953,488.894828 764.66953,488.202263 765.096668,487.775178 L772.132861,480.738986 C772.821847,480.049893 773.999975,480.537914 773.999975,481.512353 Z" transform="translate(769.388145, 488.548534) rotate(-180.000000) translate(-769.388145, -488.548534) "></path>
      </g>
    </SvgKeepRatio>
  )
}