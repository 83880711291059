import { styled } from "external/material"
import { centerVertically } from "utils/style"

import TeacherFilter from "components/Common/Filters/TeacherFilter"
import GroupFilter from "components/Common/Filters/GroupFilter"
import EventTypeCategory from "components/Common/Filters/EventCategoryFilter"

import ScheduleComment from "./ScheduleComment"
import ScheduleCommentForm from "./ScheduleComment/ScheduleCommentForm"

const StyledFilters = styled('div')(filterStyles)

export default function Filters () {
    return (
      <StyledFilters className="filters-wrapper">
        <div className="filters-container">
            <div className="filters">
                <TeacherFilter className="teacher" preserveQuery />
                <GroupFilter className="group" preserveQuery />
                <EventTypeCategory className="type" preserveQuery />
            </div>
            <div className="filler" />
            <div className="add-comment">
                <ScheduleCommentForm />
                <ScheduleComment />
            </div>
        </div>
      </StyledFilters>
    )
}

function filterStyles(){
    return {
        backgroundColor: '#fff',
        ...centerVertically,
        '& .filters-container': {
            display: 'flex'
        },
        "& .filters": {
            flexShrink: 0,
            display: 'flex',
            padding: 15,
            "& .group": {
                width: 250,
                marginLeft: 15,
            },
            "& .type": {
                marginLeft: 15,
                width: 275
            }
        },
        "& .filler": {
            flexGrow: 1
        },
        "& .add-comment": {
            padding: 15,
            ...centerVertically
        }
    }
}