import { useState } from 'external/react'
import {styled} from 'external/material'
import Icon from 'components/Common/Icon'

import useTranslations from 'hooks/translations'
import ConfirmDialog from 'components/Common/ConfirmDialog'

const StyledDeleteComment = styled('div')(deleteCommentStyles)

export default function DeleteComment(props) {

    const [open, setOpen] = useState(false)
    const {event, onDeleteComment} = props
    const {translate} = useTranslations()

    const confirmTitle = translate('confirm-action') || ''

    const contentText = translate('delete-comment-text', event.content) || ''

    function handleOpen() {
        setOpen(true)
    }
    function handleClose() {
        setOpen(false)
    }
    
    return (<StyledDeleteComment>
        <span onClick={handleOpen} title={contentText} className={props.className}><Icon name="frTrashCan"/></span>
        <ConfirmDialog
            open={open}
            title={confirmTitle}
            contentText={contentText}
            confirmText={translate('delete-comment-confirmation')}
            confirmLabel={translate('yes')}
            cancelLabel={translate('no')}
            onConfirm={() => {onDeleteComment(event)}}
            handleClose={handleClose}
        />
    </StyledDeleteComment>)

}

function deleteCommentStyles() {
    return {
        color: '#d32f2f',
        cursor: 'pointer',
        fontSize: 18
    }
}