import { intersectionBy, uniq, uniqBy, orderBy, differenceBy } from 'external/lodash'
import { searchSubstring, isEmpty, capitalizeFirstLetter } from 'utils/text'
import {useState, useEffect, useMemo} from 'external/react'
import {clsx, styled} from 'external/material'

import SelectStudents from 'components/Common/TransferList/SelectStudents'

import useAdmin from 'hooks/admin'
import useTranslations from 'hooks/translations'
import useCatalog from 'hooks/catalog'

const StyledStudentCommentsStep = styled('div')(studentCommentsStepStyles)

const initialFilters = {
  pattern: '',
  grade: '',
  category: ''
}

export default function EditScheduleCommentStudentsStep(props) {

  const {translate} = useTranslations()
  const [filters, setFilters] = useState(initialFilters)
  const {gradesList} = useCatalog() 
  const {update, editScheduleCommentForm: {open, studentList, selectedStudents, checkedStudents}} = useAdmin()
  
  const filteredStudents = useMemo(()=> {
    let filteredStudents = studentList
    if (filters.pattern != ''){
      filteredStudents = filteredStudents.filter(s => searchSubstring(s.name, filters.pattern))
    }
    if (filters.grade != '') {
      filteredStudents = filteredStudents.filter(s => s.category.grade == filters.grade)
    }
    if (filters.category != '') {
      filteredStudents = filteredStudents.filter(s => s.category.category == filters.category)
    }
    if (selectedStudents.length > 0) {
      filteredStudents = differenceBy(filteredStudents, selectedStudents, 'id')
    }
    return filteredStudents
  }, [studentList, selectedStudents, filters])

  const studentGrades = useMemo(() => {
    const studentGrades = uniq(studentList.map(s => s.category.grade)).map(id => ({id}))
    return [{id: '', name: '*'}].concat(intersectionBy(gradesList, studentGrades, 'id'))
  }, [studentList, gradesList])

  const studentCategories = useMemo(() => {
    const studentCategories = orderBy(uniqBy(studentList.map(s => s.category), 'category'), 'categoryName')
    return [{category: '', categoryName: '*'}].concat(studentCategories)
  }, [studentList])


  const handleStudentsChecked = (studentId) => {
    update({editScheduleCommentForm: {checkedStudents:{[studentId]: {$apply: checked => !checked}}}})
  }

  const handleStudentsChange = (props) => {
    const {right: selectedStudents} = props
    update({editScheduleCommentForm: {selectedStudents: {$set: selectedStudents}}})
  }
  
  useEffect(() => {
    if (open) {
      setFilters(initialFilters)
    }
  }, [open])
  
  const handlePatternChange = (e) => {
    setFilters({...filters, pattern: e.target.value})
  }

  const handleGradeChange = (e) => {
    setFilters({...filters, grade: e.target.value})
  }

  const handleCategoryChange = (e) => {
    setFilters({...filters, category: e.target.value})
  }

  return (
      <StyledStudentCommentsStep className={clsx('students-step', props.className)}>
        <div className='select-students-filters'>
          <div className={clsx('select-students-filter', 'search')}>
            <div className='select-students-filter-label'>{capitalizeFirstLetter(translate('search'))}:</div>
            <input
              type="text"
              value={filters.pattern}
              onChange={handlePatternChange}
            />
          </div>
          <div className={clsx('select-students-filter', 'grade')}>
            <div className='select-students-filter-label'>{capitalizeFirstLetter(translate('grade'))}:</div>
            <select value={filters.grade} onChange={handleGradeChange}>
              {studentGrades.map(g => (<option key={g.id} value={g.id}>{g.name}</option>))}
            </select>
          </div>
          <div className={clsx('select-students-filter', 'category')}>
            <div className='select-students-filter-label'>{capitalizeFirstLetter(translate('category'))}:</div>
            <select value={filters.category} onChange={handleCategoryChange}>
              {studentCategories.map(g => (<option key={g.category} value={g.category}>{isEmpty(g.categoryName) ? translate('uncategorized-students') : g.categoryName}</option>))}
            </select>
          </div>
        </div>
        <SelectStudents
          students={filteredStudents}
          selectedStudents={selectedStudents}
          checkedStudents={checkedStudents}
          handleStudentsChange={handleStudentsChange}
          handleStudentsChecked={handleStudentsChecked}
        />
      </StyledStudentCommentsStep>
  )
}

function studentCommentsStepStyles(){
  return {
    "& .select-students-filters": {
      display: 'flex',
      padding: '0 20px',
      "& .select-students-filter": {
        display: 'flex',
        marginLeft: 15,
        marginBottom: 20,
        '&:first-of-type': {
          marginLeft: 0
        },
        "& .select-students-filter-label": {
          marginRight: 8 
        }
      }
    },
    "& .transfer-list": {
      flexGrow: 1
    },
    "& .select-students": {
      flexGrow: 1
    }

  }
}