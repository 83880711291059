import { useMemo } from 'external/react'
import {styled, clsx} from 'external/material'
import useAdmin from 'hooks/admin'
import { parse } from 'external/html-parser'

const StyledStep = styled('div')(stepStyles)

export default function PreviewStep(props) {
    const {className} = props
    const {sendUsersInfo: {preview}} = useAdmin()
    const content = useMemo(() => parse(preview), [preview])

    return (
        <StyledStep className={clsx('step', 'container', className)}>
            <div className={clsx('step', 'preview', 'content')}>
                {content}
            </div>
        </StyledStep>
    )
}

function stepStyles() {
    return {
        display: 'flex',
        flexDirection: 'column',
        '& > .content': {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            backgroundColor: '#f3f1f170',
            border: 'solid 1px #ccc',
            borderRadius: 5,
            padding: 12,
        }
    }
}