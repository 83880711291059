import {styled} from 'external/material'
import useTranslations from 'hooks/translations'

const StyledEditScheduleCommentButtons = styled('div')(editScheduleCommentButtonsStyles)

export default function EditScheduleCommentButtons ({left, right}) {
    const {translate} = useTranslations()
    return (
      <StyledEditScheduleCommentButtons>
        <div className="filler" />
        <button disabled={!left.enabled} className={left.label} onClick={left.onClick}>
          {translate(`${left.label}_label`)}
        </button>
        <button disabled={!right.enabled} className={right.label} onClick={right.onClick}>
          {translate(`${right.label}_label`)}
        </button>
      </StyledEditScheduleCommentButtons>
  )
}

function editScheduleCommentButtonsStyles() {
    return {
        display: 'flex',
        paddingTop: 20,
        paddingRight: 25,
        paddingBottom: 25,
        "& .filler": {
            flexGrow: 1
        },
        "& button": {
          fontSize: '12pt',
          borderRadius: '4px',
          padding: '8px',
          width: '150px',
          cursor: 'pointer',
          "&.cancel, &.back": {
            backgroundColor: '#fff',
            color: '#687189',
            border: 'solid 1px #d2d2d2',
            marginRight: '20px',
            "&:hover": {
            }
          },
          "&.continue, &.save": {
            backgroundColor: '#2c75bd',
            color: '#fff',
            border: 'none',
            "&:hover": {
            }
          },
          "&.save[disabled]": {
            cursor: 'default',
            opacity: '0.5',
            border: '1px solid #999999',
            backgroundColor: '#cccccc',
            color: '#666666'
          }

        }
    }
}
