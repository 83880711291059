import {omit} from 'external/lodash'
import Icon from './Icon'

import useTranslations from 'hooks/translations'

export default function IconLink (props) {
  const {name, label} = props
  const {translate} = useTranslations()
  return (
    <a {...omit(props, ['name','label'])}>
      <Icon name={name} />
      <label className="label">
        {translate(label)}
      </label>
    </a>
  )
}
