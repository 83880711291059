import {Link} from 'external/react'
import { styled, clsx, Box} from 'external/material'

import Icon from 'components/Common/Icon'

import useSession from 'hooks/session'
import useTranslations from 'hooks/translations'

const StyledDashboardButton = styled(Box)(dashboardButtonStyles)

export default function DashboardButton({pageName, iconName, href, target}) {

    const {navigation} = useSession()
    const {translate} = useTranslations()

    const navigationProps = href ? {component: 'a', href, target} : {component: Link, to: navigation.page.link({pageName})}

    return <StyledDashboardButton 
        id={`dashboard-button-${pageName}`}
        className={clsx('dashboard-button', pageName)}
        {...navigationProps}
    >
      <div className="button-content">
        <div className="icon">
            <Icon name={iconName} />
        </div>
        <div className="label">{translate(`${pageName}_title`)}</div>
      </div>
    </StyledDashboardButton>
        
}

function dashboardButtonStyles() {
    return {
        width: 220,
        height: 130,
        textDecoration: 'none',
        color: '#2c75bd',
        backgroundColor: '#fff',
        borderRadius: 8 ,
        padding: 10,
        border: 'solid 1px #dbdbdb',
        display: 'flex', 
        justifyContent: 'center',
        '& .button-content': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        '& .icon': {
            display: 'flex',
            marginLeft: -2,
            justifyContent: 'center',
            fontSize: '30pt',
        },
        '& .label': {
            display: 'flex',
            justifyContent: 'center',
            fontSize: '12.5pt',
            marginTop: 8 
        }
    }
}