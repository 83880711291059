import { styled, Button, Dialog, DialogTitle, DialogContent, DialogActions } from 'external/material'
import Icon from './Icon'

const StyledDialog = styled(Dialog)(dialogStyles)

export default function AlertDialog({ open, onClose, title, content, icon }) {
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle className="title">
        {icon ? <Icon name={icon} style={{ fontSize: '20pt' }} /> : null}
        {title}
      </DialogTitle>
      <DialogContent className="content">
        {content}
      </DialogContent>
      <DialogActions>
        <Button className="button" onClick={onClose}>
          OK
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

function dialogStyles() {
  return {
    '& .MuiDialog-paper': {
      '& .title': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
        backgroundColor: '#2c7cbd',
        color: '#fff',
        padding: '8px',
        fontSize: '16pt',
      },
      '& .content': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px 24px',
        borderBottom: 'solid 1px rgb(220, 225, 236)',
      },
      '& .button': {
        fontSize: '12pt',
        padding: '6px',
        backgroundColor: '#2c75bd',
        width: '65px',
        color: '#fff',
        border: 'none',
      }
    },
  }
}