import {clsx, styled} from 'external/material'

import {range} from 'external/lodash'
import moment from 'external/moment'
import * as dateLocale from 'date-fns/locale'
import useTranslations from 'hooks/translations'
import useRollbook from 'hooks/rollbook'
import DatePicker from 'components/Common/DatePicker'

const currentYear = moment().get('year')

const StyledRollbookName = styled('div')(rollbookNameStyles)

export default function RollbookName (props) {

  const {start, finish, update} = useRollbook()
  const {translate, language} = useTranslations()
  const startDate = moment(start).toDate()
  const finishDate = moment(finish).toDate()

  const locale = language == 'en' ? dateLocale.enUS : dateLocale.es
  const format = translate('date-format') || 'MM/dd/yyyy'

  function handleDateChange(target) {
    return date => {
      update({[target]: {$set: date}, rearrange: {$apply: i => i + 1}})
    }
  }

  return (
    <StyledRollbookName>
      <div className={clsx('name')}>
        <div className="label">
          {translate('rollbook-name')}
        </div>
        <div className="value-wrapper">
          <div className="value">
            {translate('rollbook')} {moment(startDate).get('year')}-{moment(finishDate).get('year')}
          </div>
        </div>
      </div>
      <div className={clsx('start')}>
        <div className="label">
          {translate('start-period')}
        </div>
         <div className="date">
            <DatePicker 
              locale={locale}
              format={format}
              date={startDate}
              selected={startDate}
              minDate={startDate}
              maxDate={finishDate}
              setDate={handleDateChange('start')}
            />
        </div>
      </div>
      <div className={clsx('finish')}>
        <div className="label">
          {translate('end-period')}
        </div>
          <div className="date">
            <DatePicker
              locale={locale}
              format={format}
              date={finishDate}
              selected={finishDate}
              minDate={startDate}
              maxDate={finishDate}
              setDate={handleDateChange('finish')}
            />
        </div>
      </div>
    </StyledRollbookName>
  )
}

function rollbookNameStyles(theme) {

  return {
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'row !important',
    "& .label": {
        marginBottom: 5
    },
    "& .value-wrapper": {
        display: 'flex',
    },
    "& .value": {
        borderRadius: 2,
        padding: 6,
        border: '1px solid #979797',
        minWidth: 200,
        backgroundColor: '#f4f4f4'
    },
    '& .name': {
        flexGrow: 1
    },
    '& .start': {
        marginRight: 25
    },
    '& .date': {
      border: 'solid 1px #4F576E',
      borderRadius: 2,
      padding: 6,
    }
  }
}

