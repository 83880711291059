import {createElement} from 'external/react'

import {routes} from 'utils/routing'
import useNavigate from 'hooks/navigate'

import Translations from  'context/translations-context'
import Settings from  'context/settings-context'
import Profile from  'context/profile-context'
import Session from  'context/session-context'
import Catalog from  'context/catalog-context'
import Admin from  'context/admin-context'
import Rollbook from  'context/rollbook-context'

//import Assessment from 'context/assessment-context'
////import Homework from 'context/homework-context'

const contextProviders = [
   Translations.Provider
  ,Settings.Provider
  ,Profile.Provider
  ,Session.Provider
  ,Catalog.Provider
  ,Admin.Provider
  ,Rollbook.Provider
  //,Assessment.Provider
  //,Homework.Provider
].reverse()

const composeProviders = props => (Previous, Next) => (<Next props={props} children={Previous} />)


export default function withFullContext (Dep) {
  return props => {
    const routingProps = useNavigate(routes)
    return contextProviders.reduce(composeProviders({...props, ...routingProps}), createElement(Dep, {}, props.children))
  }
}
