import create, {initializeAndRegisterHooks} from 'context/create' 
import hooks, * as resolves from './hooks'

const initialState = {
  language: undefined,
  languages: [],
  translations: undefined
}

const id = 'Translations'

const context = create(id, initialState, {
  onInit: initializeAndRegisterHooks(id, hooks, resolves),
})

export {context as default}
