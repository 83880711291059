import {SvgKeepRatio} from 'utils/style'

export default function UsersIcon(props) {
  const fill= props.fill || '#ffffff'
  return (<SvgKeepRatio viewBox={{width: 37, height: 27}} {...props}>
     <g fill={fill}>
        <path d="M30.8369427,10.8749741 C28.333422,10.8749741 26.3057035,8.84725559 26.3057035,6.34373488 C26.3057035,3.84021416 28.333422,1.81249568 30.8369427,1.81249568 C33.3404634,1.81249568 35.3681819,3.84021416 35.3681819,6.34373488 C35.3681819,8.84725559 33.3404634,10.8749741 30.8369427,10.8749741 Z"></path>
        <path d="M5.46200319,10.8749741 C2.95848247,10.8749741 0.930763994,8.84725559 0.930763994,6.34373488 C0.930763994,3.84021416 2.95848247,1.81249568 5.46200319,1.81249568 C7.96552391,1.81249568 9.99324239,3.84021416 9.99324239,6.34373488 C9.99324239,8.84725559 7.96552391,10.8749741 5.46200319,10.8749741 Z"></path>
        <path d="M27.9143155,16.4767407 C28.6166244,17.5188925 29.024447,18.7706805 29.0131079,20.1187131 L29.0131079,22.2005153 C29.0131079,23.7014883 27.9143155,24.8424852 26.2943643,25.3749395 C23.9561863,25.8886988 21.2520487,26.1455784 18.1819515,26.1455784 C15.1118543,26.1455784 12.3785049,25.8886988 9.98190323,25.3749395 C8.3733465,24.8866 7.26315971,23.7014883 7.26315971,22.2005153 L7.26315971,20.1187131 C7.26315971,18.7706805 7.6709823,17.5188925 8.3733465,16.4767407 C9.93095997,14.1601225 12.9441898,13.3048732 15.6119901,14.1148212 C16.4162961,14.3583642 17.2602062,14.4943235 18.143831,14.4943235 C19.0274005,14.4943235 19.871366,14.3583642 20.6756167,14.1148212 C23.3434169,13.3048732 26.3567021,14.1657644 27.9143155,16.4767407 Z" ></path>
        <path d="M18.1494729,12.6874698 C14.6434045,12.6874698 11.8057381,9.84980333 11.8057381,6.34373488 C11.8057381,2.83766642 14.6434045,-1.77635684e-15 18.1494729,-1.77635684e-15 C21.6555414,-1.77635684e-15 24.4932078,2.83766642 24.4932078,6.34373488 C24.4932078,9.84980333 21.6555414,12.6874698 18.1494729,12.6874698 Z" ></path>
        <path d="M8.79245294,13.4974177 C8.03350356,14.0695198 6.7693407,15.0114 6.14168907,16.4747091 C5.51403744,17.9380182 5.46200319,19.8674344 5.46200319,20.5896564 C4.29344428,20.5896564 2.47809348,20.5896564 1.21179892,20.5896564 C0.413892898,20.5896564 -0.000688297936,20.0296771 -1.8562929e-13,19.3876979 C0.000863022943,18.6321526 0.0245161546,17.9348777 0.0245161546,17.4452709 C0.0245161546,14.8228273 2.15987369,12.6874698 4.78231731,12.6874698 L6.14168907,12.6874698 C7.12155848,12.6874698 8.03350356,12.9876533 8.79245294,13.4974177 Z"></path>
        <path d="M36.2712433,13.4974177 C35.5122939,14.0695198 34.2481311,15.0114 33.6204794,16.4747091 C32.9928278,17.9380182 32.9407935,19.8674344 32.9407935,20.5896564 C31.7722346,20.5896564 29.9568838,20.5896564 28.6905893,20.5896564 C27.8926833,20.5896564 27.4781021,20.0296771 27.4787904,19.3876979 C27.4796534,18.6321526 27.5033065,17.9348777 27.5033065,17.4452709 C27.5033065,14.8228273 29.638664,12.6874698 32.2611077,12.6874698 L33.6204794,12.6874698 C34.6003488,12.6874698 35.5122939,12.9876533 36.2712433,13.4974177 Z" transform="translate(31.875017, 16.638563) scale(-1, 1) translate(-31.875017, -16.638563) "></path>
     </g>
    </SvgKeepRatio>
  )
}