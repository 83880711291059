import { createLoadableList } from 'external/immutability-helper'
import create, {initializeAndRegisterHooks} from 'context/create' 
import hooks, * as resolves from './hooks'

const initialState = {
  refreshData: 0,
  refreshEvents: 0,
  events: [],
  eventsByDay: {},
  eventCategories: [],
  contractTerms: createLoadableList(),
  exportUsers: createLoadableList(),
  teacherList: createLoadableList(),
  studentList: createLoadableList(),
  groupList: createLoadableList(),
  studentLogs: createLoadableList(),
  studentLog: createLoadableList(),
  studentLogPageSize: 0,
  users: createLoadableList(),
  usersPageSize: 0,
  scheduledMessages: createLoadableList(),
  scheduledMessagesPageSize: 0,
  deletedMessages: createLoadableList(),
  deletedMessagesPageSize: 0,
  groups: createLoadableList(),
  groupsPageSize: 0,
  studentCategories: createLoadableList(),
  dashboardActivity: {},
  licenseCountForm: {
    licensesLoaded: false,
    licenseCount: {},
    courses: [],
  },
  editGroupForm: {
    open: false,
    group: {
      Group: "",
      Name: "",
      Course: "",
      Teachers: [],
      Students: [],
    },
    groupName: "",
    subject: "",
    course: "",
    activeTab: "group",
    teacherList: [],
    studentList: [],
    selectedTeachers: [],
    selectedStudents: [],
    checkedTeachers: {},
    checkedStudents: {},
    credits: {
      totalCredits: 0,
      usedCredits: 0,
    },
    isSaving: false,
  },
  addGroupForm: {
    open: false,
    subject: "",
    course: "",
    groupName: "",
    activeTab: "group",
    checkedStudents: {},
    loadingStudents: false,
    studentList: [],
    selectedStudents: [],
    checkedTeachers: {},
    loadingTeachers: false,
    teacherList: [],
    selectedTeachers: [],
    credits: {
      totalCredits: 0,
      usedCredits: 0,
    },
    isSaving: false,
  },
  editUserForm: {
    open: false,
    loading: false,
    user: {},
    userRole: 'student',
  },
  scheduleCommentForm: {
    open: false,
    date: new Date(),
    message: "",
    activeTab: "students",
    checkedStudents: {},
    loadingStudents: false,
    studentList: [],
    selectedStudents: [],
  },
  editScheduleCommentForm: {
    id: false,
    open: false,
    date: new Date(),
    message: "",
    activeTab: "students",
    checkedStudents: {},
    loadingStudents: false,
    studentList: [],
    selectedStudents: [],
  },
  addUserForm: {
    open: false,
    userRole: 'student',
  },
  sendUsersInfo: {
    open: false,
    activeTab: 'comment',
    content: '',
    sendPuk: false,
    language: 'en',
    passports: [],
    checkedStudents: {},
    loadingStudents: false,
    studentList: [],
    selectedStudents: [],
    checkedTeachers: {},
    loadingTeachers: false,
    teacherList: [],
    selectedTeachers: [],
    isSaving: false,
    successDialog: false,
    emailCount: 0,
    preview: '',
    loadingPreview: false,
  },
  downloadUsers: {
    users: [],
    isSearching: false,
    searchGroups: [],
    options: {
      roles: [{label: 'Students', value: 'students'}, {label: 'Teachers', value: 'teachers'}],
      groups: [],
    },
    filters: {
      roles: [],
      groups: [],
    }
  },
  importUsers: {
    open: false,
    openWarning: false,
    importedUsers: [],
    usedCounter: 0,
    loadingFile: false,
    fileError: false, // TODO: set error message
    searchUser: [],
    isSearching: false,
    successedImport: false,
  }
}

const id = 'Admin'

const context = create(id, initialState, {onInit: initializeAndRegisterHooks(id, hooks, resolves)})

export {context as default}
