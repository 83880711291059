import {Checkbox, styled, clsx} from 'external/material'

import EditableHtml from 'components/Common/EditableHtml'
import TeacherSelector from 'components/Common/TeacherSelector'

import useAdmin from 'hooks/admin'
import useTranslations from 'hooks/translations'

const StyledStep = styled('div')(styledStep)

export default function InstructionStep(props) {
    const {touchField, errors, className} = props
    const {update, sendUsersInfo} = useAdmin()
    const {content, sendPuk, teacherList, selectedTeachers, checkedTeachers, selectedStudents} = sendUsersInfo
    const {translate} = useTranslations()

    function handleEditableTextChange(event) {
        touchField('content')()
        update({sendUsersInfo: {content: {$set: event}}})
    }

    function handlePuk() {
        update({sendUsersInfo: {sendPuk: {$set: !sendPuk}}})
    }

    const handleTeachersChecked = (teacherId) => {
        update({sendUsersInfo: {checkedTeachers:{[teacherId]: {$apply: checked => !checked}}}})
    }

    const handleTeachersChange = (props) => {
        const {right: selectedTeachers} = props
        touchField('passports')()
        update({
            sendUsersInfo: {
                selectedTeachers: {$set: selectedTeachers},
                passports: {$set: [...selectedTeachers, ...selectedStudents]}
            }
        })
    }

    const teacherFilter = (teacher) => teacher.hasEmail

    return (
        <StyledStep className={clsx('step', 'container', className)}>
            <div className={clsx('step', 'content')}>
                <div className={clsx('editor', 'container')}>
                    <span className={clsx('label', 'message')}>{translate('send-users-info-tab-label-comment')}</span>
                    <label className={clsx('checkbox', 'send-puk')}>
                        <Checkbox checked={sendPuk} onChange={handlePuk} />
                        {translate('puk-label')}
                    </label>
                    <EditableHtml
                        html={content}
                        onChange={handleEditableTextChange}
                        config={{
                            buttons: "bold,underline,italic,|,ul,ol,|,undo,redo",
                            allowResizeX: false,
                            allowResizeY: false,
                        }}
                    />
                </div>
                <div className={clsx('teacher', 'container')}>
                    <span className={clsx('label', 'teachers')}>{translate('teachers')}</span>
                    <TeacherSelector className="teacher-selector" {...{teacherList, selectedTeachers, checkedTeachers, handleTeachersChange, handleTeachersChecked, teacherFilter}} />
                    {errors.passports && <label className="field-error">{translate(errors.passports)}</label>}
                </div>
            </div>
        </StyledStep>
    )
}

function styledStep() {
    return {
        '& .step.content': {
            display: 'flex',
            overflowY: 'auto',
            gap: 25
        },
        '& .container': {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            '&.teacher': {
                minWidth: 520,
                maxWidth: 660,
                '& .teacher-selector': {
                    flexGrow: 1,
                }
            },
            '& .html-editor-root': {
                flexGrow: 1,
                overflowY: 'hidden',
                "& .jodit-container": {
                    display: 'flex',
                    flexDirection: 'column',
                    "& .jodit-toolbar__box": {
                        flexShrink: 0
                    },
                    "& .jodit-workplace": {
                        flexGrow: 1,
                        "& .jodit-wysiwyg": {
                            padding: 15
                        }
                    },
                    "& .jodit-status-bar": {
                        flexShrink: 0
                    }
                }
            }
        }

    }
}