import {range, chunk, orderBy} from 'external/lodash'
import { useMemo, useRef } from 'external/react'
import { styled } from 'external/material'

import useDimension from 'hooks/dimensions'

import Student, {paddingWidth, itemHeight, itemWidth} from './Student'
import StudentsCount from './StudentsCount'

import useSession from 'hooks/session'
import useAdmin from 'hooks/admin'
import useSearchParam from 'hooks/search-param'
import { searchSubstring, isEmpty } from 'utils/text'

const StyledMonitoringStudents = styled('div')(monitoringStudentsStyles)


export default function Students () {

  const {groupTeachers} = useAdmin()
  const {teacherId, groupId} = useSession()
  const contentRef = useRef(null)
  const [pattern] = useSearchParam('pattern')
  const {width: contentWidth} = useDimension(contentRef)

  const students = useMemo(() => {
    if (!teacherId || !groupId) return []
    const {groups} = groupTeachers[teacherId] || {groups: {}}
    const students = groups[groupId]
      ? groups[groupId].students
      : []
    return orderBy(isEmpty(pattern) ? students : students.filter(s => searchSubstring(s.name, pattern)), 'name')
  }, [pattern, teacherId, groupId, groupTeachers])



  const studentsPerRow = useMemo(() =>
    contentWidth > 0
      ? Math.floor((contentWidth - paddingWidth) / itemWidth)
      : false
  , [contentWidth])

  const fillersNeeded = useMemo(() => {
    const needed = studentsPerRow > 0 && students.length > 0
      ? studentsPerRow - students.length % studentsPerRow
      : 0
    return needed == studentsPerRow ? 0 : needed
  }, [studentsPerRow, students])
  
  const rows = useMemo(() =>
    studentsPerRow > 0    
      ? chunk(students, studentsPerRow)
      : []
  , [studentsPerRow, students])

  return (
    <StyledMonitoringStudents ref={contentRef}>
      <StudentsCount students={students} />
      <div className="students">
          {rows.map((row, i, rows) => {
            const lastRow = i == (rows.length - 1)
            return (<div key={i} className='student-row'>
            {row.map((student, i) => <Student key={i} {...student} />)}
            {lastRow && fillersNeeded > 0 && range(fillersNeeded).map(key => <div key={key} className="student-filler" style={{flexShrink: 0, width: itemWidth, height: itemHeight}} />)}
          </div>)}
          )}
      </div>
    </StyledMonitoringStudents>)
}

function monitoringStudentsStyles() {
  return {
      display: 'flex',
      overflowY: 'hidden',
      flexDirection: 'column',
      marginTop: '15px',
      flexGrow: 1,
      backgroundColor: '#fff',
      border: 'solid 1px #d9d9d9',
      borderRadius: '4px',
      "& > .students-count": {
        borderBottom: 'solid 1px #e7edf0',
        padding: '10px 20px',
        display: 'flex',
        "& .icon": {
          color: '#78a7ff',
          fontSize: '15pt',
        },
        "& .count": {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textTransform: 'lowercase',
          padding: '0 20px 0 8px',
          borderRight: 'solid 1px #e7edf0'
        }
      },
      "& .students": {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflowY: 'auto',
        borderTop: 'solid 1px #d9d9d9',
        padding: '0 20px 20px 20px',
        "& .student-row": {
          display: 'flex',
          justifyContent: 'space-evenly',
          marginTop: 30
        }
      }
    }
}
