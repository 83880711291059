
import {styled, clsx} from 'external/material'

import useAdmin from 'hooks/admin'
import useTranslations from 'hooks/translations'

import TextInput from 'components/Common/Forms/TextInput'
import TeacherSelector from 'components/Common/TeacherSelector'

import SelectCourse from 'components/Common/Forms/SelectCourse'
import CourseCover from 'components/Common/CourseCover'

import EditGroupButtons from './EditGroupButtons'
import { useMemo } from 'react'

const StyledGroupStep = styled('div')(groupStepStyles)

export default function GroupStep(props) {

  const {handleClose, handleNext, errors, touchField} = props
  const {update, editGroupForm} = useAdmin()
  const {group, groupName, teacherList, selectedTeachers, checkedTeachers} = editGroupForm
  const {translate} = useTranslations()

  const handleGroupNameChange= (e) => {
    update({editGroupForm: {groupName: {$set: e.target.value}}})
  }

  const handleTeachersChecked = (teacherId) => {
    update({editGroupForm: {checkedTeachers:{[teacherId]: {$apply: checked => !checked}}}})
  }

  const handleTeachersChange = (props) => {
    const {right: selectedTeachers} = props
    touchField('selectedTeachers')()
    update({editGroupForm: {selectedTeachers: {$set: selectedTeachers}}})
  }

  const {subject, course} = useMemo(() => {
    const course = group.Course
    const subject = course && course.substring(0,2)
    return {subject, course}
  }, [group])

  
  return (
    <StyledGroupStep className="tab-content">
      <div className="step-content">
        <div className="group-step">
          <div className="select-container">
            <SelectCourse {...{subject, course, readonly: true, className: 'select-course'}}/>
            <div className="name-step">
              <label>{translate('group-name-label')}:</label>
              <TextInput
                id="edit-group-name"
                inputValue={groupName}
                inputOnChange={handleGroupNameChange}
                inputOnBlur={touchField('groupName')}
                validationResults={errors.groupName}
                controlClassName="group-input"
              />
            </div>
          </div>
          <div className={clsx("cover-container", {"no-cover": !course})}>
            {course && (<CourseCover course={course} courseName="" viewBox={{width: 201, height: 260}} />)}
            {!course && (<div className="dummy-cover" />)}
          </div>

        </div>
        <div className="teachers-selector-container">
          <TeacherSelector {...{teacherList, selectedTeachers, checkedTeachers, handleTeachersChange, handleTeachersChecked}} />
          {errors.selectedTeachers && <label className="field-error">{translate(errors.selectedTeachers)}</label>}
        </div>
      </div>
      <EditGroupButtons
        left={{label: 'cancel', onClick: handleClose, enabled: true}}
        right={{label: 'continue', onClick: handleNext, enabled: true}}
      />
    </StyledGroupStep>
  )
}

function groupStepStyles() {
  return {
    '& .group-step': {
      display: 'flex',
      flexShrink: 0,
      marginTop: 20,
      '& .select-container': {
        padding: '0 20px',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        gap: 10,
      },
      '& .cover-container': {
        backgroundColor: '#ccc',
        width: 203,
        height: 262,
        border: 'solid 1px black',
        display: 'flex',
        marginRight: 50
      },
      '& .select-course': {
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 10,
      },
      '& .name-step': {
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        gap: 1,
      },
    },
    '& .teachers-selector-container': {
      display: 'flex',
      marginTop: 15,
      padding: '0 20px',
      flexDirection: 'column',
      overflowY: 'hidden', 
      flexGrow: 1,
      '& .teacher-selector': {
        flexGrow: 1,
        overflowY: 'hidden', 
        '& .select-teachers': {
          "& .transfer-list .MuiList-root": {
          }
        }
      }
    },
    '& .field-error': {
      color: '#d32f2f',
      margin: '0 14px'
    },
  }
}