import {orderBy} from 'external/lodash'
import ClearableQueryFilter from '../ClearableQueryFilter'

import useSession from 'hooks/session'
import useTranslations from 'hooks/translations'


export default function LanguageFilter (props) {

  const {languageId, query} = useSession()
  const translationsContext = useTranslations()
  const {translate} = translationsContext
  const {availableLanguages} = props

  const selectedLanguage = query.languageId || languageId
  const filteredLanguages = availableLanguages
    ? translationsContext.languages.filter(a => availableLanguages.indexOf(a.id) >= 0)
    : translationsContext.languages

  const languages = orderBy(filteredLanguages, ['id'], ['desc'])
        .map(l => ({...l, selected: l.id == selectedLanguage}))
        
  return (<ClearableQueryFilter {...{...props, caption: translate('language-label'), items: languages, name: 'languageId'}} />)

}