import Icon from 'external/font-awesome'
import {useState, useEffect, forwardRef} from 'external/react'

function CalendarAltInput({onClick}, ref) {
  const [showing, setShowing] = useState(false)

  function show() { setShowing(true); onClick && onClick() }
  function hide() { setShowing(false)}

  useEffect(() => {
      if (showing) {
        document.addEventListener("click", hide)
      } else {
          document.removeEventListener('click', hide)
      }
  }, [showing])

  return (<div onClick={(e) => {
    showing ? hide() : show()
    e.stopPropagation()
  }}><Icon name='faCalendarAlt' /></div>)

}

export default forwardRef(CalendarAltInput)