import {useEffect, useNavigate} from 'external/react'
import {
   styled
  ,FormControl 
  ,NativeSelect 
  ,InputBase 
  ,ArrowDropDownIcon
} from 'external/material'

import useProfile from 'hooks/profile'
import useSession from 'hooks/session'
import useTranslations from 'hooks/translations'

const StyledLanguageFormControl = styled(FormControl)(languageFormControlStyles)
const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)(() => ({fontSize: 'inherit'})) 

export default function PageLanguage (props) {

  const {languageId, pageName, navigation} = useSession()
  const {language, languages, setLanguage} = useTranslations()
  const navigate = useNavigate()
  const {passport} = useProfile()

  const handleChange = (event) => {
    const to =`${location.pathname.replace(/\/(es|en)\//, `/${event.target.value}/`)}${location.search}`
    navigate(to)
  }

  const updateTranslationsLanguage = () => {
    if (languages.length > 0 && languageId != language)
      setLanguage(languageId)
  }

  useEffect(() => {
    if (navigation && passport && !languageId) {
      navigation.page.go({
        languageId: passport.last_language
          ? passport.last_language.id
          :'es',
        pageName: pageName || 'dashboard'
      })
    }
  }, [navigation, passport])

  useEffect(updateTranslationsLanguage, [languageId, pageName, languages])

  return (
    <StyledLanguageFormControl {...props}>
      <NativeSelect
        value={language}
        onChange={handleChange}
        IconComponent={StyledArrowDropDownIcon}
        input={<InputBase />}
      >
        {languages.map(language => (<option key={language.id} value={language.id}>{language.name}</option>))}
      </NativeSelect>
    </StyledLanguageFormControl>
  )
}

function languageFormControlStyles({theme}) {
  return {
    display: 'flex',
    "& .MuiInputBase-root": {
      borderRadius: 50,
      backgroundColor: theme.palette.background.paper,
      border: theme.status.defaultBorder,
      ...theme.typography.caption,
      "& > select.MuiNativeSelect-select": {
        padding: '0 25px 2px 12px',
        [theme.breakpoints.up('desktop')]: {
          padding: '0 28px 3px 14px',
        },
      }
    }
  }
}