
import {styled, clsx} from 'external/material'

import useSearchParam from 'hooks/search-param'
import useTranslations from 'hooks/translations'

const StyledFilterButton = styled('div')(buttonStyles)

export default function LanguageFilterButton ({id, selected}) {

    const [_, setLanguageId] = useSearchParam('languageId')
    const {translate} = useTranslations()
    return (
      <StyledFilterButton className={clsx({selected})} onClick={(e) => !selected && setLanguageId(id)}>
        {translate(`${id}_label`)}
      </StyledFilterButton>
    )
}

function buttonStyles() {
    return {
        cursor: 'pointer',
        textDecoration: 'none',
        color: '#565f77',
        display: 'inline-block',
        width: '100px',
        textAlign: 'center',
        padding: '8px 0',
        borderRadius: '5px',
        backgroundColor: 'white',
        "&:hover": {
            transform: 'scale(1.03)',
            fontWeight: 'bold'
        },
        "&.selected": {
            fontWeight: 'bold',
            color: 'white',
            backgroundColor: '#2ca5ec',
            "&:hover": {
                transform: 'none'
            }
        },
        "&:last-child": {
            marginLeft: '10px'
        }
    }
}
