import { createPost } from 'utils/command'
import {styled, Dialog, DialogContent, DialogTitle, IconButton} from 'external/material'
import Icon from 'components/Common/Icon'

import { isEmpty, sqlDate } from 'utils/text'

import TabBar from './ScheduleCommentTabBar'
import ScheduleCommentButtons from './ScheduleCommentButtons'
import ScheduleCommentDateStep from './ScheduleCommentDateStep'

import useTranslations from 'hooks/translations'
import useAdmin from "hooks/admin"
import ScheduleCommentStudentsStep from './ScheduleCommentStudentsStep'

const StyledScheduleCommentForm = styled(Dialog)(scheduleCommentFormStyles)

export default function ScheduleCommentForm () {

  const {translate, language} = useTranslations()
  const {update, scheduleCommentForm: {open, activeTab, date, selectedStudents, message}} = useAdmin()

  function closeForm(refresh) {
      if (refresh) update({refreshEvents: {$apply: i => i + 1}, scheduleCommentForm: {open: {$set: false}}})
      else update({scheduleCommentForm: {open: {$set: false}}})
  }

  function handleClose() {
      closeForm(false)
  } 

  function handleNext() {
      update({scheduleCommentForm: {activeTab: {$set: 'date'}}})
  }

  function handlePrevious() {
      update({scheduleCommentForm: {activeTab: {$set: 'students'}}})
  }

  async function handleSave() {
    const commentPayload = {
      Start: sqlDate(date),
      Finish: sqlDate(date),
      Type: 'comment',
      Passports: selectedStudents.map(s => s.id),
      Content: {linked: false, source: 'admin', message, language}
    } 
    const addCommentResult = await createPost('admin', 'add_comment')(commentPayload)
    closeForm(true)
  }

  const canSave = selectedStudents.length > 0 && !isEmpty(message)

  return (
  <StyledScheduleCommentForm
    open={open}
    onClose={handleClose}
  >
    <DialogTitle className="title">
        <label>{translate('schedule-comment-form-title')}</label>
        <IconButton className="close-button" onClick={handleClose}>
            <Icon name="faCircleXmark" />
        </IconButton>
    </DialogTitle>
    <DialogContent>
      <TabBar className="tab-heading" />
      <div className="tab-content">
        {activeTab == 'students' && (
          <>
            <ScheduleCommentStudentsStep className="step-content" />
            <ScheduleCommentButtons
              left={{label: 'cancel', onClick: handleClose, enabled: true}}
              right={{label: 'continue', onClick: handleNext, enabled: true}}
            />
          </>
        )}
        {activeTab == 'date' && (
            <>
              <ScheduleCommentDateStep className="step-content" />
              <ScheduleCommentButtons
                left={{label: 'back', onClick: handlePrevious, enabled: true}}
                right={{label: 'save', onClick: handleSave, enabled: canSave}}
              />
            </>
          )}

      </div>
    </DialogContent>
  </StyledScheduleCommentForm>)
}

function scheduleCommentFormStyles(){
  return {
    '& .MuiDialog-paper':{
      height: 'calc(100% - 120px)',
      minWidth: 1024,
      maxWidth: 1200,
      '& .title': {
          textAlign: 'center',
          backgroundColor: '#2c7cbd',
          color: '#fff',
          '& .close-button': {
              position: 'absolute',
              right: 15,
              color: '#fff'
          }
      }, 
      '& .MuiDialogContent-root':{
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        "& .tab-heading": {
          flexShrink: 0
        },
        "& .tab-content": {
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          overflow: 'hidden',
          "& .step-content": {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflowY: 'hidden',
            padding: 15
          }
        }
      }
    }
  }
}