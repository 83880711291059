import {useSearchParams, stringifyQueryString, useMemo} from 'external/react'
import moment from 'external/moment'
import {partial} from 'external/lodash'

export default function useQueryDate () {

  const [params, setSearchParams] = useSearchParams()
  const start = useMemo(()=> params.get('start') || moment().format('yyyy-MM-DD'), [params])
  const end = useMemo(()=> params.get('end') || moment().format('yyyy-MM-DD'), [params])
  const setDate = (props) => {
    const {preserveQuery = true} = props
    const currentQuery = {}
    if (preserveQuery) {
      for (const [key, value] of params.entries()) {
        currentQuery[key] = value
      }
    }
    setSearchParams(stringifyQueryString({
        ...currentQuery,
        start: props.start ? props.start : start,
        end: props.end ? props.end : end
    }))
  }
  const date = useMemo(() => getDate({start, end}), [start, end])
  return [date, setDate]
}

function getTime(date) {
  return moment(date).toDate().getTime()
}

function getDate ({start, end}) {
  const time = (getTime(start) + getTime(end)) / 2
  return partial(moment, time)
}