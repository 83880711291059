import styled from '@emotion/styled'
import {clsx, TableCell, TableHead, TableRow, AddIcon} from 'external/material'
import useRollbook from 'hooks/rollbook'
import useTranslations from 'hooks/translations'

const headCells = [
  { id: 'type', align: 'left', disablePadding: false},
  { id: 'start', align: 'left', disablePadding: false},
  { id: 'end', align: 'left', disablePadding: false},
]

const StyledPeriodsHead = styled(TableHead)(periodsHeadStyles)

export default function PeriodsHead(props) {
  const {periods, addPeriod: onAddPeriod} = useRollbook()
  const {translate} = useTranslations()

  return (
    <StyledPeriodsHead>
      <TableRow className={'headersRow'}>
        {headCells.map(({id, align, disablePadding}) => (
          <TableCell
            key={id}
            // className={clsx('header', {[id]: true})}
            align={align}
            padding={disablePadding ? 'none' : 'normal'}
            style={{top:92}}
          >
            {translate(`${id}-new-period-header`)}
          </TableCell>
        ))}
        <TableCell className={'actions'} style={{top:92}} align="center">
          <div className={clsx('addPeriodWrapper', {hidden: periods.items.length >= 12})}>
            <div className={'addPeriod'} onClick={onAddPeriod}>
              <AddIcon />
            </div>
          </div>
        </TableCell>
      </TableRow>
    </StyledPeriodsHead>
  )
}

function periodsHeadStyles(theme) {
  return {
    '& .headersRow': {
      "& > th": {
        padding: '12px 15px',
        color: '#4f576c',
        fontWeight: 'bold',
        border: 'solid 1px #d2d7db',
        borderTop: 0,
        borderBottom: 0,
        backgroundColor: '#f1faff',
        "&:first-of-type": {
          borderLeft: 0
        },
        "&:last-child": {
          borderRight: 0
        }
      }
    },
    '& .header': {
      "&.type": {
        width: '25%'
      },
      "&.start": {
        width: '35%'
      },
      "&.end": {
        width: '35%'
      },
    },
    '& .actions': {
      justifyContent: 'center',
    },
    '& .addPeriodWrapper': {
      display: 'flex',
      justifyContent: 'center',
      "&.hidden": {
        visibility: 'hidden'
      }
    },
    '& .addPeriod': {
      display: 'flex',
      justifyContent: 'center',
      flexGrow: 1,
      backgroundColor: '#2c74bc',
      borderRadius: 5,
      color: '#fff',
      padding: '10px 30px',
      cursor: 'pointer',
      '&:hover': {
        transform: 'scale(1.02)',
      },
      maxWidth: 65,
    }
  }
}

