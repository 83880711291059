
import {omit} from 'external/lodash'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

import {
  faUsers,
  faCalendarDays,
  faDatabase,
  faQuestionCircle,
  faMessage,
  faGear,
  faBook,
  faEye,
  faHouse,
  faSquareArrowUpRight,
  faPenToSquare,
  faChevronRight,
  faChevronLeft,
  faCalendarAlt,
  faComment,
  faPlusCircle,
  faSearch,
  faUserLarge,
  faUserGraduate,
  faUserGroup,
  faCircleXmark,
  faFileExcel,
  faFileCsv,
  faFileInvoice,
  faFileArrowUp,
  faFileArrowDown,
  faFileCircleCheck,
  faCircleInfo,
  faTrashCan,
  faCheck,
  faCloudUpload,
  faTimes,
  faTriangleExclamation
} from "@fortawesome/free-solid-svg-icons"

import {
  faSquarePlus as frSquarePlus,
  faTrashCan as frTrashCan
} from "@fortawesome/free-regular-svg-icons"

const icons = {faFileArrowDown, faFileExcel, faFileCsv, faCircleInfo, faHouse, faCalendarDays, faTrashCan, faFileInvoice, faEye, faDatabase, faGear, faMessage, faUsers, faBook, faQuestionCircle, faFileCircleCheck, faFileArrowUp, faSquareArrowUpRight, faChevronRight, faChevronLeft, faCalendarAlt, faComment, faPlusCircle, faSearch, faUserLarge, faUserGraduate, faUserGroup, frSquarePlus, frTrashCan, faPenToSquare, faCircleXmark, faCheck, faCloudUpload, faTimes, faTriangleExclamation}

export default function Icon(props) {
  return <FontAwesomeIcon icon={icons[props.name]} {...omit(props, 'name')} />
}