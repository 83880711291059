import {styled} from 'external/material'
import TransferList from 'components/Common/TransferList'

import useTranslations from 'hooks/translations'

const StyledSelectTeachers = styled('div')(selectTeachersStyles)

export default function SelectTeachers (props) {

    const {translate} = useTranslations()

  return (
    <StyledSelectTeachers className="select-teachers">
        <TransferList
          label={({name}) => name}
          left={props.teachers} 
          LeftHeader={() => (
            <div className="teachers-header">
              {translate('teachers')}:
            </div>
          )}
          RightHeader={() => (
            <div className="selected-header">
              {translate('selected-teachers')}:
            </div>
          )}
          right={props.selectedTeachers} 
          checklist={props.checkedTeachers}
          onChecked={props.handleTeachersChecked}
          onTransfer={props.handleTeachersChange}
        />
    </StyledSelectTeachers>
  )
}

function selectTeachersStyles ({theme}) {
    return {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        "&.select-teachers": {
          display: 'flex',
          overflowY: 'hidden',
          "& .transfer-list-right-paper": {
          },
          "& .transfer-list-left-paper": {
          }
        }
    }
}

