import {useSearchParams, stringifyQueryString} from 'external/react'
import ClearableSelect from './ClearableSelect'

export default function ClearableQueryFilter (props) {

  const {
    name,
    caption,
    className,
    preserveQuery = false,
    interceptQuery = false,
    items = [],
    getOptionValue = item => item.id,
    getOptionText = item => item.name
  } = props

  const [params, setSearchParams] = useSearchParams()

  const value = items.length > 0 ? params.get(name) || '' : ''

  const handleChange = (event) => {
    let currentQuery = {}
    if (preserveQuery) {
      for (const [key, value] of params.entries()) {
        currentQuery[key] = value
      }
    }
    if (interceptQuery) currentQuery = interceptQuery(currentQuery)
    setSearchParams(stringifyQueryString({...currentQuery, [name]: event.target.value}))
  }

  return (
      <ClearableSelect
          caption={caption || ''}
          value={value}
          onChange={handleChange}
          className={className}
          items={items.map(item => ({text: getOptionText(item), value: getOptionValue(item)}))}
      />
  )
}
