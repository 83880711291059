import create from 'utils/command'

const hooks =  [
    {paths: ['refreshSession/'], resolves:[refreshSession]}
]

export {hooks as default}

export async function refreshSession({update}) {
  try {
    const [value, id] = await create('security', 'session')()
    update({value: {$set: value}, id: {$set: id}})
  } catch (ex) {
  }
}
