import {
   styleAndCapture
  ,InputBase
  ,InputAddornment
  ,Select
  ,MenuItem
  ,ClearIcon
} from 'external/material'

const StyledClearableSelect = styleAndCapture(Select, 'items')(clearableSelectStyles)
const StyledClearableInput = styleAndCapture(InputBase, 'items', 'onClear')(clearableInputStyles)

export default function ClearableSelect(props) {
  const {items = []} = props
  const options = items.map(({value, text}, i) => {
    return (<MenuItem key={i} value={value}>{text}</MenuItem>)
  })

  return (
    <StyledClearableSelect
      displayEmpty
      MenuProps={{classes: {}}}
      input={props.notClearable ? undefined : <ClearableInput {...props} />}
      disabled = {items.length == 0}
    >
      <MenuItem value={""} className="item-caption" sx={{display: 'none'}}>{props.caption}</MenuItem>
      {options}
    </StyledClearableSelect>
  )
}

function ClearableInput (props) {
  return (<StyledClearableInput
    {...props}
    endAdornment={
      props.value == ''
        ? undefined 
        : <Clear onClear={props.onClear || props.onChange} />
      }
    />)
}

function clearableInputStyles() {
  return {
      paddingLeft: 20,
      backgroundColor: '#fff',
      borderRadius: 5,
      border: 'solid 1px #bdbdbd',
      color: '#4c5263',
      fontWeight: 500,
     "& .clear": {
      cursor: 'pointer',
      display: 'flex',
      width: 16,
      height: 16,
      position: 'relative',
      top:0,
      right: 35,
      "& svg": {
        fontSize: '18px',
        color: '#4c5263'
      }, 
    }
  }
}

function Clear (props) {
   return (
     <InputAddornment onClick={props.onClear} className="clear" position="end" >
       <ClearIcon />
     </InputAddornment>
   )

}

function clearableSelectStyles(){
  return {
    "& .MuiSvgIcon-root": {
      fontSize: '30px',
      color: '#4c5263'
    }
  }
}
