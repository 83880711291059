import {SvgKeepRatio} from 'utils/style'

export default function HomeworkIcon(props) {
  const fill = props.fill || '#92aabb'
  return  <SvgKeepRatio viewBox={{width: 18, height: 21}} {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <polygon  fill={fill} fillRule="nonzero" transform="translate(7.558203, 9.335122) rotate(-180.000000) translate(-7.558203, -9.335122) " points="15.1164056 1.8189894e-12 15.1164056 18.6702439 4.45885308 18.6702439 0 14.2249477 0.0181608448 12.4468292 0.891770616 12.4468292 0.891770616 13.8376721 4.52607367 17.7683141 14.2932642 17.7683141 14.2932642 0.889059232 11.6064551 0.870553215 11.6064551 1.8189894e-12"></polygon>
          <polygon  fill={fill} fillRule="nonzero" transform="translate(13.076311, 16.676107) rotate(-180.000000) translate(-13.076311, -16.676107) " points="15.1164056 14.6819699 15.1164056 15.5710291 11.927432 15.5709834 11.9279879 18.6702439 11.0362173 18.6702439 11.0362173 14.6819699"></polygon>
          <path d="M14.6162244,0.5 L14.6146538,4.83435539 L10.2693363,0.71727787 L14.6162244,0.5 Z"  stroke={fill} transform="translate(12.441094, 2.667178) rotate(-180.000000) translate(-12.441094, -2.667178) "></path>
        </g>
                <rect  fill={fill} x="4.41515823" y="6.22341462" width="6.24239431" height="1"></rect>
                <rect  fill={fill} x="4.41515823" y="7.11247386" width="1" height="1"></rect>
                <rect  fill={fill} x="9.76578193" y="7.11247386" width="1" height="1"></rect>
                <rect  fill={fill} x="7.09047008" y="6.22341462" width="1" height="6.22341462"></rect>
                <g  transform="translate(10.590332, 13.713402) rotate(-315.000000) translate(-10.590332, -13.713402) translate(9.252676, 6.156398)" stroke={fill}>
                    <g  transform="translate(0.000000, 0.000000)">
                        <rect strokeLinejoin="round" x="-9.09494702e-13" y="2.6671777" width="2.67531185" height="8.89059232"></rect>
                        <path d="M1.33765592,0 C2.07642289,-1.3570929e-16 2.67531185,0.598888956 2.67531185,1.33765592 L2.67531185,2.6671777 L2.67531185,2.6671777 L0,2.6671777 L0,1.33765592 C1.31571745e-16,0.598888956 0.598888956,1.3570929e-16 1.33765592,0 Z" ></path>
                        <polygon strokeLinejoin="round" points="0 11.55777 2.67531185 11.55777 1.33765592 15.1140069"></polygon>
                    </g>
                </g>
            </g>
    </SvgKeepRatio>
}