import {chunk} from 'external/lodash'
import {styled} from 'external/material'
import DashboardButton from './DashboardButton'

const StyledDashboardButtons = styled('div')(dashboardButtonsStyles)

const buttonRows = chunk([
    {pageName: 'users', iconName:'faUserLarge'},
    {pageName: 'groups', iconName:'faUsers'},
    {pageName: 'catalogue', iconName: 'faDatabase'},
    {pageName: 'monitoring', iconName: 'faEye'},
    {pageName: 'messages', iconName: 'faMessage'},
    {pageName: 'calendar', iconName: 'faCalendarAlt'},
    {pageName: 'guides', iconName: 'faBook', target:'_blank', href: 'https://edusystem.com/cursos/'},
    {pageName: 'settings', iconName: 'faGear'}
], 4)

const createButton = (props, i) => (<DashboardButton key={i} {...props} />)
const createRow = (row, i) => (<div key={i} className='row'>{row.map(createButton)}</div>)

export default function DashboardButtons(props) {
    return <StyledDashboardButtons {...props}>
        <div className='buttons'>
            {buttonRows.map(createRow)}
        </div>
    </StyledDashboardButtons>
}

function dashboardButtonsStyles () {
    return {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        '& .buttons': {
            display: 'flex',
            flexDirection: 'column',

        },
        '& .row': {
            display: 'flex',
            marginTop: 30,
            '&:first-of-type': {
                marginTop: 0
            },
            '& .dashboard-button': {
                marginLeft: 30,
                '&:first-of-type': {
                    marginLeft: 0
                }
            }
        }
    }
}

