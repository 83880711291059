
import {Routes, Route} from 'external/react' 

import CatalogueSubjects from './CatalogueSubjects'
import CatalogueCourses from './CatalogueCourses'
import CatalogueUnits from './CatalogueUnits'
import CatalogueSearch from './CatalogueSearch'

export default function Catalogue() {
  return (
    <Routes>
      <Route index element={<CatalogueSubjects/>} />
      <Route exact path="search" element={<CatalogueSearch />} />
      <Route exact path=":section" element={<CatalogueCourses />} />
      <Route exact path=":subject/:course" element={<CatalogueUnits/>} />
      <Route exact path=":subject/:course/:unit" element={<CatalogueUnits/>} />
    </Routes>
  )
}
