import {styled, clsx} from 'external/material'
import ResourceRow from './ResourceRow'

import useTranslations from 'hooks/translations'

const StyledResourceTable = styled('div')(resourceTableStyles)

export default function ResourceTable ({kind, resources}) {

  const {translate} = useTranslations()
  return (
    <StyledResourceTable className={clsx(kind, 'resource-table')}>
      <p><strong>{translate(kind)}</strong></p>
      <table>
        <thead>
          <tr>
            <th className="name">{translate(`${kind}_product`)}</th>
            <th className="description">{translate(`${kind}_description`)}</th>
            <th className="download">{translate('link')}</th>
          </tr>
        </thead>
        <tbody>{resources.map((id, key) => (<ResourceRow {...{key, id, kind}} />))}</tbody>
      </table>
    </StyledResourceTable>
  )
}

function resourceTableStyles(){
  return {
  '& a': {
    color: '#3366cc',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 'bold'
    }
  },
  '& p': {
    '& strong': {
      color: '#1c4b90'
    }
  },
  '& table': {
    width: '100%',
    borderCollapse: 'collapse',
    border: 'solid 1px #cfd6df'
  },
  '& th.name, & td.name': {
    width: 280
  },

  '& th.download, & td.download': {
    width: 80,
    textAlign: 'center'
  },
  '& th, & td': {
    padding: 10
  },
  '& thead': {
    backgroundColor: '#2c75bd',
    '& th': {
      textAlign: 'left',
      color: '#fff',
      fontWeight: 'bold'
    }
  },
  '& tbody': {
    '& td': {
      color: '#000'
    },
    '& tr:nth-of-type(even)': {
      background: '#f0f5fa'
    },
    '& tr:nth-of-type(odd)': {
      background: '#fff'
    }
    }
  }
}