import {useState, Link} from 'external/react'
import AddButton from 'components/Common/AddButton'
import Icon from 'components/Common/Icon'
import {styled, Menu, MenuItem, ListItemIcon, ListItemText} from 'external/material'

import useTranslations from 'hooks/translations'
import useAdmin from 'hooks/admin'
import useSession from 'hooks/session'

const StyledAddUser = styled('div')(addUserStyles)
const StyledMenu = styled(Menu)(addUserMenuStyles)

export default function AddUser(props) {

    const {translate} = useTranslations()
    const {update} = useAdmin()
    const {navigation} = useSession()
    const [anchorEl, setAnchorEl] = useState(null)

    const contentText = translate('add-user') || ''

    const handleClick = (event) => { setAnchorEl(event.currentTarget) }
    const handleClose = () => { setAnchorEl(null) }
    const handleOpenDialog = (role) => e => {
      handleClose()
      update({addUserForm: {open: {$set: true}, role: {$set: role}}})
    }
    
    return (<StyledAddUser {...props}>
        <AddButton onClick={handleClick} title={contentText} label={contentText} className="add-user-button" />
        <StyledMenu
          id="add-edusystem-user"
          className="menu"
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
            <MenuItem onClick={() => {
              navigation.usersAction.go({action: 'create', userId: 'student'})
            }
            }>
                <ListItemIcon><Icon name="faUserLarge" /></ListItemIcon>
                <ListItemText>{translate('student')}</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => {
              navigation.usersAction.go({action: 'create', userId: 'teacher'})
            }
            }>
                <ListItemIcon><Icon name="faUserGraduate" /></ListItemIcon>
                <ListItemText>{translate('teacher')}</ListItemText>
            </MenuItem>
       </StyledMenu>
    </StyledAddUser>)
}

function addUserStyles() {
    return {
        color: '#2c75bd',
        cursor: 'pointer',
        fontSize: 18,
        '& .add-user-button': {
          padding: '11px 14px',
        },
    }
}
function addUserMenuStyles({theme}) {
    return {
       "& .MuiPaper-root": {
        border: '1px solid #d3d4d5',
      },
      "& .MuiMenuItem-root": {
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.primary.main,
            },
        }
    }
}