import {styled, Box} from 'external/material'
import Event from './Event'
import useQueryDate from 'hooks/query-date'
import useAdmin from 'hooks/admin'

import EditScheduleCommentForm from '../EditScheduleComment/EditScheduleCommentForm'

const StyledDay = styled(Box)(dayStyles)

export default function DayView () {

  const {eventsByDay} = useAdmin()
  const [date] = useQueryDate()
  const dayId = date().format('YYYY-MM-DD')

  const events = (eventsByDay[dayId] || [])

  return (
    <StyledDay>
      <EditScheduleCommentForm />
      <div className='schedule-events'>
        {events.map((props, i) => (<Event key={i} {...props} />))}
      </div>
    </StyledDay>
  )

}

function dayStyles() {
  return {
    overflow: 'hidden',
    padding: '0 2px 0 0',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    color: '#69738e',
    backgroundColor: '#fff',
    border: 'solid 1px #e1eaee',
    "& .schedule-events": {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto'
    }
  } 
}
