import {styled, Box} from 'external/material'
import moment from 'external/moment'
import {range} from 'external/lodash'

import Day from './Day'

const StyledWeek = styled(Box)(weekStyles)

export default function Week(props) {
  return (
    <StyledWeek className="week">
      {range(7).map(day => (<Day key={day} {...dayProps(day, props)} />))}
    </StyledWeek>
  )
}

function dayProps(day, props) {
  return {
    ...props, date: moment(props.start).add(day, 'days')
  }
}

function weekStyles() {
  return {
    "&.week": {
      display: 'flex',
      borderBottom: 'solid 1px #e1eaee',
      "&:last-of-type": {
        borderBottom: 'none'
      }
    }  
  }
}