import {Link} from 'external/react'
import {styled, Box, Avatar} from 'external/material'
import Icon from 'components/Common/Icon'

const StyledActivityItem = styled(Box)(activityItemStyles)

export default function DashboardActivity(props) {
    return <StyledActivityItem className="activity-item" component={Link} to={props.to}>
        <div className='content'>
            <div className='count'>{props.count}</div>
            <div className='label'>{props.label}</div>
        </div>
        <Avatar sx={{bgcolor: props.circleColor}}><Icon name={props.icon} /></Avatar>
    </StyledActivityItem>
}

function activityItemStyles () {
    return {
        width: 220,
        display: 'flex',
        textDecoration: 'none',
        backgroundColor: '#fff',
        border: 'solid 1px #dbdbdb',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 8 ,
        '& .content': {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            '& .count': {
                color: '#4e576e',
                fontSize: '16pt',
                fontWeight: 'bold'
            },
            '& .label': {
                color: '#7b86a1',
                fontSize: '9.5pt'
            }
        },
        '& .MuiAvatar-root': {
            width: '50px',
            height: '50px',
            flexShrink: 0,
            fontSize: '14.5pt',
            color: '#4e576e'
        }
    }
}


