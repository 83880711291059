import {createContext, useEffect} from 'external/react'
import useImmutableState from 'hooks/immutable-state'
import {register, registerContext, contexts} from 'utils/hook'

export default function create(id, initialState, { onInit, onUpdate} = {}) {
  const Context = createContext()
  Context.displayName = id
  const {Provider, Consumer} = Context
  const result = {
    Context,
    Consumer,
    Provider: props => {
      const context = useImmutableState(id, {...initialState, props: props.props})
      registerContext(id, context)
      useEffect(() => { onInit && onInit(context) }, [])
      useEffect(() => { onUpdate && onUpdate(context) }, [context])
      useEffect(() => {
        context.update({props: {$set: props.props}})
      }, [props.props])
      return (<Provider value={context} children={props.children} />)
    }
  }
  result.Provider.displayName = `${id}.State`
  return result
}

export function initializeAndRegisterHooks (id, hooks, resolves) {
  return context => {
    Object.values(resolves).forEach(async resolve => typeof(resolve)== 'function' && await resolve(context, contexts))
    hooks.forEach(({paths, resolves}) => register(id, paths, resolves))
  }
}
