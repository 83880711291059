import {Routes, Route} from 'external/react' 
import {getLanguageRoute} from 'utils/routing'

import withFullContext from 'context'
import Container from 'components/Container'

import Help from 'components/Pages/Help'
import Dashboard from './Pages/Dashboard'
import Users from 'components/Pages/Users'
import Groups from 'components/Pages/Groups'
import Catalogue from 'components/Pages/Catalogue'
import Monitoring from 'components/Pages/Monitoring'
import Messages from 'components/Pages/Messages'
import Calendar from 'components/Pages/Calendar'
import Settings from 'components/Pages/Settings'


import {ThemeProvider} from 'external/material'
import EduSystemTheme from 'themes/edusystem'

const pages = [
  {names: ['help', 'ayuda'], element: (<Help />)},
  {names: ['dashboard', 'tablero',''], element: (<Dashboard />)},
  {names: ['users', 'usuarios'], element: (<Users />)},
  {names: ['groups', 'grupos'], element: (<Groups />)},
  {names: ['monitoring', 'monitoreo'], element: (<Monitoring />)},
  {names: ['catalogue', 'catalogo'], element: (<Catalogue />)},
  {names: ['messages', 'mensajes'], element: (<Messages />)},
  {names: ['calendar', 'calendario'], element: (<Calendar />)},
  {names: ['settings', 'configuracion'], element: (<Settings/>)},
 ]

const routes = pages.reduce((routes, {names, element}) => routes.concat(names.map(name => ({path: `${getLanguageRoute(name)}/*`, element}))), []) 

function App() {
  return (
    <Routes>
      <Route path="/" element={<ThemeProvider theme={EduSystemTheme}><Container /></ThemeProvider>}>
        {routes.map(({path, element}, key) => (<Route path={path} element={element} key={key} />))}
      </Route>
    </Routes>
  )
}

const AppWithContext = withFullContext(App)
AppWithContext.displayName = 'AppWithContext'

//const AppWithStoreProvider = withStoreProvider(AppWithContext)
//AppWithStoreProvider.displayName = 'AppWithStoreProvider'

export {AppWithContext as default}
