import {styled} from 'external/material'
import TransferList from 'components/Common/TransferList'

import useTranslations from 'hooks/translations'

const StyledSelectStudents = styled('div')(selectStudentsStyles)

export default function SelectStudents (props) {

  const {translate} = useTranslations()
  const {checkedStudents, selectedStudents, handleStudentsChecked, handleStudentsChange, students} = props

  return (
    <StyledSelectStudents className="select-students">
        <TransferList
          label={({name}) => name}
          left={students} 
          LeftHeader={() => (
            <div className="students-header">
              {translate('students')}:
            </div>
          )}
          RightHeader={() => (
            <div className="selected-header">
              {translate('selected-students')}:
            </div>
          )}
          right={selectedStudents} 
          checklist={checkedStudents}
          onChecked={handleStudentsChecked}
          onTransfer={handleStudentsChange}
        />
    </StyledSelectStudents>
  )
}

function selectStudentsStyles ({theme}) {
    return {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 20px',
        overflow: 'hidden',
        "&.select-students": {
          display: 'flex',
          overflowY: 'hidden',
          "& .transfer-list-right-paper": {
          },
          "& .transfer-list-left-paper": {
          }
        }
    }
}

