import {omit} from 'external/lodash'
import {CardMediaWithRatio} from 'utils/style'
import {staticAssetLink} from 'utils/url'
import defaultCover from 'resources/default.jpg'

const coverImage = course => staticAssetLink('covers', `${course}.jpg`)

export default function CourseCover(props) {
  const {course, courseName} = props
  return (
   <CardMediaWithRatio
      image={coverImage(course)}
      {...omit(props, 'course', 'courseName')}
      onError={e => e.target.src = defaultCover}
      alt={courseName}
    />
  )
}

