import {mapValues, groupBy, omit} from 'external/lodash'
import update from 'external/immutability-helper'
import { capitalizeFirstLetter } from './text'

export const pairSum = (a = 0, b = 0) => a + b

export function associate(head, ...keys) {
  return (data) => keys.length > 0
    ? mapValues(groupBy(data, (item) => item[head]), associate(...keys))
    : data[0][head]
}

export function reduceByKey(key, omitKey) {
  return data => data.reduce((grouped, item) => ({...grouped, [item[key]]: omitKey ? omit(item, [key]) : item}), {})
}

export function accumulateByKey(key, omitKey) {
  return data => data.reduce((grouped, item) => update(grouped, {[item[key]]: {$autoArray: {$push: [(omitKey ? omit(item, [key]) : item)]}}}), {})
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function filterProps(props, options) {
  const {filterFn, transformFn} = options

  const filteredEntries = Object
    .entries(props)
    .filter(filterFn)

  const filtered = filteredEntries
    .map(transformFn)
    .reduce((filtered, [key, value]) => ({...filtered, [key]: value}), {})

  const rest = omit(props, filteredEntries.map(([key]) => key))
  return {filtered, rest}
}

export function filterByPrefix(prefix, props) {
  const filterFn = (([key]) => key.startsWith(prefix))
  const transformFn = (([key, value]) => {
    const name = capitalizeFirstLetter(key.substring(prefix.length), false)
    return [name, value]
  })

  return filterProps(props, {filterFn, transformFn})
}

export function extractField(name) {
  return data => data[name]
}

export const extractId = extractField('id')
