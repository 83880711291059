import ClearableQueryFilter from 'components/Common/ClearableQueryFilter'

import useTranslations from 'hooks/translations'
import useCatalog from 'hooks/catalog'

export default function SubjectFilter (props) {

  const {translate} = useTranslations()
  const {subjectsList} = useCatalog()

  return (<ClearableQueryFilter {...{...props, caption: translate('subject-label'), items: subjectsList, name: 'subject'}} />)

}
