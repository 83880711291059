import {styled} from 'external/material'

const StyledSubjectRow = styled('div')(subjectRowStyles)

export default function SubjectRow ({children, length}) {
  return (
    <StyledSubjectRow className="subject-row">
      {children}
      {length < 2 ? <div className="filler" /> : null}
      {length < 3 ? <div className="filler" /> : null}
    </StyledSubjectRow>
  )
}

function subjectRowStyles() {
  return {
    display: 'flex',
    justifyContent: 'center',
    gap: 35,
    margin: 30,
    '&:first-of-type': {
      marginTop: 30
    },
    '&:last-child': {
      paddingBottom: 20
    },
    '& .filler': {
      flex: 1,
      minWidth: 200,
      maxWidth: 500,
      backgroundColor: 'transparent',
      border: 'none',
    },
  }
}
