import {useContext, useMemo} from 'external/react'
import {sprintf} from 'external/sprintf-js'
import {format, parseISO, locales} from 'external/date-fns'
import Translations from 'context/translations-context'

export default function useTranslations() {
  const {state: {translations, language, languages}, update} = useContext(Translations.Context)
  const ready = !!(translations && language)
  const translate = ready
    ? (key, params) => {
      const translation = translations[key] || {}
      const value = translation[language]
      if (value) return params ? sprintf(value, params) : value
      else return key
    }
    : () => false
  const formatDate = ready
    ? date => typeof(date) == 'string'
      ? format(parseISO(date), translate('date-format'))
      : format(date, translate('date-format'))
    : () => false

  const formatDateTime = ready
    ? date => typeof(date) == 'string'
      ? format(parseISO(date), translate('date-format-time'))
      : format(date, translate('date-format-time'))
    : () => false
  
  const locale = useMemo(() => locales[language] || locales['es'], [language])

  const setLanguage = l => update({language: {$set: l}}) 
  return {translate, formatDate, formatDateTime, language, languages, setLanguage, ready, locale}
}

