import {useContext, useMemo, useCallback} from 'external/react'
import Settings from 'context/settings-context'
import useProfile from 'hooks/profile'

export default function useMenu () {
  const {update, state: {dashboardTab, displayingScheduleMeeting, sidebarOpened, menuOpened: open, drawerClicked: clicked, items = {menu: {}}}} = useContext(Settings.Context)
  const {project} = useProfile()
  const menu = items.menu[project] || []
  const toggleMenu = (override) => {
    update({menuOpened: {$apply: o => override ? override : !o}, drawerClicked: {$set: true}})
  }

  const toggleSidebar = (override) => {
    update({sidebarOpened: {$apply: o => override ? override : !o}})
  }
  
  const changeDashboardTab = (tab = 'messages') => {
    update({dashboardTab: {$set: tab}})
  }
  
  const hideMenu = useMemo(()=> {
    return menu.filter(item => item.menuVisible === false).reduce((hidden, {page}) => ({...hidden, [page]: true}), {})
  }, [menu])

  const displayScheduleMeeting = useCallback(() => {
    update({displayingScheduleMeeting: {$apply: a => !a}})
  }, [])
  
  return {menu, clicked, open, toggleMenu, sidebarOpened, toggleSidebar, dashboardTab, changeDashboardTab, hideMenu, displayingScheduleMeeting, displayScheduleMeeting}

}
