import {useState, useMemo, useCallback} from 'external/react'
import {
    clsx,
    styled,
    Box,
    Fade,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from 'external/material'

import {subSeconds} from 'external/date-fns'
import {keepAliveLeeway} from 'settings'

import useTranslations from 'hooks/translations'
import useProfile from 'hooks/profile'
import useIntervalCallback from 'hooks/interval-callback'

import {logout, keepAlive} from 'utils/users'

import Timer from 'components/Common/Timer'

const StyledDialog = styled(Dialog)(dialogStyles)

const id = 'keep-alive-form'

export default function KeepAliveForm () {

  const {translate, ready} = useTranslations()
  const {update, expiration, project} = useProfile()
  const [open, setOpen] = useState(false)

  const target = useMemo(() => subSeconds(expiration, keepAliveLeeway), [expiration])
  
  useIntervalCallback(()=> {
      setOpen(true)
  }, target)

  const handleKeepAlive = useCallback(() => {
      keepAlive().then(data => {
          const [{expiration}] = data
          update({info: {expiration: {$set: expiration}}})
          setOpen(false)
      })
  }, [])

  const handleLogout = useCallback (() => { logout() }, [])
  
  const titleId = `${id}-title`

  return (
    <StyledDialog open={open} aria-labelledby={titleId} disableEscapeKeyDown>
      <DialogTitle id={titleId} className="title-container">
          <Box className="title">{translate(`${id}-title`)}</Box>
      </DialogTitle>
      <DialogContent className="content-container">
        <Box className="content">{translate(`${id}-message`, {project: translate(`${project}_project_title`)})}</Box>
      </DialogContent>
      <Fade in={ready}>
          <DialogActions className="actions-container">
            <Box className="actions">
                <Button variant="contained" color="background" className={clsx('button', 'keep-alive')} onClick={handleKeepAlive}>
                    {translate(`${id}-keep-alive`)}
                </Button>
                <Button variant="contained" color="error" className={clsx('button', "logout")} onClick={handleLogout}>
                  <span className="label">{translate(`${id}-logout`)}</span>
                  <Timer className="logout-timer" startWrapper="(" endWrapper=")" startsAt={expiration} onExpiration={handleLogout} format={i => i} />
                </Button>
            </Box>
          </DialogActions>
      </Fade>
    </StyledDialog>
  )
}

function dialogStyles() {

  return {
    display: 'flex',
    justifyContent: 'center',
    "& .MuiPaper-root": {
      maxWidth: 600
    },
    "& .title-container": {
      display: 'flex',
      justifyContent: 'center'
    },
    "& .content-container": {
      display: 'flex',
      padding: '0px 15px 15px 15px',
      justifyContent: 'center',
      "& .content": {
        maxWidth: 400,
        textAlign: 'justify'
      }
    },
    "& .actions-container": {
      display: 'flex',
      padding: '0px 15px 15px 15px',
      '& .actions': {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end',
       '& .button': {
          width: 85,
          display: 'flex',
         "& .label": {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
         },
        "&.logout": {
          marginLeft: 20
        },
        "& .logout-timer": {
           marginLeft: 5,
           display: "flex"          
         }
        }
      }
    }
  }
}