import {SvgKeepRatio} from 'utils/style'

export default function DownloadsIcon(props) {
  const fill = props.fill || "#009DD8"
  return <SvgKeepRatio viewBox={{width: 26, height: 26}} {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-778.000000, -341.000000)" fill={fill}>
          <path d="M799.937558,363.546958 C799.378913,363.546958 798.921947,364.003924 798.921947,364.562568 C798.921947,365.121045 799.378913,365.578179 799.937558,365.578179 C800.496202,365.578179 800.953168,365.121045 800.953168,364.562568 C800.953168,364.003924 800.496202,363.546958 799.937558,363.546958 L799.937558,363.546958 Z M796.687537,363.546958 C796.128892,363.546958 795.671926,364.003924 795.671926,364.562568 C795.671926,365.121045 796.128892,365.578179 796.687537,365.578179 C797.246181,365.578179 797.703147,365.121045 797.703147,364.562568 C797.703147,364.003924 797.246181,363.546958 796.687537,363.546958 L796.687537,363.546958 Z M793.031305,341 C793.706754,341 794.250105,341.543351 794.250105,342.2188 L794.250105,350.749895 L798.703632,350.749895 C799.60748,350.749895 800.059404,351.841807 799.419585,352.481626 L791.695701,360.210552 C791.314868,360.591385 790.690342,360.591385 790.309341,360.210552 L782.575373,352.481626 C781.935554,351.841807 782.387646,350.749895 783.291494,350.749895 L787.750063,350.749895 L787.750063,342.2188 C787.750063,341.543351 788.293414,341 788.968863,341 L793.031305,341 Z M804,365.7812 C804,366.456481 803.456649,367 802.7812,367 L779.2188,367 C778.543351,367 778,366.456481 778,365.7812 L778,360.093747 C778,359.418466 778.543351,358.874947 779.2188,358.874947 L786.668403,358.874947 L789.156758,361.363303 C790.177411,362.383955 791.822757,362.383955 792.84341,361.363303 L795.331765,358.874947 L802.7812,358.874947 C803.456649,358.874947 804,359.418466 804,360.093747 L804,365.7812 Z"></path>
        </g>
      </g>
  </SvgKeepRatio>
}