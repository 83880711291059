import {useCallback} from 'external/react'
import {
  useTheme
 ,useMediaQuery
 ,Drawer
 ,List
 ,styled
} from 'external/material'

import {mixStyle} from 'utils/style'
import MenuItem from 'components/Menu/MenuItem'
import useMenu from 'hooks/menu'
import {inViewer, version} from 'settings'
import useProfile from 'hooks/profile'


const StyledMenu = styled(Drawer)(menuStyles)

export default function Menu() {
  const {noChat} = useProfile()
  const context = useMenu()
  const theme = useTheme()
  const isSmallFactor = useMediaQuery(theme.breakpoints.down('desktop'))
  const variant = isSmallFactor ? 'temporary' : 'persistent'
  const open = context.clicked
    ? context.open
    : !isSmallFactor

  const handleDrawerClose = useCallback(() => {
    isSmallFactor && context.toggleMenu()
  }, [isSmallFactor])

  return (
    <StyledMenu open={open} anchor="left" variant={variant} onClose={handleDrawerClose}>
      <List disablePadding={true} className="list">
        {context.menu
          .filter(i => !i.inViewer || inViewer)
          .filter(i => i.page == 'chat' ? !noChat : true)
          .map(item => <MenuItem context={context} theme={theme} isSmallFactor={isSmallFactor} key={item.page} {...item} />)}
      </List>
      <div className="version">{version}</div>
  </StyledMenu>)
}

function menuStyles({theme}) {

  const marginTop = mixStyle(theme.mixins.toolbar, ['minHeight', 'maxHeight'], (height, type) => {
    if (type == 'maxHeight') return
    return {marginTop: `${height * 2}px`, flexGrow: 1}
  })

  return {
    flexShrink: 0,
    "& .MuiDrawer-paper": {
        backgroundColor: theme.palette.primary.dark,
        ...theme.mixins.use('drawer'),
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column'
    },
    "& .list": {...marginTop},
    "& .version": {
      color:'#fff',
      fontFamily: 'mono',
      fontSize: '7.6pt',
      marginBottom: 4,
      display: 'flex',
      justifyContent: 'center'
    }
  }
}
