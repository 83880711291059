import create, { createPost } from 'utils/command'
import { useForm, useEffect, FormController, useState } from 'external/react'
import { debounce } from 'external/lodash'
import {
	styled,
	clsx,
	Dialog,
	DialogTitle,
	IconButton,
	DialogContent,
	DialogActions,
	Button,
	FormControl,
	TextField,
	InputLabel,
	InputAddornment,
	Select,
	MenuItem,
	Autocomplete,
	Box,
	ContentPasteIcon,
	CheckIcon
} from 'external/material'
import { emailRegex, phoneRegex, dummyPassword } from '../../../../settings'
import { capitalizeFirstLetter } from 'utils/text'
import useTranslations from 'hooks/translations'
import useAdmin from 'hooks/admin'
import useCatalog from 'hooks/catalog'
import UserGroups from '../EditUser/UserGroups'
import useSession from 'hooks/session'

const StyledEditUserForm = styled(Dialog)(editUserFormStyles)

const defaultValues = {
	"first_name": "",
	"last_name": "",
	"middle_name": "",
	"maiden_name": "",
	"email": "",
	"phone": "",
	"mobile": "",
	"username": "",
	"password": dummyPassword,
	"puk": "",
	"grade": "",
	"category": "",
	"groups": [
	]
}

export default function Edit() {

	const { editUserForm: { open, user }, studentCategories, update } = useAdmin()
	const { studentGrades = [] } = useCatalog()
	const [iconValue, setIconValue] = useState('toCopy')

	const {navigation} = useSession()

	const { translate } = useTranslations()

	const { register, getValues, setValue, handleSubmit, reset, resetField, watch, control, formState: { errors }, clearErrors } = useForm({
		defaultValues,
		values: user.id ? user : defaultValues,
		mode: 'onSubmit',
		reValidateMode: 'onBlur'
	})

	const isUsernameAvailable = debounce(async value => {
		if (!user.username || !value || (user.username == value)) return true
		const [{ valid }] = await create('admin', 'is_valid_user_name')({ Name: value })
		return valid || translate('username-in-use')
	}, 500)

	function closeForm(refresh) {
		if (refresh) update({ users: { $refreshLoadableList: true }, editUserForm: { open: { $set: false }, user: { $set: {} } } })
		else update({ editUserForm: { open: { $set: false }, user: { $set: {} } } })
	}

	function handleClose() {
		closeForm(false)
	}

	async function onSubmit(form) {
		try {
			const updatedUser = form.password === dummyPassword ? { ...form, password: undefined } : form
			const [updated] = await createPost('admin', 'editUser')(updatedUser)
			if (updated) {
				closeForm(true)
				navigation.users.go()
			} else {
				console.log('Error updating user')
			}
		} catch (e) {
			console.error('error' + e)
		}
	}

	useEffect(() => {
		if (!user.id) {
			reset(user)
		}
	}, [user])

	console.log({
		...register('username', {
			required: translate('required-username'),
			validate: { isUsernameAvailable }
		})
	})

	const icons = {
		toCopy: <ContentPasteIcon />,
		copied: <CheckIcon/>
	}

	const handleCopy = async () => {
		await navigator.clipboard.writeText(user.puk.toUpperCase())
		setIconValue('copied')
	}


	return (
			<>
			<div
				style={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: 'white', overflow: 'auto', paddingTop: '25px', height: '100vh' }}
			>
				<form className="form" style={{height: '100%'}}>
					<div style={{ display: 'grid', width: '100%', height: '100vh', gridTemplateColumns: '4fr 3fr', columnGap: '40px' }}>
						<div style={{
							display: 'grid',
							// flexDirection: 'column',
							gridTemplateColumns: '1fr 1fr',
							width: '100%',
							height: '100%',
							paddingLeft: '24px',
							columnGap: '16px',
							// justifyContent: 'center',
							// alignItems: 'center',
							// flexFlow: 1,
						}}
						>
							<FormControl sx={{ width: '100%', display: 'flex' }} className={clsx('username', 'form-control')} variant='standard'>
								<label style={{ fontWeight: 'bold' }} htmlFor="username">{capitalizeFirstLetter(translate('username'))}</label>
								<TextField
									id="username"
									type="text"
									variant="outlined"
									className={clsx('username', 'form-item')}
									// style={{marginBottom: 25}}
									defaultValue={user?.username || 'f'}
									error={!!errors.username}
									helperText={errors.username && errors.username.message}
									{...register('username', {
										required: translate('required-username'),
										validate: { isUsernameAvailable }
									})}
								/>
							</FormControl>
							{user.role == 'student' && user.grade && (
								<FormControl style={{ flexGrow: 1, width: '100%' }} className={clsx('grade', 'form-control')}>
									<label style={{ fontWeight: 'bold' }} htmlFor="grade">{capitalizeFirstLetter(translate('grade'))}</label>
									<FormController
										name="grade"
										control={control}
										rules={{ required: false }}
										render={(props) => {
											return <Select
												className={clsx('grade', 'form-item')}
												id='grade'
												value={props.field.value}
												onChange={({ target }) => {
													setValue('grade', target.value);
												}}
											>
												{studentGrades.map(({ id, name }) => (
													<MenuItem
														value={id}
														key={id}
													>
														{name}
													</MenuItem>
												))}
											</Select>
										}}
									/>
								</FormControl>
							)}
							{user.role == 'student' && (
								<FormControl style={{ flexGrow: 1, width: '100%' }} className={clsx('category', 'form-control')}>
									<label style={{ fontWeight: 'bold' }} htmlFor="category">{capitalizeFirstLetter(translate('category'))}</label>
									<FormController
										name="category"
										control={control}
										rules={{ required: false }}
										render={({ field: { onChange, value } }) => {
											return <Autocomplete
												freeSolo={true}
												value={value}
												options={studentCategories.items.map(o => o.name)}
												onChange={(event, category, reason) => {
													onChange(category || "")
												}}
												renderInput={(params) => {
													return <TextField
														{...params}
														value={value}
														error={!!errors.category}
														helperText={errors.category && errors.category.message}
														onChange={onChange}
													/>
												}}
											/>
										}}
									/>
								</FormControl>
							)}
							<FormControl style={{ flexGrow: 1, width: '100%' }} className={clsx('first-name', 'form-control')}>
								<label style={{ fontWeight: 'bold' }} htmlFor="first_name">{capitalizeFirstLetter(translate('first-name'))}</label>
								<TextField
									id="first_name"
									name="first_name"
									type="text"
									variant="outlined"
									className={clsx('first-name', 'form-item')}
									error={!!errors.first_name}
									helperText={errors.first_name && errors.first_name.message}
									{...register('first_name', {
										required: translate('required-first-name'),
										minLength: { value: 2, message: translate('minimum-input-text-length', { length: 2 }) }
									})}
								/>
							</FormControl>
							<FormControl style={{ flexGrow: 1, width: '100%' }} className={clsx('middle-name', 'form-control')}>
								<label style={{ fontWeight: 'bold' }} htmlFor="middle_name">{capitalizeFirstLetter(translate('middle-name'))}</label>
								<TextField
									id="middle_name"
									name="middle_name"
									type="text"
									variant="outlined"
									className={clsx('middle-name', 'form-item')}
									{...register('middle_name', { required: false })}
								/>
							</FormControl>
							<FormControl style={{ flexGrow: 1, width: '100%' }} className={clsx('last-name', 'form-control')}>
								<label style={{ fontWeight: 'bold' }} htmlFor="last_name">{capitalizeFirstLetter(translate('last-name'))}</label>
								<TextField
									id="last_name"
									name="last_name"
									type="text"
									variant="outlined"
									className={clsx('last-name', 'form-item')}
									error={!!errors.last_name}
									helperText={errors.last_name && errors.last_name.message}
									{...register('last_name', {
										required: translate('required-last-name'),
										minLength: { value: 2, message: translate('minimum-input-text-length', { length: 2 }) }
									})}
								/>
							</FormControl>
							<FormControl style={{ flexGrow: 1, width: '100%' }} className={clsx('maiden-name', 'form-control')}>
								<label style={{ fontWeight: 'bold' }} htmlFor="maiden_name">{capitalizeFirstLetter(translate('maiden-name'))}</label>
								<TextField
									id="maiden_name"
									name="maiden_name"
									type="text"
									variant="outlined"
									className={clsx('maiden-name', 'form-item')}
									error={!!errors.maiden_name}
									helperText={errors.maiden_name && errors.maiden_name.message}
									{...register('maiden_name', {
										minLength: { value: 2, message: translate('minimum-input-text-length', { length: 2 }) }
									})}
								/>
							</FormControl>
							<FormControl style={{ flexGrow: 1, width: '100%' }} className={clsx('email', 'form-control')}>
								<label style={{ fontWeight: 'bold' }} htmlFor="email">{capitalizeFirstLetter(translate('email'))}</label>
								<TextField
									id="email"
									name="email"
									type="text"
									variant="outlined"
									className={clsx('email', 'form-item')}
									error={!!errors.email}
									helperText={errors.email && errors.email.message}
									{...register('email', {
										pattern: { value: emailRegex, message: translate('input-must-be-a-valid-email') }
									})}
								/>
							</FormControl>
							<FormControl style={{ flexGrow: 1, width: '100%' }} className={clsx('phone', 'form-control')}>
								<label style={{ fontWeight: 'bold' }} htmlFor="phone">{capitalizeFirstLetter(translate('phone'))}</label>
								<TextField
									id="phone"
									name="name"
									type="text"
									variant="outlined"
									className={clsx('phone', 'form-item')}
									error={!!errors.phone}
									helperText={errors.phone && errors.phone.message}
									{...register('phone', {
										pattern: { value: phoneRegex, message: translate('input-must-be-a-valid-phone-number') }
									})}
								/>
							</FormControl>
							<FormControl style={{ flexGrow: 1, width: '100%' }} className={clsx('mobile', 'form-control')}>
								<label style={{ fontWeight: 'bold' }} htmlFor="mobile">{capitalizeFirstLetter(translate('mobile'))}</label>
								<TextField
									id="mobile"
									name="mobile"
									type="text"
									variant="outlined"
									className={clsx('mobile', 'form-item')}
									error={!!errors.mobile}
									helperText={errors.mobile && errors.mobile.message}
									{...register('mobile', {
										pattern: { value: phoneRegex, message: translate('input-must-be-a-valid-mobile-number') }
									})}
								/>
							</FormControl>
							<FormControl style={{ flexGrow: 1, width: '100%' }} className={clsx('puk', 'form-control')}>
								<label style={{ fontWeight: 'bold' }} htmlFor="puk">{capitalizeFirstLetter(translate('puk'))}</label>
								<TextField
									id="puk"
									name="puk"
									readOnly={true}
									InputProps={{
										endAdornment: (
											<InputAddornment position='end'>
												<IconButton onClick={async () => await handleCopy()}>
													{icons[iconValue]}
												</IconButton>
											</InputAddornment>
										)
									}}
									type="text"
									variant="outlined"
									value={user.puk ? user.puk.toUpperCase() : ''}
									className={clsx('puk', 'form-item')}
								>
									{/* <IconButton>
										<ContentPasteIcon/>
									</IconButton> */}
								</TextField>
							</FormControl>
							<FormControl style={{ flexGrow: 1, width: '100%' }} className={clsx('password', 'form-control')}>
								<label style={{ fontWeight: 'bold' }} htmlFor="password">{capitalizeFirstLetter(translate('password'))}</label>
								<TextField
									id="password"
									name="password"
									type="password"
									autoComplete="on"
									variant="outlined"
									className={clsx('password', 'form-item')}
									error={!!errors.password}
									helperText={errors.password && errors.password.message}
									onFocus={() => setValue('password', '')}
									{...register('password', {
										minLength: { value: 4, message: translate('minimum-input-text-length', { length: 4 }) }
									})}
								/>
							</FormControl>
						</div>
						{user.groups && user.groups.length > 0 && (
							<div className="left">
								<UserGroups />
							</div>
						)}
					</div>
				</form>


			</div>
			<DialogActions>
				<Button
					sx={{
						width: 140,
						height: 40,
						background: '#2c7cbd',
						color: '#fff',
						'&:hover': {
							backgroundColor: '#2ca5ec'
						}
					}}
					type="submit" 
					onClick={handleSubmit(onSubmit)}
				>
						{translate('save')}
				</Button>
			</DialogActions>
		</>)

}

function editUserFormStyles() {
	return {
		'& .MuiPaper-root': {
			width: '100%',
			maxWidth: '1280px',
			'& .title': {
				textAlign: 'center',
				backgroundColor: '#2c7cbd',
				color: '#fff',
				'& .close-button': {
					position: 'absolute',
					right: 15,
					color: '#fff'
				}
			},
			'& .form': {
				display: 'flex',
				flexDirection: 'column',
				overflow: 'hidden',
				'& .form-control': {
					display: 'flex',
					flexDirection: 'row'
				},
				'& .MuiDialogContent-root': {
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					'& .form-item, .MuiAutocomplete-inputRoot': {
						marginBottom: 25
					}
				},
				'& .MuiDialogActions-root': {
					justifyContent: 'center',
					paddingBottom: 30,
					'& button': {
						width: 140,
						height: 40,
						background: '#2c7cbd',
						color: '#fff'
					}
				},
				'& .right, .left': {
					flexGrow: 1
				},
				'& .right': {
					display: 'flex',
					flexDirection: 'column',
					width: '50%',
					'& .MuiFormControl-root, .MuiInputBase-root': {
						width: '100%'
					}
				},
				'& .left': {
					padding: '0px 15px'
				},
				'& label.grade': {
					transform: 'translate(14px, 16px) scale(1)'
				},
				'& .category': {
					'& .MuiAutocomplete-root': {
						width: '100%',
						textAlign: 'end',
						'& .MuiFormControl-root': {
							width: 88,
						}
					}
				}
			},
			'& .input-label': {
				fontWeight: 'bold',
				color: '#434a5d',
				fontSize: '1.15em',
				position: 'relative',
				width: '60%',
				lineHeight: '0.97rem',
			}
		}
	}
}