import create from 'utils/command'
import {associate} from 'utils/functions' 

const hooks =  [ ]

export async function initializeTranslations({update}) {
  const [translations, languages] = await Promise.all([
    create('public', 'translations')({prefix: 'admin-client'}).then(associate('name','language', 'value')),
    create('support', 'languages')()
  ])
  update({translations: {$set: translations}, languages: {$push: languages}})
}

export {hooks as default}
