import {styled, Box} from 'external/material'

const StyledHeader = styled(Box)(headerStyles)

export default function Header(props) {
  const {date, daySize} = props
  return (
    <StyledHeader
      className="header day"
      style={{width: daySize.width}}
    >
      {date.format('ddd DD/MM')}
   </StyledHeader>)
}

export function headerStyles() {
  return {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
    padding: '6px 10px',
    textTransform: 'uppercase'
  }
}
