import {useCallback} from 'external/react'
import { styled, useMediaQuery, useTheme, ButtonBase, MenuIcon } from 'external/material'
import useMenu from 'hooks/menu'

const StyledDrawer = styled(ButtonBase)(drawerStyles)

export default function Drawer() {
  const {clicked, toggleMenu} = useMenu()
  const theme = useTheme()
  const isSmallFactor = useMediaQuery(theme.breakpoints.down('desktop'))
  const handleDrawerClick = useCallback(() => {toggleMenu(!clicked && isSmallFactor)}, [clicked])
  return (
    <StyledDrawer onClick={handleDrawerClick} edge="start" disableRipple={true}>
      <MenuIcon />
    </StyledDrawer>
  )
}

function drawerStyles ({theme}) {
  return {
    color: "#1c4b90",
    backgroundColor: '#f4f4f4',
    ...theme.mixins.use('drawer', 'toolbar'),
    '&:hover': {
      border: 'outset 1px #ffffffdb',
      color: '#1c4b90'
    },
    '&:active': {
      border: 'inset 1px #ffffffdb',
      color: '#1c4b90'
    },
  }
}
