import create, {initializeAndRegisterHooks} from 'context/create' 
import hooks, * as resolves from './hooks'

const initialState = {
  dashboardTab: 'recent-activities',
  sidebarOpened: false,
  menuOpened: false,
  displayingScheduleMeeting: false,
  drawerClicked: true,
  items: undefined
}

const id = 'Settings'

const context = create(id, initialState, { onInit: initializeAndRegisterHooks(id, hooks, resolves) })

export {context as default}
