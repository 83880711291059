import { createLoadableList } from 'external/immutability-helper'
import create, {initializeAndRegisterHooks} from 'context/create' 
import hooks, * as resolves from './hooks'

const initialState = {
    id: null,
    periods: createLoadableList(),
    newPeriods: {},
    periodTypes: [],
    rearrange: 0,
    start: null,
    finish: null,
    isSaving: false,
}

const id = 'Rollbook'

const context = create(id, initialState, { onInit: initializeAndRegisterHooks(id, hooks, resolves) })

export {context as default}
