import {NavLink, forwardRef} from 'external/react'

const activeClassName = 'active-page'
const MenuLinkBehavior = forwardRef((props, ref) => (
  props.href
  ? (<a ref={ref} {...props} />)
  : (
  <NavLink
    ref={ref}
    {...props}
    className={({isActive}) => [props.className, isActive ? activeClassName : null].filter(Boolean).join(" ")}
  />)
))

export {MenuLinkBehavior as default} 
