import {Outlet, useMemo} from 'external/react' 
import {styled} from 'external/material'

import BackButton from 'components/Common/BackButton'
import SearchPattern from 'components/Common/SearchPattern'
import useSession from 'hooks/session'

const StyledMonitoring = styled('div')(usersStyles)

export default function MonitoringContainer() {
  const {section, teacherId, groupId, studentId, navigation} = useSession()
  
  const backLink = useMemo (() => {
    if (!teacherId && !studentId) return navigation.page.link({pageName: 'dashboard'})
    if (studentId) return undefined
    if (!groupId) return navigation.page.link({pageName: 'monitoring'})
    if (!studentId) return navigation.monitoringTeacher.link({})
    return navigation.monitoringGroup.link({})
  }, [groupId, teacherId, section])

  return (
      <StyledMonitoring>
        <div className='filter-topbar'>
          <BackButton to={backLink} />
          <div className='filler' />
          <SearchPattern />
        </div>
        <Outlet />
        <div className='filter-sidebar' />
      </StyledMonitoring>
  )
}

function usersStyles() {
   return {
    display: 'flex',
    padding: 40,
    flexGrow: 1,
    overflowY: 'hidden',
    flexDirection: 'column',
    "& .filter-topbar": {
      display: 'flex',
      '& .filler': {
        flexGrow: 1
      }
    },
    "& .filters": {
        flexShrink: 0,
        marginBottom: 30,
        flexWrap: 'wrap'
    },
    "& .calendar": {
        flexGrow: 1,
        overflowY: 'auto'
    }
   } 
}