import { useEffect, useMemo, useState } from 'external/react'
import { styled, Checkbox } from 'external/material'

import useAdmin from 'hooks/admin'
import useTranslations from 'hooks/translations'

import ToggleExportFormat from './ToggleExportFormat'
import DownloadUsersButton from './DownloadUsersButton'
import DownloadAllUsersButton from './DownloadAllUsersButton'
import CheckboxList from 'components/Common/CheckboxList'
import { exportUsersFile, exportAllUsersFile } from './export'
import SearchGroups from './SearchGroups'

const StyledExportUsers = styled('div')(settingsMenuStyles)

export default function ExportUsers() {

  const [format, setFormat] = useState('xlsx')
  const { exportUsers, downloadUsers, groupList, update } = useAdmin()
  const { options, filters, searchGroups, isSearching } = downloadUsers
  const { translate, language } = useTranslations()

  const groups = useMemo(() => {
    return groupList.items.map(({groupName, id}) => ({label: groupName, value: id}))
  }, [groupList])

  const roles = useMemo(() => {
    return [
      {label: translate('option-role-students'), value: 'students'},
      {label: translate('option-role-teachers'), value: 'teachers'},
    ]
  }, [language])

  const allRolesSelected = useMemo(() => {
    return filters.roles.length === options.roles.length
  }, [options.roles, filters.roles])

  const allGroupsSelected = useMemo(() => {
    return filters.groups.length === options.groups.length
  }, [options.groups, filters.groups])

  useEffect(() => {
    update({
      downloadUsers: {
        options: {
          groups: {$set: groups},
          roles: {$set: roles}
        },
        filters: {
          groups: {$set: groups.map((group) => group.value)},
          roles: {$set: roles.map((role) => role.value)}
        }
      }
    })
  }, [groups, roles])

  return (
    <StyledExportUsers>
      <div className="buttons">
        <ToggleExportFormat format={format} handleFormatChange={setFormat} />
        <div style={{ margin: '0 10px' }} />
        <SearchGroups />
        <div style={{ flexGrow: 1 }} />
        {format == 'csv' && <DownloadAllUsersButton handleDownloadClick={exportAllUsersFile} />}
        <div style={{ margin: '0 8px' }} />
        <DownloadUsersButton format={format} handleDownloadClick={exportUsersFile} />
      </div>
      <div className="preview-users">
        <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
          <div className="titleContainer">
            <span className="title">{translate('download-users-roles-label')}</span>
            <div className="checkboxContainer">
              <Checkbox
                onClick={() => update({
                  downloadUsers: {
                    filters: {
                      roles: {$set: allRolesSelected ? [] : options.roles.map(r => r.value)}
                    }
                  }
                })}
                edge="start"
                checked={allRolesSelected}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': 'checkbox-list-label-selectAll'}}
              />
              <label>{translate('all')}</label>
            </div>
          </div>
          <div style={{padding: 5}}>
            <CheckboxList
              mapLabel={g => g.label}
              mapValue={g => g.value}
              checked={filters.roles}
              options={options.roles}
              handleToggle={selected => {
                const selectIndex = filters.roles.indexOf(selected)
                const command = selectIndex < 0 ? {$push: [selected]} : {$splice: [[selectIndex, 1]]}
                update({downloadUsers: {filters: {roles: command } } })
              }}
            />
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', flex: 5, overflowY: 'hidden', borderLeft: '1px solid #d9d9d9'}}>
          <div className="titleContainer">
            <span className="title">{translate('download-users-groups-label')}</span>
            <div className="checkboxContainer">
              <Checkbox
                onClick={() => update({
                  downloadUsers: {
                    filters: {
                      groups: {$set: allGroupsSelected ? [] : options.groups.map(g => g.value)}
                    }
                  }
                })}
                edge="start"
                checked={allGroupsSelected}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': 'checkbox-list-label-selectAll'}}
              />
              <label>{translate('all')}</label>
            </div>
            <div>
              <span>
                {language === 'es'
                  ? `${filters.groups.length} de ${options.groups.length} grupos seleccionados`
                  : `${filters.groups.length} of ${options.groups.length} groups selected`}
              </span>
            </div>
          </div>
          <div style={{padding: 5, overflowY: 'auto'}}>
            <CheckboxList
              mapLabel={g => g.label}
              mapValue={g => g.value}
              checked={filters.groups}
              options={isSearching ? searchGroups : options.groups}
              className="checkboxlist"
              handleToggle={selected => {
                const selectIndex = filters.groups.indexOf(selected)
                const command = selectIndex < 0 ? {$push: [selected]} : {$splice: [[selectIndex, 1]]}
                update({downloadUsers: {filters: {groups: command } } })
              }}
            />
          </div>
        </div>
      </div>
    </StyledExportUsers>
  )
}

function settingsMenuStyles() {
  return {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
    '& .checkboxlist': {
      textAlign: 'center',
      padding: 0,
      margin: 0,
      columnCount: 4,
      columnGap: '20px',
    },
    "& > .buttons": {
      display: 'flex',
      flexShrink: 0,
      marginBottom: 10
    },
    "& > .preview-users": {
      display: 'flex',
      flexGrow: 1,
      backgroundColor: '#fff',
      border: '1px solid #d9d9d9',
      borderRadius: 5,
      overflowY: 'hidden',
      "& .titleContainer": {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 15,
        borderBottom: '1px solid #d9d9d9',
        "& .title": {
          padding: 10,
          fontSize: '14pt',
        },
        "& .checkboxContainer": {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }
      },
    }
  }
}
