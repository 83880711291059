import {
   AppBar
  ,ToolBar
  ,Box
} from 'external/material'

import {styleAndCapture}  from 'external/material'
import DrawerButton from 'components/Menu/Drawer'
import ProjectName from 'components/TopBar/ProjectName'
import PageLanguage from 'components/TopBar/PageLanguage'
import PageName from 'components/TopBar/PageName'
import UserName from 'components/TopBar/UserName'
import Logout from 'components/TopBar/Logout'
import HelpLink from 'components/TopBar/HelpLink'

import { centerVertically, flexGrow } from 'utils/style'

const StyledAppBar = styleAndCapture(AppBar, 'isMenuVisible')(appBarStyles)

export default function TopBar(props) {
  const {isMenuVisible} = props
  return (
  <StyledAppBar component="nav" position="fixed" isMenuVisible={isMenuVisible} >
    <ToolBar disableGutters variant="dense">
      <Box>
        {isMenuVisible && <DrawerButton />}
        <ProjectName className="project-name" />
        <PageLanguage className="select-language" />
        <Logout className="logout-button" />
        <HelpLink />
      </Box>
    </ToolBar>
    <ToolBar disableGutters variant="dense">
      <Box>
        <PageName className="page-name" />
        <Box sx={flexGrow()} />
        <UserName className="username" />
      </Box>
    </ToolBar>
  </StyledAppBar>)
}

function appBarStyles({theme, isMenuVisible}) {

  const toolbarComponent = {
    ...centerVertically,
    padding: isMenuVisible ? '5px 15px' : '5px 30px',
    [theme.breakpoints.down('desktop')]: {
      padding: isMenuVisible ? '4px 8px' : '4px 27px'
    }
  }

  return {
    display: 'flex',
    zIndex: theme.zIndex.drawer + 1,
    "& .MuiToolbar-root": {
      display: 'flex',
       "&:first-of-type": {
          backgroundColor: theme.palette.background.paper
       },
      ...theme.mixins.toolbar,
     "& > .MuiBox-root": {
       display: 'flex',
       flexGrow: 1,
       "& > .project-name": {
          flexGrow: 1,
          ...toolbarComponent
       },
       "& > .select-language": { ...centerVertically },
       "& > .logout-button": {
        ...toolbarComponent,
        paddingLeft: 0
        },
        "& > .page-name": {
          paddingLeft: 30,
          [theme.breakpoints.down('desktop')]: {
            paddingLeft: 27
          }
        },
        "& > .username": {
          paddingRight: 31,
          [theme.breakpoints.down('desktop')]: {
            paddingRight: 20
          }
        },

      }
    }
  }
}
