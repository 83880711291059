import {omit} from 'external/lodash'
import {Link, forwardRef} from 'external/react'

const LinkBehavior = forwardRef(
  (props, ref) => (
    <Link
      ref={ref}
      to={props.to}
      {...omit(props, ['to'])}
    />
  )
)

LinkBehavior.displayName = 'link'
export {LinkBehavior as default}
