import {Routes, Route} from 'external/react' 

import UsersContainer from './UsersContainer'
import UsersTable from './UsersTable'
import EditUser from './Edit/index'
import CreateUser from './Create/index'

export default function Users() {
  return (
    <Routes>
        <Route element={<UsersContainer />}>
          <Route index element={<UsersTable />} />
          <Route exact path="edit/:userId" element={<EditUser />} />
          <Route path='create/:userType' element={<CreateUser/>} />
       </Route>
    </Routes>
  )
}