import {styled} from 'external/material'
import {chunk} from 'external/lodash'

import useAdmin from 'hooks/admin'
import useCatalog from 'hooks/catalog'

import SubjectRow from './SubjectRow'
import SubjectCard from './SubjectCard'

const StyledLicenseCountForm = styled('div')(licenseCountFormStyles)

export default function LicenseSubjects() {

  const {licenseCountForm} = useAdmin()
  const {licensesLoaded, licenseCount} = licenseCountForm
  const {subjectsList} = useCatalog()
  const subjects = subjectsList.filter(s => !!licenseCount[s.id]).map(s => ({...s, ...licenseCount[s.id]}))
  const rows = chunk(subjects || [], 3)

  return (
    <StyledLicenseCountForm>
      {licensesLoaded &&
        rows.map((subjects, index) => (
          <SubjectRow key={index} length={subjects.length}>
            {subjects.map((s, i) => <SubjectCard key={i} {...s} />)}
          </SubjectRow>
        ))
      }
    </StyledLicenseCountForm>
  )
}


function licenseCountFormStyles(){
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1,
    padding: 0,
    backgroundColor: '#fff',
    color: '#4f576e',
    border: 'solid 1px #d9d9d9',
    borderRadius: 5,
    overflowY: 'auto',
    '& .header': {
      display: 'flex',
      justifyContent: 'center',
      flexShrink: 0,
      borderBottom: 'solid 1px #bdd0d7',
      padding: 15,
      fontSize: '14pt',
      fontWeight: 'bold'
    },
  }
}