import {styled, Grid, Button} from 'external/material'

const StyledTransferButton = styled(Grid)(transferButtonStyles)

export default function TransferButton (props) {
 return <StyledTransferButton item className="transfer-button"><Button variant="outlined" size="small" {...props}>{props.children}</Button></StyledTransferButton>
}

function transferButtonStyles(){
  return {
    "& .MuiButtonBase-root": {
      padding: 8,
      '&:hover': {
        transform: 'scale(1.02)'
      },
      "&.Mui-disabled": {
          opacity: 0.5
      },
    }
  }
}
