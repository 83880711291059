import moment from 'external/moment'
import create from 'utils/command'

const hooks =  [
    {paths: ['page/','periods/refreshes/'], resolves: [getPeriods]},
    {paths: ['settings/rollbook/', 'props/params/section'], resolves: [getPeriods, getPeriodTypes]},
]

export {hooks as default}

function guard(state) {
  const {props: {params}} = state
  const {pageName, section} = params
  if (pageName != 'settings') return true
  if (section != 'rollbook') return true

  return false
}

export async function getPeriods ({state, update}) {
  if (guard(state)) return
  update({periods: {$startLoading: true}})
  const periods = await create('grading', 'getPeriods')()
  const [firstPeriod = {}] = periods
  const {year = {}} = firstPeriod || {}
  const {start, finish, id} = year

  update({
    id: {$set: id},
    periods: {$updateLoadableList: periods},
    start: {$set: start},
    finish: {$set: finish},
  })
}

export async function getPeriodTypes({state, update}) {
  if (guard(state)) return
  const periodTypes = await create('grading', 'getPeriodTypes')()
  update({periodTypes: {$set: periodTypes}})
}