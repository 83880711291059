import TabBar from 'components/Common/TabBar'
import useAdmin from 'hooks/admin'

export default function SchedulecommentTabBar() {

  const {scheduleCommentForm: {activeTab}, update} = useAdmin()
  
  function handleTabButtonClick(tab) {
    if (activeTab != tab) {
      update({scheduleCommentForm: {activeTab: {$set: tab}}})
    }
  }

  return (
    <TabBar
      tabs={[
        {id: 'students', label: 'schedule-comment-tab-students-label', icon: 'faUserLarge'},
        {id: 'date', label: 'schedule-comment-tab-date-label', icon: 'faCalendarAlt'},
      ]}
      tabButtonActiveTab={activeTab}
      tabButtonOnClick={handleTabButtonClick} />
  )
}
