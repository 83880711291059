import moment from 'external/moment'
import { latinise } from "external/latinise"

export function isEmpty(str) {
  return (!str || 0 === str.length)
}

export const formatNumber = (function (formatter) {
  return formatter.format.bind(formatter)
}(new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 })))

export function capitalizeFirstLetter(str, capitalized = true) {
  if (!str) return '';

  const firstCP = str.codePointAt(0);
  const index = firstCP > 0xFFFF ? 2 : 1;

  return capitalized
    ? String.fromCodePoint(firstCP).toUpperCase() + str.slice(index)
    : String.fromCodePoint(firstCP).toLowerCase() + str.slice(index);
}

export function searchSubstring(text, sub) {
  const processedText = latinise(text.toLowerCase())
  const processedSub = latinise(sub.toLowerCase())
  return processedText.includes(processedSub)
}

export function sqlDate(date) {
  return moment(date).format()
}

export function getFileExtension(filename) {
  return filename.split('.').pop()
}
