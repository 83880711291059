import {useEffect, useMemo} from 'external/react'
import {styled, Dialog, DialogTitle, DialogContent, DialogActions, Button, clsx, CircularProgress} from 'external/material'

import useAdmin from 'hooks/admin'
import useTranslations from 'hooks/translations'

import Icon, { IconButton } from 'components/Common/Icon'
import ConfirmDialog from 'components/Common/ConfirmDialog'

import PreviewTable from './PreviewTable'
import PreviewSearchBar from './PreviewSearchBar'

const StyledPreviewImport = styled(Dialog)(PreviewImportStyles)

export default function PreviewImport(props) {
  const {open, handleClose, sendImport} = props
  const {update, importUsers, getPosibleUsernames} = useAdmin()
  const {importedUsers, usedCounter, openWarning, loadingFile} = importUsers
  const {translate, language} = useTranslations()

  const hasWarning = useMemo(() => usedCounter > 0, [usedCounter])

  const openWarningModal = () => {
    update({importUsers: {openWarning: {$set: true}}})
  }

  const closeWarningModal = () => {
    update({
      importUsers: {
        open: {$set: false},
        openWarning: {$set: false},
      }
    })
  }

  const confirmImport = (confirm) => {
    if (confirm) return openWarningModal()
    sendImport()
  }

  useEffect(() => {
    const checkUsedUsernames = async () => {
      const list = await getPosibleUsernames(importUsers)
      const countUsed = list.reduce((count, item) => {
        if (!item.firstMatch) {
          return count + 1
        }
        return count
      }, 0)
      update({importUsers: {usedCounter: {$set: countUsed}}})
    }
    checkUsedUsernames()
  }, [importedUsers])

  return (
    <StyledPreviewImport
      open={open}
      onClose={handleClose}
    >
      <DialogTitle className="title">
        <label>{translate('preview-users-title')}</label>
        <IconButton
          className="close-button"
          onClick={handleClose}
          iconName="faCircleXmark"
        />
      </DialogTitle>
      <DialogContent className="dialog-content">
        {
          loadingFile ? (
            <div className='loading'>
              <CircularProgress />
            </div>
          ) : (
            <>
              <PreviewSearchBar />
              <PreviewTable />
            </>
          )
        }
      </DialogContent>
      <DialogActions className="actions">
        <Button
          className={clsx('cancel')}
          onClick={handleClose}
        >
          {translate('cancel_label')}
        </Button>
        <Button
          className={clsx('save', {'warning': hasWarning})}
          onClick={() => confirmImport(hasWarning)}
        >
          {hasWarning ? <Icon name="faTriangleExclamation"/> : null}
          <span>{translate('import_label')}</span>
        </Button>
      </DialogActions>
      {hasWarning ? (
        <ConfirmDialog
          open={openWarning}
          handleClose={closeWarningModal}
          onConfirm={() => confirmImport(!hasWarning)}
          titleIcon={"faTriangleExclamation"}
          title={"Usuarios Duplicados"}
          contentText={
            language !== 'en'
            ? `Se han encontrado ${usedCounter} usuarios potencialmente duplicados. ¿Esta seguro de querer continuar?`
            : `${usedCounter} imported users are potencially duplicated. Are your sure to save it anyways?`
          }
          confirmText={translate('import-users-checkbox-text')}
          cancelLabel={translate('cancel_label')}
          confirmLabel={translate('import_label')}
        />
      ) : null}
    </StyledPreviewImport>
  )
}

function PreviewImportStyles() {
  return {
    '& .MuiDialog-paper': {
      height: 'calc(100% - 80px)',
      minWidth: 1280,
      maxWidth: 1520,
      overflow: 'hidden',
      '& .title': {
        textAlign: 'center',
        backgroundColor: '#2c7cbd',
        color: '#fff',
        '& .close-button': {
          position: 'absolute',
          right: 15,
          color: '#fff',
          width: 35,
          fontSize: '16pt',
          borderRadius: 100,
        }
      },
      '& .dialog-content': {
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
        '& .loading': {
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
      '& .actions': {
        borderTop: 'solid 1px #d2d2d2',
        "& button": {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 5,
          fontSize: '12pt',
          borderRadius: '4px',
          padding: '8px',
          width: '150px',
          cursor: 'pointer',
          "&.cancel": {
            backgroundColor: '#fff',
            color: '#687189',
            border: 'solid 1px #d2d2d2',
            marginRight: '15px',
            "&:hover": {
              backgroundColor: '#cccccc',
            }
          },
          "&.save": {
            backgroundColor: '#2c75bd',
            color: '#fff',
            border: 'none',
            "&:hover": {
              backgroundColor: '#2ca5ec',
            }
          },
          "&.warning": {
            backgroundColor: '#e0b104',
            color: '#fff',
            border: 'none',
            "&:hover": {
              backgroundColor: '#ebc334',
            }
          },
        },
      },
    },
  }
}