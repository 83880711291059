import {Typography} from 'external/material'
import useTranslations from 'hooks/translations'
import useProfile from 'hooks/profile'

export default function PageLanguage (props) {
  const {translate} = useTranslations()
  const {fullName} = useProfile()
  return <Typography variant="subtitle" component="div" {...props}>{`${translate('welcome')}, ${fullName}`}</Typography>
}

