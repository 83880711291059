import create from 'utils/command'
import {styled} from 'external/material'
import Icon from 'components/Common/Icon'

import useTranslations from 'hooks/translations'
import useAdmin from 'hooks/admin'

const StyledEditGroup = styled('div')(editGroupStyles)

export default function EditGroup(props) {

    const {group} = props
    const {translate} = useTranslations()
    const {update} = useAdmin()

    const contentText = translate('edit-group-text', group)

    async function handleOpen() {
        const [editableGroup] = await create('admin', 'getGroup')({id: group.id})
        const {Students, Teachers, Name, Course} = editableGroup
        update({
            editGroupForm: {
                open: {$set: true},
                group: {$set: editableGroup},
                groupName: {$set: Name},
                course: {$set: Course},
                selectedTeachers: {$set: Teachers},
                selectedStudents: {$set: Students},
            }
        })
    }
    
    return (
        <StyledEditGroup>
            <span onClick={handleOpen} title={contentText}><Icon name="faPenToSquare"/></span>
        </StyledEditGroup>
    )
    
}

function editGroupStyles() {
    return {
        color: '#2c75bd',
        cursor: 'pointer',
        fontSize: 18
    }
}