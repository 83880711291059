import {useEffect, useState} from 'external/react'
import {clsx, styled, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControlLabel, Checkbox, Button} from 'external/material'

import useTranslations from 'hooks/translations'
import Icon from 'external/font-awesome'

const StyledConfirmDialog = styled(Dialog)(confirmDialogStyles)

export default function ConfirmDialog(props) {

    const {className, open, handleClose, onConfirm, title, titleIcon, contentText, confirmText, cancelLabel, confirmLabel, withReason = false} = props

    const {translate} = useTranslations()
    const [confirmed, setConfirmed] = useState(false)
    const [reason, setReason] = useState('')
    
    useEffect(()=>{
        if (open == false)
            setConfirmed(false)
    }, [open])
    
    function handleConfirmCheck(e) {
      setConfirmed(e.target.checked)
    }

    function handleConfirmClick() {
        onConfirm(reason)
        handleClose()
    }
    function handleKeyDown(e) {
      switch (e.code) {
        case 'Enter':
          confirmed && onConfirm(reason);
          handleClose();
          return;
        case 'Space':
          setConfirmed(!confirmed);
          return;
        default: return;
      }
    }

    return (
        <StyledConfirmDialog open={open} onClose={handleClose} className={className} onKeyDown={handleKeyDown}>
          {title && <DialogTitle className="title">
            {titleIcon && <Icon name={titleIcon} />}
            {title}
            </DialogTitle>}
          <DialogContent>
            {contentText && (<DialogContentText>{contentText}</DialogContentText>)}
            <FormControlLabel control={<Checkbox checked={confirmed} onChange={handleConfirmCheck} />} label={confirmText} />
            <div className={clsx('reason', {withReason})}>
              <label>{translate('reason')}</label>
              <textarea disabled={!confirmed} value={reason} onChange={e => setReason(e.targetvalue)} maxLength={255} rows={3} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button className="cancel" onClick={handleClose}>{cancelLabel || translate('cancel')}</Button>
            <Button className="confirm" disabled={!confirmed} onClick={handleConfirmClick}>{confirmLabel || translate('confirm')}</Button>
          </DialogActions>
        </StyledConfirmDialog>
  )
}

function confirmDialogStyles() {
    return {
      '& .MuiDialogContent-root': {
        display: 'flex',
        flexDirection: 'column',
        '& .reason':{
          display: 'none',
          '&.withReason': {
            display: 'flex',
            flexDirection: 'column',
            '& textarea': {
              flexGrow: 1,
              resize: 'none'
            }
          }
        }
      },
      '& .title': {
        backgroundColor: '#2c7cbd',
        color: '#fff',
        marginBottom: '8px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
      },
      "& button": {
        fontSize: '12pt',
        borderRadius: '4px',
        padding: '8px',
        "&.cancel": {
          backgroundColor: '#fff',
          color: '#687189',
          border: 'solid 1px #d2d2d2',
          marginRight: '15px',
          "&:hover": {
            backgroundColor: '#cccccc',
          }
        },
        "&.confirm": {
          backgroundColor: '#2c75bd',
          color: '#fff',
          border: 'none',
          "&:disabled": {
            backgroundColor: '#cccccc',
          },
          "&:hover": {
            backgroundColor: '#2ca5ec',
          }
        },
      }
    }
}