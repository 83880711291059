import { useMemo } from 'external/react'
import { Button, styled } from 'external/material'

import useAdmin from 'hooks/admin'
import useProfile from 'hooks/profile'
import useTranslations from 'hooks/translations'

import Icon from 'components/Common/Icon'

const StyledTogleDownloadUsersButton = styled(Button)(toogleDownloadUsersButtons)

export default function DownloadAllUsersButton(props) {

  const { handleDownloadClick } = props
  const { downloadUsers: { users }} = useAdmin()
  const { translate } = useTranslations()
  const { institution: {name} } = useProfile()

  const isDisabled = useMemo(() => {
    if (users.length < 1) return true
  }, [users])

  return (
    <StyledTogleDownloadUsersButton
      disabled={isDisabled}
      color="primary"
      onClick={() => handleDownloadClick(name, users, translate)}
    >
      <span className="label">{translate('download-all-users-button')}</span>
      <span className="icon"><Icon name='faUsers' /></span>
    </StyledTogleDownloadUsersButton>
  )
}

function toogleDownloadUsersButtons() {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,
    color: '#fff',
    backgroundColor: '#2c75bd',
    "&:disabled": {
      backgroundColor: '#cccccc',
    },
    "&:hover": {
      backgroundColor: '#2ca5ec',
    },
    "& .button": {
      padding: "5px 15px",
      "& .label": {
        textTransform: 'capitalize',
        marginRight: 8,
        fontSize: '12pt'
      },
      "& .icon": {
        fontSize: '18px'
      }
    },
  }
}
