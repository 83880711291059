import {styled, clsx} from 'external/material'
import useSession from 'hooks/session'
import useTranslations from 'hooks/translations'

const StyledFilterButton = styled('div')(buttonStyles)

export default function FilterButton ({id}) {

    const {translate} = useTranslations()
    const {section = 'scheduled', navigation} = useSession()
    const selected = section == id
    return (
      <StyledFilterButton className={clsx({selected})} onClick={(e) => !selected && navigation.messages.go({section:id})}>
        {translate(`${id}-filter-button-label`)}
      </StyledFilterButton>
    )
}

function buttonStyles() {
    return {
        cursor: 'pointer',
        textDecoration: 'none',
        color: '#565f77',
        display: 'inline-block',
        width: '200px',
        textAlign: 'center',
        padding: '8px 0',
        borderRadius: '5px',
        backgroundColor: 'white',
        marginLeft: 25,
        "&:hover": {
            transform: 'scale(1.03)',
            fontWeight: 'bold'
        },
        "&.selected": {
            fontWeight: 'bold',
            color: 'white',
            backgroundColor: '#2ca5ec',
            "&:hover": {
                transform: 'none'
            }
        },
        "&:last-child": {
            marginLeft: '10px'
        }
    }
}
