import create from 'utils/command'
import {reduceByKey, accumulateByKey} from 'utils/functions' 
import { paramsHook } from 'utils/hook'

const hooks =  [
  {paths: paramsHook('pageName', '?languageId', 'languageId', 'section', 'course'), resolves: [getCatalogSubjects]},
  {paths: paramsHook('pageName', '?languageId', 'languageId', 'section', 'course'), resolves: [getCatalogCourses]},
  {paths: paramsHook('pageName', 'course'), resolves: [getCatalogueUnits]},
  {paths: paramsHook('course', 'unit'), resolves: [getCatalogueUnitLessons]},
  {paths: paramsHook('?subject', '?grade', '?languageId', 'pageName', '?orderBy', '?page', '?pattern').concat(['refreshData', 'catalogueSearch/refreshes', 'catalogueSearchPageSize/']), resolves: [catalogueSearch]},
]

export {hooks as default}

export async function  fetchSubjectsAndGrades({state, update}) {
  const shouldFetch = (state.subjects == undefined) || (state.grades == undefined)
  if (shouldFetch) {
    const [subjects, grades] = await Promise.all([
      create('public', 'subjects')().then(reduceByKey('id')),
      create('catalog', 'grades')().then(reduceByKey('id'))
    ])
    update({subjects: {$set: subjects}, grades: {$set: grades}})
  }
}

export async function getCatalogSubjects({state, update}) {
  const {props: {params, query}} = state
  const {pageName, section} = params
  if (pageName != 'catalogue' || !!section) return
  update({catalogSubjects: {$startLoading: true}})
  const catalogSubjects = await create('catalog', 'subjects')({language: query.languageId || params.languageId})
  update({catalogSubjects: {$updateLoadableList: catalogSubjects}})
}

export async function getCatalogCourses({state, update}) {
  const {props: {params, query}} = state
  const {pageName, section} = params
  if (pageName != 'catalogue' || !section || section == 'search') return
  update({catalogCourses: {$startLoading: true}})
  const catalogCourses = await create('catalog', 'courses')({subject: section, language: query.languageId || params.languageId})
  update({catalogCourses: {$updateLoadableList: catalogCourses}})
}



export async function getCatalogueUnits({state, update}){
  const {course, pageName} = state.props.params
  if (pageName != 'catalogue') return 
  if (!course) return
  const [unitsCourse, ...units] = await create('admin', 'units')({course})
  update({units: {$set: units}, unitsCourse: {$set: unitsCourse}})
}

export  async function getCatalogueUnitLessons({state, update}, opt) {
  const {course, unit, pageName} = state.props.params
  if (pageName != 'catalogue' || !course || !unit) return
  if (state.unitLessons && state.unitLessons[course] && state.unitLessons[course][unit]) return
  const [{lessons, content}] = await create('admin', 'unitLessons')({course, unit})
  const unitLessons = {course, lessons, content: accumulateByKey('id', true)(content)}
  update({unitLessons: {$auto: {[unit]: {$set: unitLessons}}}})
}


export async function catalogueSearch({state, update}, {path}) {
  const {catalogueSearchPageSize: pageSize, props: {query, params}} = state
  const {pageName, section} = params
  if (pageName != 'catalogue') return
  if (section != 'search') return
  // PageSize validation
  if (pageSize == 0) return
  if (!path || !path.startsWith('props/query'))
    update({catalogueSearch: {$initLoading: true}}) // Clear content unless is updating a query parameter
  else
    update({catalogueSearch: {$startLoading: true}})
  const searchResults = await create('catalog', 'search')({pageSize, ...query})
  update({catalogueSearch: {$updateLoadableList: searchResults}})
}