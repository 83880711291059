import {Link} from 'external/react'
import {styled, clsx} from 'external/material'
import Icon from 'components/Common/Icon'
import Label from 'components/Common/Label'
import { centerVertically } from 'utils/style'
import { useMemo } from 'external/react'

import useSession from 'hooks/session'
import useTranslations from 'hooks/translations'
import useAdmin from 'hooks/admin'

const StyledStudent = styled('div')(studentStyles)

export const paddingWidth = 58
export const itemWidth = 300
export const itemHeight = 110

export default function Student(props) {

  const {id: studentId, passport, name} = props
  const {translate} = useTranslations()
  const {navigation} = useSession()
  const {studentDownloads, studentRecords} = useAdmin() 
  
  const {downloads = 0, groups = []} = useMemo(() => {
    const studentRecord = studentRecords[passport] || {}
    const {groups: studentGroups = {}} = studentRecord
    const groups = Object.values(studentGroups).map(g => g.groupName)
    const downloadRecord = studentDownloads[studentId] || {}
    const {onlineViews = 0, downloadCount = 0} = downloadRecord
    const downloads = onlineViews + downloadCount
    return {groups, downloads}

  }, [passport, studentRecords, studentDownloads])

  return (
    <StyledStudent >
      <Link to={navigation.monitoringStudent.linkWithQuery(null, false, {studentId: passport})} className='student-link'>
        <div className="icon-wrapper">
          <div className="icon">
            <Icon name="faUserGraduate" />
          </div>
        </div>
        <div className="label">
          <div className="name">
            <Label value={name || ''} maxLength={25} />
          </div>
          <div className={clsx('count')}>
            {downloads}{" "}{translate(`download${downloads == 1 ? '' : 's'}_label`)}
          </div>
          <div className={clsx('groups', {hidden: groups.length == 0})}>
            <div className="groups-label">{translate('groups')}:</div>
            <Label value={groups.join(', ')} className="groups-string" maxLength={25} />
          </div>
        </div>
      </Link>
    </StyledStudent>
  )
}

function studentStyles() {
    return {
    borderRadius: 8,
    border: 'solid 1px #ced3e0',
    height: itemHeight,
    width: itemWidth,
    padding: '10px 20px',
    marginBottom: '20px',
    display: 'flex',
    "&:hover": {
      '& .label': {
        textDecoration: 'underline'
      }
    },
    "& .student-link": {
      cursor: 'pointer',
      flexGrow: 1,
      display: 'flex',
      textDecoration: 'none',
      "& .icon-wrapper": {
        ...centerVertically,
        backgroundColor: '#fff',
        border: '1px solid #d8e8fb',
        borderRadius: '50%',
        height: 56,
        width: 56,
        padding: 4,
        "& .icon": {
          borderRadius: '50%',
          padding: '10px 5px',
          fontSize: '18pt',
          display: 'flex',
          backgroundColor: '#e6f2ff',
          justifyContent: 'center',
          color: '#c54ebf'
        },
      },
      "& .label": {
        color: '#4e576e',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: 25,
        "& .name": {
          fontWeight: 'bold',
          fontSize: '11pt'
        },
        "& .groups": {
          paddingTop: 1,
          fontSize: '9.5pt'
        },
        "& .count": {
          paddingTop: 1,
          textTransform: 'lowercase',
          fontSize: '9.5pt'
        }

      },
      '& .has-no-students': {
        visibility: 'hidden'
      }
    }
    }
  }