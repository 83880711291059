import {useCallback, useState} from 'external/react'
import {styled} from 'external/material'

import useAdmin from 'hooks/admin'
import useTranslations from 'hooks/translations'

import Icon from 'components/Common/Icon'

const StyledSearchGroups = styled('div')(searchGroupsStyle)

export default function SearchGroups() {
  const [searchInput, setSearchInput] = useState('')
  const {update, downloadUsers} = useAdmin()
  const {translate} = useTranslations()

  const handleSearch = useCallback(() => {
    const {groups} = downloadUsers.options
    const filter = groups.filter((group) => group.label.toLowerCase().includes(searchInput.toLowerCase()))
    update({
      downloadUsers: {
        searchGroups: {$set: filter},
        isSearching: {$set: searchInput !== ''}
      }
    })
  }, [downloadUsers.options, searchInput])

  return (
    <StyledSearchGroups>
      <div className="wrapper">
        <Icon name="faSearch" />
        <input
          value={searchInput}
          placeholder={translate('search-groups-placeholder') || ''}
          onChange={e => setSearchInput(e.target.value)}
          onBlur={() => handleSearch()}
          onKeyDown={({key}) => key == 'Enter' && handleSearch()}
        /> 
        <span className="button" onClick={handleSearch}>
          {translate("go-label")}
        </span>
      </div>
    </StyledSearchGroups>
  )
}

function searchGroupsStyle() {
  return {
    "& .wrapper": {
      display: 'flex',
      backgroundColor: 'white',
      border: 'solid 1px #bdbdbd',
      borderRadius: '5px',
      "& svg": {
        padding: '12px 6px 8px 10px'
      },
      "& input": {
        padding: '8px 0',
        flexGrow: 1,
        paddingLeft: 5,
        color: '#565f77',
        fontSize: '12pt',
        border: 'none',
        "&:focus": {
          outline: 'none'
        }
      },
      "& .button": {
        flexShrink: 0,
        padding: '8px 0',
        width: '30px',
        textAlign: 'center',
        borderLeft: 'solid 1px #f4f4f4',
        cursor: 'pointer',
        backgroundColor: 'white',
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
        fontSize: '11pt',
        "&:hover": {
          fontWeight: 'bold'
        }
      }
    }
  }
}