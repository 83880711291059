import { FormControl, FormHelperText, TextField} from 'external/material'
import { filterByPrefix } from 'utils/functions'
import {isEmpty} from 'utils/text'
import useTranslations from 'hooks/translations'

export default function TextInput(props) {
  
  const {id, validationResults} = props
  const controlProps = filterByPrefix('control', props).filtered
  const inputProps = filterByPrefix('input', props).filtered
  const errorProps = filterByPrefix('error', props).filtered
  const {translate} = useTranslations()

  const error = !isEmpty(validationResults)

  return (
    <FormControl {...controlProps} error={error} >
      <TextField
        id={id}
        error={error}
        aria-describedby={validationResults}
        size='small'
        {...inputProps}
      />
      <FormHelperText
        id={validationResults}
        {...errorProps}
        sx={{ visibility: error ? 'visible' : 'hidden' }}
      >
        {translate(validationResults)}
      </FormHelperText>
    </FormControl>
  )
}
