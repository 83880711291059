import create, {initializeAndRegisterHooks} from 'context/create' 
import hooks, * as resolves from './hooks'

const initialState = {
  info: undefined,
  refreshProfile: 0
}

const id = 'Profile'

const context = create(id, initialState, {onInit: initializeAndRegisterHooks(id, hooks, resolves)})

export {context as default}
