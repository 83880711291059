import { useMemo } from 'external/react'
import {styled, clsx} from 'external/material'
import ResourceTable from './ResourceTable'
import useProfile from 'hooks/profile'
import useMenu from 'hooks/menu'
import BackButton from 'components/Common/BackButton'
import ScheduleSpecialistMeeting from './ScheduleSpecialistMeeting'

const StyledHelp = styled('div')(helpStyles)

export default function Help () {
    const {project, settings, domainName} = useProfile()
    const isDreyfous = useMemo (() => domainName == 'dreyfous', [domainName])
    const {helpManualsByProject = {}, toolsByProject = {}} = settings
    const {displayingScheduleMeeting} = useMenu()
    const manual = helpManualsByProject[project] || helpManualsByProject['edusystem']
    const tools = toolsByProject[project] || toolsByProject['edusystem']
    
    return (
    <StyledHelp className={clsx({displayingMeeting: displayingScheduleMeeting})}>
      <div className="topbar">
        <BackButton track="help" />
      </div>
      <div className="resource-tables">
        <ResourceTable kind="tools" resources={tools || []} />
        <ResourceTable kind="manuals" resources={manual || []} />
      </div>
      {isDreyfous && <ScheduleSpecialistMeeting />}
    </StyledHelp>)
}


function helpStyles() {
  return {
    display: 'flex',
    padding: 40,
    flexDirection: 'column',
    flexGrow: 1,
    '& .topbar': {
      marginBottom: 20
    },
    '&.displayingMeeting': {
      '& .resource-tables': {
        display: 'none',
      }
    }
  }
}