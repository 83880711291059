import {clsx, styled} from 'external/material'
import Icon from "components/Common/Icon"
import useTranslations from "hooks/translations"

const StyledTabButton = styled('div')(tabButtonStyles)

export default function TabButton(props) {
    
  const {id, activeTab, label, icon, onClick, checkErrors} = props

  const {translate} = useTranslations()

  const active = activeTab == id
  const selectable = !active

  const className = clsx(`${id}-button`, {active, selectable})
  
  const error = checkErrors && checkErrors(id)

  return (
      <StyledTabButton className={clsx(className, {error})} onClick={() => onClick(id)}>
        <Icon name={icon} />
        {translate(label)}
      </StyledTabButton>
  )

}

function tabButtonStyles({theme}) {
  return {
    cursor: 'pointer',
    borderRight: 'solid 1px #dce1ec',
    padding: '12px 20px',
    minWidth: 200,
    color: '#626d88',
    display: 'flex',
    alignItems: 'center',
    "&.error": {
      color: '#d32f2f'
    },
    "&.active": {
      fontWeight: 'bold',
      borderBottom: 'solid 3px #48c0ff'
    },
    "&.selectable:hover": {
      color: theme.palette.primary.main,
      "& svg": {
        filter: 'brightness(100%)'
      }
    },
    "& svg": {
      filter: 'brightness(80%)',
      fontSize: '20pt',
      marginRight: '10px',
      color: theme.palette.primary.main,
    }
}
}