import {
   styleAndCapture
  ,clsx
  ,TableCell 
  ,TableHead 
  ,TableRow 
  ,TableSortLabel 
} from 'external/material'

import {useSearchParams} from 'external/react'
import useTranslations from 'hooks/translations'
import SortLink from './SortLink' 

const StyledTableHead = styleAndCapture(TableHead, 'headings')(tableHeadStyles)

export default function makeHeadComponent(headCells) {
  return function HeadWithSort() {
    const [search] = useSearchParams()
    const searchOrder = search.get('orderBy') || false
    const {translate} = useTranslations()
    const order = searchOrder ? (searchOrder.startsWith('-') ? 'desc' : 'asc') : undefined
    const orderBy = searchOrder ? (order == 'asc' ? searchOrder : searchOrder.substring(1)) : undefined
    return (
      <StyledTableHead headings={headCells}>
        <TableRow className="headersRow">
            {headCells.map((cell) => {
              const {id, align, disablePadding, dummy, useSorting = true} = cell
              if (dummy) {
                return (
                  <TableCell key={id} align={align} className={id} padding={disablePadding ? 'none' : 'normal'} />
                )
              }
              const label = cell.label || id

              return (<TableCell
                key={id}
                align = {align}
                className={id}
                padding={disablePadding ? 'none' : 'normal'}
                sortDirection={order}
              >
                {useSorting ? (
                  <TableSortLabel
                    className={clsx("headerLabel", id)}
                    property={id}
                    order={order}
                    orderBy={orderBy}
                    active={orderBy === id}
                    direction={order}
                    component={SortLink}
                  >
                    {translate(`${label}-header`)}
                    {orderBy === id ? (
                      <span className="visuallyHidden">
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  <span className={clsx("headerLabel", id)}>
                    {translate(`${label}-header`)}
                  </span>
                )}
                
              </TableCell>)
            })}
        </TableRow>
      </StyledTableHead>
    )
  }
}

function tableHeadStyles ({headings}) {
  const centeredSortingOffset = headings.filter(h => h.align == 'center').reduce((offsets, h) => ({
    ...offsets, [`& th.${h.id} a`]: {
      marginLeft: 35
    }}), {})
  return {
    ...centeredSortingOffset,
    "& .headersRow": {
      color: '#50566e',
      '& > th': {
        backgroundColor: '#ffffff',
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5
      }
    },
    "& .headerLabel": {
      fontWeight: 500,
      color: '#9ea5b9',
    },
    "& .visuallyHidden": {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }
}