import {SvgKeepRatio} from 'utils/style'

export default function LockIcon(props) {

  const fill = props.fill || '#075fc9'
  const backgroundColor = props.backgroundColor || '#ededed'

  return (<SvgKeepRatio viewBox={{width: 66, height: 66}} {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="vv" transform="translate(-1, -1)">
            <g id="Group" transform="translate(1, 1)">
                <circle id="Oval" stroke="#D1D1D1" fill={backgroundColor} cx="33" cy="33" r="32.5"></circle>
                <g id="lock" transform="translate(19.7247, 17.1549)" fill={fill} fillRule="nonzero">
                    <path d="M4.6875,11.25 L4.6875,8.4375 C4.6875,3.77753913 8.46679688,0 13.125,0 C17.7832031,0 21.5625,3.77753913 21.5625,8.4375 L21.5625,11.25 L22.5,11.25 C24.5683587,11.25 26.25,12.9316404 26.25,15 L26.25,26.25 C26.25,28.3183587 24.5683587,30 22.5,30 L3.75,30 C1.67871058,30 0,28.3183587 0,26.25 L0,15 C0,12.9316404 1.67871058,11.25 3.75,11.25 L4.6875,11.25 Z M8.4375,11.25 L17.8125,11.25 L17.8125,8.4375 C17.8125,5.84882855 15.7148445,3.75 13.125,3.75 C10.5351564,3.75 8.4375,5.84882855 8.4375,8.4375 L8.4375,11.25 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </g>
    </SvgKeepRatio>
  )
}