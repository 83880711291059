import {styled} from 'external/material'

const StyledSubjectRow = styled('div')(subjectRowStyles)

export default function SubjectRow ({children, length}) {
  return (
    <StyledSubjectRow className="subject-row">
      {children}
      { length < 3 && <div className="subject filler" />}
      { length < 2 && <div className="subject filler" />}
    </StyledSubjectRow>
  )
}

function subjectRowStyles() {
    return {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: 50,
        '&:first-of-type': {
            marginTop: 30
        },
        '&:last-child': {
            paddingBottom: 20
        }
    }
}
