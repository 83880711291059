import {SvgKeepRatio} from 'utils/style'

export default function CatalogueIcon(props) {
  const fill= props.fill || "#fff"
  return (
    <SvgKeepRatio viewBox={{width: 30, height: 36}}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect stroke={fill} x="4.5" y="10.5" width="8" height="9"></rect>
          <polygon fill={fill} fillRule="nonzero" points="4.5 1.5 28.5 1.5 28.5 32.5 26.9295804 32.5 26.9295804 33.5 29.5 33.5 29.5 0.5 3.5 0.5 3.5 2.95817736 4.5 2.95817736"></polygon>
          <rect stroke={fill} x="0.5" y="5.5" width="24" height="30"></rect>
          <rect fill={fill} x="15" y="10" width="6" height="1"></rect>
          <rect fill={fill} x="15" y="13" width="6" height="1"></rect>
          <rect fill={fill} x="15" y="16" width="6" height="1"></rect>
          <rect fill={fill} x="15" y="19" width="6" height="1"></rect>
          <rect fill={fill} x="4" y="22" width="17" height="1"></rect>
          <rect fill={fill} x="4" y="25" width="17" height="1"></rect>
          <rect fill={fill} x="4" y="28" width="9" height="1"></rect>
      </g>
    </SvgKeepRatio>
  )
}