import {styled} from 'external/material'

import DashboardActivity from './DashboardActivity'
import DashboardButtons from './DashboardButtons'

const StyledDashboard = styled('div')(dashboardStyles)


export default function DashboardPage() {
    
    return <StyledDashboard>
        <DashboardActivity className="activity" />
        <DashboardButtons  className="buttons" />
    </StyledDashboard>
}

function dashboardStyles () {
    return {
        display: 'flex',
        padding: 40,
        flexGrow: 1,
        overflowY: 'hidden',
        flexDirection: 'column',
        '& .buttons': {
            marginTop: 40 
        }
    }
}
