import { omit } from 'lodash'
import {styled, clsx} from 'external/material'

import FilterChip from 'components/Common/FilterChip'

import useTranslations from 'hooks/translations'
import SearchPattern from 'components/Common/SearchPattern'

import { centerVertically } from 'utils/style'
import BackButton from 'components/Common/BackButton'
import useSession from 'hooks/session'
import useCatalog from 'hooks/catalog'
import SubjectFilter from 'components/Common/Filters/SubjectFilter'
import GradeFilter from 'components/Common/Filters/GradeFilter'
import LanguageFilter from 'components/Common/Filters/LanguageFilter'

const StyledFilterBar = styled('div')(filterBarStyles)

export default function FilterBar(props) {
    
    const {className} = props
    const {navigation} = useSession()
    const {catalogueSearch, catalogueSearchPageCount} = useCatalog()
    const {translate, languages} = useTranslations()
    const hasResults = catalogueSearch.length > 0
    
    function removePage(query) {
        return omit(query, 'page')
    }

    return <StyledFilterBar className={className}>
        <div className={clsx('top', 'wrapper')}>
           <BackButton track="catalogue-lessons" to={navigation.page.link({pageName: 'catalogue'})} />
           <div className={clsx('wrapper', 'results')}>
            <div className="label">{translate('catalogue-search-results')}{hasResults && ':'}</div>
            {hasResults && (<div className="count">{catalogueSearchPageCount.recordCount}</div>)}
            {hasResults && (
                <div className="lesson">{translate(catalogueSearch.length == '1' ? 'lesson' : 'lessons')}</div>
            )}
           </div>
            <div className={clsx('search', 'wrapper')}>
                <div className="container">
                    <SearchPattern preserveQuery={true} interceptQuery={removePage} />
                </div>
            </div>
        </div>
        <div className={clsx('bottom', 'wrapper')}>
          <SubjectFilter preserveQuery={true} className={clsx('filter', 'subject')} interceptQuery={removePage} />
          <GradeFilter preserveQuery={true} className={clsx('filter', 'grade')} interceptQuery={removePage}/>
          <LanguageFilter preserveQuery={true} className={clsx('filter', 'language')} interceptQuery={removePage} />
          <div className="filler" />
          <FilterChip name="pattern" />
        </div>
    </StyledFilterBar>
}

function filterBarStyles() {
    return {
        display: 'flex',
        flexDirection: 'column',
        color: '#4e576e',
        '& > div': {
        },
        '& .wrapper': {
            display: 'flex',
        },
        '& .results': {
            flexGrow: 1,
            paddingLeft: 10,
            fontSize: '18pt',
            color: '#535562',
            '& .count': {
                textTransform: 'lowercase',
                fontSize: '18pt',
                marginLeft: 15,
                ...centerVertically
            },
            '& .lesson': {
                marginLeft: 5,
                fontSize: '13pt',
                display: 'flex',
                ...centerVertically
            },
            '& .label': {
                ...centerVertically
            }
        },
        '& span.container': {
            fontSize: '17pt',
            color: '#2c75bd',
            marginRight: 20
        },
        '& .container': {
            ...centerVertically,
        },
        '& .top': {
            flexGrow: 1,
            flexWrap: 'wrap',
            gap: 10
        },
        '& .bottom': {
            '& .filter': {
                marginLeft: 20,
                '&.subject': {
                    width: 175
                },
                '&.grade': {
                    width: 130
                },
                '&.language': {
                    width: 140
                },
                '&:first-of-type': {
                    marginLeft: 5 
                }
            },
            '& .filler': {
                flexGrow: 1
            }
        }
        
    }
}