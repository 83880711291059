import {SvgKeepRatio} from 'utils/style'

export default function SideMenuOpenIcon(props) {
  const fill = props.fill || "#979797"
  return (<SvgKeepRatio viewBox={{width: 60, height: 53}} {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill={fill} fillRule="nonzero">
        <path d="M10,53 C4.4771525,53 0,48.5228475 0,43 L0,10 C0,4.4771525 4.4771525,0 10,0 L50,0 C55.5228475,0 60,4.4771525 60,10 L60,43 C60,48.4292399 55.6733292,52.8479317 50.2799048,52.9961582 L50,53 L10,53 Z M30,5 L10,5 C7.23857625,5 5,7.23857625 5,10 L5,43 C5,45.7614237 7.23857625,48 10,48 L30,48 L30,5 Z M52,30 L37,30 L37,34 L52,34 L52,30 Z M52,19 L37,19 L37,23 L52,23 L52,19 Z M52,8 L37,8 L37,12 L52,12 L52,8 Z" />
        <g transform="translate(13.874999, 18.811874)">
          <path d="M2.66601667,0.459609707 L8.91601667,6.66566469 C9.22363326,7.01234468 9.37500075,7.41273515 9.37500075,7.76918062 C9.37500075,8.12562682 9.22241285,8.56898554 8.91723707,8.87416132 L2.66723707,15.0802163 C2.22016647,15.5272869 1.54858381,15.6612707 0.96411109,15.4192296 C0.379638374,15.1771886 0,14.6930081 0,14.0191806 L0,1.56312563 C0,0.931289573 0.379931927,0.360977431 0.964356214,0.118788858 C1.5487805,-0.123398225 2.22168118,0.0118566353 2.66601741,0.459609707 L2.66601667,0.459609707 Z" />
        </g>
        </g>
    </g>
  </SvgKeepRatio>)
}