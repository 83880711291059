import {contentHost, apiDomain, staticAssetsDomain, inViewer} from 'settings'

export function parentDomain (content, options = {}) {

  if (process.env.NODE_ENV == 'development') {
    if (options.devOverride) return options.devOverride
    else return content ? staticAssetsDomain : apiDomain
  }

  const domains = location.hostname.split('.')
  return domains.length > 2
    ? domains.slice(1).join('.')
    : location.hostname
}


export function subdomainResource (subdomain, ...resource) {
  const domain = `${subdomain}.${parentDomain()}`
  return resource.length == 0 ? domain :`${domain}/${resource.join('/')}`
}

export function pdfPreviewLink (subdomain, ...resource) {
  const parent = parentDomain(false, {devOverride: 'edufile.net'})
  const domain = `${subdomain}.${parent}`
  const pdfUrl = resource.length == 0 ? domain :`${domain}/${resource.join('/')}`
  return `//download.${parent}/pdfjs/web/viewer.html?file=//${pdfUrl}`
}


export function subdomainLink (subdomain, ...path) {
  return `//${subdomainResource(subdomain,...path)}`
}

export function staticAssetLink (...path) {
  return `//${staticAssetsDomain}/${path.join('/')}`
}

export function viewerGuard ({target, href}) {
  return inViewer
    ? {target: '_self', href: `${href}__blank`}
    : {target, href}
}

export function contentLink (passport, ...resource) {
  return `//${contentHost}.${apiDomain}/${passport}/${resource.map(r => r.replace(/^\//,'')).join('/')}`
}

export function domainLogoLink (domainName) {

  let host = 'download'
  let domain = apiDomain
  if (['production', 'staging'].indexOf(process.env.NODE_ENV) < 0) {
     domain = 'edufile.net'
     host = 'download'
  }
  return `//${host}.${domain}/${domainName}/settings/logo.png`

}