import {clsx, styled} from 'external/material'
import Icon from "components/Common/Icon"
import useTranslations from "hooks/translations"

import useAdmin from 'hooks/admin'

const StyledTabBar = styled('div')(addCommentTabBarStyles)

export default function TabBar(props) {

  const {editScheduleCommentForm: {activeTab}, update} = useAdmin()

  const setActiveTab = activeTab => update({editScheduleCommentForm: {activeTab: {$set: activeTab}}})
  const onClick = tab => () => (activeTab != tab) && setActiveTab(tab)

  const {translate} = useTranslations()

  return (
    <StyledTabBar {...props}>
      <div className={clsx('students-button', {active: activeTab == 'students', selectable: activeTab != 'students'})} onClick={onClick('students')}>
        <Icon name="faUserLarge" />
        {translate('schedule-comment-tab-students-label')}
      </div>
      <div className={clsx('date-button', {active: activeTab ==  'date', selectable: activeTab != 'date'})} onClick={onClick('date')}>
        <Icon name="faCalendarAlt" />
        {translate('schedule-comment-tab-date-label')}
      </div>
    </StyledTabBar>
  )
}

function addCommentTabBarStyles ({theme}) {
  return {
      display: 'flex',
      borderBottom: 'solid 1px #dce1ec',
      "& div": {
        cursor: 'pointer',
        borderRight: 'solid 1px #dce1ec',
        padding: '12px 20px',
        minWidth: 200,
        color: '#626d88',
        "&.active": {
          fontWeight: 'bold',
          borderBottom: 'solid 3px #48c0ff'
        },
        "&.selectable:hover": {
          color: theme.palette.primary.main,
          "& svg": {
            filter: 'brightness(100%)'
          }
        },
        "& svg": {
          filter: 'brightness(80%)',
          fontSize: '20pt',
          marginRight: '10px',
          color: theme.palette.primary.main,
        }
      }
    }
}