import FilterButton from './FilterButton'
import SearchPattern from 'components/Common/SearchPattern'
import { BackToDashboard } from 'components/Common/BackButton'

export default function Topbar (props) {
  return (
    <div className={props.className}>
      <BackToDashboard />
      <FilterButton id='scheduled' />
      <FilterButton id='deleted' />
      <div style={{flexGrow: 1}} />
      <div className="search-container">
          <SearchPattern />
      </div>
    </div>
  )
}