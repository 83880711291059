import create from 'utils/command'

const hooks =  [
    {paths: ['refreshProfile/'], resolves:[refreshProfile]}
]

export {hooks as default}

export async function refreshProfile({update}) {
  const [info] = await create('public', 'myinfo')()
  update({info: {$set: info}})
}
