import moment from 'external/moment'
import {range} from 'external/lodash'
import {useRef} from 'external/react'
import {styled} from 'external/material'
import Header from './Header'
import Week from './Week'

import useDimensions from 'hooks/dimensions'
import useQueryDate from 'hooks/query-date'

const StyledWeek = styled('div')(weekStyles)

export default function WeekView () {

  const ref = useRef()
  const [date] = useQueryDate()
  const start = date().startOf('week')
  const {width} = useDimensions(ref)
  const dayWidth = Math.round((width - 12) / 7)
  const daySize = {
    width: dayWidth, maxWidth: dayWidth, minWidth: dayWidth
  }


  const headers = range(7)
    .map(d => ({date: moment(start).add(d, 'days'), daySize}))
    .map((props,i) => (<Header key={i} {...props}/>))

  return (<StyledWeek className="week-view" ref={ref}>
        <div className="headers">
          {headers}
        </div>
        <div className="weeks">
          <Week start={start} daySize={daySize} />
        </div>
  </StyledWeek>)
}

function weekStyles () {
  return {
    "&.week-view": {
        overflow: 'hidden',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        color: '#69738e',
        backgroundColor: '#fff',
        "& .headers": {
          display: 'flex',
          flexShrink: 0,
          fontSize: '10pt',
          color: '#69738e',
          borderTop: 'solid 1px #e1eaee',
          borderBottom: 'solid 1px #e1eaee'
        },
        "& .weeks": {
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          overflowY: 'auto'
        }
    }
  }
}
