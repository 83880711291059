import {Button, styled} from 'external/material'
import {Papa} from 'external/papaparse'
import Icon from 'external/font-awesome'
import {XLSX} from 'external/sheetjs'
import {uniq} from 'external/lodash'

import useAdmin from 'hooks/admin'
import useTranslations from 'hooks/translations'

import {getFileExtension} from 'utils/text'

import AlertDialog from 'components/Common/AlertDialog'
import PreviewImport from './PreviewImport'

const StyledImportUsers = styled('div')(ImportUsersStyles)

export default function ImportUsers(props) {
  const {update, importUsers, importUsersFile} = useAdmin()
  const {open, importedUsers, successedImport} = importUsers
  const {translate} = useTranslations()

  const handleOpen = () => {
    update({importUsers: {open: {$set: true}}})
  }

  const handleClose = () => {
    update({importUsers: {open: {$set: false}}})
  }

  const handleFileUpload = async (event) => {
    if (!event.target.files) return
    handleOpen()
    update({importUsers: {loadingFile: {$set: true}}})
    const file = event.target.files[0]
    const extension = getFileExtension(file.name)

    extension !== 'xlsx' ? importCsvUsers(file) : await importXlsxUsers(file)

    update({importUsers: {loadingFile: {$set: false}}})
  }

  const clearInput = (event) => {
    if (!event.target.files) return
    event.target.value = null
  }

  const sendImport = async () => {
    const response = await importUsersFile(importUsers)
    update({importUsers: {importedUsers: {$set: []}, successedImport: {$set: !!response}}})
  }

  const importCsvUsers = (file) => {
    Papa.parse(file, {
      worker: true,
      header: false,
      complete: ({data: users}) => {
        users.shift() // Remove header row
        users.pop() // Papaparse push a non-valid value so we pop it
        users = users.map(([grade, category, firstName, middleName, lastName, maidenName, role, email]) => ({
          grade: grade,
          category: category,
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
          maiden_name: maidenName,
          role: role,
          email: email,
        }))
        update({importUsers: {importedUsers: {$set: [...users]}}})
      }
    })
  }

  const importXlsxUsers = async (file) => {
    const data = await file.arrayBuffer()
    const wb = XLSX.read(data)
    let users = Object.keys(wb.Sheets).reduce((acc, sheet) => {
      const users = XLSX.utils.sheet_to_json(wb.Sheets[sheet], {header: 1})
      users.shift() // Remove header row
      const sheetUsers = users.map(([grade, category, firstName, middleName, lastName, maidenName, role, email]) => {
        return JSON.stringify(
          {
            grade: grade,
            category: category,
            first_name: firstName,
            middle_name: middleName,
            last_name: lastName,
            maiden_name: maidenName,
            role: role,
            email: email,
          },
          (_, v) => v || ''
        )
      })
      return [...acc, ...sheetUsers]
    }, [])
    users = uniq(users).map((user) => JSON.parse(user)) // Remove duplicate users from sheets
    update({importUsers: {importedUsers: {$set: [...users]}}})
  }

  return (
    <StyledImportUsers {...props}>
      <Button
        component="label"
        className="add-user-button"
        startIcon={<Icon name="faCloudUpload" />}
      >
        {translate('import-users-button')}
        <input
          hidden
          type="file"
          accept=".csv,.xlsx"
          onChange={handleFileUpload}
          onClick={clearInput}
        />
      </Button>
      {importedUsers.length > 0 ? <PreviewImport {...{open, handleClose, sendImport}} /> : null}
      {successedImport ?
        <AlertDialog
          open={successedImport}
          content="Usuarios importados con exito."
          onClose={() => update({importUsers: {successedImport: {$set: false}}})}
          title="Importar Usuarios"
          icon="maCheckCircleOutline"
        /> : null
      }
    </StyledImportUsers>
  )
}

function ImportUsersStyles() {
  return {
    paddingTop: 9,
    paddingBottom: 9,
    '& .add-user-button': {
      backgroundColor: '#2c7cbd',
      color: '#fff',
      fontSize: '13pt',
      padding: '8.5px 14px',
      '&:hover': {
        backgroundColor: '#2ca5ec',
        borderColor: '#2ca5ec',
        boxShadow: 'none',
      },
    },
  }
}