import {range, chunk} from 'external/lodash'
import { useMemo, useRef } from 'external/react'
import { styled } from 'external/material'
import {searchSubstring} from 'utils/text'

import useDimension from 'hooks/dimensions'
import useAdmin from 'hooks/admin'

import Teacher, {paddingWidth, itemHeight, itemWidth} from './Teacher'
import GroupsCount from './GroupsCount'
import useSearchParam from 'hooks/search-param'

const StyledMonitoringTeachers = styled('div')(monitoringTeachersStyles)


export default function Teachers () {

  const contentRef = useRef(null)
  const {teacherList} = useAdmin()
  const [pattern] = useSearchParam('pattern') 
  const {width: contentWidth} = useDimension(contentRef)

  const teachersPerRow = useMemo(() =>
    contentWidth > 0
      ? Math.floor((contentWidth - paddingWidth) / itemWidth)
      : false
  , [contentWidth])
  
  const teachers = useMemo(() => {
    return pattern
      ? teacherList.items.filter(t => {
        return searchSubstring(t.name ? t.name : '', pattern)
      })
      : teacherList.items
  }, [teacherList.items, pattern])

  const fillersNeeded = useMemo(() => {
    const needed = teachersPerRow > 0 && teachers.length > 0
      ? teachersPerRow - teachers.length % teachersPerRow
      : 0
    return needed === teachersPerRow ? 0 : needed
  }, [teachersPerRow, teachers])

  const rows = useMemo(() =>
    teachersPerRow > 0    
      ? chunk(teachers, teachersPerRow)
      : []
  , [teachersPerRow, teachers])


  return (
    <StyledMonitoringTeachers ref={contentRef}>
      <GroupsCount teachers={teachers} />
      <div className="teachers">
          {rows.map((row, i, rows) => {
            const lastRow = i == (rows.length - 1)
            return (<div key={i} className='teacher-row'>
            {row.map(teacher => <Teacher key={teacher.id} {...teacher} />)}
            {lastRow && fillersNeeded > 0 && range(fillersNeeded).map(key => <div key={key} className="teacher-filler" style={{width: itemWidth, height: itemHeight}} />)}
          </div>)}
          )}
      </div>
    </StyledMonitoringTeachers>)
}

function monitoringTeachersStyles() {
  return {
      display: 'flex',
      overflowY: 'hidden',
      flexDirection: 'column',
      marginTop: '15px',
      flexGrow: 1,
      backgroundColor: '#fff',
      border: 'solid 1px #d9d9d9',
      borderRadius: '4px',
      "& > .groups-count": {
        borderBottom: 'solid 1px #e7edf0',
        padding: '10px 20px',
        display: 'flex',
        "& .icon": {
          color: '#78a7ff',
          fontSize: '15pt',
        },
        "& .count": {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textTransform: 'lowercase',
          padding: '0 20px 0 8px',
          borderRight: 'solid 1px #e7edf0'
        }
      },
      "& .teachers": {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflowY: 'auto',
        borderTop: 'solid 1px #d9d9d9',
        padding: '0 20px 20px 20px',
        "& .teacher-row": {
          display: 'flex',
          justifyContent: 'space-evenly',
          marginTop: 30
        }
      }
    }
}
