import { orderBy } from 'external/lodash'
import { useEffect, useNavigate, useParams } from 'external/react'
import {
  styled
  , clsx
  , Accordion
  , AccordionSummary
  , AccordionDetails
} from 'external/material'

import Icon from 'components/Common/Icon'
import useTranslations from 'hooks/translations'
import useAdmin from 'hooks/admin'
import useCatalog from 'hooks/catalog'

const StyledCourseAccordion = styled('div')(courseAccordionStyles)

export default function CourseAccordion(props) {
  const { course } = useParams()
  const navigate = useNavigate()
  const { translate } = useTranslations()
  const { licenseCountForm: { licenseCount, courses }, update } = useAdmin()
  const { subjectsList } = useCatalog()

  useEffect(() => {
    const subjects = subjectsList.filter(s => !!licenseCount[s.id]).map(s => ({ ...s, ...licenseCount[s.id] }))
    const subject = subjects.find((s => s.id === course))
    if (!subject) return navigate(-1)
    const courses = orderBy(Object.entries(subject.courses).map(([id, course]) => ({ ...course, id })), 'id')
    update({licenseCountForm: {courses: {$set: courses}}})

    return () => update({licenseCountForm: {courses: {$set: []}}})
  }, [])

  return (
    <StyledCourseAccordion>
      <AccordionDetails className='accordion'>
        {courses.map((course) => {
          return (
            <Accordion key={course.id}>
              <AccordionSummary className={clsx('course', 'summary')} expandIcon={<Icon name="maExpandMore" />}>
                <div className="name">{course.id} - {course.courseName}</div>
                <div className={clsx('total', 'credits')}><label>{translate('contracted-credits')}:</label><span>{course.total}</span></div>
                <div className={clsx('used', 'credits')}><label>{translate('used-credits')}:</label><span>{course.used}</span></div>
                <div className={clsx('available', 'credits')}><label>{translate('available-credits')}:</label><span>{course.total < course.used ? 0 : course.total - course.used}</span></div>
              </AccordionSummary>
              <AccordionDetails className='groups'>
                {orderBy(Object.values(course.groups), 'name').map(group => (<div className='group' key={group.id}>
                  <div className='name'>{group.name}</div>
                  <div className='credits'>{group.used}</div>
                </div>))}
              </AccordionDetails>
            </Accordion>
          )
        })}
      </AccordionDetails>
    </StyledCourseAccordion>
  )
}

function courseAccordionStyles() {
  return {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 0,
    backgroundColor: '#fff',
    color: '#4f576e',
    border: 'solid 1px #d9d9d9',
    borderRadius: 5,
    overflow: 'hidden',
    '& .header': {
      display: 'flex',
      justifyContent: 'center',
      flexShrink: 0,
      borderBottom: 'solid 1px #bdd0d7',
      padding: 15,
      fontSize: '14pt',
      fontWeight: 'bold'
    },
    '& .accordion': {
      margin: 0,
      padding: 0,
      overflowY: 'auto',
      '& .groups': {
        backgroundColor: '#a1b2c4',
        display: 'flex',
        flexDirection: 'column',
        '& .group': {
          paddingLeft: 50,
          marginTop: 10,
          display: 'flex',
          '& .name': {
            flexGrow: 1
          },
          '& .credits': {
            paddingRight: 20
          }
        }
      },
      '&.Mui-expanded': {
        padding: 0,
        margin: 0
      },
      '& .MuiAccordionSummary-content': {
        padding: 0,
        margin: 0
      },
      '& .MuiAccordion-root': {
        padding: 0,
        margin: 0,
        '&.Mui-expanded': {
          padding: 0,
          margin: 0
        }
      },
      '& .course.summary': {
        minHeight: 'auto',
        padding: 0,
        display: 'flex',
        '& .name': {
          padding: 15,
          paddingLeft: 40,
          flexGrow: 1
        },
        '& .credits': {
          padding: '5px 8px',
          display: 'flex',
          width: 130,
          fontSize: '11pt',
          color: '#fff',
          '& label': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          },
          '& span': {
            display: 'flex',
            flexGrow: 1,
            textAlign: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }
        },
        '& .used': {
          backgroundColor: '#f28888',
        },
        '& .available': {
          backgroundColor: '#66c571'
        },
        '& .total': {
          backgroundColor: '#b4c3ca'
        }
      }
    },
  }
}