import fetch from 'cross-fetch'
import queryString from 'query-string'

export default function init(initOptions = {}) {

  const defaultOptions = Object.assign({
    method: 'GET',
    sendAsArgs: false,
    language: 'es',
    mapParameters: a => a,
    mapResponse: a => a,
  }, initOptions)

  return (context, name, options = {}) => {

    const {method, sendAsArgs, language, mapParameters, mapResponse, server, protocol = 'http'} = Object.assign({}, defaultOptions, options)

    const commandUrl = context
      ? `${protocol}://${server}/${context}/${name}`
      : `${protocol}://${server}/${name}`

    const requestOptions = {
      method: method,
      cache: 'no-store',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept-Language': language
      }),
      mode: 'cors',
      redirect: 'follow',
      credentials: 'include',
    }

    return (args) => {

      const parameters = mapParameters(args)
      const url = (method == 'GET' || sendAsArgs) && parameters
        ? `${commandUrl}?${queryString.stringify(parameters)}`
        : commandUrl
      let settings = requestOptions
      if (method != 'GET' && parameters) {
        settings = Object.assign({}, requestOptions, {body: JSON.stringify(parameters)})
      }

      return fetch(url, settings).then(response => {
        if (response.ok) {
          if (response.status != 200) return true
          const contentType = /^(.*?)(;|$)/.exec(response.headers.get('content-type'))[1]
          switch (contentType) {
            case 'application/json': return response.json().then(mapResponse)
            default: return true
          }
        }
        else throw(response)
      })
    }
  }
}
