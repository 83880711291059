import {SvgKeepRatio} from 'utils/style'

export default function SideMenuCloseIcon(props) {
  const fill = props.fill || "#96A3B6"
  return (<SvgKeepRatio viewBox={{width: 27, height: 24}} {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1134.000000, -109.000000)" fill={fill} fillRule="nonzero">
              <g transform="translate(1134.000000, 109.000000)">
                  <path d="M4.5,24 C2.01471863,24 0,21.9726102 0,19.4716981 L0,4.52830189 C0,2.02738981 2.01471863,0 4.5,0 L22.5,0 C24.9852814,0 27,2.02738981 27,4.52830189 L27,19.4716981 C27,21.9726102 24.9852814,24 22.5,24 L4.5,24 Z M13.5,2.264 L4.5,2.26415094 C3.25735931,2.26415094 2.25,3.27784585 2.25,4.52830189 L2.25,19.4716981 C2.25,20.7221542 3.25735931,21.7358491 4.5,21.7358491 L13.5,21.735 L13.5,2.264 Z" transform="translate(13.500000, 12.000000) rotate(-180.000000) translate(-13.500000, -12.000000) " />
                  <path d="M18.4434572,8.72670977 L21.2559572,11.5369988 C21.3943846,11.693986 21.4625,11.8752949 21.4625,12.0367041 C21.4625,12.1981137 21.3938354,12.39888 21.2565063,12.5370728 L18.4440063,15.3473618 C18.2428246,15.5498089 17.9406124,15.6104808 17.6775997,15.5008773 C17.4145869,15.3912738 17.2437497,15.1720223 17.2437497,14.8668928 L17.2437497,9.22641509 C17.2437497,8.94030065 17.414719,8.6820461 17.67771,8.5723758 C17.9407009,8.46270618 18.2435062,8.52395366 18.4434575,8.72670977 L18.4434572,8.72670977 Z" />
              </g>
          </g>
      </g>
  </SvgKeepRatio>)
}