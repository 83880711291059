import {ResizeObserver} from 'external/polyfills'
import {
   useRef
  ,useState
  ,useEffect
} from 'external/react'

const initialState = { width: 0, height: 0 }

export default function useDimension (ref) {
  const [dimensions, setDimensions] = useState(initialState);
  const resizeObserverRef = useRef(null)

  useEffect(() => {
    resizeObserverRef.current = new ResizeObserver((entries = []) => {
      entries.forEach((entry) => {
        const { width, height } = entry.contentRect
        setDimensions({ width, height })
      })
    })
    if (ref.current) resizeObserverRef.current.observe(ref.current)
    return () => {
      if (resizeObserverRef.current) resizeObserverRef.current.disconnect()
    }
  }, [ref])
  return dimensions
}
