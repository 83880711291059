import AddButton from 'components/Common/AddButton'
import {styled} from 'external/material'

import useTranslations from 'hooks/translations'
import useAdmin from 'hooks/admin'

const StyledAddGroup = styled('div')(addGroupStyles)

export default function AddGroup({className}) {

    const {translate} = useTranslations()
    const {update, contractGrades} = useAdmin()

    const contentText = translate('add-group') || ''

    async function handleOpen() {
        update({addGroupForm: {open: {$set: true}}})
    }
    
    return (<StyledAddGroup className={className}>
        <AddButton onClick={handleOpen} title={contentText} label={contentText} />
    </StyledAddGroup>)
}

function addGroupStyles() {
    return {
        color: '#2c75bd',
        cursor: 'pointer',
        fontSize: 18
    }
}