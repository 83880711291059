import {clsx, List, ListItem, ListItemIcon, ListItemText, Checkbox} from 'external/material'


export default function CheckboxList(props) {

  const {
      options, 
      canSelectAll,
      selectAllLabel,
      checked, 
      handleToggle,
      handleSelectAll,
      mapLabel = o => o.label,
      mapValue = o => o.value,
      Secondary,
      Text,
      disabled,
      className
  } = props

  const allSelected = checked.length == options.length
  const someSelected = !allSelected && checked.length > 0

  return (
    <List className={className}>
      {canSelectAll && options.length > 0 && (
        <ListItem role={undefined} dense onClick={() => handleSelectAll(allSelected ? [] : options.map(o => mapValue(o)))}>
        <ListItemIcon>
          <Checkbox
            disabled={disabled}
            edge="start"
            checked={allSelected} 
            indeterminate={someSelected}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': 'checkbox-list-label-selectAll'}}
          />
        </ListItemIcon>
        <ListItemText id='checkbox-list-label-selectAll' primary={selectAllLabel} sx={{cursor: 'pointer'}} />
      </ListItem>)}

      {options.map((option) => {

        const label = mapLabel(option)
        const value = mapValue(option)
        const labelId = `checkbox-list-label-${value}`;
        const selected = checked.indexOf(value) !== -1

        return (
          <ListItem key={value} role={undefined} dense className={clsx('checkboxlist-item', {selected})} onClick={() => !disabled && handleToggle(value, option) }>
            <ListItemIcon>
              <Checkbox
                disabled={disabled}
                edge="start"
                checked={selected}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            {!Text && (<ListItemText id={labelId} primary={label} sx={{cursor: 'pointer'}} />) }
            {Text && (<Text option={option} sx={{cursor: 'pointer'}} />) }
            {Secondary && (<Secondary option={option} sx={{cursor: 'pointer'}} />)}
          </ListItem>
        );
      })}
    </List>
  )
}
