import {orderBy, uniqBy, intersectionWith} from 'external/lodash'
import {useContext, useMemo} from 'external/react'
import Catalog from 'context/catalog-context'

export default function useCatalog () {
  const {state, update} = useContext(Catalog.Context)
  const {subjects, grades, units, unitsCourse, unitLessons, catalogSubjects} = state
 
  const [catalogueSearchPageCount = {}, ...catalogueSearch ] = state.catalogueSearch.items

  const subjectsList = useMemo(() => {
    return subjects ? orderBy(Object.values(subjects), 'key') : []
  }, [subjects])

  const gradesList = useMemo(() => {
    return grades ? orderBy(Object.values(grades), 'precedence') : []
  }, [grades])
  
  const [coursesSubject, coursesLanguages, ...catalogCourses] = state.catalogCourses.items || []

  const studentGrades = useMemo(() => {
    return orderBy(Object.values(grades || {}).filter(g => g.studentGrade), ['precedence'])
  }, [grades])
  
  const courseGrades = useMemo(() => {
    return orderBy(intersectionWith(gradesList, uniqBy(catalogCourses, 'grade'), (a, b) => a.id == b.grade), 'precedence')
  }, [gradesList, catalogCourses])

  return {
    catalogueSearchPageCount, catalogueSearch,
    subjects, subjectsList, grades,gradesList,  courseGrades, studentGrades, units, unitsCourse, unitLessons, update, catalogSubjects, catalogCourses, coursesSubject, coursesLanguages}
}
