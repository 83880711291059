import {Typography} from 'external/material'

import useTranslations from 'hooks/translations'
import useProfile from 'hooks/profile'

export default function ProjectName (props) {
  const {translate} = useTranslations()
  const {project} = useProfile()
  return (<Typography variant="title" component="div" {...props}>{translate(`${project}_project_title`)}</Typography>)
}

