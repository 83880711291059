import {useMemo} from 'external/react'
import TabBar from 'components/Common/TabBar'
import useAdmin from 'hooks/admin'
import {isEmpty} from 'utils/text'

const tabFields = {
  comment: ['passports'],
  students: ['passports'],
  preview: [],
}

export default function SendUsersInfoTabBar(props) {

  const {errors, className} = props
  const {sendUsersInfo, update} = useAdmin()
  const {activeTab} = sendUsersInfo
  
  function handleTabButtonClick(tab) {
    if (activeTab != tab) {
      update({sendUsersInfo: {activeTab: {$set: tab}}})
    }
  }

  const checkErrors = useMemo(() => {
    return tabId => tabFields[tabId].find(fieldId => !isEmpty(errors[fieldId])) != undefined
  }, [errors])

  return (
    <TabBar
      className={className}
      tabs={[
        {id: 'comment', label: 'send-users-info-tab-label-comment', icon: 'faComment'},
        {id: 'students', label: 'send-users-info-tab-label-student', icon: 'faUserLarge'},
        {id: 'preview', label: 'send-users-info-tab-label-preview', icon: 'faEye'},
      ]}
      tabButtonActiveTab={activeTab}
      tabButtonOnClick={handleTabButtonClick}
      tabButtonCheckErrors={checkErrors} />
  )
}
