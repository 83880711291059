import { createLoadableList } from 'external/immutability-helper'
import create, {initializeAndRegisterHooks} from 'context/create' 
import hooks, * as resolves from './hooks'

const initialState = {
  subjects: undefined,
  catalogSubjects: createLoadableList(),
  catalogCourses: createLoadableList(),
  downloadedContent: {},
  grades: undefined,
  units: undefined,
  unitsCourse: undefined,
  unitLessons: undefined,
  catalogueSearch: createLoadableList(),
  catalogueSearchPageSize: 0,
}

const id = 'Catalog'

const context = create(id, initialState, {onInit: initializeAndRegisterHooks(id, hooks, resolves)})

export {context as default}
