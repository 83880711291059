import { exportCSV } from "external/sheetjs"
import { createWorkbook } from 'protobi-msexcel-builder'
import { downloadBlob } from "external/bowser"
import { formatDate } from "external/date-fns"

export function exportUsersFile(institution, groups, users, translate, filters, format = 'csv') {

  const filtered = groups.filter(group => {
    // Filter group
    const filteredGroup = filters.groups.find(id => group.id === id)
    if (!filteredGroup) return
    // Filter roles
    const students = filters.roles.indexOf('students')
    const teachers = filters.roles.indexOf('teachers')
    if (students < 0) group.students = []
    if (teachers < 0) group.teachers = []

    return filteredGroup
  })

  const bookFile = `${institution}-${formatDate(new Date(), 'yyyy-MM-dd')}.${format}`
  switch (format) {
    case 'csv':
      const jsonCSV = groupUsers(filtered, users, translate)
      return exportCSV(jsonCSV, { filename: bookFile })
    default:
      const sheets = filtered.map(group => groupSheet(group, users, translate))
      return exportUsersExcel(bookFile, sheets)
  }
}

export function exportAllUsersFile(institution, users, translate) {
  const bookFile = `${institution}-${formatDate(new Date(), 'yyyy-MM-dd')}.csv`
  const jsonCSV = parseUsersToObject(users, translate)
  return exportCSV(jsonCSV, { filename: bookFile })
}

async function exportUsersExcel(filename, sheets = []) {
  const workbook = createWorkbook()
  sheets.map(([groupName, columns, rows]) => {
    const sheet = workbook.createSheet(groupName, columns.length, rows.length + 1)
    columns.forEach((c, cidx) => {
      const columnIndex = cidx + 1
      sheet.set(columnIndex, 1, c[0])
      sheet.width(columnIndex, 45)
      sheet.height(1, 30)
      let rowIndex = 2 // starts setting data from second row
      rows.forEach((r) => {
        sheet.set(columnIndex, rowIndex, r[c[0].set])
        sheet.height(rowIndex, 45)
        rowIndex += 1
      })
    })
  })
  const jszip = await workbook.generate()
  const blob = await jszip.generateAsync({ type: "blob", mimeType: 'application/vnd.ms-excel;' })
  downloadBlob(filename, blob)
}

function parseUsersToObject(users, translate) {
  const headers = users[0]
    .split(',')
    .map((header) => translate(`export-users-csv-${header}-header`))

  const usersJson = users.reduce((acc, user, index) => {
    if (index === 0) return [...acc]
    const values = user.split(',')
    const jsonValue = values.reduce((acc, value, index) => ({ ...acc, [headers[index]]: value }), {})
    return [...acc, jsonValue]
  }, [])

  return usersJson
}

function groupUsers(groups, users, translate) {
  const usersJson = parseUsersToObject(users, translate)

  const filterGroup = groups.reduce((acc, group) => {
    const groupUsers = usersJson.filter(user => {
      const studentExist = acc.find(s => s['ID'] === user['ID'])
      if (studentExist) return

      const student = group.students.find(s => user['ID'] === s.passport)
      if (student) return student

      const teacherExist = acc.find(t => t['ID'] === user['ID'])
      if (teacherExist) return

      const teacher = group.teachers.find(t => user['ID'] === t.passport)
      if (teacher) return teacher
    })
    return [...acc, ...groupUsers]
  }, [])

  const removeColumns = filterGroup.map(columns => {
    const {ID, ...rest} = columns
    return rest
  })

  return removeColumns
}

const groupSheet = (group, users, translate) => {
  const sheetGroupName = group.groupName
  const fontStyles = (sz = 11) => ({ font: { bold: true, sz, name: 'Verdana' } })
  const valign = 'center'
  const height = 100
  const sheetHeaders = users[0]
    .split(',')
    .reduce((acc, header) => {
      if (header === 'id') return [...acc]
      return [
        ...acc,
        [{ height, valign, set: translate(`export-users-csv-${header}-header`), ...fontStyles() }]
      ]
    }, [])
  const usersJson = parseUsersToObject(users, translate)
  const groupUsers = usersJson.filter(user => {
    const student = group.students.find(s => user['ID'] === s.passport)
    if (student) return student
    const teacher = group.teachers.find(t => user['ID'] === t.passport)
    if (teacher) return teacher
  }).map(columns => {
    const {ID, ...rest} = columns
    return rest
  })
  return [sheetGroupName, sheetHeaders, groupUsers]
}
