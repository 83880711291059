import {useRef} from 'external/react'
import {styled, Box} from 'external/material'
import moment from 'external/moment'
import {range} from 'external/lodash'

import {minEventHeight, minEventWidth} from 'settings'

import useQueryDate from 'hooks/query-date'
import useDimensions from 'hooks/dimensions'

import Week from './Week'
import Header from './Header'


const StyledMonth = styled(Box)(monthStyles)

export default function MonthView () {

  const ref = useRef()
  const sizes = useDimensions(ref)
  const [date] = useQueryDate()

  const start = date().startOf('month').startOf('week')
  const end = date().endOf('month').endOf('week')
  const weekCount = Math.ceil(end.diff(start, 'days') / 7)

  const daySize = {
    width: Math.max(minEventWidth, Math.round((sizes.width - 12)  / 7)),
    height: Math.max(minEventHeight, Math.round((sizes.height - 40) / weekCount))
  }

  return (
    <StyledMonth ref={ref} className="month-view">
      <div className="headers">
        {range(7).map(day => (<Header key={day} {...headerProps(day, {start, daySize})} />))}
      </div>
      <div className="weeks">
        {range(weekCount).map(week => (<Week key={week} {...weekProps(week, {start, daySize})} />))}
      </div>
      <div style={{flexGrow: 1}} />
    </StyledMonth>
  )
}

function headerProps (day, {start, daySize}) {
  return {date: moment(start).add(day, 'days'), daySize}
}

function weekProps(week, {start, daySize}) {
  return {start: moment(start).add(week, 'weeks'), daySize}
}

function monthStyles() {
  return {
    overflow: 'hidden',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    color: '#69738e',
    backgroundColor: '#fff',
    "& .headers": {
      display: 'flex',
      fontSize: '10pt',
      color: '#69738e',
      borderTop: 'solid 1px #e1eaee',
      borderBottom: 'solid 1px #e1eaee',
      flexShrink: 0,
      "& .header": {
        flexShrink: 0
      }
    },
    "& .weeks": {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto'
    }
  } 
}