import {useMemo} from 'external/react'
import ClearableQueryFilter from 'components/Common/ClearableQueryFilter'

import useTranslations from 'hooks/translations'
import useAdmin from 'hooks/admin'
import useSearchParam from 'hooks/search-param'

export default function GroupFilter (props) {

  const {translate} = useTranslations()
  const {teacherList} = useAdmin()
  const [teacher] = useSearchParam('teacher')
  
  const groups = useMemo(() => teacher && teacherList.items.length > 0
    ? (teacherList.items.find(t => t.id == teacher) || {}).groups || []
    : []
    , [teacherList.items, teacher])

  return (<ClearableQueryFilter {...{...props, caption: translate('group_label'), items: groups, name: 'clique'}} />)

}
