import create, {createPost} from 'utils/command'
import { useForm, useEffect, FormController} from 'external/react'
import {debounce} from 'external/lodash'
import Icon from 'external/font-awesome'
import {
    styled, 
    clsx, 
    Dialog, 
    DialogTitle, 
    IconButton, 
    DialogContent, 
    DialogActions, 
    Button, 
    FormControl, 
    TextField, 
    InputLabel, 
    Select, 
    MenuItem, 
    Autocomplete,
} from 'external/material'
import { emailRegex, phoneRegex } from 'settings'
import { capitalizeFirstLetter } from 'utils/text'
import useTranslations from 'hooks/translations'
import useAdmin from 'hooks/admin'
import useCatalog from 'hooks/catalog'

const StyledAddUserForm = styled(Dialog)(addUserFormStyles)

const defaultValues = {
    "first_name": "",
    "last_name": "",
    "middle_name": "",
    "maiden_name": "",
    "email": "",
    "phone": "",
    "mobile": "",
    "puk": "",
    "grade": "",
    "category": "",
}

export default function AddUserForm(props) {

    const {addUserForm: {open, role}, studentCategories, update} = useAdmin()
    const {studentGrades = []} = useCatalog()

    const {translate} = useTranslations()
    const { register, setValue, handleSubmit, reset, resetField, watch, control, formState: {errors}} = useForm({
        defaultValues,
        values: defaultValues,
        mode: 'onSubmit',
        reValidateMode: 'onBlur'
     })

    function closeForm(refresh) {
        if (refresh) update({users: {$refreshLoadableList: true}, addUserForm: {open: {$set: false}}})
        else update({addUserForm: {open: {$set: false}}})
    }

    function handleClose() {
        closeForm(false)
    } 

    function getApiNameByUserRole () {
        switch (role) {
            case 'student':
              return 'add_student'
            case 'teacher':
              return 'add_teacher'
            default:
              return 'add_student'
        }
    }


    async function onSubmit (form) {
        try {
            const apiName = getApiNameByUserRole()
            await createPost('admin', apiName)({...form, role})
            closeForm(true)
        } catch(e) {
            console.error('error' + e)
        }
    }

    useEffect(() => {
        if (open) reset(defaultValues)
    }, [open])
    

    return (
        <StyledAddUserForm
            open={open}
            onClose={handleClose}
        >
            <DialogTitle className="title">
                <label>{translate('add-user-form-title', {role: translate(role)})}</label>
                <IconButton className="close-button" onClick={handleClose}>
                    <Icon name="faCircleXmark" />
                </IconButton>
            </DialogTitle>
            <form className="form">
                <DialogContent>
                    <div className="right">
                        {role == 'student' && (
                            <FormControl className={clsx('grade', 'form-control')}>
                                <InputLabel className={clsx('grade', 'input-label')} id="select_grade">{capitalizeFirstLetter(translate('grade'))}:</InputLabel>
                                <FormController
                                    name="grade"
                                    control={control}
                                    rules={{required: false}}
                                    render={(props) => { 
                                        return <Select
                                            className={clsx('grade', 'form-item')}
                                            value={props.field.value}
                                            onChange={({target}) => {
                                                setValue('grade', target.value);
                                            }}
                                            >
                                    {studentGrades.map(({id, name}) => (
                                        <MenuItem
                                            value={id} 
                                            key={id} 
                                        >
                                            {name} 
                                        </MenuItem>
                                    ))}
                                </Select>}}
                            />
                        </FormControl>
                        )}
                        {role == 'student' && (
                            <FormControl className={clsx('category', 'form-control')}>
                                <InputLabel className={clsx('category-label', 'input-label')} id="category_label">{capitalizeFirstLetter(translate('category'))}:</InputLabel>
                                <FormController
                                    name="category"
                                    control={control}
                                    rules={{required: false}}
                                    render={({field: {onChange, value}}) => {
                                        return <Autocomplete
                                            freeSolo={true}
                                            value={value}
                                            options={studentCategories.items.map(o => o.name)}
                                            onChange={(event, category, reason) => {
                                                onChange(category || "")
                                            }}
                                            renderInput={(params) => {
                                                return <TextField
                                                    {...params}
                                                    value={value}
                                                    error={!!errors.category}
                                                    helperText={errors.category && errors.category.message}
                                                    onChange={onChange}
                                                />
                                            }}
                                        />
                                    }}
                                    />
                            </FormControl> 
                        )}
                        <FormControl className={clsx('first-name', 'form-control')}>
                            <InputLabel className={clsx('first-name-label', 'input-label')} id="first_name_label">{capitalizeFirstLetter(translate('first-name'))}:</InputLabel>
                            <TextField
                                id="first_name"
                                type="text"
                                variant="standard"
                                className={clsx('first-name', 'form-item')}
                                error={!!errors.first_name}
                                helperText={errors.first_name && errors.first_name.message}
                                {...register('first_name', {
                                    required: translate('required-first-name'), 
                                    minLength: {value: 2, message: translate('minimum-input-text-length', {length: 2})}
                                })}
                            />
                        </FormControl>
                        <FormControl className={clsx('middle-name', 'form-control')}>
                            <InputLabel className={clsx('middle-name-label', 'input-label')} id="middle_name_label">{capitalizeFirstLetter(translate('middle-name'))}:</InputLabel>
                            <TextField
                                id="middle_name"
                                type="text"
                                variant="standard"
                                className={clsx('middle-name', 'form-item')}
                                {...register('middle_name', {required: false})}
                            />
                        </FormControl>
                        <FormControl className={clsx('last-name', 'form-control')}>
                            <InputLabel className={clsx('last-name-label', 'input-label')} id="last_name_label">{capitalizeFirstLetter(translate('last-name'))}:</InputLabel>
                            <TextField
                                id="last_name"
                                type="text"
                                variant="standard"
                                className={clsx('last-name', 'form-item')}
                                error={!!errors.last_name}
                                helperText={errors.last_name && errors.last_name.message}
                                {...register('last_name', {
                                    required: translate('required-last-name'), 
                                    minLength: {value: 2, message: translate('minimum-input-text-length', {length: 2})}
                                })}
                            />
                        </FormControl>
                        <FormControl className={clsx('maiden-name', 'form-control')}>
                            <InputLabel className={clsx('maiden-name-label', 'input-label')} id="maiden_name_label">{capitalizeFirstLetter(translate('maiden-name'))}:</InputLabel>
                            <TextField
                                id="maiden_name"
                                type="text"
                                variant="standard"
                                className={clsx('maiden-name', 'form-item')}
                                error={!!errors.maiden_name}
                                helperText={errors.maiden_name && errors.maiden_name.message}
                                {...register('maiden_name', {
                                    minLength: {value: 2, message: translate('minimum-input-text-length', {length: 2})}
                                })}
                            />
                        </FormControl>
                        <FormControl className={clsx('email', 'form-control')}>
                            <InputLabel className={clsx('email-label', 'input-label')} id="email_label">{capitalizeFirstLetter(translate('email'))}:</InputLabel>
                            <TextField
                                id="email"
                                type="text"
                                variant="standard"
                                className={clsx('email', 'form-item')}
                                error={!!errors.email}
                                helperText={errors.email && errors.email.message}
                                {...register('email', {
                                    pattern: {value: emailRegex, message: translate('input-must-be-a-valid-email')}
                                })}
                            />
                        </FormControl>
                        <FormControl className={clsx('phone', 'form-control')}>
                            <InputLabel className={clsx('phone-number-label', 'input-label')} id="phone_number_label">{capitalizeFirstLetter(translate('phone-number'))}:</InputLabel>
                            <TextField
                                id="phone"
                                type="text"
                                variant="standard"
                                className={clsx('phone', 'form-item')}
                                error={!! errors.phone}
                                helperText={errors.phone && errors.phone.message}
                                {...register('phone', {
                                    pattern: {value: phoneRegex, message: translate('input-must-be-a-valid-phone-number')}
                                })}
                            />
                        </FormControl>
                        <FormControl className={clsx('mobile', 'form-control')}>
                            <InputLabel className={clsx('mobile-number-label', 'input-label')} id="mobile_number_label">{capitalizeFirstLetter(translate('mobile-number'))}:</InputLabel>
                            <TextField
                                id="mobile"
                                type="text"
                                variant="standard"
                                className={clsx('mobile', 'form-item')}
                                error={!! errors.mobile}
                                helperText={errors.mobile && errors.mobile.message}
                                {...register('mobile', {
                                    pattern: {value: phoneRegex, message: translate('input-must-be-a-valid-mobile-number')}
                                })}
                            />
                        </FormControl>
                    </div>
                </DialogContent>
                <DialogActions>
                      <Button type="submit" onClick={handleSubmit(onSubmit)}>{translate('save')}</Button>
                </DialogActions>
            </form>


        </StyledAddUserForm>)
    
}

function addUserFormStyles() {
    return {
        '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '1280px',
            '& .title': {
                textAlign: 'center',
                backgroundColor: '#2c7cbd',
                color: '#fff',
                '& .close-button': {
                    position: 'absolute',
                    right: 15,
                    color: '#fff'
                }
            }, 
            '& .form': {
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                '& .form-control': {
                    display: 'flex',
                    flexDirection: 'row'
                },
                '& .MuiDialogContent-root': {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    '& .form-item, .MuiAutocomplete-inputRoot': {
                        marginBottom: 25
                    },
                },
                '& .MuiDialogActions-root': {
                    justifyContent: 'center',
                    paddingBottom: 30,
                    '& button': {
                        width: 140,
                        height: 40,
                        background: '#2c7cbd',
                        color: '#fff'
                    }
                },
                '& .right, .left': {
                    flexGrow: 1
                },
                '& .right': {
                    display: 'flex',
                    flexDirection: 'column',
                    width: '50%',
                    '& .MuiFormControl-root, .MuiInputBase-root': {
                        width: '100%'
                    }
                },
                '& .left': {
                    padding: '0px 15px'
                },
                '& label.grade': {
                   transform: 'translate(14px, 16px) scale(1)'
                },
                '& .category': {
                    '& .MuiAutocomplete-root':{
                        width: '100%',
                        textAlign: 'end',
                        '& .MuiFormControl-root':{
                            width: 88,
                        }
                    }
                }
            },
            '& .input-label': {
                fontWeight: 'bold',
                color: '#434a5d',
                fontSize: '1.15em',
                position: 'relative',
                width: '60%',
                lineHeight: '0.97rem',
            },
            '& .toogle-role':{
                position: 'absolute',
                left: '5rem',
                '& .css-l6q58-MuiButtonBase-root-MuiToggleButton-root.Mui-selected':{
                    color: '#fff' 
                }
            }
            
        },
        '& .MuiDialog-container':{
            maxHeight: 'calc(100% - 24px)',
            height: '100%'
        },
    }
}