import {Routes, Route, Navigate} from 'external/react' 

import DayView from './Day'
import WeekView from './Week'
import MonthView from './Month'
import CalendarContainer from './Container'

import useSession from 'hooks/session'

export default function Schedule() {
  const {navigation} = useSession()
  return (
    <Routes>
      <Route element={<CalendarContainer />}>
        <Route path="month" element={<MonthView />} />
        <Route path="week" element={<WeekView />} />
        <Route path="day" element={<DayView />} />
        <Route path="/" element={
          <Navigate to={navigation.calendarView.link({calendarView: 'month'})} replace />}
        />
      </Route>
    </Routes>
  )
}