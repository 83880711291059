import {Link} from 'external/react'
import {styled, clsx} from 'external/material'
import Icon from 'components/Common/Icon'
import Label from 'components/Common/Label'
import { centerVertically } from 'utils/style'

import useSession from 'hooks/session'
import useTranslations from 'hooks/translations'

const StyledGroup = styled('div')(groupStyles)

export const paddingWidth = 80
export const itemWidth = 285
export const itemHeight = 80

export default function Group(props) {

  const {id: groupId, groupName: name, students = []} = props
  const {translate} = useTranslations()
  const {navigation} = useSession()

  return (
    <StyledGroup >
      <Link to={navigation.monitoringGroup.linkWithQuery(null, false, {groupId})} className='group-link'>
        <div className="icon-wrapper">
          <div className="icon">
            <Icon name="faUserLarge" />
          </div>
        </div>
        <div className="label">
          <div className="name">
            <Label value={name || ''} maxLength={25} />
          </div>
          <div className={clsx('count', {"has-no-students": students.length == 0})}>
            {students.length}{" "}{translate(`student${students.length == 1 ? '' : 's'}_label`)}
          </div>
        </div>
      </Link>
    </StyledGroup>
  )
}

function groupStyles() {
    return {
    borderRadius: 8,
    border: 'solid 1px #ced3e0',
    height: itemHeight,
    width: itemWidth,
    padding: '10px 20px',
    marginBottom: '20px',
    display: 'flex',
    "&:hover": {
      '& .label': {
        textDecoration: 'underline'
      }
    },
    "& .group-link": {
      cursor: 'pointer',
      flexGrow: 1,
      display: 'flex',
      textDecoration: 'none',
      "& .icon-wrapper": {
        ...centerVertically,
        backgroundColor: '#fff',
        border: '1px solid #d8e8fb',
        borderRadius: '50%',
        height: 56,
        width: 56,
        padding: 4,
        "& .icon": {
          borderRadius: '50%',
          padding: '10px 5px',
          fontSize: '18pt',
          display: 'flex',
          backgroundColor: '#e6f2ff',
          justifyContent: 'center',
          color: '#c54ebf'
        },
      },
      "& .label": {
        color: '#4e576e',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: 25,
        "& .name": {
          fontWeight: 'bold',
          fontSize: '11pt'
        },
        "& .count": {
          paddingTop: 1,
          textTransform: 'lowercase',
          fontSize: '9.5pt'
        }

      },
      '& .has-no-students': {
        visibility: 'hidden'
      }
    }
    }
  }