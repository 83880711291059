import {omit} from 'external/lodash'
import FontAwesomeIcon from 'external/font-awesome'
import {icons, IconButton as MuiIconButton} from  'external/material'
import esIcons from 'resources/icons'
import { filterByPrefix } from 'utils/functions'

export function IconButton(props) {
    const {filtered: iconProps, rest: buttonProps} = filterByPrefix('icon', props)
    return (
        <MuiIconButton {...buttonProps}>
            <Icon {...iconProps} />
        </MuiIconButton>
    )
}

export default function Icon(props) {
    const {name} = props
    const iconProps = omit(props, 'name')
    const iconSource = name.substring(0, 2)
    switch (iconSource) {
        case 'ma':
            const MaterialIcon = icons[name]
            return MaterialIcon && (<MaterialIcon {...iconProps}/>)
       case 'es':
            const EduSystemIcon = esIcons[name]
            return EduSystemIcon && (<EduSystemIcon {...iconProps} />)
        default:
            return <FontAwesomeIcon name={name} {...iconProps} />
    }
}
