import {styled} from 'external/material'

const StyledCatalogueSearch = styled('div')(catalogueSearchStyles)

import FilterBar from './FilterBar'
import CatalogueSearchTable from './CatalogueSearchTable'

export default function CatalogueSearch() {
  return (
      <StyledCatalogueSearch>
        <FilterBar className="filters" />
        <CatalogueSearchTable className="catalogueSearch"/>
      </StyledCatalogueSearch>
  )
}

function catalogueSearchStyles() {
   return {
    display: 'flex',
    padding: 40,
    minWidth: 944,
    flexGrow: 1,
    overflowY: 'hidden',
    flexDirection: 'column',
    "& .filters": {
        flexShrink: 0,
        flexWrap: 'wrap',
        '& > .bottom': {
          marginTop: 15,
          backgroundColor: '#fff',
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          padding: 10,
          borderBottom: '1px solid rgba(224, 224, 224, 1)'
        }
    },
    "& .catalogueSearch": {
        flexGrow: 1,
        overflowY: 'auto'
    }
   } 
}
