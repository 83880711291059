import { orderBy, chunk, range } from 'external/lodash'
import { useMemo, useRef } from 'react'
import {styled, clsx} from 'external/material'

import useSession from 'hooks/session'

import LanguageFilter from 'components/Pages/Catalogue/LanguageFilter'
import SearchPattern from 'components/Common/SearchPattern'
import GradeFilter from './GradeFilter'
import Course from './Course'
import BackButton from 'components/Common/BackButton'

 
import useCatalog from 'hooks/catalog'
import useDimension from 'hooks/dimensions'
import { centerVertically } from 'utils/style'


const courseLinkWidth = 245
const courseLinkHeight = 285

const StyledCatalogueCourses = styled('div')(catalogCourseStyles)

export default function CatalogueCourses () {

  const {query, navigation, pushHistory} = useSession()

  const contentRef = useRef(null)
  const {width: contentWidth} = useDimension(contentRef)

  const {coursesSubject, grades = {}, catalogCourses = [], coursesLanguages = {}} = useCatalog()

  const courses = useMemo(() => {
    return query.grade
      ? orderBy(catalogCourses.filter(({grade}) => grade == query.grade), 'id')
      : orderBy(catalogCourses, [({grade}) => grades[grade] ? grades[grade].precedence : 'id' , 'id'])
  }, [grades, catalogCourses, query])

  

  const coursesPerRow = useMemo(() =>
    contentWidth > 0
      ? Math.floor((contentWidth - 48) / courseLinkHeight)
      : false
  , [contentWidth])

  const fillersNeeded = useMemo(() => {
    const needed = coursesPerRow > 0 && courses.length > 0
      ? coursesPerRow - courses.length % coursesPerRow
      : 0
    return needed == coursesPerRow ? 0 : needed
  }, [coursesPerRow, courses])
  
  const rows = useMemo(() =>
    coursesPerRow > 0    
      ? chunk(courses, coursesPerRow)
      : []
  , [coursesPerRow, courses])
  
  function handlePatternSearch(query) {
    pushHistory('catalogue-lessons', true)
    navigation.catalogSearch.goWithQuery(query, false)
  }

  return (
    <StyledCatalogueCourses className="catalogue-courses">
      <div className="filters-wrapper">
        <div className="filters">
          <BackButton to={navigation.catalog.linkWithQuery({languageId: query.languageId}, false)} />
          <div className="subject-name">
            { coursesSubject && (<div className="label">{coursesSubject.name}</div>)}
          </div>
          <SearchPattern className={clsx('pattern', 'filter')} onPatternSearch={handlePatternSearch} searchOnBlur={false} />
          <LanguageFilter availableLanguages={coursesLanguages.availableLanguages} />
        </div>
      </div>
      <div ref={contentRef} className="content">
        <GradeFilter subject={coursesSubject} />
        <div className="courses">
          {rows.map((row, i, rows) => {
            const lastRow = i == (rows.length - 1)
            return (<div key={i} className='course-row'>
            {row.map(course => <Course key={course.id} {...course} />)}
            {lastRow && fillersNeeded > 0 && range(fillersNeeded).map(key => <div key={key} className="course-filler" style={{width: courseLinkWidth, height: courseLinkHeight}} />)}
          </div>)}
          )}
        </div>
      </div>
    </StyledCatalogueCourses>)
}

function catalogCourseStyles() {
  return {
    display: 'flex',
    padding: 40,
    flexGrow: 1,
    overflowY: 'hidden',
    flexDirection: 'column',
    '& .filters-wrapper': {
      ...centerVertically,
      '& .filters': {
          display: 'flex',
          '& .back': {
            ...centerVertically,
            backgroundColor: '#fff',
            borderRadius: '4px',
            cursor: 'pointer',
            padding: '10px',
            paddingLeft: '12px',
            paddingRight: '6px',
            '&:hover': {
              transform: 'scale(1.06)'
            },
            '&:after': {
              display: 'inline-block',
              content: "''",
              height: '13px',
              width: '13px',
              borderBottom: '2px solid #bbc1d1',
              borderRight: '2px solid #bbc1d1',
              transform: 'rotate(135deg)'
            }
          },
          '& .subject-name': {
            ...centerVertically,
            cursor: 'default',
            flexGrow: 2,
            marginLeft: '20px',
            color: '#595b70',
            fontSize: '18pt'
          },
          '& .pattern.filter': {
            flexGrow: 1,
            marginRight: '15px'
          }
      },
    },
    '& > .content': {
      display: 'flex',
      overflowY: 'hidden',
      flexDirection: 'column',
      marginTop: '15px',
      flexGrow: 1,
      backgroundColor: '#fff',
      border: 'solid 1px #d9d9d9',
      borderRadius: '4px',
      "& .courses": {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflowY: 'auto',
        borderTop: 'solid 1px #d9d9d9',
        padding: '0 20px 20px 20px',
        "& .course-row": {
          display: 'flex',
          justifyContent: 'space-evenly',
          marginTop: 30,
          "& .course-filler": {
            width: courseLinkWidth,
            minWidth: courseLinkWidth,
          }
        }
      }

    }

  }
}