import { styled ,IconButton ,ArrowBackIosIcon } from 'external/material'
import LinkBehavior from 'components/Common/LinkBehavior'
import { useNavigate } from 'external/react'
import useSession from 'hooks/session'

const Button = styled(IconButton)(({styles = {}}) => {
  return {
    "&.MuiButtonBase-root": {
      borderRadius: 4,
      backgroundColor: '#fff',
      boxShadow: '3px 4px 14px 2px rgba(223,223,223,0.75)',
      borderRight: 'solid 1px #dbe3e6',
      '&:hover': {
        backgroundColor: '#ccc',
      },
      "& .icon": {
        fontSize: '20pt',
        marginLeft: 10,
        color: '#4f556d',
      }
    },
    ...styles
  }
})

const Container = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center'
}))

export default function BackButton(props) {
  const {historyTrack, popHistory} = useSession()
  const navigate = useNavigate()
  const {to, track} = props
  
  function handleClick(e) {
    const stack = historyTrack(track)
    if (stack) {
      e.preventDefault()
      popHistory(track)
    } else if (!to) {
      navigate(-1)
    }
  }

  return <Container>
    {to
      ? <Button className="backButton" onClick={handleClick} component={LinkBehavior} to={to}><ArrowBackIosIcon className="icon" /></Button>
      : <Button className="backButton" onClick={handleClick}><ArrowBackIosIcon className="icon" /></Button>
    }
  </Container>
}

export function BackToDashboard() {
  const {navigation} = useSession()
  return <BackButton to={navigation.page.link({pageName: 'dashboard'})} />
}