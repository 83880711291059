import Icon from 'components/Common/Icon'
import useTranslations from 'hooks/translations'
import FilterChip from 'components/Common/FilterChip'

export default function GroupsCount ({teachers}) {
    const {translate} = useTranslations()
    const groupsCount = teachers.reduce((count, teacher) => count + teacher.groups.length, 0)
    return (
      <div className="groups-count">
        <div className="icon">
          <Icon name='faUsers' />
        </div>
        <div className="count">
          {groupsCount}{" "}{translate(`group${groupsCount == 1 ? '' : 's'}_label`)}
        </div>
        <div style={{flexGrow: 1}} />
        <FilterChip name="pattern" />
      </div>
    )
}
